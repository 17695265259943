<template>
  <div>
    <nav id="nav_id" class="nav">
      <div class="inner">
        <div class="nav_top">
          <div class="inner">
            <h1 class="h1">
              <span>이용안내</span>
            </h1>
          </div>
        </div>
        <div class="nav_btm">
          <div class="inner">
            <div class="line dep01">
              <div class="inner">
                <ul>
                  <li><button v-on:click="$router.push({path: '/info/online'})"><span>수강신청 안내</span></button></li>
                  <li><button class="on"><span>지회지부 연락처</span></button></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <div class="lnb">
            <ul>
              <li><p class="home"></p></li>
              <li><p>이용안내</p></li>
              <li><p>지회지부 연락처</p></li>
            </ul>
          </div>
          <div class="page_ttl">
            <h1 class="h1">
              <span>지회지부 연락처</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="table_search">
              <div class="select">
                <select v-model="optionNo">
                  <option :value="-1">전체</option>
                  <option
                    v-for="item in optionList"
                    :key="item.branchNo"
                    :value="item.branchNo"
                  >{{ item.branchName }}</option>
                </select>
              </div>
              <div class="search_wrap">
                <div class="input">
                  <input type="search" placeholder="검색" v-model="params.searchText" v-on:keyup.enter="fetchData">
                </div>
                <button class="btn" v-on:click="fetchData">검색</button>
              </div>
            </div>
            <div class="rule_list">
              <ul class="rule_list_ul">
                <li class="rule_list_li" v-for="(item, index) in list" :key='index'>
                  <div class="rule_list_ttl">
                    <h3 class="h3">{{ item.branch.branchName }}</h3>
                  </div>
                  <div class="rule_list_con">
                    <div class="table">
                      <div class="row head">
                        <div class="cell flx150"><p>지회지부</p></div>
                        <div class="cell"><p>주소</p></div>
                        <div class="cell flx150"><p>대표번호</p></div>
                        <div class="cell"><p>이메일</p></div>
                        <div class="cell"><p>홈페이지</p></div>
                      </div>
                      <div class="page_con" v-show="item.branchDetail.length === 0">
                        <div class="empty_wrap">
                          <div class="empty">
                            <span>내역이 존재하지 않습니다.</span>
                          </div>
                        </div>
                      </div>
                      <div class="row" v-for="(data, index2) in item.branchDetail" :key="index2">
                        <div class="cell flx150"><p>{{ data.branchDetailName}}</p></div>
                        <div class="cell">
                          <p class="left">
                            {{ data.address }}&nbsp;{{ data.address2 }}
                          </p>
                        </div>
                        <div class="cell flx150"><p>{{ data.phone }}</p></div>
                        <div class="cell"><p>{{ data.email }}</p></div>
                        <div class="cell"><p>{{ data.homePage }}</p></div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'info-contact',
  description: '지회지부 연락처',
  data(){
    return {
      params: {
        branchNo: '',
        searchText: ''
      },
      optionList: {},
      branchOption: {
        branchNo: '',
        branchName: ''
      },
      list: [],
      optionNo: -1,
      branchTitle: '전체'
    }
  },
  async created() {
    // 검색 옵션
    const params = {}
    params.page = 1
    params.limit = -1
    const res2 = await this.$BranchSvc.branchList(params)
    this.optionList = res2.entity.list
    const res = await this.$BranchSvc.fetchBranchList(this.params)
    this.list = res.entity.list
  },
  methods: {
    fetchData: async function() {
      // 전체로 검색시 전체 리스트 보여주기
      this.params.branchNo = this.optionNo
      this.params.searchText = this.params.searchText
      console.log(this.params)
      const res = await this.$BranchSvc.fetchBranchList(this.params)
      console.log(res)
      this.list = res.entity.list
    }
  }
}
</script>

<style scoped>

</style>
