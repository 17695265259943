'use strict'
// import '@babel/polyfill'
import Vue from 'vue'
import 'babel-polyfill'
import store from '@/store'
import ConstCode from '../constants/ConstCode'
import VueScrollTo from 'vue-scrollto'

class Util {
  constructor () {
    this.store = store
    this.isSafari = this.isSafari()
  }
  isEmpty (value) {
    return (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length))
  }
  /**
   * 아이디 정규식 (땅땅기준)
   * @param value
   * @returns {boolean}
   */
  isIdValid(value) {
    if (this.isEmpty(value)) return false
    const regExp1 = /[0-9]/
    const regExp2 = /[a-zA-Z]/
    if (
      !regExp1.test(value) ||
      !regExp2.test(value) ||
      value.length < 6 ||
      value.length > 12
    ) {
      return false
    }
    return true
  }

  /**
   * 비밀번호 정규식 (땅땅기준)
   * 영문/숫자/특수문자 조합 6자리 이상
   * @param value
   * @returns {boolean}
   */
  isPasswordValid(value) {
    if (this.isEmpty(value)) return false
    const regExp1 = /[0-9]/
    const regExp2 = /[a-zA-Z]/
    // eslint-disable-next-line no-useless-escape
    const regExp3 = /['"~․!@#$%^&*()_\-+=\[\]\[\]|;:‘“<>,.?\\/{}]/
    if (
      !regExp1.test(value) ||
      !regExp2.test(value) ||
      !regExp3.test(value) ||
      value.length < 6 ||
      value.length > 50
    ) {
      return false
    }
    return true
  }

  /**
   * 연속된 비밀번호 사용인지 여부 판단
   * @param value
   * @returns {boolean}
   */
  isContinueousVaild (value) {
    let userPwPassed = true
    let SamePass0 = 0 // 동일문자 카운트
    let SamePass1 = 0 // 연속성(+) 카운드
    let SamePass2 = 0 // 연속성(-) 카운드
    for (let i = 0; i < value.length; i++) {
      let chrPass0
      let chrPass1
      let chrPass2
      if (i >= 2) {
        chrPass0 = value.charCodeAt(i - 2)
        chrPass1 = value.charCodeAt(i - 1)
        chrPass2 = value.charCodeAt(i)
        // 동일문자 카운트
        if ((chrPass0 === chrPass1) && (chrPass1 === chrPass2)) {
          SamePass0++
        } else {
          SamePass0 = 0
        }
        // 연속성(+) 카운드
        if (chrPass0 - chrPass1 === 1 && chrPass1 - chrPass2 === 1) {
          SamePass1++
        } else {
          SamePass1 = 0
        }
        // 연속성(-) 카운드
        if (chrPass0 - chrPass1 === -1 && chrPass1 - chrPass2 === -1) {
          SamePass2++
        } else {
          SamePass2 = 0
        }
      }

      if (SamePass0 > 0) {
        userPwPassed = false
      }
      if (SamePass1 > 0 || SamePass2 > 0) {
        alert('영문, 숫자는 3자 이상 연속으로 입력할 수 없습니다.')
        return false
      }
      if (!userPwPassed) {
        alert('동일 문자를 3자 이상 연속으로 입력할 수 없습니다.')
        return false
      }
    }
    return true
  }

  /**
   * 휴대폰 번호 정규식
   *  - 01012341234 형식 체크
   * @class Util
   */
  isPhoneValid (value) {
    if (this.isEmpty(value)) return false
    let regExp = /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/
    return (regExp.test(value))
  }
  /**
   * 핸드폰 하이픈(-) 포맷방식 출력 (type : 0일경우, 가운데 자리 **** 표시)
   * @param num
   * @param type
   * @returns {*|string}
   */
  getPhoneFommat (num, type = 1) {
    let formatNum = ''
    try {
      if (num.length === 11) {
        if (type === 0) {
          formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3')
        } else {
          formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
        }
      } else if (num.length === 8) {
        formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2')
      } else {
        if (num.indexOf('02') === 0) {
          if (num.length === 10) {
            if (type === 0) {
              formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3')
            } else {
              formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3')
            }
          } else {
            if (type === 0) {
              formatNum = num.replace(/(\d{2})(\d{3})(\d{4})/, '$1-****-$3')
            } else {
              formatNum = num.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3')
            }
          }
        } else {
          if (num.length === 10) {
            if (type === 0) {
              formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3')
            } else {
              formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
            }
          } else {
            if (type === 0) {
              formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-***-$3')
            } else {
              formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
            }
          }
        }
      }
    } catch (e) {
      formatNum = num
      console.log(e)
    }
    return formatNum
  }
  /**
   * 생년월일 번호 정규식
   *  - YYMMDD 형식 체크
   * @class Util
   */
  isBirthValid (value) {
    if (this.isEmpty(value)) return false
    let regExp = /^(?:[0-9]{4}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))$/
    return (regExp.test(value))
  }
  /**
   * 이메일 정규식
   *  - test123@test.com
   * @class Util
   */
  isEmailValid (value) {
    if (this.isEmpty(value)) return false
    // let regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
    /*
      세종 Email validation
     */
    let regExp = /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
    return (regExp.test(value))
  }

  /**
   * 만나이 계산
   *  - YYYYMMDD 형식 체크
   * @class Util
   */
  getBirthAge (birth) {
    let today = new Date()
    let age = today.getFullYear() - birth.getFullYear()
    birth.setFullYear(today.getFullYear())
    if (today < birth) age--
    return age
  }

  /**
   * 자리 뒤에 문자들 마스킹 처리
   * @param str
   * @param index
   * @returns {string}
   */
  setMasking (str, startIndex = str.length - 3) {
    if (this.isEmpty(str)) return ''
    const temp = str.substring(0, startIndex)
    const temp2 = str.substring(startIndex, str.length).replace(/\w/g, '*')
    // return str.replace(/(?<=.{3})./gi, '*');
    // str.replace(new RegExp('(?<=.{' + startIndex + '}).', 'gi'), '*');
    return `${temp}${temp2}`
  }


  /**
   * YYYYMMDD -> YYYY.MM.DD 형식으로 리턴
   * @param birth
   * @returns {string}
   */
  formatStringBirth (birth = '20010101') {
    if (birth.length !== 8) return ''
    return `${birth.substr(0,4)}.${birth.substr(4,2)}.${birth.substr(6,2)}`
  }

  /**
   * age가 되는 날짜를 구함
   *  - YYYYMMDD 형식 체크
   * @class Util
   */
  getAgeToDate (age, birth) {
    let years = birth.getFullYear() + age
    birth.setFullYear(years)
    return birth
  }

  getScrollTop () {
    const el = document.getElementById('wrap')
    return el.scrollTop
  }
  scrollByWrap (position, el) {
    el.scrollBy(0, position)
  }
  scrollToTop (elId, scrollDuration = 300) {
    let _this = this
    const el = document.getElementById(elId)
    let scrollStep = -el.scrollTop / (scrollDuration / 15)
    let scrollInterval =
      setInterval(function () {
        if (el.scrollTop !== 0) {
          _this.scrollByWrap(scrollStep, el)
          // el.scrollBy(0, scrollStep)
        } else {
          clearInterval(scrollInterval)
        }
      }, 12)
  }
  scrollToEl (el, scrollDuration = 300) {
    let _this = this
    let scrollStep = -el.scrollTop / (scrollDuration / 15)
    let scrollInterval =
      setInterval(function () {
        if (el.scrollTop !== 0) {
          _this.scrollByWrap(scrollStep, el)
          // el.scrollBy(0, scrollStep)
        } else {
          clearInterval(scrollInterval)
        }
      }, 12)
  }
  scrollLock (isLock = false) {
    document.body.className = ''
    if (isLock) document.body.className = 'scroll_lock'
  }

  isMobile () {
    let body = document.getElementsByTagName('body')
    return (body[0].clientWidth < 1000)
    // return (screen.width < 1000)
  }
  isScrollBar () {
    return (document.getElementById('app').scrollHeight === 0 && document.getElementById('app').clientHeight === 0) ||
      (document.getElementById('app').scrollHeight > document.getElementById('app').clientHeight)
  }

  isApp () {
    return (navigator.userAgent.indexOf(`${ConstCode.CODE_ENV_LIST.APP_SCHEMA}`) > -1)
  }
  isSafari () {
    return (/^((?!chrome|android).)*safari/i.test(navigator.userAgent.toLowerCase()))
  }
  isIPhone () {
    console.log((/^((?!chrome|android).)*iphone/i.test(navigator.userAgent.toLowerCase())))
    return (/^((?!chrome|android).)*iphone/i.test(navigator.userAgent.toLowerCase()))
  }
  formatNumber (value) {
    if ((this.isEmpty(value))) return 0
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  formatNumber2 (value, options) {
    if (this.isEmpty(value) || isNaN(value) || value === 0) return '-'
    let dl = options['decimalLength'] || 0
    let ts = options['thousandsSep'] || ','
    let ty = options['type'] || ''
    let ut = options['unit'] || ''

    if (ty !== '') value = value * 100
    value = parseFloat(value)
    let re = '\\d(?=(\\d{3})+' + (dl > 0 ? '\\D' : '$') + ')'
    let num = value.toFixed(Math.max(0, ~~dl))
    return `${num.replace(new RegExp(re, 'g'), '$&' + ts)}${ut}`
  }

  calcUnit (value, area) {
    return value / area
  }

  calcUnitCut (value, unit) {
    if (isNaN(value)) value = 0
    return Math.floor((value) / unit) * unit
  }

  formatDate (value, options = {flag: false}) {
    let text = ''
    let flag = options['flag'] || false
    if (value.length === 6) {
      text = flag ? `${value.substring(0, 4)}년 ${value.substring(4, 6)}월` : `${value.substring(0, 4)}-${value.substring(4, 6)}`
    } else if (value.length === 8) {
      text = flag ? `${value.substring(0, 4)}년 ${value.substring(4, 6)}월 ${value.substring(6, 8)}일` : `${value.substring(0, 4)}-${value.substring(4, 6)}-${value.substring(6, 8)}`
    } else if (value.length > 8) {
      text = value
    } else {
      text = '-'
    }
    return text
  }

  formattedDate(value) {
    // Create a Date object from the original date string
    const date = new Date(value);
    
    // Format the date and time
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    // Return formatted string
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  formatRpad (target, value, n) {
    return `${target}${Array(n - target.length + 1).join(value || '0')}`
  }
  formatNumberToPhone (value) {
    if (this.isEmpty(value)) return ''
    return value.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3')
  }

  formatBizNum(value) {
    if (this.isEmpty(value)) return ''
    return value.replace(/([0-9]{3})([0-9]{2})([0-9]{5})/, '$1-$2-$3')
  }

  formatLpad (target, value, n) {
    if (this.isEmpty(target)) return ''
    target = target.toString()
    console.log(`${Array(n - target.length + 1).join(value || '0')}${target}`)
    return `${Array(n - target.length + 1).join(value || '0')}${target}`
  }
  secondsToTimes (value, format = [':', ':', '']) {
    value = parseInt(value)
    let hour = Math.floor(value / 3600)
    let minutes = Math.floor((value - (hour * 3600)) / 60)
    let seconds = value - (hour * 3600) - (minutes * 60)
    // let covHour = this.formatLpad(hour, 0, '2')
    // let covMinutes = this.formatLpad(minutes, 0, '2')
    // let covSeconds = this.formatLpad(seconds, 0, '2')
    let covHour = hour.toString().padStart(2, '0')
    let covMinutes = minutes.toString().padStart(2, '0')
    let covSeconds = seconds.toString().padStart(2, '0')
    return `${covHour}${format[0]}${covMinutes}${format[1]}${covSeconds}${format[2]} `
    // if (this.isEmpty(format)) return `${this.formatLpad(hour, 0, '2')}:${this.formatLpad(minutes, 0, '2')}:${this.formatLpad(seconds, 0, '2')}`
  }
  secondsToTimes2(value, format = [':', '']) {
    value = parseInt(value)
    const hour = Math.floor(value / 3600)
    const minutes = Math.floor((value - hour * 3600) / 60)
    const seconds = value - hour * 3600 - minutes * 60
    const covHour = hour * 60
    let covMinutes = ''
    const sum = minutes + covHour
    if (sum.length > 2) {
      // covMinutes = this.formatLpad(sum, 0, '3')
      covMinutes = sum.toString().padStart(3, '0')
    } else {
      // covMinutes = this.formatLpad(sum, 0, '2')
      covMinutes = sum.toString().padStart(2, '0')
    }
    // const covSeconds = this.formatLpad(seconds, 0, '2')
    const covSeconds = seconds.toString().padStart(2, '0')
    return `${covMinutes}${format[0]}${covSeconds}${format[1]} `
  }
  convertPyeong (value, flag = false) {
    if (this.isEmpty(value)) return 0
    return flag ? value / 3.305785 : value * 3.305785
  }
  fnConvertSssan (val) {
    return val === '1' ? '' : '산'
  }
  // 현재 url 공유
  intentShareUrl () {
    let currentUrl = location.href
    this.nativeShareText(currentUrl)
  }


  isValid (v, el) {
    v.$touch()
    let isInvalid = v.$invalid
    if (isInvalid) {
      let options = {
        // container: '#scroll_top',
        offset: -80,
        easing: 'ease',
        duration: 500,
        onDone: function (el) {
          // let msg = el.getAttribute('msg') || '필수 입력 항목 입니다.'
          // alert(msg)
          el = el.querySelector('input')
          if (el) {
            setTimeout(() => {
              el.focus()
            }, 100)
          }
        }
      }
      setTimeout(() => {
        VueScrollTo.scrollTo(el.querySelector('.form-group--error'), 500, options)
      }, 100)
      return false
    }
    return true
  }

  // api.ipify.org을 통해 public ip를 가져옴
  // getPublicIp () {
  //   // return fetch('https://api.ipify.org?format=json')
  //   return new Promise((resolve, reject) => {
  //     let xhr = new XMLHttpRequest()
  //     xhr.open('GET', 'https://api.ipify.org?format=jsonp=', true)
  //     xhr.onreadystatechange = () => {
  //       if (xhr.readyState === 4 && xhr.status === 200) {
  //         resolve(xhr.responseText)
  //       }
  //     }
  //     xhr.send(null)
  //   })
  // }

  toastAlert (Vue, msg, duration = 4000) {
    this.toastConfirm(Vue, msg, [{text: '닫기', callBack: ''}], duration)
  }

  toastConfirm (Vue, msg, args, duration = 8000) {
    let actions = []
    args.forEach((item) => {
      let map = {}
      map['text'] = item.text
      map['onClick'] = (e, toastObject) => typeof item.callBack !== 'function' ? toastObject.goAway(0) : item.callBack(e, toastObject)
      actions.push(map)
    })
    Vue.toasted.clear()
    Vue.toasted.show(msg, {
      action: actions,
      duration: duration
    })
  }

  /**
   * 모달 confirm 창 (땅땅용)
   * @param message
   * @param callBack
   * @param confirmLabel
   * @param cancelLabel
   */
  modalConfirm (message = '', callBack = () => {}, confirmLabel = '확인', cancelLabel = '취소') {
    console.log(confirmLabel)
    store.commit('layout/setModalAlert',
      {modalAlertName: 'modal-alert-confirm', isHideHeader: false, data: { message }, callBack, confirmLabel, cancelLabel }
    )
  }

  /**
   * 모달 alert 창 (땅땅용)
   * @param message
   */
  modalAlert (message = '', callBack = () => {}) {
    store.commit('layout/setModalAlert',
      {modalAlertName: 'modal-alert-alert', isHideHeader: false, data: { message }, callBack }
    )
  }

  maxLengthCheck (object) {
    if (object.value.length > object.maxLength) {
      object.value = object.value.slice(0, object.maxLength)
    }
  }

  getGender (gender = -1) {
    return gender === 0 ? '암컷' : (gender === 1 ? '수컷' : '무성')
  }
  /**
   * 디바이스 정보 native call
   * @param callBack(콜백 받을 functiuon name)
   * @return callBack(deviceTypeID, deviceID, registrationKey, isFirst)
   * @class Util
   */
  nativeGetRegistrationKey (callBack) {
    location.href = `native://action.${ConstCode.CODE_ENV_LIST.APP_SCHEMA}?cmd=getRegistrationKey&callback=${callBack}`
  }

  /**
   * 기본 텍스트 공유
   * @param data(Intent 공유할 메세지 혹은 url)
   * @return ''
   * @class Util
   */
  nativeShareText (data) {
    location.href = `native://action.${ConstCode.CODE_ENV_LIST.APP_SCHEMA}?cmd=shareText&callback=CALLBACK&content=${encodeURIComponent(data)}`
    return ''
  }

  /**
   * 기본 텍스트 공유
   * @param data(Intent 공유할 메세지 혹은 url)
   * @return ''
   * @class Util
   */
  nativeShareUrl (data) {
    data += '&rUrl=/main/map'
    location.href = `native://action.${ConstCode.CODE_ENV_LIST.APP_SCHEMA}?cmd=shareUrl&url=${encodeURIComponent(data)}`
    return ''
  }

  /**
   * 카카오 공유
   * @param data(Intent 공유할 메세지 혹은 url)
   * @return ''
   * @class Util
   */
  nativeShareKaKao (data) {
    data += '&rUrl=/main/map'
    location.href = `native://action.${ConstCode.CODE_ENV_LIST.APP_SCHEMA}?cmd=shareKaKao&callback=CALLBACK&url=${encodeURIComponent(data)}`
    return ''
  }

  /**
   * SHOW SMS
   * @param data(휴대폰번호)
   * @return ''
   * @class Util
   */
  nativeShowSms (data) {
    location.href = `native://action.${ConstCode.CODE_ENV_LIST.APP_SCHEMA}?cmd=showSms&callback=CALLBACK&data=${data}`
  }

  /**
   * 프린트 인쇄
   * @param
   * @return ''
   * @class Util
   */
  nativePrint () {
    location.href = `native://action.${ConstCode.CODE_ENV_LIST.APP_SCHEMA}?cmd=startPrint`
  }

  /**
   * 프린트 인쇄 refresh
   * @param
   * @return ''
   * @class Util
   */
  nativeRefresh () {
    location.href = `native://action.${ConstCode.CODE_ENV_LIST.APP_SCHEMA}?cmd=refresh`
  }

  /**
   * GET CURRENT LOCATE
   * @return ''
   * @class Util
   */
  nativeGetLocationWithLatLng (_callBack, isUpdateList = true) {
    window.nativeCallBackLocationWithLatLng = (code, lat, lng) => {
      const callBack = (isSetting) => {
        if (isSetting) {
          Vue.prototype.$Util.nativeLocationGpsSetting(_callBack)
        } else if(isUpdateList) {
          _callBack(isSetting)
        }
      }
      if (Number(code) < 1) {
        store.commit('layout/setModalAlert', {
          modalAlertName: Vue.prototype.$modalAlertNames.LOCATIONINFO, isHideHeader: false,
          callBack: callBack
        })
      } else {
        _callBack(true, lat, lng)
      }
    }
    location.href = `native://action.${ConstCode.CODE_ENV_LIST.APP_SCHEMA}?cmd=getLocationWithLatLng&callback=nativeCallBackLocationWithLatLng`
  }

  /**
   * GPS 설정 call
   * @return ''
   * @class Util
   */
  nativeLocationGpsSetting (callBack) {
    location.href = `native://action.${ConstCode.CODE_ENV_LIST.APP_SCHEMA}?cmd=gpsSetting&callBack=${callBack}`
  }

  /**
   * 강제 인트로 HIDE
   * @return ''
   * @class Util
   */
  nativeHideIntro () {
    location.href = `native://action.${ConstCode.CODE_ENV_LIST.APP_SCHEMA}?cmd=hideIntro`
  }

  /**
   * 앱 종료
   * @return ''
   * @class Util
   */
  nativeFinish () {
    location.href = `native://action.${ConstCode.CODE_ENV_LIST.APP_SCHEMA}?cmd=finish`
  }

  /**
   * 외부링크 URL 호출시
   * @return ''
   * @class Util
   */
  nativeLink (link) {
    location.href = `native://action.${ConstCode.CODE_ENV_LIST.APP_SCHEMA}?cmd=browse&url=${encodeURIComponent(link)}`
  }

  /**
   * (shoppingValue) orderRequestNo로 주문시간 구하기
   * @param orderKey
   * @returns {string | *}
   */
  parseOrderKey (orderKey = '') {
    if (orderKey.length > 0) return orderKey.substr(2, 14)
    else return orderKey
  }

  /**
   *  call
   * @return ''
   * @class Util
   */
  nativeTel (tel) {
    location.href = `tel:${tel}`
  }

  /**
   * Object -> queryString 변환
   * @param obj
   * @returns {string}
   */
  objectToQuerystring (obj) {
    return Object.keys(obj).reduce(function (str, key, i) {
      let delimiter, val
      delimiter = (i === 0) ? '?' : '&'
      key = encodeURIComponent(key)
      val = encodeURIComponent(obj[key])
      return [str, delimiter, key, '=', val].join('')
    }, '')
  }

  calcCrow (lat1, lon1, lat2, lon2) {
    const R = 6371; // km
    const _lat1 = this.toRad(lat1)
    const _lat2 = this.toRad(lat2)
    const dLat = this.toRad(lat2-lat1)
    const dLon = this.toRad(lon2-lon1)


    const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(_lat1) * Math.cos(_lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
    const d = R * c
    return d
  }

  // Converts numeric degrees to radians
  toRad (Value) {
    return Value * Math.PI / 180
  }

}
export default new Util()
