var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('info-tab-index'),_c('main',{staticClass:"main",attrs:{"role":"main"}},[_c('div',{staticClass:"page"},[_c('div',{staticClass:"inner"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"page_con"},[_c('div',{staticClass:"rule_list"},[_c('ul',{staticClass:"rule_list_ul"},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('li',{staticClass:"rule_list_li"},[_vm._m(9),_c('div',{staticClass:"rule_list_con"},[_c('div',{staticClass:"table"},[_vm._m(10),_c('div',{staticClass:"row"},[_vm._m(11),_c('div',{staticClass:"col"},[_c('div',{staticClass:"cell"},[_c('p',[_vm._v(_vm._s(`${_vm.$Util.formatNumber(_vm.codeList[6].price)}원`))])])]),_vm._m(12),_c('div',{staticClass:"col"},[_c('div',{staticClass:"cell"},[_c('p',[_vm._v(_vm._s(`${_vm.$Util.formatNumber(_vm.codeList[7].price)}원`))])])]),_vm._m(13),_c('div',{staticClass:"col"},[_c('div',{staticClass:"cell"},[_c('p',[_vm._v(_vm._s(`${_vm.$Util.formatNumber(_vm.codeList[8].price)}원`))])])])]),_c('div',{staticClass:"row"},[_vm._m(14),_c('div',{staticClass:"col"},[_c('div',{staticClass:"cell"},[_c('p',[_vm._v(_vm._s(`${_vm.$Util.formatNumber(_vm.codeList[9].price)}원`))])])]),_vm._m(15),_c('div',{staticClass:"col"},[_c('div',{staticClass:"cell"},[_c('p',[_vm._v(_vm._s(`${_vm.$Util.formatNumber(_vm.codeList[10].price)}원`))])])]),_vm._m(16),_c('div',{staticClass:"col"},[_c('div',{staticClass:"cell"},[_c('p',[_vm._v(_vm._s(`${_vm.$Util.formatNumber(_vm.codeList[11].price)}원`))])])])])])])]),_vm._m(17)])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lnb"},[_c('ul',[_c('li',[_c('p',{staticClass:"home"})]),_c('li',[_c('p',[_vm._v("고객센터")])]),_c('li',[_c('p',[_vm._v("교육안내")])]),_c('li',[_c('p',[_vm._v("집합 위생교육 안내")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page_ttl"},[_c('h1',{staticClass:"h1"},[_c('span',[_vm._v("집합 위생교육 안내")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"rule_list_li"},[_c('div',{staticClass:"rule_list_ttl"},[_c('h3',{staticClass:"h3"},[_vm._v("근거규정")])]),_c('div',{staticClass:"rule_list_con"},[_c('p',[_vm._v("영업자를 대상으로 한 식품위생교육은 다음과 같은 근거규정을 실천합니다.")]),_c('ol',[_c('li',[_c('span',[_vm._v(" 식품위생법 제41조 제2항(법 제 88조 제8항에 따라 준용되는 경우 포함)"),_c('br'),_vm._v(" 제 36조 제1항의 각 호에 따른 영업을 하려는 자는 미리 식품 위생교육을 받아야 한다. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"rule_list_li"},[_c('div',{staticClass:"rule_list_ttl"},[_c('h3',{staticClass:"h3"},[_vm._v("교육 대상자")])]),_c('div',{staticClass:"rule_list_con"},[_c('p',[_vm._v(" 협회 회원 중, 신규영업자 또는 기존영업자 및 그의 식품위생관리책임자에 해당하며 상기 집합 위생교육을 수강하려는 자 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"rule_list_li"},[_c('div',{staticClass:"rule_list_ttl"},[_c('h3',{staticClass:"h3"},[_vm._v("해당 업종")])]),_c('div',{staticClass:"rule_list_con"},[_c('p',[_vm._v("일반음식점, 집단급식소, 위탁급식업")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"rule_list_li"},[_c('div',{staticClass:"rule_list_ttl"},[_c('h3',{staticClass:"h3"},[_vm._v("교육 기간")])]),_c('div',{staticClass:"rule_list_con"},[_c('p',[_vm._v("지역별, 교육기관 별 지정 집합 교육일")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"rule_list_li"},[_c('div',{staticClass:"rule_list_ttl"},[_c('h3',{staticClass:"h3"},[_vm._v("교육 시간")])]),_c('div',{staticClass:"rule_list_con"},[_c('div',{staticClass:"table"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col head"},[_c('div',{staticClass:"cell"},[_c('p',[_vm._v("신규영업자")])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"cell"},[_c('p',{staticClass:"left"},[_vm._v("6시간")])])]),_c('div',{staticClass:"col head"},[_c('div',{staticClass:"cell"},[_c('p',[_vm._v("기존영업자")])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"cell"},[_c('p',{staticClass:"left"},[_vm._v("3시간")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"rule_list_li"},[_c('div',{staticClass:"rule_list_ttl"},[_c('h3',{staticClass:"h3"},[_vm._v("교육 장소")])]),_c('div',{staticClass:"rule_list_con"},[_c('p',[_vm._v("교육장 시설을 갖춘 교통이 편리한 곳")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"rule_list_li"},[_c('div',{staticClass:"rule_list_ttl"},[_c('h3',{staticClass:"h3"},[_vm._v("교육신청방법")])]),_c('div',{staticClass:"rule_list_con"},[_c('img',{attrs:{"src":require("@/assets/static/images/img_steps02.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rule_list_ttl"},[_c('h3',{staticClass:"h3"},[_vm._v("집합 교육 수강료")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row head"},[_c('div',{staticClass:"cell"},[_c('p',[_vm._v("일반음식점")])]),_c('div',{staticClass:"cell"},[_c('p',[_vm._v("집단급식소")])]),_c('div',{staticClass:"cell"},[_c('p',[_vm._v("위탁급식업")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col head"},[_c('div',{staticClass:"cell"},[_c('p',[_vm._v("신규 영업자")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col head"},[_c('div',{staticClass:"cell"},[_c('p',[_vm._v("신규 영업자")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col head"},[_c('div',{staticClass:"cell"},[_c('p',[_vm._v("신규 영업자")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col head"},[_c('div',{staticClass:"cell"},[_c('p',[_vm._v("기존 영업자")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col head"},[_c('div',{staticClass:"cell"},[_c('p',[_vm._v("기존 영업자")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col head"},[_c('div',{staticClass:"cell"},[_c('p',[_vm._v("기존 영업자")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"rule_list_li"},[_c('div',{staticClass:"rule_list_ttl"},[_c('h3',{staticClass:"h3"},[_vm._v("결제방법")])]),_c('div',{staticClass:"rule_list_con"},[_c('div',{staticClass:"pay_way small"},[_c('div',{staticClass:"pay_way_card"},[_c('img',{attrs:{"src":require("@/assets/static/images/img_creditcard.svg"),"alt":""}}),_c('div',{staticClass:"txt"},[_c('h4',{staticClass:"h4"},[_vm._v("신용카드 결제")]),_c('p',[_vm._v("ISP/안심클릭")])])]),_c('div',{staticClass:"pay_way_card"},[_c('img',{attrs:{"src":require("@/assets/static/images/img_phone.svg"),"alt":""}}),_c('div',{staticClass:"txt"},[_c('h4',{staticClass:"h4"},[_vm._v("휴대폰 결제")]),_c('p',[_vm._v("본인명의 휴대폰으로 결제")])])]),_c('div',{staticClass:"pay_way_card"},[_c('img',{attrs:{"src":require("@/assets/static/images/img_bank.svg"),"alt":""}}),_c('div',{staticClass:"txt"},[_c('h4',{staticClass:"h4"},[_vm._v("가상계좌")]),_c('p',[_vm._v("발급된 가상계좌로 송금")])])]),_c('div',{staticClass:"pay_way_card"},[_c('img',{attrs:{"src":require("@/assets/static/images/img_livecount.svg"),"alt":""}}),_c('div',{staticClass:"txt"},[_c('h4',{staticClass:"h4"},[_vm._v("실시간계좌이체")]),_c('p',[_vm._v("실시간 계좌이체로 결제")])])])])])])
}]

export { render, staticRenderFns }