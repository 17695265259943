<template>
  <infinite-loading spinner="waveDots" @infinite="fnPage">
    <div slot="no-more"></div>
    <div slot="no-results"></div>
  </infinite-loading>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
export default {
  name: 'base-list-scroll-page',
  props: {
    isActive: {
      description: '컴포넌트 활성 여부',
      type: [Boolean],
      default: () => false
    },
    listFunction: {
      description: '리스트 조회 function',
      type: [Function],
      required: true
    },
    page: {
      description: '컴포넌트 활성 여부',
      type: [Number],
      default: () => 0
    },
    endPage: {
      description: '컴포넌트 활성 여부',
      type: [Number],
      default: () => 1
    },
    currentIndex: {
      description: '현재 인덱스',
      type: [Number],
      default: () => 1
    },
    resetIndex: {
      description: '값이 변경되면 reset 실행',
      type: [Number],
      default: () => 1
    }
  },
  components: {
    'infinite-loading': InfiniteLoading
  },
  data: function () {
    return {
      list: [],
      pageLoading: {},
      pageInfo: {
        page: 0,
        endPage: 1000
      },
      infiniteId: new Date().getTime() + 1
    }
  },
  watch: {
    isActive: function (isActive) {
      if (isActive) {
        this.pageLoading.reset()
      }
    },
    page: function (val) {
      if (val < 0) return
      this.pageInfo.page = val
      this.pageInfo.endPage = this.endPage
      this.pageLoading.reset()
      this.fnPage(this.pageLoading)
    },
    currentIndex: function() {
      this.pageInfo.page = 0
      this.pageInfo.endPage = this.endPage
      this.pageLoading.reset()
      this.fnPage(this.pageLoading)
    },
    resetIndex : function() {
      this.pageInfo.page = 0
      this.pageInfo.endPage = this.endPage
      this.pageLoading.reset()
      this.fnPage(this.pageLoading)
    }
  },
  methods: {
    fnPage: function ($state) {
      this.pageLoading = $state
      if (!this.isActive) return
      if (this.pageInfo.page >= this.pageInfo.endPage) { this.pageLoading.complete(); this.$emit('emptyFetch'); return }
      let page = ++this.pageInfo.page
      this.fetchList($state, page)
    },
    fetchList: function ($state = '', page = 1) {
      let params = {}
      params['page'] = page
      this.$store.commit('layout/setNoneLoading', {isNoneLoading: true})
      this.listFunction(params).then(({entity}) => {
        let dataList = entity.list
        this.pageInfo.endPage = Number(entity.pageInfo.totalPage)
        this.pageInfo.page = Number(entity.pageInfo.page)
        this.pageInfo.totalRow = Number(entity.pageInfo.totalRow)
        this.$store.commit('layout/setNoneLoading', {isNoneLoading: false})
        this.pageLoading.reset()
        if ($state && page > 1) {
          if (this.$Util.isEmpty(dataList)) { this.pageLoading.complete(); this.$emit('emptyFetch'); return }
          this.list.push(...dataList)
          this.pageLoading.loaded()
        } else {
          this.list = dataList
        }
        this.$emit('finishFetch', this.list, this.pageInfo)
      })
    }
  }
}
</script>

<style scoped>

</style>
