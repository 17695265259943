'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
/**
 * 공통 API Service
 * @class AuthSvc
 */
class AuthSvc extends ServiceExec {

  /**
   *  인증번호 확인
   *  @param authPhone {string} authPhone
   *  @param userPhone {string} userPhone
   */
  match(params = {}) {
    return this
      .get(`/api/auth/match`, params)
      .then(response => {
        return response.data
      })
  }
  /**
   *  인증번호 발송
   *  @param type {integer} type
   *  @param userNo {integer} userNo
   *  @param userPhone {string} userPhone
   */
  send(params = {}) {
    return this
      .get(`/api/auth/send`, params)
      .then(response => {
        return response.data
      })
  }  
  /**
  *  인증번호 발송 (휴대폰 변경만)
  *  @param userNo {integer} userNo
  *  @param name {string} name
  *  @param userPhone {string} userPhone
  */
 sendForPhoneChange(params = {}) {
   return this
     .get(`/api/auth/sendForPhoneChange`, params)
     .then(response => {
       return response.data
     })
 }

}
export default new AuthSvc()
