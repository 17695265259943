<template>
  <div class="modal_dim" v-bind:class="{ 'on': isShowAlert}" style="z-index: 20;"><!--작은팝업-->
    <div class="modal_wrap" v-if="!$Util.isEmpty(modalAlertName)">
      <component v-bind:is="modalAlertName"
                 v-bind:data="data"
                 v-bind:zIndex="zIndex"
                 v-bind:userData="userData"
                 v-bind:callBack="callBack"
                 v-bind:confirmLabel="confirmLabel"
                 v-on:eventMoveRoute="fnMoveRoute"
                 v-on:eventClose="fnClose"
                 v-on:close="fnMainPopupClose"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'modal-alert-container',
    props: {
      modalAlertName: {
        type: [Object, String],
        description: 'modal component 명'
      },
      zIndex: {
        type: Number,
        default: 2100
      },
      data: {
        type: Object
      },
      modalAlertTitle: {
        type: [String],
        description: 'modal 타이틀',
        default: () => ''
      },
      callBack: {
        type: Function,
        description: 'callBack function'
      },
      confirmLabel: {
        type: [String],
        default: '확인'
      }
    },
    data: function () {
      return {
        query: this.$route.path,
        userData: {}
      }
    },
    mounted() {
      const route = this.$_.clone(this.$route)
      if (!route.hash.match('#ma')) {
        this.isBack = true
        route.hash = "#ma"
        this.$router.push(route)
      }
      setTimeout(() => {
        this.$store.commit('layout/setIsShowModalAlert', {isShowAlert: true})
      }, 10)
    },
    computed: {
      isShowAlert: function () {
        return this.$store.getters['layout/getIsShowModalAlert']
      }
    },
    watch: {
      '$route.hash': function (val) {
        if (!val) this.$emit('close')
      }
    },
    methods: {
      fnClose: function (go = -1) {
        this.$router.go(go)
        this.$emit('close')
      },
      // 홈페이지 유지보수 개발요청안 | 메인 팝업 닫는 형식 변경 (서남아이에스 임현아, 2024-07-01)
      fnMainPopupClose: function () {
        this.$emit('close')
      },
      fnMoveRoute: function (path = '/', query = {}) {
        this.$emit('close')
        setTimeout(() => {
          this.$router.push({
            path: path,
            query: query
          })
        }, 300)
      }
    }
  }
</script>

<style lang="scss" scoped>
  /*팝업 on*/
  .pop_all {
    z-index: 100;
    position: fixed;
    top: 100vh;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: white;
    transition:top 0.5s;
    .header {
      top:-10rem;
      transition:top 0.8s;
    }
    &.on {
      top:0;
      transition:top 0.5s;
      .header {
        top:0;
        transition:top 0.8s;
      }
    }
  }
</style>
