'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
/**
 * 공통 API Service
 * @class SiteSvc
 */
class SiteSvc extends ServiceExec {

  /**
   *  관련 사이트 리스트
   *  @param limit {integer} 10
   *  @param page {integer} 1
   */
  fetchSiteList(params = {}) {
    return this.get(`/api/site/fetchSiteList`, params)
      .then(response => {
        return response.data
      })
  }

}
export default new SiteSvc()
