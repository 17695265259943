<template>
  <header class="header" v-show="layoutState.isHeader && !isHideHeader" :key="layoutState.titleName">
    <div class="inner">
      <div class="header_top">
        <div class="inner">
          <div class="btn_wrap">
            <p v-show="!$Util.isEmpty(userData)" style="padding: 5px 10px;">로그인 만료 까지 <span style="font-weight: 700;">{{ Math.round(timeout / 60) }}</span>분 남았습니다.</p>
            <button class="btn bo grey" @click="fnHome">협회 홈페이지 바로가기</button>
            <!--로그인 전-->
            <button v-show="$Util.isEmpty(userData)" class="btn bo grey" v-on:click="fnSignUp">회원가입</button>
            <button v-show="$Util.isEmpty(userData)" class="btn bo grey" v-on:click="fnView('/login')">로그인</button>
            <!--로그인 후-->
            <button class="btn bo" v-show="!$Util.isEmpty(userData)" v-on:click="fnView('/mypage')">마이페이지</button>
            <button class="btn bo grey" v-show="!$Util.isEmpty(userData)" @click="fnLogout">로그아웃</button>
          </div>
        </div>
      </div>
      <div class="header_btm">
        <div class="inner">
          <h1 class="logo">
            <span>한국외식산업협회</span>
            <a v-on:click="fnView('/main')"><img src="@/assets/static/images/logo_type01.svg" alt=""></a>
          </h1>
          <!--gnb-->
          <div class="gnb">
            <ul class="gnb_ul">
              <li class="gnb_li">
                <div class="gnb_ttl">
                  <a href="#"><h2 class="h2">기존영업자</h2></a>
                </div>
                <div class="gnb_hover">
                  <ul class="gnb_hover_ul">
                    <li class="gnb_hover_li">
                      <a href="" v-on:click="fnOnOff('/apply/online', 2, 1,1,1)"><p class="gnb_hover_ttl">일반음식점</p></a>
                      <ul>
                        <li><a href="" v-on:click="fnOnOff('/apply/online', 2, 1,1,1)"><p>교육신청</p></a></li>
                        <!-- <li><a href="" v-on:click="fnList('/apply/list',2,1,1,2)"><p>결제내역</p></a></li> -->
                        <li><a href="" v-on:click="fnList('/apply/list',2,1,1,2)"><p>신청내역</p></a></li>
                        <li><a href="" v-on:click="fnList('/payment',2,1,1,3)"><p>영수증 출력</p></a></li>
                      </ul>
                    </li>
                    <li class="gnb_hover_li">
                      <a href="" v-on:click="fnOnOff('/apply/online', 2, 2,1,1)"><p class="gnb_hover_ttl">집단급식소</p></a>
                      <ul>
                        <li><a href="" v-on:click="fnOnOff('/apply/online', 2, 2,1,1)"><p>교육신청</p></a></li>
                        <!-- <li><a href="" v-on:click="fnList('/apply/list',2,2,1,2)"><p>결제내역</p></a></li> -->
                        <li><a href="" v-on:click="fnList('/apply/list',2,2,1,2)"><p>신청내역</p></a></li>
                        <li><a href="" v-on:click="fnList('/payment',2,2,1,3)"><p>영수증 출력</p></a></li>
                      </ul>
                    </li>
                    <li class="gnb_hover_li">
                      <a href="" v-on:click="fnOnOff('/apply/online', 2, 3,1,1)"><p class="gnb_hover_ttl">위탁급식업</p></a>
                      <ul>
                        <li><a href="" v-on:click="fnOnOff('/apply/online', 2, 3,1,1)"><p>교육신청</p></a></li>
                        <!-- <li><a href="" v-on:click="fnList('/apply/list',2,3,1,2)"><p>결제내역</p></a></li> -->
                        <li><a href="" v-on:click="fnList('/apply/list',2,3,1,2)"><p>신청내역</p></a></li>
                        <li><a href="" v-on:click="fnList('/payment',2,3,1,3)"><p>영수증 출력</p></a></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </li>
              <!--//menu 2-->
              <!--menu 3-->
              <li class="gnb_li">
                <div class="gnb_ttl">
                  <a href="#"><h2 class="h2">신규영업자</h2></a>
                </div>
                <div class="gnb_hover">
                  <ul class="gnb_hover_ul">
                    <li class="gnb_hover_li">
                      <a href="" v-on:click="fnOnOff('/apply/offline', 1, 1, 2,1)"><p class="gnb_hover_ttl">일반음식점</p></a>
                      <ul>
                        <li><a href="" v-on:click="fnOnOff('/apply/offline', 1, 1, 2,1)"><p>교육신청</p></a></li>
                        <!-- <li><a href="" v-on:click="fnList('/apply/list',1,1,2,2)"><p>결제내역</p></a></li> -->
                        <li><a href="" v-on:click="fnList('/apply/list',1,1,2,2)"><p>신청내역</p></a></li>
                        <li><a href="javascript:return false;" v-on:click="fnCalender(1, 1, 2)"><p>교육 일정 변경</p></a></li>
                        <li><a href="" v-on:click="fnPrint('/eduprint',1,1,2,3)"><p>교육접수증 출력</p></a></li>
                        <li><a href="" v-on:click="fnPrint('/payment',1,1,2,4)"><p>영수증 출력</p></a></li>
                      </ul>
                    </li>
                    <li class="gnb_hover_li">
                      <a href="" v-on:click="fnOnOff('/apply/offline', 1, 2, 2,1)"><p class="gnb_hover_ttl">집단급식소</p></a>
                      <ul>
                        <li><a href="" v-on:click="fnOnOff('/apply/offline', 1, 2, 2,1)"><p>교육신청</p></a></li>
                        <!-- <li><a href="" v-on:click="fnList('/apply/list',1,2,2,2)"><p>결제내역</p></a></li> -->
                        <li><a href="" v-on:click="fnList('/apply/list',1,2,2,2)"><p>신청내역</p></a></li>
                        <li><a href="javascript:return false;" v-on:click="fnCalender(2, 1, 2)" ><p>교육 일정 변경</p></a></li>
                        <li><a href="" v-on:click="fnPrint('/eduprint',1,2,2,3)"><p>교육접수증 출력</p></a></li>
                        <li><a href="" v-on:click="fnPrint('/payment',1,2,2,4)"><p>영수증 출력</p></a></li>
                      </ul>
                    </li>
                    <li class="gnb_hover_li">
                      <a href="" v-on:click="fnOnOff('/apply/offline', 1, 3,2,1)"><p class="gnb_hover_ttl">위탁급식업</p></a>
                      <ul>
                        <li><a href="" v-on:click="fnOnOff('/apply/offline', 1, 3,2,1)"><p>교육신청</p></a></li>
                        <!-- <li><a href="" v-on:click="fnList('/apply/list',1,3,2,2)"><p>결제내역</p></a></li> -->
                        <li><a href="" v-on:click="fnList('/apply/list',1,3,2,2)"><p>신청내역</p></a></li>
                        <li><a href="javascript:return false;" v-on:click="fnCalender(3, 1, 2)"><p>교육 일정 변경</p></a></li>
                        <li><a href="" v-on:click="fnPrint('/eduprint',1,3,2,3)"><p>교육접수증 출력</p></a></li>
                        <li><a href="" v-on:click="fnPrint('/payment',1,3,2,4)"><p>영수증 출력</p></a></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </li>
              <!--//menu 3-->
              <!--menu 5-->
              <li class="gnb_li">
                <div class="gnb_ttl">
                  <a href=""><h2 class="h2">나의 학습방</h2></a>
                </div>
                <div class="gnb_hover">
                  <ul class="gnb_hover_ul">
                    <li class='gnb_hover_li'>
                      <ul>
                        <li>
                          <a href="" v-on:click="fnView('/mystudy/list')"><p>수강하기</p></a>
                        </li>
                        <li>
                          <a href="" v-on:click="fnView('/mystudy/print')"><p>수료증 출력</p></a>
                        </li>
                        <li>
                          <a href="" v-on:click="fnView('/mystudy/confirmation')"><p>지정확인서 제출</p></a>
                        </li>
                        <li>
                          <a href="" v-on:click="fnView('/mystudy/replay/list')"><p>복습하기</p></a>
                        </li>
                        <li>
                          <a href="" v-on:click="fnBoard('/board', 1)"><p>강의자료</p></a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </li>
              <!--//menu 5-->
              <!--menu 6-->
              <!--//menu 6-->
              <!--menu 7-->
              <li class="gnb_li">
                <div class="gnb_ttl">
                  <a href=""><h2 class="h2">고객센터</h2></a>
                </div>
                <div class="gnb_hover">
                  <ul class="gnb_hover_ul">
                    <li class="gnb_hover_li">
                    <ul>
                      <li >
                        <a href="" v-on:click="fnInfo('/board', 6, -1)"><p>공지사항</p></a>
                      </li>
                      <li>
                        <a href="" v-on:click="fnInfo('/board', 7, -1)"><p>알림/소식</p></a>
                      </li>
                        <li >
                          <a href="" v-on:click="fnInfo('/info/online', 1 , 1)"><p>교육안내</p></a>
                        </li>
                        <li >
                          <a href="" v-on:click="fnInfo('/info/board',2, -1)"><p>자주하는 질문</p></a>
                        </li>
                        <!-- 고객센터 목차명 변경 및 숨김처리 요청 1:1 게시판/신고센터로 통합, 원격지원 숨김 (서남아이에스 임현아, 2024-08-30) -->
                        <li >
                          <a href="" v-on:click="fnInfo('/support/board', 3, -1)"><p>1:1 게시판 / <br/> 신고센터</p></a>
                        </li>
                        <!-- <li >
                          <a href="" v-on:click="fnInfo('/support/board', 3, -1)"><p>1:1 게시판</p></a>
                        </li> -->
                        <!-- <li>
                          <a href="" v-on:click="fnInfo('/report/board', 4, -1)"><p>신고센터</p></a>
                        </li> -->
                        <li>
                          <!-- <a href="" v-on:click="fnInfo('/support/remote', 5, -1)"><p>원격지원</p></a> -->
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </li>
              <!--//menu 7-->
            </ul>
          </div>
          <!--//gnb-->
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: 'HeaderBar',
  props: {
    layoutState: {
      type: [Object],
      description: 'layout(헤더, 푸터 등) 상태'
    }
  },
  data: function() {
    return {
      searchText: '',
      count: 0,
      recipeType: this.$route.query.type,
      isPhone: '',
      form: {},
      salesParams: {},
      salesList: {}
    }
  },
  watch: {
    text: function(val) {
      this.searchText = val
    }
  },
  mounted() {
    this.count = 0
    const el = document.getElementsByClassName('detail_btns')
    for (let item of el) {
      for (let data of item.getElementsByTagName('button')) {
        if (data) this.count++
      }
      console.log(this.count)
    }
    this.isPhone = /iPhone|iPad|iPod/i.test(navigator.userAgent)
  },
  computed: {
    isHideHeader() {
      return this.$store.getters['layout/getIsHideHeader']
    },
    userData() {
      return this.$store.getters['user/getUserData']
    },
    timeout() {
      return this.$store.getters['user/getTimeout']
    },
    isSideBar: function() {
      return this.$store.getters['layout/getIsSideBar']
    },
    isScroll: function() {
      return this.$store.getters['layout/getIsScroll']
    },
    scrapNos: function() {
      return this.$store.getters['user/getScrapNos']
    },
    text: function() {
      return this.$store.getters['user/getSearchText']
    }
  },
  methods: {
    fnView: function(path) {
      if (path === '/info/contact') {
        alert('준비중입니다.')
        return false
      }
      this.$router.push({ path: path })
    },
    fnHome: function() {
      window.open(this.$ConstCode.CODE_ENV_LIST.WEB_MOVE_URL, '_blank')
    },
    fnOnOff: function(path, salesType, industryType, isOnline, optionType) {
      const year = new Date().getFullYear();
      // 2024.01.01 교육마감 START\
      if (salesType === 2) {
          if (this.$moment('2024-12-31 18:00:00').format('YYYY-MM-DD HH:mm:ss') <= this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss') &&
            this.$moment('2025-01-01 09:00:00').format('YYYY-MM-DD HH:mm:ss') >= this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')) {
          alert(`현재는 ${year}년도 교육 수강 신청 기간이 아닙니다.`);
          return false;
        }
      }
      // 2024.01.01 교육마감 END

      this.$store.commit('education/setEduType', {eduData: {salesType: salesType, industryType: industryType, isOnline: isOnline, optionType: optionType}})
      this.$router.push({ path: path })
    },
    fnList: function(path, salesType, industryType, isOnline, optionType) {
      this.$store.commit('education/setEduType', {eduData: {salesType: salesType, industryType: industryType, isOnline: isOnline, optionType: optionType }})
      this.$router.push({ path: path })
    },
    fnPrint: function(path, salesType, industryType, isOnline, optionType) {
      this.$store.commit('education/setEduType', {eduData: {salesType: salesType, industryType: industryType, isOnline: isOnline, optionType: optionType }})
      this.$router.push({ path: path })
    },
    fnBoard: function(path, boardType) {
      this.$store.commit('education/setBoardType', {boardData: {boardType: boardType}})
      this.$router.push({ path: path })
    },
    fetchList: async function(industryType, salesType, isOnline) {
      this.salesParams.salesType = salesType
      this.salesParams.industryType = industryType
      this.salesParams.eduType = isOnline
      this.salesParams.eduDate = this.$moment(new Date()).format('YYYYMMDD')
      this.salesParams.isFinishPayment = 1
      this.salesParams.page = 1
      this.salesParams.limit = -1
      const res = await this.$OfflineEduSvc.fetchUserOfflineEduList(this.salesParams)
      if (res.code === 1) {
        this.salesList = res.entity.list
      }
    },
    fnCalender: async function(industryType, salesType, isOnline) {
      // if (this.$Util.isEmpty(this.userData)) {
      //   alert('로그인이 필요한 서비스입니다.');
      //   return false;
      // }
      // await this.fetchList(industryType, salesType, isOnline)
      // console.log(this.salesList)
      // if (this.salesList.length <= 0 ) {
      //   alert('교육 결제 이후 사용가능한 서비스 입니다.');
      //   return false;
      // }
      this.$store.commit('layout/setModalAlert', {modalAlertName: this.$modalAlertNames.CALENDER, data: { industryType: industryType, salesType: salesType, zipProvinceNo: 1, isModify: true, isOnline: isOnline, applyEduNo: -1 }, callBack: this.fnBindEdu})
    },
    fnInfo: function(path, boardType, subType) {
      if (boardType === 2) {
        this.$store.commit('education/setBoardType', {boardData: {boardType: 4}})
      }
      if (boardType === 6) {
        this.$store.commit('education/setBoardType', {boardData: {boardType: 2}})
      }
      if (boardType === 7) {
        this.$store.commit('education/setBoardType', {boardData: {boardType: 3}})
      }
      this.$store.commit('board/setBoardType', {boardTab: {boardType: boardType, subType: subType}})
      this.$router.push({ path: path })
    },
    fnBack: function() {
      let query = this.$route.query
      if (!this.$Util.isEmpty(query.rUrl)) {
        this.$router.replace({ path: query.rUrl })
        return
      } else if (query.isPush === 1) {
        this.$router.replace({ path: '/' })
        return
      }
      this.$router.go(-1)
    },
    fnLogout: function () {
      if (!confirm('로그아웃하시겠습니까?')) return false
      
      const params = {}
      params.applicationId = 'edu'
      this.$UserSvc.logout(params).then(res => {
        if(res.code === 1) {
          this.$router.push({ path: '/main' })
        }
      })
    },
    fnSignUp: function() {
      window.open(`${this.$ConstCode.CODE_ENV_LIST.WEB_MOVE_URL}/sign/up/step1`, '_blank')
    },
    fnShowDrawer: function() {
      this.$store.commit('layout/setIsSideBar', { isSideBar: true })
    },
    fnScrap: async function() {
      const query = this.$_.clone(this.$route.query)
      const params = {}
      params.recipeNo = Number(query.recipeNo)
      const count = await this.$store.dispatch('user/toggleScrap', params)
      params.count = count
      await this.$RecipeSvc.updateRecipeLikeCount(params)
      if (count === 1) {
        alert('스크랩 되었습니다.')
      } else {
        alert('스크랩 취소되었습니다.')
      }
    },
    isScrap: function() {
      const query = this.$_.clone(this.$route.query)
      const recipeNo = Number(query.recipeNo)
      return this.scrapNos.indexOf(recipeNo) > -1
    },
    fnSearch: function() {
      this.$router.push({ path: '/search' })
    },
    fnSearchText: function() {
      const params = {}
      params.searchText = this.searchText
      // this.$store.dispatch('user/toggleSearchText', params)
      this.$store.commit('user/setSearchText', params)
      this.$store.commit('user/setSearchIndex')
    },
    fnShare: function() {
      this.$Util.nativeShareText(`https://food-service2.suhyup.co.kr/landing/link.html?recipeNo=${this.$route.query.recipeNo}`)
    },
    fnBindEdu: async function(item, emitType) {
      if (emitType === 'modify') {
        if (!confirm("교육 일정을 변경 하시겠습니까?")) return false;
        this.form.sceneNo = item.list.sceneNo
        this.form.offlineEducationNo = item.edu.offlineEducationNo
        this.form.offlineEduNo = item.info.edu.offlineEduNo
        this.form.educationCodeNo = item.info.edu.educationCodeNo
        this.form.applyEduNo = item.info.edu.applyEduNo
        this.form.salesType = item.info.edu.salesType
        const res = await this.$OfflineEduSvc.changeEducation(this.form)
        if (res.code === 1) {
          alert("교육 일정이 변경 되었습니다.\n 교육 접수증 내 변경 일정을 확인하세요.");
        }else{          
          // 홈페이지 유지보수 개발요청안 | 교육 일정 변경 작동조건 변경 (서남아이에스 임현아, 2024-07-03)
          alert(res.message)
        }
      }
    }
  }
}
</script>
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
