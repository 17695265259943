<template>
  <section class="container">
    <quill-editor
      ref="myTextEditor"
      :content="contents"
      :options="editorOption"
      @change="onEditorChange($event)"
    >
    </quill-editor>
  </section>
</template>

<script>
import hljs from 'highlight.js'
let app
export default {
  name: 'editor',
  props: {
    contents: {},
    index: {},
    valid: {
      type: Boolean,
      description: 'Whether is valid',
      default: true
    }
  },
  mounted: function() {
    app = this
    console.log(this)
  },
  data() {
    return {
      name: 'Editor',
      content: `${this.contents}`,
      $this: this,
      editorOption: {
        modules: {
          table: true,
          toolbar: {
            container: app.$quillContainer,
            handlers: {
              image: function() {
                app.$quillWatch.emit(this.quill.id)
              }
            }
          },
          ImageExtend: {
            loading: true,
            name: 'file',
            action: '/api/common/uploadFile',
            response: res => {
              console.log(res)
              const envImagePath = this.$ConstCode.getImagePath(
                res.entity.storedFileName
              )
              return envImagePath
            }
          },
          syntax: {
            highlight: text => hljs.highlightAuto(text).value
          },
          history: {
            delay: 1000,
            maxStack: 50,
            userOnly: false
          },
          imageDrop: true
        },
        bounds: '.quill-editor'
      }
    }
  },
  methods: {
    onEditorChange({ quill, html, text }) {
      console.log('editor change!', quill, html, text)
      // this.$emit('input', this.$event.target.value)
      this.$emit('eventBusChangeEditor', html, this.index)
      this.content = html
    }
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quill
    }
  }
}
</script>
<style>
  .quill-editor:not(.bubble) .ql-container,
  .quill-editor:not(.bubble) .ql-container .ql-editor {
    height: 30rem;
    padding-bottom: 1rem;
  }
</style>
<style lang="scss" scoped>
  .quill-code {
    height: 25rem;
    border: none;
    > .title {
      border: 1px solid #ccc;
      border-left: none;
      height: 3em;
      line-height: 3em;
      text-indent: 1rem;
      font-weight: bold;
    }
    > code {
      width: 100%;
      margin: 0;
      padding: 1rem;
      border: 1px solid #ccc;
      border-top: none;
      border-left: none;
      border-radius: 0;
      height: 20rem;
      overflow-y: auto;
    }
  }
  .container {
    /*max-width: 1150rem !important;*/
    width: 100%;
    margin: 0 0;
    padding: 2rem 0;
    overflow-y: auto;
  }
  .is-invalid .container {
    box-sizing: border-box !important;
    border-color: red !important;
  }
</style>
