<template>
  <div>
    <info-tab-index />
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <div class="lnb">
            <ul>
              <li><p class="home"></p></li>
              <li><p>고객센터</p></li>
              <li><p>신고센터</p></li>
            </ul>
          </div>
          <div class="page_ttl">
            <h1 class="h1">
              <span>신고센터</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="table_search">
              <div class="select">
                <select name="" id="">
                  <option value="">제목</option>
                </select>
              </div>
              <div class="search_wrap">
                <div class="input">
                  <input type="search" placeholder="검색" v-model="params.searchText" v-on:keyup.enter="fetchData(1)">
                </div>
                <button class="btn" v-on:click="fetchData(1)">검색</button>
              </div>
            </div>
            <div class="table hover"><!--'게시판: hover'-->
              <div class="row head">
                <div class="cell fix150"><p>번호</p></div>
                <div class="cell"><p>제목</p></div>
                <div class="cell fix180"><p>작성자</p></div>
                <div class="cell fix180"><p>답변여부</p></div>
                <div class="cell fix240"><p>등록일</p></div>
              </div>
              <div class="empty_wrap" v-if="list.length === 0">
                <div class="empty">
                  <span>글이 존재하지 않습니다.</span>
                </div>
              </div>
              <div class="row" v-for="(item, index) in list" :key="index">
                <div class="cell fix150"><p>{{ pageInfo.virtualNum - index }}</p></div>
                <div class="cell">
                  <p class="left">
                    <span class="lock" v-if="item.inquiry.isSecret === 1"></span>
                    <span class="lock on" v-if="item.inquiry.isSecret === 2"></span>
                    <span class="board_ttl" v-on:click="fnView(item.inquiry)">{{ item.inquiry.title }}</span>
                  </p>
                </div>
                <div class="cell fix180">
                  <p>
                    {{ item.user.userId.replace( (item.user.userId).substring(item.user.userId.length -2, item.user.userId.length), '**' ) }}
                  </p>
                </div>
                <div class="cell fix180">
                  <p class="state" :class="{'grey': item.inquiry.isAnswer === 2}">{{ item.inquiry.isAnswer === 1 ? '답변완료' : '답변대기' }}</p>
                </div>
                <div class="cell fix240"><p class="date">{{ $moment(item.inquiry.regDate).format('YYYY.MM.DD') }}</p></div>
              </div>
            </div>
            <div class="table_under">
              <button class="btn" v-on:click="fnWrite">글쓰기</button>
            </div>
            <base-pagination
              v-model="pageInfo.page"
              v-on:input="fetchData"
              v-on:change="fetchData"
              v-bind:pageInfo="pageInfo"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import BasePagination from '@/components/common/base/BasePagingation'
import InfoTabIndex from '@/components/common/info/tab-index'

export default {
  name: 'report-board',
  description: '신고센터 게시판',
  components: { InfoTabIndex, BasePagination },
  data() {
    return {
      params: {
        page: 1,
        limit: 10,
        searchText: '',
        isSecret: '',
        boardType: 2
      },
      pageInfo: {},
      list: []
    }
  },
  computed: {
    userData: function() {
      return this.$store.getters['user/getUserData']
    }
  },
  async created() {
    const res = await this.$InquirySvc.fetchInquiryList(this.params)
    if (res.code === 1) {
      this.list = res.entity.list
      this.pageInfo = res.entity.pageInfo
    }
  },
  methods: {
    fetchData: async function(page) {
      this.params.page = page
      const res  = await this.$InquirySvc.fetchInquiryList(this.params)
      if (res.code === 1) {
        this.list = res.entity.list
        this.pageInfo = res.entity.pageInfo
      }
    },
    fnView: function(inquiry) {
      if (inquiry.isSecret === 2) {
        if (this.userData.userNo !== inquiry.userNo) {
          alert('작성자와 관리자만 열람하실 수 있습니다.')
          return false
        }
      }
      this.$router.push({
        path: '/report/boarddetail',
        query: { inquiryNo: inquiry.inquiryNo }
      })
    },
    fnWrite: function() {
      this.$router.push({ path: '/report/boardwrite' })
    },
    fnBoard: function(path, boardType) {
      this.$store.commit('education/setBoardType', {boardData: {boardType: boardType}})
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped>

</style>
