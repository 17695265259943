<template>
  <main class="main" role="main">
    <div class="page grey"><!--'로그인, 아이디찾기, 비밀번호찾기: grey'-->
      <div class="inner">
        <div class="page_ttl">
          <h1 class="h1">
            <span>로그인</span>
          </h1>
        </div>
        <div class="page_con">
          <!--로그인-->
          <div class="login w420">
            <h5>처음 방문하시면 회원가입을 먼저 진행해주세요.</h5>
            <!--230427 문구 추가-->
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_in">
                    <div class="input">
                      <input type="text" placeholder="아이디" v-model="user.userId" v-on:keyup.enter="fnLogin">
                      <p class="valid on" v-show="$v.user.userId.$error">{{ msg.userId }}</p><!--'on 추가'-->
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_in">
                    <div class="input">
                      <input type="password" placeholder="비밀번호" v-model="user.userPw" v-on:keyup.enter="fnLogin">
                      <p class="valid on" v-show="$v.user.userPw.$error">{{ msg.userPw }}</p><!--'on 추가'-->
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <button class="btn" v-on:click="fnLogin">로그인</button>
            <div class="btn_wrap">
              <button v-on:click="fnFindId">아이디 찾기</button>
              <button v-on:click="fnFindPw">비밀번호 찾기</button>
              <button v-on:click="fnSignUp">회원가입</button>
            </div>
          </div>
          <!--//로그인-->
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'login-index',
  description: '로그인 페이지',
  data() {
    return {
      user: {
        userId: '',
        userPw: ''
      },
      msg: {
        userId: '',
        userPw: ''
      },
      isNotMatchUserPw: true,
      isNotMatchUserId: true,
      checkInterval: null
    }
  },
  computed: {
    userData() {
      return this.$store.getters['user/getUserData']
    }
  },
  methods: {
    fnSignUp: function() {
      window.open(`${this.$ConstCode.CODE_ENV_LIST.WEB_MOVE_URL}/sign/up/step1`, '_blank')
      // window.open('https://www.kofsia.or.kr/sign/up/step1', '_blank')
      // window.open('http://1.201.137.124:52070/sign/up/step1', '_blank')
    },
    fnFindId: function() {
      this.$router.push({ path: '/login/findid' })
    },
    fnFindPw: function() {
      this.$router.push({ path: '/login/findpw' })
    },
    fnLogin: async function() {
      // 초기화
      this.isNotMatchUserPw = true
      this.isNotMatchUserId = true
      this.$v.$touch()
      if (!this.$Util.isValid(this.$v, this.$el)) return false
      const params = {}
      params.userId = this.user.userId
      params.userPw = this.user.userPw
      params.applicationId = 'edu'

      const resDuplicate = await this.$UserSvc.userDuplicateValidCheck(params)
        if(resDuplicate.code === -1013) {// 동시 로그인 방지 기능 생성 (서남아이에스 임현아, 2024-09-09)
          const message = '이미 연결되어있는 홈페이지가 있습니다.<br/>연결을 해제하고 로그인 하시겠습니까?'
          const type = 'checkLoginSession'
          const itemList = {userId : this.user.userId, userPw : this.user.userPw}

          this.$store.commit('layout/setModalAlert', { data: { message, type, itemList }, modalAlertName: this.$modalAlertNames.COMMONCONFIRMINFO })
          
          return false
        } else {
          const res = await this.$UserSvc.login(params)
          if (res.code === 1) {
            if (res.entity.isAuth === 2 || res.entity.isAuth < 0 || res.entity.isAuth === null) {
              alert('협회에서 본인인증을 진행해주시길 바랍니다.')
              const params = {}
              params.applicationId = 'edu'
              await this.$UserSvc.logout(params)
              return false
              // await this.$router.replace({
              //   path: '/mystudy/list/auth'
              // })
            } else if(res.entity.isAuth !== null) {
              await this.$router.push({ path: this.$Util.isEmpty(this.$route.query.rUrl) ? '/main' : this.$route.query.rUrl })
            }
          } else {
            if (res.code === -1002) {
              this.isNotMatchUserPw = false
              this.msg.userPw = res.message
              return false
            } else if (res.code === -1000) {
              this.isNotMatchUserId = false
              this.msg.userId = res.message
              return false
            } else if (res.code === -1005) {
              // 홈페이지 유지보수 개발요청안 | 휴면해제 기능 추가 (서남아이에스 임현아, 2024-07-03)          
              this.isNotMatchUserId = false
              this.msg.userId = res.message

              const message = '휴면 회원입니다. 휴면 회원 해제를 진행하시겠습니까?'
              const type = 'changeUserStatus'

              this.$store.commit('layout/setModalAlert', { data: { message, type }, modalAlertName: this.$modalAlertNames.COMMONCONFIRMINFO })

              return false
            } else {
              this.isNotMatchUserId = false
              this.msg.userId = res.message
              return false
            }
          }
        }
    }
  },
  validations: {
    user: {
      userId: {
        test: function(val) {
          if (!this.isNotMatchUserId) {
            return false
          }
          if (!required(val)) {
            this.msg.userId = '아이디를 입력해주세요.'
            return false
          }
          if (val.length < 5) {
            this.msg.userId = '올바르지 않은 아이디 형식입니다.'
            return false
          }
          return true
        }
      },
      userPw: {
        test: function (val) {
          if (!this.isNotMatchUserPw) {
            return false
          }
          if (!required(val)) {
            this.msg.userPw = '비밀번호를 입력해주세요.'
            return false
          }
          if (val.length < 4) {
            this.msg.userPw = '숫자 4자 이상 입력해주세요.'
            return false
          }
          return true
        }
      }
    }
  }
}
</script>

<style scoped></style>
