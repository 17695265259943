'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
// import { forEach } from 'lodash'
/**
 * 공통 API Service
 * @class SurveySvc
 */
class SurveySvc extends ServiceExec {

  /**
   *  설문지 상세보기
   *  @param isToday {integer} isToday
   */
  fetchData(params = {}) {
    return this
      .get(`/api/survey/fetchData`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  설문지 답변 등록
   *  @param Authorization {string} Authorization
   *  @param surveyResultBean {SurveyResultBean} surveyResultBean
   *  @param userOnlineNo {integer} userOnlineNo
   */
  insertSurveyResult(params = {}) {
    const formData = new FormData()
    formData.append(
      'surveyResultBean',
      new Blob([JSON.stringify(params)], { type: 'application/json' })
    )
    formData.append("onlineEduNo", params.onlineEduNo)
    return this
      .multiPost(`/api/survey/insertSurveyResult`, formData)
      .then(response => {
        return response.data
      })
  }

}
export default new SurveySvc()
