import Vue from 'vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'quill-table/src/css/quill.table.css'
import VueQuillEditor from 'vue-quill-editor'
import { ImageDrop } from 'quill-image-drop-module'
import quillTable from 'quill-table'
import { container, ImageExtend, QuillWatch } from 'quill-image-extend-module'
// const jsts = require('jsts')
// import ImageResize from 'quill-image-resize-module'
VueQuillEditor.Quill.register('modules/imageDrop', ImageDrop)
// VueQuillEditor.Quill.register('modules/imageResize', ImageResize)
VueQuillEditor.Quill.register('modules/ImageExtend', ImageExtend)
VueQuillEditor.Quill.register(quillTable.TableCell)
VueQuillEditor.Quill.register(quillTable.TableRow)
VueQuillEditor.Quill.register(quillTable.Table)
VueQuillEditor.Quill.register(quillTable.Contain)
VueQuillEditor.Quill.register('modules/table', quillTable.TableModule)
const BaseImageFormat = VueQuillEditor.Quill.import('formats/image')
const ImageFormatAttributesList = ['alt', 'height', 'width', 'style']
class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function(formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute)
      }
      return formats
    }, {})
  }

  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value)
      } else {
        this.domNode.removeAttribute(name)
      }
    } else {
      super.format(name, value)
    }
  }
}

VueQuillEditor.Quill.register(ImageFormat, true)
Vue.use(VueQuillEditor)

/**
 * @description
 *  Service 관련 class들을 vue, nuxt에서
 *  전역으로 접근하기 위해  nuxt Object에 주입해야한다.
 */
const maxRows = 10
const maxCols = 5
const tableOptions = []
for (let r = 1; r <= maxRows; r++) {
  for (let c = 1; c <= maxCols; c++) {
    tableOptions.push('newtable_' + r + '_' + c)
  }
}
container.push([
  { table: tableOptions },
  { table: 'append-row' },
  { table: 'append-col' }
])
export default (app, inject) => {
  inject('quill', VueQuillEditor.Quill)
  inject('quillEditor', VueQuillEditor.quillEditor)
  inject('quillContainer', container)
  inject('quillWatch', QuillWatch)
  // inject('jsts', jsts)
}
