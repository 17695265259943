<template>
  <ul class="birth clearfix">
    <li>
      <select name="" id="profile_birth_y" v-model="year" v-on:change="fnSubmit" v-bind:disabled="disabled">
        <option v-for="year in years" v-bind:value="year" v-bind:key="year">
          {{year}}년
        </option>
      </select>
    </li>
    <li>
      <select name="" id="profile_birth_m" v-model="month" v-on:change="fnSubmit" v-bind:disabled="disabled">
        <option v-for="(month) in months" v-bind:value="month" v-bind:key="month">
          {{month}}월
        </option>
      </select>
    </li>
    <li>
      <select name="" id="profile_birth_d" v-model="day" v-on:change="fnSubmit" v-bind:disabled="disabled">
        <option v-for="day in days" v-bind:value="day" v-bind:key="day">
          {{day}}일
        </option>
      </select>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'base-date-select',
  props: {
    value: {
      type: [String, Object]
    },
    disabled: {
      type: [Boolean],
      default: () => false
    }
  },
  data: function () {
    let currentDate = this.$moment(this.value || new Date())
    let startYears = this.$moment().format('YYYY') - 80
    let endYears = Number(this.$moment().format('YYYY')) + 30
    return {
      years: this.$_.range(startYears, endYears),
      months: this.$_.range(1, 13).map(cnt => String(cnt).padStart(2, '0')),
      year: currentDate.format('YYYY'),
      month: currentDate.format('MM'),
      day: currentDate.format('D'),
      date: currentDate.format('YYYYMMDD')
    }
  },
  computed: {
    days: function () {
      return this.$_.range(1, this.$moment(`${this.year}${this.month}`, 'YYYYMM').daysInMonth() + 1)
    }
  },
  watch: {
    month: function () {
      this.day = '1'
    }
  },
  methods: {
    fnSubmit: function () {
      let date = this.$moment(`${this.year}${this.month}${this.day}`, 'YYYYMMDD')
      this.$emit('input', new Date(date))
    }
  }
}
</script>

<style scoped>

</style>
