import Util from '@/common/utils/Util'

export default {
  namespaced: true,
  state: {
    accessToken: null,
    userData: null,
    tempUserData: {},
    banner: {},
    isAddress: false,
    authParams: {},
    isUptPopup: false,
    scrapNos: [],
    searchText: '',
    searchTexts: [],
    searchIndex: 0,
    timeout: 0,
    timeoutCheck: 2, // 1 : 로그인 시, 2 : 로그아웃 시
    timeoutClock: '',
    studying: 0 // 0: 공부 전, 1: 공부 후
  },
  getters: {
    getAccessToken(state) {
      state.accessToken = state.accessToken || localStorage.accessToken
      return state.accessToken
    },
    getUserData(state) {
      state.userData = localStorage.userData || '{}'
      return JSON.parse(state.userData)
    },
    getScrapNos(state) {
      state.scrapNos = localStorage.scrapNos || '[]'
      return JSON.parse(state.scrapNos)
    },
    getSearchText(state) {
      state.searchText = localStorage.searchText || ''
      return state.searchText
    },
    getSearchTexts(state) {
      state.searchTexts = localStorage.searchTexts || '[]'
      return JSON.parse(state.searchTexts)
    },
    getSearchIndex(state) {
      return state.searchIndex
    },
    getBanner (state) {
      state.banner = (localStorage.banner) || {}
      return state.banner
    },
    getTimeout (state) {
      state.timeout = (localStorage.timeout) || {}
      return state.timeout
    },
    getTimeoutClock(state) {
      state.timeoutClock = localStorage.timeoutClock || ''
      return state.timeoutClock
    },
    getStudying(state){
      state.studying = (localStorage.studying) || {}
      return state.studying
    }
  },
  mutations: {
    storeLogin(state, { accessToken = '', userData }) {
      state.userData = userData
      state.accessToken = accessToken
      // 이벤트 없을 시 자동 로그아웃 기능 추가 (서남아이에스 임현아, 2024-09-09)
      localStorage.userData = userData
      localStorage.accessToken = accessToken
      state.timeout = 120 * 60
      state.timeoutCheck = 1
      state.timeoutClock = new Date().getTime()
      localStorage.timeout = 120 * 60
      localStorage.timeoutClock = new Date().getTime()
      document.cookie = "studying=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;";
    },
    setUserData(state, { userData = {} }) {
      state.userData = userData
      localStorage.userData = JSON.stringify(userData)
    },
    setScrapNos(state, { scrapNos = [] }) {
      state.scrapNos = scrapNos
      localStorage.scrapNos = JSON.stringify(scrapNos)
    },
    setSearchText(state, { searchText = '' }) {
      localStorage.searchText = searchText
      state.searchText = searchText
    },
    setSearchTexts(state, { searchTexts = [] }) {
      state.searchTexts = searchTexts
      if (searchTexts.length > 10) {
        localStorage.searchTexts = JSON.stringify(searchTexts.slice(0, 10))
      } else {
        localStorage.searchTexts = JSON.stringify(searchTexts)
      }
    },
    setSearchIndex(state) {
      setTimeout(() => {
        state.searchIndex++
      }, 500)
    },
    storeLogout(state) {
      state.userData = null
      state.accessToken = null
      delete localStorage.deviceInfo
      delete localStorage.accessToken
      delete localStorage.userData
      delete localStorage.timeout
      delete localStorage.timeoutClock
      delete localStorage.studying
      document.cookie = "studying=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;";
    },
    setBanner (state, banner) {
      state.banner = banner
      localStorage.banner = JSON.stringify(banner)
    },
    removeBanner () {
      delete localStorage.banner
    },
    setTimeout (state, timeout) {
      if(!Util.isEmpty(localStorage.userData)) {
        if (state.timeoutCheck === 1) {
          state.timeout = 120 * 60
          localStorage.timeout = 120 * 60
          state.timeoutCheck = 2
        } else {
          state.timeout = timeout
          localStorage.timeout = timeout
        }
      }
    },
    setTimeoutClock (state, timeoutClock) {
      if(!Util.isEmpty(localStorage.userData)) {
        state.timeoutClock = timeoutClock
        localStorage.timeoutClock = timeoutClock
      }
    },
    setStudying (state, studying) {
      state.studying = studying
      localStorage.studying = studying
      
      if (studying === 0) {
        localStorage.removeItem('studying')
      }
    }
  },
  actions: {
    storeLogout({ commit }) {
      if (!confirm('로그아웃하시겠습니까?')) return false
      commit('storeLogout')
      location.reload()
    },
    async toggleScrap(state, { recipeNo }) {
      const scrapNos = state.getters['getScrapNos']
      let count = 1
      if (scrapNos.indexOf(recipeNo) > -1) {
        // 스크랩 되어있는경우
        const temp = scrapNos.filter(item => {
          return item !== recipeNo
        })
        state.commit('setScrapNos', { scrapNos: temp })
        count = -1
      } else {
        // 스크랩 안되어있는 경우
        scrapNos.unshift(recipeNo)
        state.commit('setScrapNos', { scrapNos })
      }
      const params = {}
      params.recipeNo = recipeNo
      params.count = count
      // const temp = await RecipeSvc.updateRecipeLikeCount(params)
      // console.log(temp)
      return count
    },
    async toggleSearchText(state, { searchText }) {
      const searchTexts = state.getters['getSearchTexts']
      let index = -1
      if (!Util.isEmpty(searchTexts)) {
        index = searchTexts.indexOf(searchText)
      }
      if (index > -1) {
        // 최근 검색어 포함시
        searchTexts.splice(index, 1)
      }
      searchTexts.unshift(searchText)
      state.commit('setSearchTexts', { searchTexts })
    }
  }
}
