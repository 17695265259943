'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
import { forEach } from 'lodash'
import queryString from 'query-string'
import axios from 'axios'
let jsonAdapter = require('axios-jsonp')
/**
 * 공통 API Service
 * @class CommonSvc
 */
class CommonSvc extends ServiceExec {
  /**
   *  파일업로드
   *  @param file {file} file
   */
  uploadFile(params = {}) {
    const formData = new FormData()
    forEach(params, function(value, key) {
      formData.append(key, value)
    })
    return this.multiPost(`/api/common/uploadFile`, formData)
      .then(response => {
        return response.data
      })
  }

  /**
   *  구/군 코드 조회
   *  @param zipProvinceNo {integer} zipProvinceNo
   */
  zipProvince(params = {}) {
    let zipProvinceNo = params['zipProvinceNo']
    return this.patch(
      `/comm-user/common/zip/city/${zipProvinceNo}`,
      params
    ).then(response => {
      return response.data
    })
  }

  /**
   *  행정동/법정동 코드 조회
   *  @param zipCityNo {integer} zipCityNo
   */
  zipCity(params = {}) {
    let zipCityNo = params['zipCityNo']
    return this.patch(`/comm-user/common/zip/dong/${zipCityNo}`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   *  시/도 코드 조회
   */
  sido(params = {}) {
    return this.get(`/comm-user/common/zip/sido`, params).then(response => {
      return response.data
    })
  }

  /**
   *  공공데이터 주소 API 호출
   *  @param limit {integer} limit
   *  @param page {integer} page
   *  @param searchText {string} searchText
   */
  getAddress(params = {}) {
    return this.get(`/common/common/getAddress`, params).then(response => {
      const data = response.data
      data.entity.pageInfo = {
        page: data.entity.common.currentPage,
        endPage: Math.ceil(
          data.entity.common.totalCount / data.entity.common.countPerPage
        )
      }
      data.entity.list = data.entity.juso
      delete data.entity.juso
      return data
    })
  }

  downloadFile (params = {}) {
    return this.ServiceExec.request({
        method: 'GET',
        url: `/api/common/downloadFile?${queryString.stringify(params)}`,
      responseType: 'blob'
    })
  }

  /**
   *  인허가번호 API 호출
   */
  getLicenseNumber(params = {}) {
    console.log(params)
    return axios({
      url: `//coop.foodsafetykorea.go.kr/open/upso.do?${queryString.stringify(params)}`,
      adapter: jsonAdapter,
      callBackParamName: 'callback'
    }).then((res) => {
      return res
    })
  }

  /**
   *  교육 코드 조회
   */
  code(params = {}) {
    return this.get(`/api/common/education/code`, params)
      .then(response => {
        return response.data
      })
  }

  ip () {
    return axios({
      url: '//jsonip.com',
      adapter: jsonAdapter,
      callBackParamName: 'callback'
    }).then(res => {
      console.log(res)
      return res.data.ip
    })
  }
}
export default new CommonSvc()
