<template>
  <div class="lnb">
    <ul v-if='isCenter < 0'>
      <li><p class="home"></p></li>
      <li><p>{{ $ConstCode.CODE_BOARD_TYPE[boardType.boardType].value === 1 ? '나의 학습방' : '고객센터' }}</p></li>
      <li><p>{{ $ConstCode.CODE_BOARD_TYPE[boardType.boardType].name }}</p></li>
    </ul>
    <ul v-else>
      <li><p class='home'></p></li>
      <li><p>고객센터</p></li>
      <li><p>자주하는 질문</p></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'component-board-type',
  description: '게시판 구분',
  props: {
    isCenter: {
      description: '고객센터여부 1 맞음',
      default: -1
    }
  },
  computed: {
    boardType: function() {
      return this.$store.getters['education/getBoardType']
    }
  }
}
</script>

<style scoped>

</style>
