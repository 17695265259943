export default {
  install (Vue) {
    Vue.directive('focusMargin', {
      bind (el) {
        el.addEventListener('focusin', () => {
          document.getElementsByTagName('main')[0].style.marginBottom = '25px'
        })
        el.addEventListener('blur', () => {
          document.getElementsByTagName('main')[0].style.marginBottom = ''
        })
      }
    })
  }
}
