<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_ttl">
          <h1 class="h1">
            <span>본인인증</span>
          </h1>
        </div>
        <div class="page_con">
          <div class="pay_way">
            <div class="pay_way_card">
              <img src="@/assets/static/images/img_phone.svg" alt="">
              <div class="txt">
                <h4 class="h4">휴대폰 인증</h4>
                <p>본인 명의 휴대폰으로 인증</p>
              </div>
              <div class="btn_wrap">
                <button class="btn" v-on:click="fnJoin('IDS')">인증하기</button>
              </div>
            </div>
            <div class="pay_way_card">
              <img src="@/assets/static/images/img_creditcard.svg" alt="">
              <div class="txt">
                <h4 class="h4">신용카드 인증</h4>
                <p>본인 명의 신용카드로 인증</p>
              </div>
              <div class="btn_wrap">
                <button class="btn" @click="fnJoin('CID')">인증하기</button>
              </div>
            </div>
            <div class="pay_way_card">
              <img src="@/assets/static/images/img_ipin.svg" alt="">
              <div class="txt">
                <h4 class="h4">아이핀 인증</h4>
                <p>아이핀 아이디와 비밀번호로 인증</p>
              </div>
              <div class="btn_wrap">
                <button class="btn" @click="fnJoin('TIS')">인증하기</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import queryString from 'query-string'
let vmFunction
let windowObj
export default {
  name: 'sign-up-index',
  description: '회원가입 페이지',
  data: function () {
    return {
      params: {
        name: '',
        birth: '',
        userPhone: ''
      },
      authType: 'CID'
    }
  },
  methods: {
    fnJoin: function(authType) {
      let params = {}
      this.authType = authType
      params.callBackFunction = 'serverCallPayRes'
      params.authType = authType
      vmFunction = this.fnAuth
      console.log(`${this.$ConstCode.CODE_ENV_LIST.KCB_URL}?${queryString.stringify(params)}`)
      const option = "width=500, height=800, top=100, scrollbars, resizable"
      if (authType === 'CID') { // 신용카드 인증
        windowObj = window.open(`${this.$ConstCode.CODE_ENV_LIST.KCB_URL}?${queryString.stringify(params)}`, '_blank', option)
      } else if (authType === 'IDS') { // 휴대폰 인증
        windowObj = window.open(`${this.$ConstCode.CODE_ENV_LIST.KCB_PHONE_URL}?${queryString.stringify(params)}`, '_blank', option)
      } else { // i-pin 인증
        windowObj = window.open(`${this.$ConstCode.CODE_ENV_LIST.KCB_IPIN_URL}?${queryString.stringify(params)}`, '_blank', option)
      }
      // this.$router.push({ path: '/signup/form' })
    },
    fnAuth: async function (user) {
      if (!this.$Util.isEmpty(this.userData)) {
        const params = {}
        params.authCode = this.$ConstCode.USER_KCB__AUTH_TYPE[this.authType].value
        const res = await this.$UserSvc.userUpdateAuth(params)
        if (res.code === 1) {
          await this.$router.push({ path: '/main'})
        }
      } else {
       await this.$router.push({
          path: '/signup/form',
          query: {
            name: user.name,
            birth: user.birthday,
            userPhone: user.userPhone || '',
            authType: this.authType
          }
        })
      }
    }
  }
}

window.addEventListener('message', function ({data}) {
  if (data.source === 'serverCallPayRes') {
    windowObj.close()
    vmFunction(data.params)
  }
})
</script>

<style scoped>

</style>
