<template>
<!--  <flat-picker-->
<!--    slot-scope="{ focus, blur }"-->
<!--    @on-open="focus"-->
<!--    @on-close="blur"-->
<!--    class="form-control datepicker"-->
<!--    v-model="dates.range"-->
<!--    :class="{ 'is-invalid': !valid }"-->
<!--    :config="config"-->
<!--    :valid="valid"-->
<!--  />-->
  <flat-picker
    class="form-control datepicker"
    v-model="dates.range"
    :class="{ 'is-invalid': !valid }"
    :config="config"
    :valid="valid"
  />
</template>
<script>
import flatPicker from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { Korean } from 'flatpickr/dist/l10n/ko.js'
const localeKorean = Korean
localeKorean.rangeSeparator = ' ~ '
export default {
  name: 'base-date-picker',
  components: {
    'flat-picker': flatPicker
  },
  props: {
    dateRange: {
      type: String
    },
    label: {
      type: String
    },
    valid: {
      type: Boolean,
      description: 'Whether is valid',
      default: true
    },
    isRange: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    const config = {
      allowInput: true,
      locale: localeKorean
    }
    if (this.isRange) {
      config.mode = 'range'
    }
    return {
      dates: {
        simple: new Date(),
        range: this.value
      },
      config: config
    }
  },
  watch: {
    value: function(value) {
      console.log(value)
      this.dates.range = value
    },
    'dates.range': function(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>

</style>
