<template>
  <div>
    <component-board-tap />
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <!-- 홈페이지 유지보수 개발요청안 | 사이드 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
          <side-banner v-bind:isURL="isURL"></side-banner>
          <div class="lnb">
            <ul>
              <li><p class="home"></p></li>
              <li><p>온라인 신규영업자</p></li>
              <li><p>일반음식점</p></li>
              <!-- 홈페이지 유지보수 개발요청안 | 결제내역 => 신청내역 명칭 변경 (서남아이에스 임현아, 2024-07-04) -->
              <!-- <li><p>결제 내역</p></li> -->              
              <li><p>신청 내역</p></li>
            </ul>
          </div>
          <div class="page_ttl">
            <h1 class="h1">
              <span>교육 결제</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="table">
              <div class="row">
                <div class="col head">
                  <div class="cell"><p>주문번호</p></div>
                </div>
                <div class="col">
                  <div class="cell"><p>{{ eduType.isOnline === 1 ? form.info.onlineEduCode : form.info.offlineEduCode }}</p></div>
                </div>
                <div class="col head">
                  <div class="cell"><p>교육구분</p></div>
                </div>
                <div class="col">
                  <div class="cell">
                    <p>
                      {{ $ConstCode.CODE_SALES_TYPE[form.info.salesType].name }}
                      ({{ $ConstCode.CODE_INDUSTRY_TYPE[form.info.industryType].name }})
                    </p>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col head">
                  <div class="cell"><p>업체명</p></div>
                </div>
                <div class="col">
                  <div class="cell"><p>{{ form.info.businessName }}</p></div>
                </div>
                <div class="col head">
                  <div class="cell"><p>결제금액</p></div>
                </div>
                <div class="col">
                  <div class="cell"><p class="state">{{ $Util.formatNumber(form.info.price) }}원</p></div>
                </div>
              </div>
              <div class="row">
                <div class="col head">
                  <div class="cell"><p>대표자명</p></div>
                </div>
                <div class="col">
                  <div class="cell"><p>{{ form.info.representative }}</p></div>
                </div>
                <div class="col head">
                  <div class="cell"><p>교육시간</p></div>
                </div>
                <div class="col">
                  <div class="cell"><p>{{ form.info.salesType === 1 ? '6시간' : '3시간' }}</p></div>
                </div>
              </div>
              <div class="row">
                <div class="col head">
                  <div class="cell"><p>신청자명</p></div>
                </div>
                <div class="col">
                  <div class="cell"><p>{{ form.info.userName }}</p></div>
                </div>
                <div class="col head">
                  <div class="cell"><p>영업소 소재지</p></div>
                </div>
                <div class="col">
                  <div class="cell">
                    <div>
                      <p>{{ form.info.address }}</p>
                      <p>{{ form.info.address2 }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form mt20">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_ttl">
                    <p>결제 방법</p>
                  </div>
                  <div class="form_in">
                    <div class="inline">
                      <div class="radio">
                        <input type="radio" name="a" id="aa" :value="$ConstCode.CODE_PAYMENT_TYPE.CARD.value" v-model="paymentType">
                        <label for="aa"><span>카드결제</span></label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="a" id="ab" :value="$ConstCode.CODE_PAYMENT_TYPE.PHONE.value" v-model="paymentType">
                        <label for="ab"><span>휴대폰결제</span></label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="a" id="ac" :value="$ConstCode.CODE_PAYMENT_TYPE.VIRTUAL.value" v-model="paymentType">
                        <label for="ac"><span>가상계좌입금</span></label>
                      </div>
                      <div class="radio">
                        <input type="radio" name="a" id="ad" :value="$ConstCode.CODE_PAYMENT_TYPE.REAL_ACCOUNT.value" v-model="paymentType">
                        <label for="ad"><span>실시간 계좌이체</span></label>
                      </div>
                    </div>
                    <p class="valid">유효성 문구 영역</p><!--'on 추가'-->
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="page_btn">
            <button class="btn bo grey" v-on:click="$router.go(-1)">이전으로</button>
            <button class="btn" v-on:click="fnDone">결제하기</button>
          </div>
        </div>
      </div>
      <!-- 홈페이지 유지보수 개발요청안 | 푸터 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
      <bottom-banner v-bind:isURL="isURL"></bottom-banner>
    </main>
  </div>
</template>

<script>
import ComponentBoardTap from '@/components/common/edu/board-tap'
import SideBanner from '@/components/common/banner/SideBanner.vue'
import BottomBanner from '@/components/common/banner/BottomBanner.vue'

export default {
  name: 'apply-list-payment',
  description: '신청목록 결제',
  components: { ComponentBoardTap, SideBanner, BottomBanner },
  data() {
    return {
      option: 'width=800, height=500, top=100, scrollbars, resizable',
      params: {
        onlineEduNo: -1,
        offlineEduNo: -1
      },
      form: {
        info: {},
        onlineEduCode: '',
        salesType: '',
        industryType: '',
        businessName: '',
        price: '',
        representative: '',
        userName: '',
        studyTime: '',
        address: '',
        address2 :''
      },
      paymentType: 1,
      isURL: ''
    }
  },
  computed: {
    eduType: function() {
      return this.$store.getters['education/getEduType']
    },
    URL() {
      return this.searchURL(); // 메소드 호출
    }
  },
  async created() {
    this.searchURL()
    
    let res = {}
    if(this.eduType.isOnline === 1) {
      this.params.onlineEduNo = this.$route.query.onlineEduNo
      res = await this.$OnlineEduSvc.fetchData(this.params)
    } else {
      this.params.offlineEduNo = this.$route.query.offlineEduNo
      res = await this.$OfflineEduSvc.fetchData(this.params)
    }
    console.log(res)
    if (res.code === 1) {
      this.form = res.entity
    }

  },
  methods: {
    searchURL: function(){
      if(this.eduType.isOnline === 1){
        if (this.eduType.industryType === 1) {
          this.isURL = '3'
        }
        if (this.eduType.industryType === 2) {
          this.isURL = '7'
        }
        if (this.eduType.industryType === 3) {
          this.isURL = '11'
        }
      }

      if(this.eduType.isOnline === 2){
        if (this.eduType.industryType === 1) {
          this.isURL = '15'
        }
        if (this.eduType.industryType === 2) {
          this.isURL = '20'
        }
        if (this.eduType.industryType === 3) {
          this.isURL = '25'
        }
      }
      console.log('hi')
      console.log(this.siteUrl)
    },
    fnDone: function() {
      if (this.paymentType === null) {
        alert('결제방법을 선택해주세요.')
        return false
      }
      let params = this.$_.cloneDeep(this.form.info)
      params.userNo = this.form.user.userNo
      params.userEmail = this.form.user.email
      params.isOnline = this.eduType.isOnline // 집합교육은 0 or -1
      params.eduNo = (this.eduType.isOnline === 1) ? this.form.info.onlineEduNo : this.form.info.offlineEduNo
      params.Prdtnm = this.$ConstCode.CODE_INDUSTRY_TYPE[this.form.info.industryType].name + '(' + this.$ConstCode.CODE_SALES_TYPE[this.form.info.salesType].name + ')'
      params.userId = this.form.user.userId
      params.email = this.form.user.email
      params.paymentType = this.paymentType
      params.salesType = this.form.info.salesType
      params.industryType = this.form.info.industryType
      params.onlineEduCode = (this.eduType.isOnline === 1) ? this.form.info.onlineEduCode : this.form.info.offlineEduCode
      params.fnCallbackName = 'callbackPayment'
      console.log(params)
      this.$ConstCode.openPayment(this.callbackPayment, 'callbackPayment', params, this.option)
    },
    callbackPayment(result) {
      setTimeout(() => {
        if (result.code === '0000') {
          this.$router.push({ path: '/apply/list/done', query: { paymentType: this.paymentType }})
        } else {
          alert(result.message)
          this.$ConstCode.closePayment()
        }
      }, 300)
    }
  }
}

/*window.addEventListener('message', function ({data}) {
  if (!Util.isEmpty(data.source) && data.source === vmFunctionName) {
    if (windowObj) {
      windowObj.close()
    }
    vmFunction(data.params)
  }
})*/
</script>

<style scoped>

</style>
