export default {
  description: '작은 팝업용 (팝업이 Full 사이즈가 아닐때만 사용)',
  data: function () {
    return {
      zIndex: 2011
    }
  },
  computed: {
    isCloseModalAlert: function () {
      return this.$store.getters['layout/getIsCloseModal']
    },
    modalAlertState: function () {
      let modal = this.$store.getters['layout/getModalAlert']
      return modal
    },
    isShowAlert: function () {
      let isShowAlert = this.$store.getters['layout/getIsShowModalAlert']
      this.$Util.scrollLock(isShowAlert)
      if (this.modalAlertState.isHideHeader) {
        this.$store.commit('layout/setIsHideHeader', {isHideHeader: isShowAlert})
      }
      return isShowAlert
    }
  },
  watch: {
    isCloseModalAlert: function (isClose) {
      if (isClose) window.fnCloseModalAlert()
    }
  },
  methods: {
    fnCloseModalAlert: function () {
      window.fnCloseModalAlert()
    }
  }
}
