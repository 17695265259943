'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
/**
 * 공통 API Service
 * @class BoardSvc
 */
class BoardSvc extends ServiceExec {

  /**
   *  게시판 (1:강의자료, 2:공지사항, 3:자료실, 4:자주하는질문, 5:식품위생) 리스트
   *  @param boardType {integer} 보드타입
   *  @param examType {integer} 공지사항(운영, 시험공지구분)
   *  @param limit {integer} 10
   *  @param listType {integer} 리스트타입
   *  @param page {integer} 1
   *  @param searchText {string}
   */
  fetchBoardTypeList(params = {}) {
    return this.get(`/api/board/fetchBoardTypeList`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  게시판 (1:강의자료, 2:공지사항, 3:자료실, 4:자주하는질문, 5:식품위생) 리스트
   *  @param boardType {integer} boardType
   *  @param no {integer} no
   */
  fetchData(params = {}) {
    return this
      .get(`/api/board/fetchData`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  게시판 (1:강의자료, 2:공지사항, 3:자료실, 4:자주하는질문, 5:식품위생) 조회수
   *  @param boardType {integer} boardType
   *  @param no {integer} no
   */
  // 홈페이지 유지보수 개발요청안 | 조회수 기능 추가 (서남아이에스 임현아, 2024-07-02)
  downloadCnt(params = {}) {
    return this
      .get(`/api/board/downloadCnt`, params)
      .then(response => {
        return response.data
      })
  }
  
}
export default new BoardSvc()
