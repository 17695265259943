import Vue from 'vue'
import Vuex from 'vuex'
import User from './modules/User'
import Layout from './modules/Layout'
import Education from './modules/Education'
import Board from './modules/Board'

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    layout: Layout,
    user: User,
    education: Education,
    board: Board
  }
})
