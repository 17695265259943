<template>
  <div id="video_container" class="full" :ref="'container'">
    <video :ref="'video'" id="video_player" preload="auto" autoplay webkit-playshinline="true" playsinline="true" type="video/mp4" v-if="!$Util.isEmpty(form)">
      <source :src="$ConstCode.getImagePath(form.class.filePath, 'origin/')" type="video/mp4">
    </video>
    <div id="video_player_cover" v-on:mouseover="fnShow"></div>
    <div id="video_controller" :class="{'hide': hide }" v-on:mouseover="fnControllShow" v-on:mouseout="fnControllHide">
      <div class="progress">
        <div class="bar" :style="`--video-bar-width:${this.bar}%;`" :ref="'bar'"></div>
      </div>
      <div class="control">
        <div class="action" :class="isPlay ? 'pause' : 'play'" ><!--'재생: play', '일시정지: pause'-->
          <button @click="fnPlay"></button><!--play-->
          <button @click="fnPlay"></button><!--pause-->
          <p class="time">
            <span>{{ startTime }}</span>
            <span class="slash">/</span>
            <span>{{ endTime }}</span>
          </p>
        </div>
        <div class="volume">
          <div class="icon"></div>
          <div class="range">
            <input type="range" min="1" max="100" v-model="volume">
          </div>
        </div>
        <div class="screen" :class="isFullScreen ? 'to_max' : 'to_min'"><!--'전체화면: to_max', '원래화면: to_min'-->
          <button @click="fnMaxSize"></button><!--to_max-->
          <button @click="fnMaxSize"></button><!--to_min-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'edu-replay-video',
  data() {
    return {
      form: {},
      volume: 50,
      isPlay: true,
      isFullScreen: true,
      video: '',
      bar: 0,
      // 중간 단위 변경 (서남아이에스 임현아, 2024-10-18)
      pausedInt: 40 * 60,
      pauseTime: 0,
      startTime: 0,
      endTime: '',
      hide: '',
      ip: '',
      studyTime: '',
      status: 2,
      mouse: false,
      isStart: 0,
      study: null
    }
  },
  mounted() {
    this.study = setInterval(()=> {
      this.fnTimeCheck()
      const video = document.getElementsByTagName('video')[0]
      this.bar = (100 / video.duration) * video.currentTime
      this.startTime = this.$Util.secondsToTimes(video.currentTime.toFixed(0))
      this.endTime = this.$Util.secondsToTimes(video.duration.toFixed(0))
      window.addEventListener('keydown', this.fnNoKeyCode)
    }, 100)
  },
  async created() {
    const params = {}
    params.onlineClassNo = this.$route.query.onlineClassNo
    params.onlineEduNo = this.$route.query.onlineEduNo
    const res = await this.$OnlineClassSvc.fetchUserOnlineClassData(params)
    if (res.code === 1) {
      this.form = res.entity
    }
    this.onlineEduNo = this.$route.query.onlineEduNo
  },
  watch: {
    volume: function(val) {
      const video = this.$refs.video
      video.volume = val * 0.01
    }
  },
  methods: {
    fnPlay: function() {
      this.isPlay = !this.isPlay
      const video = this.$refs.video
      if (video.paused) {
        video.play()
      } else {
        video.pause()
      }
    },
    fnNoKeyCode: function(event) {
      event.preventDefault()
    },
    fnTimeCheck: async function () {
      const video = this.$refs.video
      let currentTime = Number(video.currentTime.toFixed(0)) // 현재 시간
      let endTime = Number(video.duration.toFixed(0)) // 종료시간
      if (this.$Util.isEmpty(currentTime)) return false
      currentTime = Math.floor(currentTime)
      if (Number(currentTime) + 2 >= endTime) {
        alert('영상이 종료되었습니다.')
        window.clearInterval(this.study)
        window.close()
        return false
      }
    },
    fnMaxSize: function () {
      const videoContainer = this.$refs.container
      console.log(videoContainer)
      this.isFullScreen = !this.isFullScreen
      if (this.isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        if (videoContainer.requestFullscreen) { // edge
          videoContainer.requestFullscreen()
        } else if (videoContainer.webkitendfullscreen) {
          videoContainer.webkitEnterFullScreen()
        } else if (videoContainer.mozRequestFullScreen) { //FF
          videoContainer.mozRequestFullScreen()
        } else if (videoContainer.webkitRequestFullscreen) { //chrome opera
          videoContainer.webkitRequestFullscreen()
        } else if(videoContainer.msRequestFullscreen){ //ie
          videoContainer.msRequestFullscreen()
        } else if(videoContainer.msRequestFullscreen){ //ie
          videoContainer.msRequestFullscreen()
        }
      }
    },
    fnShow: function () {
      this.hide = false
      if (this.mouse) {
        return false
      }
      setTimeout(()=> {
        this.hide = true
      }, 5000)
    },
    fnControllShow: function () {
      this.hide = false
      this.mouse = true
    },
    fnControllHide: function () {
      this.mouse = false
    }
  }
}
</script>

<style scoped>

</style>
