<template>
  <div class="lnb">
    <ul>
      <li><p class="home"></p></li>
      <li><p>{{ $ConstCode.CODE_SALES_TYPE[eduType.salesType].name }}</p></li>
      <li><p>{{ $ConstCode.CODE_INDUSTRY_TYPE[eduType.industryType].name }}</p></li>
      <li><p>{{ $ConstCode.CODE_OPTION_TYPE[eduType.optionType].name }}</p></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'component-edu-type',
  description: '교육 구분',
  computed: {
    eduType: function() {
      return this.$store.getters['education/getEduType']
    }
  }
}
</script>

<style scoped>

</style>
