<template>
  <datepicker
    v-model="date"
    :format="format"
    :placeholder="placeholder"
    :disabledDates="disabledDates"
    :class="{'date_right': isRight}"
  />
</template>

<script>
export default {
  name: 'component-common-custom-datepicker',
  props: {
    value: {
      type: [String]
    },
    format: {
      type: [String],
      default: 'yy.MM.dd'
    },
    placeholder: {
      type: [String],
      default: 'YY.MM.DD'
    },
    disabledDates: {
      type: [Object],
      default: () => {}
    },
    isRight: {
      type: [Boolean],
      default: false
    }
  },
  data: function() {
    const date = !this.$Util.isEmpty(this.value)
      ? this.$moment(this.value).toDate()
      : ''
    return {
      date
    }
  },
  watch: {
    date: {
      handler: function(val) {
        this.$emit('input', this.$moment(val).format('YYYY-MM-DD'))
      }
    }
  }
}
</script>

<style scoped>
.date_right .vdp-datepicker__calendar {
  right: 0;
}
.vdp-datepicker__calendar {
  width: auto;
}
</style>
