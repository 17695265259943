'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
/**
 * 공통 API Service
 * @class BannerSvc
 */
class BannerSvc extends ServiceExec {

  /**
   *  배너 리스트
   *  @param bannerType {integer}
   *  @param endDate {string}
   *  @param isToday {integer}
   *  @param limit {integer} 10
   *  @param page {integer} 1
   *  @param searchText {string}
   *  @param startDate {string}
   */
  fetchBannerList(params = {}) {
    return this.get(`/api/banner/fetchBannerList`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  광고 배너 리스트
   *  @param limit {integer} 10
   *  @param sizeType {string}
   *  @param menuType {string}
   */
  // 홈페이지 유지보수 개발요청안 | 배너 표출 기능 추가 (서남아이에스 임현아, 2024-07-30)
  AdBannerList(params = {}) {
    return this.get(`/api/adBanner/AdBannerList`, params)
      .then(response => {
        return response.data
      })
  }
}
export default new BannerSvc()
