<template>
  <div>
    <info-tab-index />
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <div class="lnb">
            <ul>
              <li><p class="home"></p></li>
              <li><p>고객센터</p></li>
              <li><p>원격지원</p></li>
            </ul>
          </div>
          <div class="page_ttl">
            <h1 class="h1">
              <span>원격지원</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="complete tb_line">
              <p>
                          <span class="big">
                              여러가지 오류사항에 대한 조치가 어려우신 경우,<br>
                              <span class="bold">전문 담당자가 회원님의 컴퓨터를 같이 보면서 장애 원인을 해결해드립니다.</span><br>
                          </span>
                <br>
                <span class="color bold">
                              원격지원 서비스 관련 문의사항은 전화주시면 응대해드리겠습니다.<br>
                              T: 02-449-5009
                          </span>
              </p>
            </div>
          </div>
          <div class="page_btn">
            <button class="btn" v-on:click="fnMove">원격지원서비스 시작하기</button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import InfoTabIndex from '@/components/common/info/tab-index'
export default {
  name: 'support-remote',
  components: { InfoTabIndex },
  description: '학습지원 원격지원',
  methods: {
    fnMove: function() {
      window.open('http://367.co.kr/', '_black')
    },
    fnBoard: function(path, boardType) {
      this.$store.commit('education/setBoardType', {boardData: {boardType: boardType}})
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped>

</style>
