<template>
  <div class="pop_all" v-bind:class="{ 'on': isShow}">
    <main class="main">
      <component v-bind:is="modalName"
                 v-bind:data="data"
                 v-bind:zIndex="zIndex"
                 v-bind:userData="userData"
                 v-bind:callBack="callBack"
                 v-on:eventMoveRoute="fnMoveRoute"
                 v-on:eventClose="fnClose"/>
    </main>
  </div>
</template>

<script>
export default {
  name: 'modal-container',
  props: {
    modalName: {
      type: [Object, String],
      description: 'modal component 명'
    },
    zIndex: {
      type: Number,
      default: 2100
    },
    data: {
      type: Object
    },
    modalTitle: {
      type: [String],
      description: 'modal 타이틀',
      default: () => ''
    },
    callBack: {
      type: Function,
      description: 'callBack function'
    }
  },
  data: function () {
    return {
      query: this.$route.path,
      userData: {}
    }
  },
  mounted() {
    const route = this.$_.clone(this.$route)
    if (!route.hash.match('#m')) {
      route.hash = "#m"
      this.$router.push(route)
    }
    setTimeout(() => {
      this.$store.commit('layout/setIsShowModal', {isShow: true})
    }, 10)
  },
  computed: {
    isShow: function () {
      return this.$store.getters['layout/getIsShowModal']
    }
  },
  watch: {
    '$route.hash': function (val) {
      if (!val) this.$emit('close')
    }
  },
  methods: {
    fnClose: function (go = -1) {
      this.$router.go(go)
      this.$emit('close')
    },
    fnMoveRoute: function (path = '/', query = {}) {
      this.fnClose()
      setTimeout(() => {
        this.$router.push({
          path: path,
          query: query
        })
      }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
/*팝업 on*/
.pop_all {
  z-index: 100;
  position: fixed;
  top: 100vh;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: white;
      transition:top 0.5s;
    .header {
      top:-10rem;
      transition:top 0.8s;
    }
    &.on {
       top:0;
       transition:top 0.5s;
    .header {
      top:0;
      transition:top 0.8s;
    }
  }
}
</style>
