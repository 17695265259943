<template>
  <div class="wrap edu">
    <loading />
    <content-view></content-view>
    <footer-bar v-bind:isFooter="layoutState.isFooter"></footer-bar>
    <modal-container
      v-if="modalState.isModal"
      v-bind:modalName="modalState.modalName"
      v-bind:modalTitle="modalState.modalTitle"
      v-bind:type="modalState.type"
      v-bind:data="modalState.data"
      v-bind:isShow="isShow"
      v-bind:zIndex="zIndex"
      v-bind:callBack="modalState.callBack"
      v-on:close="fnCloseModal"
    />
    <modal-alert-container
      v-if="modalAlertState.isModalAlert"
      v-bind:modalAlertName="modalAlertState.modalAlertName"
      v-bind:modalAlertTitle="modalAlertState.modalAlertTitle"
      v-bind:type="modalAlertState.type"
      v-bind:data="modalAlertState.data"
      v-bind:isShowAlert="isShowAlert"
      v-bind:zIndex="zIndex"
      v-bind:confirmLabel="modalAlertState.confirmLabel"
      v-bind:callBack="modalAlertState.callBack"
      v-on:close="fnCloseModalAlert"
    />
  </div>
</template>
<script>
import Loading from "@/layouts/Loading"
import ModalLayoutMixin from './ModalLayoutMixin.js'
import ModalAlertLayoutMixin from './ModalAlertLayoutMixin.js'
import FooterBar from './FooterBar'
import ContentView from './ContentView'
// import SideBar from './SideBar'
export default {
  mixins: [ModalLayoutMixin, ModalAlertLayoutMixin],
  components: {
    'footer-bar': FooterBar,
    'content-view': ContentView,
    'loading': Loading
  },
  computed: {
    layoutState: function () {
      return this.$store.getters['layout/getLayout']
    },
    userDataState: function () {
      return this.$store.getters['user/getUserData']
    }
  },
  data () {
    return {}
  }
}
</script>
<style lang="scss">
</style>
