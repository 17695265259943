import Vue from 'vue'
/** 모달 전체 화면*/
import BaseListScrollPage from '@/components/common/base/BaseListScrollPage'
import BaseDateSelect from '@/components/common/base/BaseDateSelect'
import BaseDatePicker from '@/components/common/base/BaseDatePicker'
import ModalContainer from '@/components/modalContainer'
import ModalAlertContainer from '@/components/modalAlertContainer'
import ModalAddress from '@/components/common/modal/Address'
import ModalDaumPostCode from '@/components/common/alert/DaumPostCode'
import ModalPopup from '@/components/common/alert/Popup'
import ModalLicenseNumber from '@/components/common/alert/LicenseNumber'

/** 모달 ALERT*/
import ModalAlert from '@/components/common/alert/Alert'
import ModalConfirm from '@/components/common/alert/Confirm'
import ModalReceipt from '@/components/common/alert/Receipt'
import ModalCertification from '@/components/common/alert/Certification'
import ModalBills from '@/components/common/alert/Bills'
import ModalIdResult from '@/components/common/alert/IdResult'
import ModalCalender from '@/components/common/alert/Calender'

import ModalCancelEdu from '@/components/common/alert/CancelEdu'
import ModalInactiveChangeInfo from '@/components/common/alert/InactiveChangeInfo'
import ModalChangePhoneInfo from '@/components/common/alert/ChangePhoneInfo'
import ModalChangePwInfo from '@/components/common/alert/ChangePwInfo'
import ModalCommonConfirmInfo from '@/components/common/alert/CommonConfirmInfo'
import ModalCommonNotice from '@/components/common/alert/CommonNotice'

import Datepicker from '@/components/common/datepicker/custom-datepicker'

const modalNames = {
  ADDRESS: ModalAddress.name
}

const modalAlertNames = {
  ALERT: ModalAlert.name,
  CONFIRM: ModalConfirm.name,
  RECEIPT: ModalReceipt.name,
  CERTIFICATION: ModalCertification.name,
  BILLS: ModalBills.name,
  RESULT: ModalIdResult.name,
  DAUMPOSTCODE: ModalDaumPostCode.name,
  POPUP: ModalPopup.name,
  LICENSE: ModalLicenseNumber,
  CALENDER: ModalCalender,

  CANCELEDU : ModalCancelEdu,
  INACTIVECHANGEINFO : ModalInactiveChangeInfo,
  CHANGEPHONEINFO : ModalChangePhoneInfo,
  CHANGEPWINFO : ModalChangePwInfo,
  COMMONCONFIRMINFO: ModalCommonConfirmInfo.name,
  COMMONNOTICE: ModalCommonNotice.name
}

Vue.prototype.$modalNames = modalNames
Vue.prototype.$modalAlertNames = modalAlertNames

Vue.component(BaseListScrollPage.name, BaseListScrollPage)
Vue.component(BaseDateSelect.name, BaseDateSelect)
Vue.component(BaseDatePicker.name, BaseDatePicker)
Vue.component(ModalContainer.name, ModalContainer)
Vue.component(ModalAddress.name, ModalAddress)


/** 모달 ALERT */

Vue.component(ModalAlertContainer.name, ModalAlertContainer)
Vue.component(ModalAlert.name, ModalAlert)
Vue.component(ModalConfirm.name, ModalConfirm)
Vue.component(ModalReceipt.name, ModalReceipt)
Vue.component(ModalCertification.name, ModalCertification)
Vue.component(ModalBills.name, ModalBills)
Vue.component(ModalIdResult.name, ModalIdResult)
Vue.component(ModalDaumPostCode.name, ModalDaumPostCode)
Vue.component(ModalPopup.name, ModalPopup)
Vue.component(ModalLicenseNumber.name, ModalLicenseNumber)
Vue.component(ModalCalender.name, ModalCalender)

Vue.component(ModalCancelEdu.name, ModalCancelEdu)
Vue.component(ModalChangePhoneInfo.name, ModalChangePhoneInfo)
Vue.component(ModalChangePwInfo.name, ModalChangePwInfo)
Vue.component(ModalInactiveChangeInfo.name, ModalInactiveChangeInfo)
Vue.component(ModalCommonConfirmInfo.name, ModalCommonConfirmInfo)
Vue.component(ModalCommonNotice.name, ModalCommonNotice)
Vue.component('datepicker', Datepicker)
