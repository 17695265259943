<template>
  <div class="modal_inner center w1200">
    <div class="modal_con">
      <div class="modal_txt" style="padding: 80px;">
        <p v-html="data.message">
        </p>
      </div>
      <div class="modal_btn">
        <div class="btn_wrap" v-if="data.type !== 'checkPhoneNumber'">
          <button class="btn" v-on:click="fnYes(data)"><p>예</p></button>
          <button class="btn bo grey" v-on:click="fnNo(data)"><p>아니오</p></button>
        </div>
        <div class="btn_wrap" v-else>
          <button class="btn" v-on:click="fnYes(data)"><p>예<br/>수강하기</p></button>
          <button class="btn bo grey" v-on:click="fnNo(data)"><p>아니오<br/>번호변경하기</p></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-common-confirm-info',
  props: {
    data: {
      type: [Array,Object],
      default: () => {}
    },
    callBack: {
      type: [Function]
    },
    confirmLabel: {
      type: [String],
      default: '확인'
    },
    cancelLabel: {
      type: [String],
      default: '취소'
    }
  },
  methods: {
    fnYes : function(data){
      // 아이디 찾기 & 비밀번호 찾기
      if(data.type === 'findUserResult'){
        window.location.href = 'https://www.kofsia.or.kr/sign/up/step1';    
      }

      // 휴면해제하기
      if(data.type === 'changeUserStatus'){
        this.$store.commit('layout/setModalAlert', { modalAlertName: this.$modalAlertNames.INACTIVECHANGEINFO })
      }

      // 전화번호 변경하기
      if(data.type === 'changePhone'){   
        const params = {}
        params.userPhone = data.itemList.userPhone

        console.log(params)

        this.$UserSvc.updateUserPhone(params).then(res => {
          if (res.code === 1) {
            alert('휴대폰번호가 정상적으로 변경되었습니다.\n다시 로그인이 필요합니다.')
            const params = {}
            params.applicationId = 'edu'
            this.$UserSvc.logout(params).then(res => {
              if(res.code === 1) {
                this.$router.push({path: '/main'})
              }
            })
          } else {
            alert(res.message)
            this.$emit('eventClose')
          }
        })        
      }
    
      // 비밀번호 변경하기
      if(data.type === 'changePw'){  
        const params = {}
        params.userOldPw = data.itemList.password
        params.userPw = data.itemList.password2

        console.log(params)

        this.$UserSvc.updateUserPw(params).then(res => {
          if(res.code === 1) {          
            alert('비밀번호가 정상적으로 변경되었습니다.\n다시 로그인이 필요합니다.')
    
            const params = {}
            params.applicationId = 'edu'
            this.$UserSvc.logout(params).then(res => {
              if(res.code === 1) {
                this.$router.push({path: '/main'})
              }
            })
          }else{
            alert(res.message)
            this.$emit('eventClose')
          }
        })
      }

      // 수강 관련 본인인증
      if(data.type === 'checkPhoneNumber'){
        this.$emit('eventClose')
        this.callBack()
      }

      // 동시 로그인 방지
      if(data.type === 'checkLoginSession'){
        // 로그인
        const params = {}
        params.userId = data.itemList.userId
        params.userPw = data.itemList.userPw
        params.applicationId = 'edu'

        this.$UserSvc.login(params).then(res => {
              if(res.code === 1) {
                if (this.$router.currentRoute.path === '/main') {
                  this.$router.go(this.$router.currentRoute)
                } else {
                  this.$router.push({path: '/main'})
                }
              }
            })
      }
    },
    fnNo : function(data){
      // 아이디 찾기 & 비밀번호 찾기
      if(data.type === 'findUserResult'){
        this.$router.push({ path: '/main' })
      }

      // 수강 관련 본인인증
      if(data.type === 'checkPhoneNumber'){
        alert('휴대폰 번호를 변경해주세요. 마이페이지로 이동합니다.')
        this.$router.push({path: '/mypage'})
      }

      if(data.type === 'changeUserStatus' || data.type === 'changePhone' || data.type === 'changePw' || data.type === 'checkLoginSession'){
        this.$emit('eventClose')
      }
    }
  }
}
</script>

<style scoped>

</style>
