export default ({
  namespaced: true,
  state: {
    eduData: {
      salesType: 1,
      industryType: 1,
      isOnline: 1,
      optionType: 1
    },
    boardData: { boardType: 1 }
  },
  getters: {
    getEduType (state) {
      state.eduData = localStorage.eduData || '{}'
      return JSON.parse(state.eduData)
    },
    getBoardType(state) {
      state.boardData = localStorage.boardData || '{}'
      return JSON.parse(state.boardData)
    }
  },
  mutations: {
    setEduType (state, { eduData = {} }) {
      state.eduData = eduData
      localStorage.eduData = JSON.stringify(eduData)
    },
    setBoardType (state, { boardData = {} }) {
      state.boardData = boardData
      localStorage.boardData = JSON.stringify(boardData)
    }
  }
})
