<template>
  <div class="modal_inner center w1200">
    <div class="modal w80p">
      <div class="modal_con"  id="modal_con">
        <div class="print_wrap">
          <div class="print">
            <div class="table watermark" style='position: unset;page-break-before:always'>
              <img src="@/assets/static/images/watermark_emblem.svg" alt="">
              <div class="row fix210">
                <div class="posit left_top" style="left: 60px;">
                  <p class="print_number" v-if="isOld">NO. {{ form.outnumber }}</p>
                  <p class="print_number" v-if="isOnline && !isOld">NO. {{ newForm.info.onlineEduCode }}</p>
                  <p class="print_number" v-else-if="!isOld">NO. {{ newForm.info.offlineEduCode }}</p>
                </div>
                <div class="cell">
                  <p class="print_ttl">식품위생교육 수료증</p>
                </div>
              </div>
              <div class="row fix70">
                <div class="cell fix180"><p>교육 이수자 이름</p></div>
                <div class="cell">
                  <p class="left" v-if="isOld">{{ form.uname }} ({{ $moment(form.ubir).format('YY.MM.DD') }})</p>
                  <p class="left" v-else>{{ newForm.info.userName }} ({{ $moment(newForm.info.birth).format('YY.MM.DD') }})</p>
                </div>
                <div class="cell fix150"><p>직책</p></div>
                <div class="cell fix180" v-if="isOld"><p class="left">
                  {{ $Util.isEmpty(form.utel) ? form.jikchek : form.jikchek === "1" ? '대표자' : '위생관리자'}}
                </p></div>
                <div class="cell fix180" v-else><p class="left">{{ newForm.info.positionType === 1 ? '대표자' : '위생관리자'}}</p></div>
              </div>
              <div class="row fix70">
                <div class="cell fix180"><p>영업소 명칭(상호)</p></div>
                <div class="cell" v-if="isOld"><p class="left">{{ $Util.isEmpty(form.utel) ? form.cuname : form.cname }}</p></div>
                <div class="cell" v-else><p class="left">{{ newForm.info.businessName }}</p></div>
                <div class="cell fix150"><p>대표자 이름</p></div>
                <div class="cell fix180" v-if="isOld"><p class="left">{{ $Util.isEmpty(form.utel) ? form.cname : form.cuname }}</p></div>
                <div class="cell fix180" v-else><p class="left">{{ newForm.info.representative }}</p></div>
              </div>
              <div class="row fix70">
                <div class="cell fix180"><p>영업소의 소재지</p></div>
                <div class="cell">
                  <div class="left" v-if="isOld"><p>{{ form.uaddr1 }}</p></div>
                  <div class="left" v-else><p>{{ newForm.info.address }} {{ newForm.info.address2 }}</p></div>
                </div>
              </div>
              <div class="row fix70">
                <div class="cell fix180"><p>영업의 종류</p></div>
                <div class="cell" v-if="$Util.isEmpty(form.utel) && isOld"><p class="left">{{ form.gubun }}</p></div>
                <div class="cell" v-else-if="isOld"><p>{{ fnType(form.gubun) }}</p></div>
                <div class="cell" v-if="!isOld"><p>
                  {{ $ConstCode.CODE_SALES_TYPE[newForm.info.salesType].name }}
                  ({{ $ConstCode.CODE_INDUSTRY_TYPE[newForm.info.industryType].name }})
                </p></div>
              </div>
              <div class="row fix70">
                <div class="cell fix180"><p>교육일시</p></div>
                <div class="cell" v-if="isOld"><p class="left">{{ $moment(form.eduEndDate).format('YYYY.MM.DD') }}</p></div>
                <div class="cell" v-if="isOnline && !isOld"><p class="left">{{ newForm.info.endDate ? $moment(newForm.info.endDate).format('YYYY.MM.DD') : '' }}</p></div>
                <div class="cell" v-else-if="!isOld"><p class="left">{{ newForm.sche.eduDate ? $moment(newForm.sche.eduDate).format('YYYY.MM.DD') : '' }}</p></div>
                <div class="cell fix180"><p>교육시간</p></div>
                <div class="cell fix240" v-if="$Util.isEmpty(form.utel) && isOld"><p class="left">{{ fnEduTime(form.gubun) }}</p></div>
                <div class="cell fix240" v-else-if="isOld"><p class="left">{{ form.gubun === "1" || form.gubun === "2" || form.gubun === "3" ? '6시간' : '3시간' }}</p></div>
                <div class="cell fix240" v-if="!isOld"><p class="left">{{ newForm.info.salesType === 1 ? '6시간' : '3시간' }}</p></div>
              </div>
              <div class="row fix70">
                <div class="cell"><p>교육실시기관명 : 한국외식산업협회</p></div>
                <div class="cell fix180"><p>교육구분</p></div>
                <!-- 찾아가는 위생 교육 | 교육 구분 표출 변경 (서남아이에스 임현아, 2024-08-08) -->
                <div class="cell fix240"><p class="left">{{ isOnline ? ( !$Util.isEmpty(newForm.code) && newForm.code.code === 999 ? '집합 교육' : '온라인 교육' ) : '집합 교육' }}</p></div>
              </div>
              <div class="row">
                <div class="announce">
                  <p class="announce_txt">
                    위 사람은「식품위생법」제41조, 동법 시행령 제27조 및 시행규칙 제51조
                    내지 제52조의 규정에 따라 식품위생교육을 이수하였으므로 수료증을 교부합니다.
                  </p>
                  <p v-if="isOnline && !isOld" class="announce_date">{{ newForm.info.endDate ? $moment(newForm.info.endDate).format('YYYY.MM.DD') : '' }}</p>
                  <p v-else-if="!isOld" class="announce_date">{{ newForm.sche.eduDate ? $moment(newForm.sche.eduDate).format('YYYY.MM.DD') : '' }}</p>
                  <p class="announce_by" style='position: unset;'>
                        <span class="wth_stamp type01">
                            (사)한국외식산업협회장
                            <img src="@/assets/static/images/stamp01.png" alt="">
                        </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal_btn">
        <div class="btn_wrap">
          <button class="btn bo grey" v-on:click="fnCallBack">이전으로</button>
          <button class="btn" v-on:click="fnPrint">출력하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-alert-certification',
  description: '수료증',
  props: {
    callBack: {
      type: [Function]
    },
    data: {
      type: [Array, Object],
      default: () => {
      }
    }
  },
  computed: {
    eduType: function() {
      return this.$store.getters['education/getEduType']
    }
  },
  data() {
    return {
      form: {},  // 전년도 수료기록
      newForm: {}, // new 수료기록
      currentDate: new Date,
      isOnline: true, // 온라인, 오프라인 구분
      isOld: true
    }
  },
  async created() {
    this.isOld = this.data.isOld
    let params = {}
    let res2 = {}
    const type = this.eduType.isOnline
    if (this.isOld) {
      // 전년도 수료기록
      const res = await this.$OldEduSvc.fetchOldData(this.data)
      this.form = res.entity
    } else {
      if (type === 1) {
        // 온라인 수료기록
        params.onlineEduNo = this.data.item.edu.onlineEduNo
        res2 = await this.$OnlineEduSvc.fetchData(params)
      } else {
        // 집합 수료기록
        this.isOnline = false
        params.offlineEduNo = this.data.item.edu.offlineEduNo
        res2 = await this.$OfflineEduSvc.fetchData(params)
      }
      this.newForm = res2.entity
    }
  },
  methods: {
    fnCallBack: function(e) {
      if (!this.$Util.isEmpty(this.callBack)) {
        this.callBack(e)
      }
      this.$emit('eventClose')
    },
    fnPrint: function() {
      const params = {}
      if (this.isOnline && !this.isOld) {
        params.onlineEduNo = this.data.item.edu.onlineEduNo
        this.$OnlineEduSvc.updatePrintStatus(params)
      } else if (!this.isOnline && !this.isOld) {
        params.offlineEduNo = this.data.item.edu.offlineEduNo
        this.$OfflineEduSvc.updatePrintStatus(params)
      }
      const html = document.querySelector('html')
      const printContents = document.querySelector('.modal_con').innerHTML
      const printDiv = document.createElement('div')
      printDiv.className = 'print-div'

      html.appendChild(printDiv)
      printDiv.innerHTML = printContents
      document.body.style.display = 'none'
      window.print()
      document.body.style.display = 'block'
      printDiv.style.display = 'none'
    },
    fnType: function(gubun) {
      let type = ''
      let type2 = ''
      if (gubun === "1" || gubun === "2" || gubun === "3") type = '신규영업자'
      else type = '기존영업자'
      if(gubun === "1" || gubun === "4") type2 = '(일반음식점)'
      if(gubun === "2" || gubun === "5") type2 = '(집합급식소)'
      if(gubun === "3" || gubun === "6") type2 = '(위탁급식업)'
      return type+type2
    },
    fnEduTime: function(gubun) {
      const type = gubun.substr(0,2)
      if (type === "신규") return "6시간"
      else return "3시간"
    }
  }
}
</script>

<style scoped>

</style>
