<template>
  <nav id="nav_id" class="nav">
    <div class="inner">
      <div class="nav_top">
        <div class="inner">
          <h1 class="h1">
            <span v-if="eduType.isOnline === 1 && eduType.salesType === 1">신규영업자</span>
            <span v-if="eduType.isOnline === 1 && eduType.salesType === 2">기존영업자</span>
            <span v-if="eduType.isOnline === 2 && eduType.salesType === 1">신규영업자</span>
            <span v-if="eduType.isOnline === 2 && eduType.salesType === 2">기존영업자</span>
          </h1>
        </div>
      </div>
      <div class="nav_btm">
        <div class="inner">
          <div class="line dep01">
            <div class="inner">
              <ul>
                <li><button :class="{ 'on': eduType.industryType === 1 }" v-on:click="fnTab(1)"><span>일반음식점</span></button></li>
                <li><button :class="{ 'on': eduType.industryType === 2 }" v-on:click="fnTab(2)"><span>집단급식소</span></button></li>
                <li><button :class="{ 'on': eduType.industryType === 3 }"  v-on:click="fnTab(3 )"><span>위탁급식업</span></button></li>
              </ul>
            </div>
          </div>
          <div class="line dep02">
            <div class="inner">
              <ul>
                <li v-if="eduType.isOnline === 1"><button :class="{ 'on': eduType.optionType === 1 }" v-on:click="fnOptions(1, '/apply/online')"><span>교육 신청</span></button></li>
                <li v-else><button :class="{ 'on': eduType.optionType === 1 }" v-on:click="fnOptions(1, '/apply/offline')"><span>교육 신청</span></button></li>
                <!-- 홈페이지 유지보수 개발요청안 | 결제내역 => 신청내역 명칭 변경 (서남아이에스 임현아, 2024-07-04) -->
                <!-- <li><button :class="{ 'on': eduType.optionType === 2 }" v-on:click="fnOptions(2, '/apply/list')"><span>결제 내역</span></button></li> -->
                <li><button :class="{ 'on': eduType.optionType === 2 }" v-on:click="fnOptions(2, '/apply/list')"><span>신청 내역</span></button></li>
                <li  v-if="eduType.isOnline === 1"><button :class="{ 'on': eduType.optionType === 3 }" v-on:click="fnOptions(3, '/payment')"><span>영수증 출력</span></button></li>
                <li v-if="eduType.isOnline === 2" ><button :class="{ 'on': eduType.optionType === 3 } " v-on:click="fnOptions(3, '/eduprint')" ><span>교육접수증 출력</span></button></li>
                <li v-if="eduType.isOnline === 2"><button :class="{ 'on': eduType.optionType === 4 }" v-on:click="fnOptions(4, '/payment')"><span>영수증 출력</span></button></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'component-board-tap',
  description: '공통-메뉴탭',
  data() {
    return {
      industryType: ''
    }
  },
  computed: {
    eduType: function() {
      return this.$store.getters['education/getEduType']
    }
  },
  methods: {
    fnTab: function(industryType) {
      this.$store.commit('education/setEduType', {eduData: {salesType: this.eduType.salesType, industryType: industryType,
                                            isOnline: this.eduType.isOnline, optionType: this.eduType.optionType }})
    },
    fnOptions: function(optionType, path) {
      this.$store.commit('education/setEduType', {eduData: {salesType: this.eduType.salesType, industryType: this.eduType.industryType,
                                             isOnline: this.eduType.isOnline, optionType: optionType }})
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped>

</style>
