<template>
  <div>
    <board-tab-index :board-type='1' @fetchData='fetchData' />
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <div class="lnb">
            <ul>
              <li><p class="home"></p></li>
              <li><p>나의 학습방</p></li>
              <li><p>지정확인서 제출</p></li>
            </ul>
          </div>
          <div class="page_ttl">
            <h1 class="h1">
              <span>지정확인서 제출</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="inform_box mb60">
              <!-- <p class="color medium">※ 식품위생법 제41조 3항</p>
              <ol class="nb_dot li_space">
                <li>
                  <span class="medium">식품위생관리책임자를 임명하는 경우는 다음과 같습니다.</span>
                  <ol class="nb_bracket">
                    <li class="grey"><span>식품접객업 영업자가 영업에 직접 종사하지 않는 경우</span></li>
                    <li class="grey"><span>식품접객업 영업자가 두 곳 이상의 장소에서 영업을 하는 경우</span></li>
                  </ol>
                  <br>
                </li>
                <li>
                  <span class="medium">
                      위 사항에 해당할 경우, 영업자는 종업원 중에서 식품위생에 관한 책임자를 지정하여 영업자 대신 교육을 받게 할 수 있으며,<br>
                      교육시 [식품위생관리책임자 지정 확인서]를 제출하여야 합니다.
                  </span>
                </li>
              </ol> -->
              <ol class="dot">
                <li class="color">
                  <!-- 문구 수정 (서남아이에스 임현아, 2024-11-14) -->
                  <span class="ol_li_txt">
                    ※ 식품위생관리책임자(위생관리자) 관련 법령 안내 
                    <button class="btn" style="border: 0px; border-radius: 10px; background-color: #FF9F00; font-size: 14px; font-weight: 500; width: 100px !important;" v-on:click="fnDetail()">내용 확인</button><br>
                    ※ 지정확인서 제출방법 : 고객센터 ▶ 공지사항 ▶ <span class="ol_li_txt_span">‘지정 확인서 작성법 안내’</span> 게시물 참조 
                    <button class="btn" style="border: 0px; border-radius: 10px; background-color: #FF9F00; font-size: 14px; font-weight: 500; width: 150px !important;" v-on:click="fnView('/board/detail?boardType=2&no=136')">제출 양식 바로가기</button><br>
                    <span style="color: black;">- 작성 후 저장 (카메라 촬영, 스캔, 서명이 포함된 파일 등) →  [파일 선택]을 눌러 등록 → [제출하기] → 담당자 승인 (영업일 기준 1~2일 소요) </span>
                  </span>
                </li>
              </ol>
            </div>
            <div class="table_toggle">
              <div class="btn_wrap" v-if="isOnline">
                <button class="toggle_btn on"><span>온라인 교육</span></button><!--'선택 시: on'-->
                <button class="toggle_btn" v-on:click="fnChange"><span>집합 교육</span></button>
              </div>
              <div class="btn_wrap" v-if="!isOnline">
                <button class="toggle_btn" v-on:click="fnChange"><span>온라인 교육</span></button>
                <button class="toggle_btn on"><span>집합 교육</span></button>
              </div>
            </div>
            <div class="table">
              <div class="row head">
                <div class="cell fix151"><p>주문번호</p></div>
                <div class="cell"><p>신청내용</p></div>
                <div class="cell fix150"><p>업체명</p></div>
                <div class="cell fix150"><p>상태</p></div>
                <div class="cell fix150"><p>완료일</p></div>
                <div class="cell fix150"><p>지정확인서</p></div>
              </div>
              <div class="page_con" v-show="list.length === 0">
                <div class="empty_wrap">
                  <div class="empty">
                    <span>내역이 존재하지 않습니다.</span>
                  </div>
                </div>
              </div>
              <div class="row" v-for="(item, index) in list" :key="index">
                <div class="cell fix151" v-if="isOnline"><p>{{ item.edu.onlineEduCode }}</p></div>
                <div class="cell fix151" v-else><p>{{ item.edu.offlineEduCode }}</p></div>
                <div class="cell"><p class="left">{{ isOnline ? '온라인' : '집합' }} 위생교육 {{ `(${$ConstCode.CODE_INDUSTRY_TYPE[item.edu.industryType].name})`}} </p></div>
                <div class="cell fix150"><p>{{ item.edu.businessName }}</p></div>
                <div class="cell fix150">
                  <p class="state"
                     :class="{grey :item.edu.isHygiene === 2, light_grey: item.edu.isHygiene === 3 || $Util.isEmpty(item.edu.isHygiene)}">
                    {{ item.edu.isHygiene === 1 ? '승인완료' : item.edu.isHygiene === 2 ? '승인중' : item.edu.isHygiene === 3 ? '미제출' : '반려'}}
                  </p>
                </div>
                <div class="cell fix150">
                  <p>{{ !$Util.isEmpty(item.edu.hygieneDate) ? `${$moment(item.edu.hygieneDate).format('YYYY.MM.DD')}` : '-' }}</p>
                </div>
                <div class="cell fix150">
                  <div class="btn_wrap">
                    <div class="file_btn">
                      <p class="state grey" id="fileName" v-show="!$Util.isEmpty(fileName[index]) && fileName[index].length > list.length">{{ fileName[index] }}
                      </p>
                      <label v-show="!$Util.isEmpty(fileName[index]) && fileName[index].length > list.length && item.edu.isHygiene !== 1"
                             style="position: absolute;cursor: pointer; margin-top: 0.5px;"
                       @click="fnRemove($event, index, item)">
                        <img src="@/assets/static/images/times-circle-solid.svg" style="width:25px;">
                      </label>
                      <input
                        class="btn bo"
                        :ref="`file_upload_${index}`"
                        :id="`file_upload_${index}`"
                        name="fileName"
                        type="file"
                        accept="image/jpeg,.pdf"
                        @change="fnFileUpload($event, index)"
                        :disabled="item.edu.isHygiene === 1"/>
                      <label :for="`file_upload_${index}`"><span>파일 선택</span></label>
                    </div>
                    <button class="btn bo" @click="fnSubmit(item, index)" :disabled="item.edu.isHygiene === 1 && item.edu.isEnd === 1">제출하기</button>
                  </div>
                </div>
              </div>
            </div>
            <base-pagination
              v-model="pageInfo.page"
              v-on:input="fetchData"
              v-on:change="fetchData"
              v-bind:pageInfo="pageInfo"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import BasePagination from '@/components/common/base/BasePagingation'
import BoardTabIndex from '@/components/common/board/tab-index'

export default {
  name: 'mystudy-confirmation',
  description: '나의학습방 지정확인서 제출',
  components: { BoardTabIndex, BasePagination },
  computed: {
    eduType: function() {
      return this.$store.getters['education/getEduType']
    }
  },
  watch: {
    'eduType': function() {
      this.fileName = []
    }
  },
  data() {
    return {
      isOnline: true,
      pageInfo: {},
      list: [],
      type: -1,
      file: [],
      fileName: [],
      uploadBtn: true,
      isSubmit: false,
      params: {
        page: 1,
        limit: 10,
        positionType: 2,
        isFinishPayment: 1
      },
      endInterval: null
    }
  },
  beforeDestroy() {
    window.clearInterval(this.endInterval)
  },
  mounted() {
    this.endInterval = setInterval(() => {
      const nowDate = this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      const payEndDate = this.$moment(new Date().getFullYear() + '-12-31 23:58:00').format('YYYY-MM-DD HH:mm:ss')
      if (nowDate >= payEndDate && new Date().getFullYear() === 2022) {
        clearInterval(this.endInterval)
        alert(`2022년도 기존영업자 교육이 종료되었습니다.`)
        this.$router.replace('/mystudy/list')
        return false
      }
    }, 1000)
  },
  async created() {
    let res = {}
    if (this.eduType.isOnline === 1) {
      // 지정확인서 미표출되는 오류 해결 (서남아이에스 임현아, 2024-08-20)
      this.params.isEnd = -1
      res = await this.$OnlineClassSvc.fetchUserOnlineClass(this.params)
      res.entity.list.forEach(item => {
        this.fileName.push(item.edu.fileName)
      })
      this.isOnline = true
      this.isOnline = true
    } else {
      this.params.isEnd = -1
      res = await this.$OfflineEduSvc.fetchUserOfflineClass(this.params)
      res.entity.list.forEach(item => {
        this.fileName.push(item.edu.fileName)
      })
      this.isOnline = false
    }
    if (res.code === 1) {
      this.list = res.entity.list
      this.pageInfo = res.entity.pageInfo
    }
  },
  methods: {
    fetchData: async function() {
      let res = {}
      if (this.eduType.isOnline === 1) {
        res = await this.$OnlineClassSvc.fetchUserOnlineClass(this.params)
        console.log('res', res)
        // 지정확인서 미표출되는 오류 해결 (서남아이에스 임현아, 2024-08-20)
        res.entity.list.forEach(item => {
          this.fileName.push(item.edu.fileName)
        })
        this.isOnline = true
      } else {
        this.params.isEnd = -1
        res = await this.$OfflineEduSvc.fetchUserOfflineClass(this.params)
        // 지정확인서 미표출되는 오류 해결 (서남아이에스 임현아, 2024-08-20)
        res.entity.list.forEach(item => {
          this.fileName.push(item.edu.fileName)
        })
        this.isOnline = false
      }
      if (res.code === 1) {
        this.list = res.entity.list
        this.pageInfo = res.entity.pageInfo
      }
    },
    fnChange: function() {
      this.isOnline = !this.isOnline
      if(this.isOnline) this.type = 1
      else this.type = 2
      this.$store.commit('education/setEduType', {eduData: {isOnline: this.type}})
      this.fetchData()
    },
    fnView: function(path) {
      this.$router.push({ path: path })
    },
    fnBoard: function(path, boardType) {
      this.$store.commit('education/setBoardType', { boardData: { boardType: boardType } })
      this.$router.push({ path: path })
    },
    fnSubmit: async function(item, index) {
      // 아무 파일도 선택하지 않았을 경우
      if(this.file[index] === undefined) {
        return false
      }
      this.isSubmit = true
      const params = {}
      let res = {}
      if(item.edu.isHygiene === 2) {
        alert('관리자 승인중입니다.')
        return false
      }
      params.file = this.file[index]
      params.fileName = this.fileName[index]
      params.isHygiene = 2
      if (this.isOnline) {
        params.onlineEduNo = item.edu.onlineEduNo
        res = await this.$OnlineEduSvc.updateUserOnlineInfo(params)
      } else {
        params.offlineEduNo = item.edu.offlineEduNo
        res = await this.$OfflineEduSvc.updateUserOfflineInfo(params)
      }
      if (res.code === 1) {
        alert('제출되었습니다.')
        await this.fetchData()
      }
    },
    fnRemove: async function (file, index, item) {
      if (!confirm('삭제하시겠습니까?')) return false
      let res = {}
      const params = {}
      if (this.isOnline) {
        params.onlineEduNo = item.edu.onlineEduNo
        params.isHygiene = item.edu.isHygiene
        res = await this.$OnlineEduSvc.updateHygieneFile(params)
      } else {
        params.offlineEduNo = item.edu.offlineEduNo
        params.isHygiene = item.edu.isHygiene
        res = await this.$OfflineEduSvc.updateHygieneFile(params)
      }
      if (res.code === 1) {
        // 파일 삭제
        this.fileName[index] = ''
        alert('삭제되었습니다.')
        await this.fetchData()
      }
    },
    fnFileUpload: function(file, index) {
      this.isSubmit = true
      const fileValue = this.$refs[`file_upload_${index}`][0].files[0].name
      this.fileName.push(index)
      this.fileName[index] = fileValue
      this.file.push(index)
      this.file[index] = file.target.files[0]
      this.uploadBtn = false
    },
    // 식품위생관리책임자(위생관리자) 관련 법령 안내 팝업 표출 (서남아이에스 임현아, 2024-11-19)
    fnDetail: function() {
      this.$store.commit('layout/setModalAlert', { modalAlertName: this.$modalAlertNames.COMMONNOTICE })      
    }
  }
}
</script>

<style scoped>

 #fileName {
   display: inline-block;
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   width: 110px;
 }

</style>
