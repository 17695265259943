export default ({
  namespaced: true,
  state: {
    boardTab: { boardType: 1, subType: 1 }
  },
  getters: {
    getBoardType(state) {
      state.boardTab = localStorage.boardTab || '{}'
      return JSON.parse(state.boardTab)
    }
  },
  mutations: {
    setBoardType (state, { boardTab = {} }) {
      state.boardTab = boardTab
      localStorage.boardTab = JSON.stringify(boardTab)
    }
  }
})
