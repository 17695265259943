'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
/**
 * 공통 API Service
 * @class BranchSvc
 */
class BranchSvc extends ServiceExec {

  /**
   *  지회지부 리스트
   *  @param limit {integer} 10
   *  @param page {integer} 1
   *  @param seatchText {string}
   *  @param token {} token
   */
  fetchBranchList(params = {}) {
    return this
      .get(`/api/branch/fetchBranchList`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  지회지부 디테일 상세보기
   *  @param branchNo {integer} branchNo
   *  @param limit {integer} limit
   *  @param page {integer} page
   *  @param token {} token
   */
  fetchBranchDetailList(params = {}) {
    return this
      .get(`/api/branch/fetchBranchDetailList`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  지회지부 상세보기
   *  @param branchNo {integer} branchNo
   *  @param token {} token
   */
  fetchData(params = {}) {
    return this
      .get(`/api/branch/fetchData`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  지회지부 리스트
   *  @param limit {integer} 10
   *  @param page {integer} 1
   *  @param token {} token
   */
  branchList(params = {}) {
    return this.get(`/api/branch/branchList`, params)
      .then(response => {
        return response.data
      })
  }

}
export default new BranchSvc()
