export default {
  /**
   * install function
   * @param  {Vue} Vue
   * @param  {object} options  lazyload options
   */
  install (Vue) {
    Vue.directive('FixedHeader', {
      bind (el, binding) {
        let isFirst = false
        let staticOffset = 0
        let time = 500
        window.removeEventListener('scroll', fandelScroll)
        let lastOffset = el.offsetTop
        setTimeout(() => {
          lastOffset = el.offsetTop
          staticOffset = el.offsetTop
          window.addEventListener('scroll', fandelScroll)
        }, 300)
        let animateCofig = binding.value === undefined ? {} : binding.value
        if (animateCofig['animation-name'] === undefined) animateCofig['animation-name'] = 'fadeIn'
        if (animateCofig['animation-duration'] === undefined) animateCofig['animation-duration'] = '0.3'
        let isVisible = function (el) {
          let viewTop = window.pageYOffset
          let elTop = el.offsetTop
          elTop = elTop >= lastOffset ? elTop : lastOffset
          return viewTop >= elTop
        }
        let isShow = function (el) {
          if (isVisible(el)) {
            const temp = lastOffset
            setTimeout(() => {
              if (temp === 0) {
                lastOffset = staticOffset
              } else {
                lastOffset = temp
              }
            }, 90)
            lastOffset = 0
            el.classList.add('menu_detail_head')
          } else {
            console.log('!')
            el.classList.remove('menu_detail_head')
            // el.removeClass = 'menu_detail_head'
          }
        }
        var lastClick = Date.now()
        let fandelScroll = function () {
          var rate = 0
          if (Date.now() - lastClick >= rate) {
            if (!isFirst) {
              lastOffset = el.offsetTop
              isFirst = true
            }
            isShow(el, animateCofig)
            lastClick = Date.now()
          }
        }

        setTimeout(function () {
          time = 1
          isShow(el, animateCofig)
        }, time)
      }
    })
  }
}
