<template>
  <div>
    <component-board-tap :isPrint="true" />
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <!-- 홈페이지 유지보수 개발요청안 | 사이드 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
          <side-banner v-bind:isURL="isURL"></side-banner>
          <component-edu-type />
          <div class="page_ttl">
            <h1 class="h1">
              <span>교육접수증 출력</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="table">
              <div class="row head">
                <div class="cell fix151"><p>주문번호</p></div>
                <div class="cell"><p>신청 내용</p></div>
                <div class="cell fix150"><p>업체명</p></div>
                <div class="cell fix150"><p>상태</p></div>
                <div class="cell fix150"><p>완료일</p></div>
                <div class="cell fix150"><p>교육접수증</p></div>
              </div>
              <div class="page_con" v-if="list.length === 0">
                <div class="empty_wrap">
                  <div class="empty">
                    <span>내역이 존재하지 않습니다.</span>
                  </div>
                </div>
              </div>
              <div class="row" v-for="(item, index) in list" :key="index">
                <div class="cell fix151"><p>{{ item.edu.offlineEduCode }}</p></div>
                <div class="cell"><p class="left">집합 위생교육</p></div>
                <div class="cell fix150"><p>{{ item.edu.businessName }}</p></div>
                <div class="cell fix150">
                  <p class="state">완료</p>
                </div>
                <div class="cell fix150">
                  <p>{{ $Util.isEmpty(item.edu.endDate) ? '-' : $moment(item.edu.endDate).format('YYYY-MM-DD HH:mm') }}</p>
                </div>
                <div class="cell fix150">
                  <button class="btn bo" @click="fnPrint(item)" v-show='item.edu.isEnd !== 1'>출력하기</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 홈페이지 유지보수 개발요청안 | 푸터 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
      <bottom-banner v-bind:isURL="isURL"></bottom-banner>
    </main>
  </div>
</template>

<script>
import ComponentEduType from '@/components/common/edu/edu-type'
import ComponentBoardTap from '@/components/common/edu/board-tap'
import SideBanner from '@/components/common/banner/SideBanner.vue'
import BottomBanner from '@/components/common/banner/BottomBanner.vue'
export default {
  name: 'edu-print',
  description: '교육접수증 출력',
  components: { ComponentBoardTap, ComponentEduType, SideBanner, BottomBanner },
  computed: {
    eduType: function() {
      return this.$store.getters['education/getEduType']
    }
  },
  data() {
    return {
      params: {
        salesType: '',
        industryType: '',
        inOnline: '',
        isFinishPayment: 1
      },
      list: [],
      isURL: ''
    }
  },
  watch: {
    'eduType.industryType': function(val) {
      this.params.industryType = val
      this.fetchData()
      this.searchURL()
    }
  },
  async created() {
    this.searchURL()
    this.params.salesType = this.eduType.salesType
    this.params.industryType = this.eduType.industryType
    this.params.eduType = this.eduType.isOnline
    const res = await this.$OfflineEduSvc.fetchUserOfflineEduList(this.params)
    console.log(res)
    if (res.code === 1) {
      this.list = res.entity.list
    }
  },
  methods: {
    searchURL: function(){
      if (this.eduType.industryType === 1) {
        this.isURL = '16'
      }
      if (this.eduType.industryType === 2) {
        this.isURL = '21'
      }
      if (this.eduType.industryType === 3) {
        this.isURL = '26'
      }
    },
    fnPrint: function(item) {
      this.$store.commit('layout/setModalAlert', { data: { item }, modalAlertName: this.$modalAlertNames.RECEIPT })
    },
    fetchData: async function() {
      this.params.salesType = this.eduType.salesType
      this.params.industryType = this.eduType.industryType
      this.params.eduType = this.eduType.isOnline
      const res = await this.$OfflineEduSvc.fetchUserOfflineEduList(this.params)
      if (res.code === 1) {
        this.list = res.entity.list
      }
    }
  }
}
</script>

<style scoped>

</style>
