<template>
  <!-- 홈페이지 유지보수 개발요청안 | 사이드 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
  <div id="sidebanner" v-if="longItems.length > 0 || shortItems.length > 0" :style="{ top: bannerTop, left: bannerLeft }"
    style="position: absolute; width: 150px; margin: 10px 25px;">
    <!-- Long 배너 -->
    <div v-if="longItems.length > 0">
      <div class="side_bnr_log_url" v-if="(typeof longItems[0].url !== 'undefined') && (longItems[0].url.length > 0)"
        v-on:click="fnMoveURL(longItems[0].url)">
        <img :src="$ConstCode.getImagePath(longItems[0].imagePath, '720/')" alt="" />
      </div>
      <div class="side_bnr_log" v-else>
        <img :src="$ConstCode.getImagePath(longItems[0].imagePath, '720/')" alt="" />
      </div>
    </div>
    <!-- Long 배너 -->
    <!-- Short 배너 -->
    <div v-if="shortItems.length > 0">
      <div class="img_wrap_short_url"
        v-if="(typeof shortItems[0].url !== 'undefined') && (shortItems[0].url.length > 0)"
        v-on:click="fnMoveURL(shortItems[0].url)">
        <img :src="$ConstCode.getImagePath(shortItems[0].imagePath, '720/')" alt="" />
      </div>
      <div class="img_wrap_short" v-else>
        <img :src="$ConstCode.getImagePath(shortItems[0].imagePath, '720/')" alt="" />
      </div>
      <div v-if="shortItems.length > 1">
        <div class="img_wrap_short_url"
          v-if="(typeof shortItems[1].url !== 'undefined') && (shortItems[1].url.length > 0)"
          v-on:click="fnMoveURL(shortItems[1].url)">
          <img :src="$ConstCode.getImagePath(shortItems[1].imagePath, '720/')" alt="" />
        </div>
        <div class="img_wrap_short" v-else>
          <img :src="$ConstCode.getImagePath(shortItems[1].imagePath, '720/')" alt="" />
        </div>
      </div>
    </div>
    <!-- Short 배너 -->
  </div>
</template>
<script>
export default {
  name: 'SideBanner',
  props: {
    isURL: {
      type: [String],
      description: 'url 경로',
      default: () => false
    }
  },
  data: function () {
    return {
      params: {
        page: 1,
        limit: 2,
        site: 2,
        position: 'Side',
        sizeType: '',
        connectType: 1,
        menuType: '',
        isToday: 1
      },
      longItems: {
      },
      shortItems: {
      },
      siteUrl: ''
    }
  },
  async created() {
    await this.fnShowBanner()
  },
  watch: {
    // URL 은 같으나 표출 화면이 다른 경우 사용
    'isURL': async function () {
      await this.fnShowBanner()
    }
  },
  mounted() {
    // 창 크기 변경 시 이벤트 리스너 등록
    window.addEventListener('resize', this.setElementClass);
  },
  computed: {
    bannerTop() {
      if (this.siteUrl === '0') {
        return '600px'
      }else if (this.siteUrl === '55' || this.siteUrl === '56' || this.siteUrl === '57') {
        return '200px'        
      } else {
        return '400px'
      }
    },
    bannerLeft() {
      if (window.innerWidth < 1200) {
        return '1200px';
      } else {
        return 'calc(50% + 1200px / 2)';
      }
    }
  },
  methods: {
    searchURL() {
      if (this.isURL === '/main') { this.siteUrl = '0' }
      else if (this.isURL === '/mystudy/list') { this.siteUrl = '28' }
      else if (this.isURL === '/mystudy/list/studyroom') { this.siteUrl = '29' }
      else if (this.isURL === '/mystudy/list/auth') { this.siteUrl = '30' }
      else if (this.isURL === '/mystudy/list/exam') { this.siteUrl = '31' }
      else if (this.isURL === '/mystudy/list/survey') { this.siteUrl = '32' }
      else if (this.isURL === '/mystudy/print') { this.siteUrl = '33' }
      else if (this.isURL === '/mystudy/confirmation') { this.siteUrl = '34' }
      else if (this.isURL === '/mystudy/replay/list') { this.siteUrl = '35' }
      else if (this.isURL === '/mystudy/replay/view') { this.siteUrl = '36' }
      else if (this.isURL === '/info/online') { this.siteUrl = '43' }
      else if (this.isURL === '/info/offline') { this.siteUrl = '44' }
      else if (this.isURL === '/info/board') { this.siteUrl = '45' }
      else if (this.isURL === '/support/board') { this.siteUrl = '47' }
      else if (this.isURL === '/support/boarddetail') { this.siteUrl = '48' }
      else if (this.isURL === '/support/boardwrite') { this.siteUrl = '49' }
      else if (this.isURL === '/report/board') { this.siteUrl = '50' }
      else if (this.isURL === '/report/boarddetail') { this.siteUrl = '51' }
      else if (this.isURL === '/report/boardwrite') { this.siteUrl = '52' }
      else if (this.isURL === '/support/remote') { this.siteUrl = '53' }
      else if (this.isURL === '/mypage') { this.siteUrl = '54' }
      else if (this.isURL === '/login') { this.siteUrl = '55' }
      else if (this.isURL === '/login/findid') { this.siteUrl = '56' }
      else if (this.isURL === '/login/findpw') { this.siteUrl = '57' }
      else { 
        console.log('Side')
        console.log(this.isURL)
        this.siteUrl = this.isURL }
    },
    async fnShowBanner() {
      this.searchURL()
      // 배너 정보 가져오는 곳 (타입, 이미지, 이동 URL 등등)
      // Long 타입 불러오기
      // 다중으로 사용하고 있을 경우, 해당하는 배너를 찾기 위함으로 limit 주석
      // this.params.limit = 1
      this.params.sizeType = 'L'
      this.params.menuType = this.siteUrl

      const res = await this.$BannerSvc.AdBannerList(this.params)
      if (res.entity.list && Array.isArray(res.entity.list)) {
        this.longItems = res.entity.list.slice(0, 1)
      }

      // 다중으로 사용하고 있을 경우, 해당하는 배너를 찾기 위함으로 limit 주석
      // this.params.limit = 2
      this.params.sizeType = 'S'

      const res2 = await this.$BannerSvc.AdBannerList(this.params)
      if (res2.entity.list && Array.isArray(res2.entity.list)) {
        this.shortItems = res2.entity.list.slice(0, 2)
      }
    },
    fnMoveURL: function (url) {
      window.location.href = url
    },
    setElementClass: function () {
      // 창 크기 줄여도 사이드 배너 잘 보이도록
      if (window.innerWidth < 1200) {
        document.getElementById('sidebanner').style.left = '1200px';
      } else {
        document.getElementById('sidebanner').style.left = 'calc(50% + 1200px / 2)';
      }
    }
  }
}
</script>
<style>
.side_bnr_log_url {
  width: 150px;
  height: 400px;
  cursor: pointer;
}

.side_bnr_log {
  width: 150px;
  height: 400px;
}

.img_wrap_short_url {
  width: 150px;
  height: 200px;
  cursor: pointer;
}

.img_wrap_short {
  width: 150px;
  height: 200px;
}

.side_bnr_log_url img,
.side_bnr_log img,
.img_wrap_short_url img,
.img_wrap_short img {
  width: 100%;
  height: 100%;
}
</style>
