<template>
  <div>
    <board-tab-index :board-type='1' @fetchData='fetchData' />
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <div class="lnb">
            <ul>
              <li><p class="home"></p></li>
              <li><p>나의 학습방</p></li>
              <li><p>복습하기</p></li>
            </ul>
          </div>
          <div class="page_ttl">
            <h1 class="h1">
              <span>복습하기</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="inform_box mb60">
              <ol class="nb_dot li_space">
                <span class="medium">※ 복습하기는 교육 결제 완료 후 1달 이내로 다시 보기가 가능합니다.</span>
                <br>
              </ol>
            </div>
            <div class="table">
              <div class="row head">
                <div class="cell"><p>강의명</p></div>
                <div class="cell fix150"><p>강의 수</p></div>
                <div class="cell fix150"><p>상태</p></div>
                <div class="cell fix150"><p>재수강</p></div>
              </div>
              <div class="page_con" v-show="list.length === 0">
                <div class="empty_wrap">
                  <div class="empty">
                    <span>내역이 존재하지 않습니다.</span>
                  </div>
                </div>
              </div>
              <div class="row" v-for="(item, index) in list" :key="index">
                <div class="cell"><p>{{ $ConstCode.CODE_SALES_TYPE[item.edu.salesType].name }}
                  ({{ $ConstCode.CODE_INDUSTRY_TYPE[item.edu.industryType].name }})</p></div>
                <div class="cell fix150">
                  <p>{{ item.class.length }} 강의</p>
                </div>
                <div class="cell fix150">
                  <p class="state" :class="{light_grey : item.edu.isEnd === 2}">{{ item.edu.isEnd == 1 ? '수료' : '미수료'}}</p>
                </div>
                <div class="cell fix150">
                  <button class="btn bo" @click="$router.push({ path : '/mystudy/replay/list'})">바로가기</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import BoardTabIndex from '@/components/common/board/tab-index'
export default {
  name: 'mystudy-replay',
  components: { BoardTabIndex },
  description: '온라인 교육 다시보기기',
  data() {
    return {
      list: {},
      params: {
        page: 1,
        limit: 10,
        salesType: '',
        isFinishPayment: 1,
        isEnd: -1,
        rePlay: 1
      }
    }
  },
  async created() {
    const res = await this.$OnlineClassSvc.fetchUserOnlineClass(this.params)
    console.log('res', res)
    this.list = res.entity.list
  },
  methods: {
    fetchData: async function() {
      const res = await this.$OnlineClassSvc.fetchUserOnlineClass(this.params)
      console.log('res', res)
      this.list = res.entity.list
    },
    fnView: function(path) {
      this.$router.push({ path: path })
    },
    fnBoard: function(path, boardType) {
      this.$store.commit('education/setBoardType', { boardData: { boardType: boardType } })
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped>

</style>
