<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="lnb">
          <ul>
            <li><p class="home"></p></li>
            <li><p>나의 학습방</p></li>
            <li><p>수강하기</p></li>
          </ul>
        </div>
        <div class="page_ttl">
          <h1 class="h1">
            <span>본인인증</span>
          </h1>
        </div>
        <div class="page_con">
          <div class="inform_box center">
            <div>
              <span class="big bold">온라인 교육 수강을 위해 본인인증이 필요합니다.</span><br>
              <span class="small color">※ 본인의 회원가입 정보가 허위일 경우 불이익을 당할 수 있습니다.</span><br>
              <br>
              <span class="grey">
                            본인인증시 제공하는 정보는 해당 인증기관에서 직접 수집하며,<br>
                            인증 이외의 용도로 사용되지 않습니다.<br>
                            <br>
                            본인인증이 어려우신 분들에게는<br>
                            온라인교육 이수가 아닌 집합교육 이수를 권장합니다.<br>
                        </span>
              <span class="small color">(고객센터 – 공지사항 게시판 참조)</span>
            </div>
          </div>
          <div class="simple_auth">
            <button class="btn" @click="fnAuth" id="wait" disabled >간편 인증하기</button>
          </div>
          <div class="form" style="margin: 30px auto 0;width: 430px;" v-show="isAuthForm">
            <ul class="form_ul">
              <li class="form_li">
                <div class="form_in">
                  <p class="bind">{{ userData.userPhone }}</p>
                  <div class="inline time_count">
                    <div class="input">
                      <input type="number"  v-model="authNumber" id="authNumber">
                      <span class="time" v-show="isRunning">{{ count }}</span>
                    </div>
                    <button class="btn bo grey" disabled v-on:click="fnConfirmAuth" id="block">인증하기</button>
                  </div>
<!--                  <p class="valid"> 유효성문구</p>&lt;!&ndash;'on 추가'&ndash;&gt;-->
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

export default {
  name: 'mystudy-list-auth',
  description: '나의학습방 수강목록 본인인증',
  data: function () {
    return {
      rUrl: this.$route.query.rUrl,
      count: '03:00',
      timeCounter: 180, // 3분
      isAuthForm: false,
      authNumber: '', // 인증번호
      authConfirmNumber: '', // 발송된 인증번호,
      isAuth: false,  // 인증번호 인증여부
      isRunning: false, // 타이머 작동 여부,
      msg: {
        send: '인증'
      }
    }
  },
  computed: {
    userData: function() {
      return this.$store.getters['user/getUserData']
    }
  },
  mounted() {
    document.getElementById("wait").disabled = false
  },
  destroyed() {
    this.fnStop()
  },
  methods: {
    fnConfirmAuth: function() {
      if(this.$Util.isEmpty(this.authNumber)) return false
      if (this.authNumber !== this.authConfirmNumber) {
        alert('인증번호가 일치하지 않습니다.')
        return false
      } else {
        this.$UserSvc.updateAuthUser()
        this.fnStop()
        alert('인증되었습니다.')
        document.getElementById("block").disabled = true
        this.isRunning = false // 타이머 숨기기
        this.isAuth = true
        this.$router.push({path: this.$route.query.rUrl})
      }
    },
    fnAuth: function() {
      if (this.$Util.isEmpty(this.userData.userPhone)) return false

      // 홈페이지 유지보수 개발요청안 | 교육 진행 시, 휴대폰 번호 맞는지 확인 (서남아이에스 임현아, 2024-07-20)
      // const message = '휴대폰 번호 ' + this.userData.userPhone + '로 본인 인증 번호가 발송됩니다.<br/> 진행하시겠습니까?'
      const message = '휴대폰 번호 ' + this.userData.userPhone + '가 <br/> 신청자 본인 연락처가 맞으십니까?'
      const type = 'checkPhoneNumber'
      
      this.$store.commit('layout/setModalAlert', { data: { message, type }, modalAlertName: this.$modalAlertNames.COMMONCONFIRMINFO, callBack: this.fnAuthSend })      
    },
    fnAuthSend: function(){
      // 발송후 핸드폰 번호칸은  readonly
      this.isAuthForm = true
      // 인증번호 타이머 시작 (세팅시간 3분)
      this.fnTimer()
      this.isRunning = true
      document.getElementById("block").disabled = false
      document.getElementById("wait").disabled = true
      const params = {}
      params.userNo = this.userData.userNo
      params.userPhone = this.userData.userPhone
      this.$AuthSvc.send(params).then(res => {
        if (res.code === 1 ) {
          this.authConfirmNumber = res.entity
          // 개발 환경
          // this.authNumber = this.authConfirmNumber
        }
      })
    },
    fnTimer: function() {
      this.fnStop()
      this.timer = setInterval( () => {
        this.timeCounter --
        if (this.timeCounter <= 0) {
          this.fnStop()
          alert('인증 번호가 만료되었습니다.\n다시 인증해주세요.')
          document.getElementById("block").disabled = true
        }
        this.disabled = true
        let time = this.timeCounter / 60
        let min = parseInt(time)
        let sec = Math.round((time - min) * 60)
        this.count = this.pad(min, 2) + ':' + this.pad(sec, 2)
      }, 1000)
    },
    pad: function(n, width){
      n = n + ''
      return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n
    },
    fnStop: function() {
      clearInterval(this.timer)
      document.getElementById("wait").disabled = false
      this.timeCounter = 180
      this.count = "03:00"
    }
  }
}

</script>

<style scoped>

</style>
