<template>
  <div>
    <board-tab-index :board-type='1' @fetchData='fetchData' />
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <div class="lnb">
            <ul>
              <li><p class="home"></p></li>
              <li><p>나의 학습방</p></li>
              <li><p>수강하기</p></li>
            </ul>
          </div>
          <div class="page_ttl">
            <h1 class="h1">
              <span>수강하기</span>
            </h1>
          </div>
          <div class="page_con">
            <!-- 문구 변경 (서남아이에스 임현아, 2024-11-19) -->
            <!-- <div class="inform_box mb30 ft18">
              <span class="grey bold">★ 온라인 위생교육 수료 완료 조건</span>
              <br>
              <span class="grey">기존영업자: 총 3과목 (3시간) 수강</span>
              <ol class="ol dot">
                <li class="grey">
                            <span class="ol_li_txt">
                               {{ `필수 2과목, 필수(선택) 1과목 (*식중독의 이해, ${form.info.industryType === 1 ? '식품관련제도' : '위생관리'} 중 택 1과목)` }}
                            </span>
                </li>
                <li class="grey">
                            <span class="ol_li_txt">
                               교육평가 시험, 설문조사
                            </span>
                </li>
              </ol>
              <br>
              <span class="color bold" style='color: red;'>
                        ※ ‘필수 2과목, 필수(선택) 1과목’ 모두 수료 후 ‘교육평가시험, 설문조사’까지 완료하셔야 교육 수료 처리 됩니다.
                    </span>
              <br>
              <br>
              <span class="bold strong">★ 강의 수강 방법</span>
              <br>
              <span class="strong">- 강의는 순차적으로 수강하기<br>
  {{ `예시) ${form.info.industryType === 1 ? '식품위생법Ⅱ → 위생관리' : '단체급식의 이해 → 식품위생법'} → 식중독의 이해 또는 ${form.info.industryType === 1 ? '식품관련제도' : '위생관리'} → 교육평가시험 → 설문조사 → 수강완료` }}
                    </span>
              <br>
              <br>
              <ol class="ol dot">
                <span class="grey">※ (선택)과목 수강은 선택 사항 입니다.</span><br>
                <span class="grey">※ 크롬(chrome)브라우저에서 원활한 강의 수강 및 수료증 출력이 가능합니다.</span><br>
                <span class="grey">※ 위생관리자의 경우 수강 완료 전 지정확인서를 미리 제출했는지 확인하세요.
                                <button class="txt_btn" v-on:click="fnView('/mystudy/confirmation')">지정확인서 제출하기</button></span>
              </ol>
            </div> -->
            <div class="inform_box mb30 ft18">
              <span class="ol_li_txt" style="color: #41AC26; font-weight: 700;">※ 기존영업자 온라인 위생교육 수료 완료 조건</span><br>
              <span><strong>- 총 3과목 수강 (</strong>[<span style="color:red">필수</span>] 2과목 / [<span style="color:red">필수</span><span style="color:blue">선택</span>] 택 1)<strong> → 교육평가시험 → 설문조사 → 수강완료</strong></span>
              <ol class="ol dot">
                <li>
                  <span class="ol_li_txt">
                    [<span class="grey">자율선택</span>] 과목 : 추가적인 정보 제공을 위한 강의로, 위생교육 수료와는 무관<br>
                  </span>
                </li>
                <li>
                  <span class="ol_li_txt">
                    <strong>위생관리자 : 지정확인서 제출</strong> 후 승인되어야만 수강 완료 가능 (영업일 기준 1~2일 소요)
                    <button class="btn" style="border: 0px; border-radius: 10px; background-color: #FF9F00; font-size: 14px; font-weight: 500; width: 150px !important;" v-on:click="fnView('/board/detail?boardType=2&no=136')">제출 양식 바로가기</button><br>
                  </span>
                </li>
              </ol>
              <br>
              <br>
              <ol class="ol dot">
                <span class="grey">※ PC 및 안드로이드는 크롬(Chrome)에서, iOS는 사파리 브라우저에서 원활한 수강이 가능합니다.</span><br>
                <span class="grey">※ 학습진도율이 100%가 되어 수강완료 처리가 되어야만 수료된 것이니 꼭 확인해주세요.</span><br>
              </ol>
            </div>
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <div class="rule_list_ttl">
                <h3 class="h3">{{ form.info.businessName }}</h3>
              </div>
              <div class="progress-container" style="width: 540px;">
                <p class="progress-text">학습 진도율 {{percentage}}%</p>
                <div class="progress-bar" :style="barStyle"/>
              </div>
            </div>
            <div class="table">
              <div class="row head">
                <div class="cell"><p>강의명</p></div>
                <div class="cell fix100"><p>상태</p></div>
                <div class="cell fix180"><p>학습일시</p></div>
                <div class="cell fix180"><p>학습시간</p></div>
                <div class="cell fix180"><p>강의 총시간</p></div>
                <div class="cell fix180"><p>수강하기</p></div>
              </div>
              <div class="row" v-for="(item, index) in list" :key="index">
                <div class="cell">
                  <p class="left">
                    <!-- 한국외식산업협회 위생교육 수강 페이지 강의명 하이라이트 (서남아이에스 임현아, 2024-08-30) -->
                    <!-- 문구 변경 (서남아이에스 임현아, 2024-11-19) -->
                    <span>[</span>
                    <span v-if="item.isChoice === 1" style="color:red">필수</span>
                    <!-- <span v-else-if="item.isChoice === 2" style="color:green">선택</span> -->
                    <span v-else-if="item.isChoice === 2" style="color:grey">자율선택</span>
                    <span v-else><span style="color:red">필수</span><span style="color:blue">선택</span></span>
                    <span style="margin-right: 5px;">] </span>
                    <span style="font-weight: 700;">{{item.className}}</span>
                    <!-- {{ `[${item.isChoice === 1 ? '필수' : item.isChoice === 2 ? '선택': '필수(선택)'}] ${item.className}` }} -->
                  </p>
                </div>
                <div class="cell fix100"><p>{{ item.status === 1 ? '수강' : '미수강'  }}</p></div>
                <!-- 학습일시 표시 (서남아이에스 임현아, 2024-09-10) -->
                <div class="cell fix180"><p>{{ $Util.isEmpty(item.endStudyDate) ? ($Util.isEmpty(item.startStudyDate) ? 'ㅡ' : `${$Util.formattedDate(item.startStudyDate)} `) : `${$Util.formattedDate(item.endStudyDate)} ` }}</p></div>
                <div class="cell fix180"><p>{{ $Util.isEmpty(item.studyTime) ? '' : $Util.secondsToTimes2(item.studyTime) }}</p></div>
                <div class="cell fix180"><p>{{ item.classTime }}</p></div>
                <div class="cell fix180">
                  <p class="state" v-show="item.status === 1">수강완료</p>
                  <!-- <button
                    class="btn bo activate"
                    v-show="item.status === 2 && item.isChoice === 1 || item.status === 2 && item.isChoice === 2"
                    :disabled="index !==0 && list[choiceIndex(index -1)].isChoice === 1 && choiceIndex(index) === index && list[choiceIndex(index -1)].status === 2 && list[index].isChoice === 1"
                    @click="fnVideo(item.onlineClassNo, item.onlineEduNo)"
                  >수강하기</button>
                  <button
                    class="btn bo activate"
                    v-show="item.status === 2 && list[index].isChoice === 3"
                    :disabled="choiceLastIndex > 1 && list[choiceLastIndex - 1].status === 2  || choiceLastIndex === 1 && list[choiceLastIndex].status === 2"
                    @click="fnVideo(item.onlineClassNo, item.onlineEduNo)"
                  >수강하기</button> -->
                  <!-- 수강하기 버튼 표출 변경 (서남아이에스 임현아, 2024-11-19) -->
                  <button
                    class="btn bo activate"
                    v-show="item.status === 2 && item.isChoice === 1 || item.status === 2 && item.isChoice === 2"
                    @click="fnVideo(item.onlineClassNo, item.onlineEduNo)"
                  >수강하기</button>
                  <button
                    class="btn bo activate"
                    v-show="item.status === 2 && list[index].isChoice === 3"
                    @click="fnVideo(item.onlineClassNo, item.onlineEduNo)"
                  >수강하기</button>
                  <!--                 해당 내용 변경 2023-02-02 :disabled="list[choiceLastIndex -1].status === 2"-->
                </div>
              </div>
              <div class="row" v-if="form.info.positionType === 2">
                <div class="cell">
                  <p class="left">지정확인서 제출</p>
                  <button class="btn" style="border: 0px; border-radius: 10px; background-color: #FF9F00; font-size: 14px; font-weight: 500;" v-on:click="fnView('/board/detail?boardType=2&no=136')">제출 양식 바로가기</button><br>
                </div>
                <div class="cell fix100"><p>{{ form.info.isHygiene === 1 ? '수강' : '미수강' }}</p></div>
                <div class="cell fix180"><p>{{ $Util.isEmpty(form.info.hygieneDate) ? ' ㅡ' : $Util.formattedDate(form.info.hygieneDate) }}</p></div>
                <div class="cell fix540" v-if="form.info.salesType === 1">
                  <button class="btn bo" v-on:click="fnView('/mystudy/confirmation')"
                          :disabled=" classStatus > 0 || form.info.isHygiene === 1">
                  지정확인서 제출하기(위생관리자만 제출 필요)</button>
                </div>
                <div class="cell fix540" v-else>
                  <!-- 문구 변경 (서남아이에스 임현아, 2024-11-19) -->
                  <!-- <button class="btn bo" v-on:click="fnView('/mystudy/confirmation')"
                          :disabled=" classStatus > 0 || form.info.salesType === 2 && subClassStatus < 1 && choiceSelectIndex > 0 || form.info.isHygiene === 1"> -->
                  <button class="btn bo" v-on:click="fnView('/mystudy/confirmation')"
                          :disabled="form.info.isHygiene === 1">
                    지정확인서 제출하기(위생관리자만 제출 필요)</button>
                </div>
              </div>
              <div class="row">
                <div class="cell"><p class="left">교육평가시험</p></div>
                <div class="cell fix100"><p>{{ form.info.isEvaluation === 1 ? '수강' : '미수강' }}</p></div>
                <div class="cell fix180"><p>{{ $Util.isEmpty(form.info.evaluationDate) ? ' ㅡ' : $Util.formattedDate(form.info.evaluationDate) }}</p></div>
                <div class="cell fix540">
                  <button
                    v-if="form.info.positionType === 1"
                    class="btn bo"
                    v-on:click="fnView('/mystudy/list/exam' )"
                    :disabled="classStatus > 0 || form.info.salesType === 2 && subClassStatus < 1 && choiceSelectIndex > 0 || form.info.isEvaluation === 1">
                    교육평가시험 진행하기
                  </button>
                  <button
                    v-if="form.info.positionType === 2"
                    class="btn bo"
                    v-on:click="fnView('/mystudy/list/exam' )"
                    :disabled="classStatus > 0 || form.info.salesType === 2 && subClassStatus < 1 && choiceSelectIndex > 0 || form.info.isHygiene !== 1 || form.info.isEvaluation === 1 ">
                    교육평가시험 진행하기
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="cell"><p class="left">설문조사</p></div>
                <div class="cell fix100"><p>{{ form.info.isSurvey === 1 ? '수강' : '미수강' }}</p></div>
                <div class="cell fix180"><p>{{ $Util.isEmpty(form.info.surveyDate) ? ' ㅡ' : $Util.formattedDate(form.info.surveyDate) }}</p></div>
                <div class="cell fix540">
                  <button
                    class="btn bo"
                    :disabled="form.info.isEvaluation === 2 || form.info.isSurvey === 1"
                    v-on:click="fnSurvey">설문조사 진행하기</button>
                </div>
              </div>
              <div class="row">
                <div class="cell"><p class="left">수강완료</p></div>
                <div class="cell fix100"><p>{{ form.info.isEnd === 1 ? '수강' : '미수강' }}</p></div>
                <div class="cell fix180"><p></p></div>
                <div class="cell fix540">
                  <button class="btn disabled" @click="fnPrint" :disabled="form.info.isSurvey === 2 || form.info.isEnd === 2">{{ form.info.isEnd === 1 ? '수료증 출력' : '미수강' }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import Util from '@/common/utils/Util'

import BoardTabIndex from '@/components/common/board/tab-index'
export default {
  name: 'mystudy-list-studyroom',
  components: { BoardTabIndex },
  description: '나의학습방 수강목록 학습방',
  data() {
    return {
      form: {},
      list: [],
      education: {},
      params: {
        onlineClassNo: -1,
        onlineEduNo: -1,
        ipAddress: '',
        studyTime: '',
        status: 2,
        isStart: 0
      },
      user: {},
      currentIndex: 1,
      studyInterval: null,
      endInterval: null,
      percentage: 0 // 퍼센트 바의 값
    }
  },
  beforeDestroy() {
    window.clearInterval(this.studyInterval)
    window.clearInterval(this.endInterval)
  },
  mounted() {
    window.addEventListener('keydown', this.fnUpdateVideo)
    this.endInterval = setInterval(() => {
      const nowDate = this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      // console.log('nowDate', nowDate)
      const payEndDate = this.$moment(new Date().getFullYear() + '-12-31 23:58:00').format('YYYY-MM-DD HH:mm:ss')
      if (this.form.info.salesType === 2 && nowDate >= payEndDate && new Date().getFullYear() === 2022) {
        clearInterval(this.endInterval)
        alert(`2022년도 기존영업자 교육이 종료되었습니다.`)
        this.$router.replace('/mystudy/list')
        return false
      }
    }, 1000)
    
    // 학습률 퍼센트 계산기
    this.fnPercent()
  },
  async created() {
    console.log('this.$open')
    console.log(this.$open)
    this.$setPopupTerminal(this)
    const onlineEduNo = this.$route.query
    const res = await this.$OnlineEduSvc.fetchData(onlineEduNo)
    const res2 = await this.$UserSvc.fetchUser()
    if (res.code === 1) {
      this.form = res.entity
      this.list = res.entity.class
      this.user = res2.entity
    } else if (res.code === -8024) {
      alert(res.message)
      this.$router.go(-1)
    }
    
    // 학습률 퍼센트 계산기
    this.fnPercent()

    
    window.addEventListener('beforeunload', () => {
      this.$cookies.remove('studying')
    });
  },
  computed: {
    userData: function() {
      return this.$store.getters['user/getUserData']
    },
    // 필수 선택이 0보다 크면 true
    subClassStatus: function() {
      let subClassStatus = 0
        this.list.forEach(item => {
        if(item.isChoice === 3 && item.status === 1) {
          subClassStatus++
        }
      })
      return subClassStatus
    },
    // 강의상태(1:수료, 2:미수료)가 미수료일경우 리턴 false (버튼 활성화)
    classStatus: function() {
      let classStatus = 0
      this.list.forEach(item => {
        if(item.isChoice === 1 && item.status === 2) {
          classStatus++
        }
      })
      console.log(classStatus)
      return classStatus
    },
    choiceSelectIndex: function () {
      let choiceSelectIndex = 0
      this.list.forEach((item) => {
        if (item.isChoice === 3) {
          choiceSelectIndex++
          return false
        }
      })
      return choiceSelectIndex
    },
    choiceLastIndex: function () {
      let choiceLastIndex = 0
      this.list.forEach((item) => {
        if (item.isChoice === 1) {
          choiceLastIndex++
          return false
        }
      })
      console.log('choiceLastIndex', choiceLastIndex)
      return choiceLastIndex
    },    
    barStyle() {
      return {
        width: `${this.percentage}%`
      };
    }
  },
  methods: {
    choiceIndex: function (listIndex) {
      let index = 0
      this.list.forEach((item, idx) => {
        if (item.isChoice === 1 && idx === listIndex) {
          index = idx
          return false
        }
      })
      return index
    },
    fnView: function(path) {
      // 평가시험 답안 제출하기 Alert 추가 (서남아이에스 임현아, 2024-09-06)
      console.log(path)
      if (path === '/mystudy/list/exam') {
        alert('평가시험 시간에는 제한이 없습니다.\n시험 중 브라우저를 닫거나, 새로고침을 하는 경우\n새로운 문항이 출제되는 점 유의바랍니다.')
      }

      this.$router.push({
        path: path,
         query: {onlineEduNo: this.form.info.onlineEduNo, salesType: this.form.info.salesType, rUrl: this.$route.fullPath}
      })
    },
    fnSurvey: function() {
      this.$router.push({
        path: '/mystudy/list/survey',
        query: { no: this.$route.query.onlineEduNo, salesType: this.form.info.salesType, industryType: this.form.info.industryType, eduCode: this.form.code.code, rUrl: this.$route.fullPath }
      })
    },
    fnBoard: function(path, boardType) {
      this.$store.commit('education/setBoardType', { boardData: { boardType: boardType } })
      this.$router.push({ path: path })
    },
    fnUpdateVideo: function() {
      if( (event.ctrlKey === true && (event.keyCode === 78 || event.keyCode === 82)) || (event.keyCode === 116) ) {
        this.$UserSvc.userExtendSession()
        
        const user = this.$store.getters['user/getUserData']
      
        if (this.$Util.isEmpty(user)) {
          alert('로그인이 필요한 서비스입니다.')
          this.$router.push({ path: '/login' })
          return false
        }

        this.fnUpdate()
        this.fetchData()
      }
    },
    fnVideo: async function (onlineClassNo, onlineEduNo) {
      await this.$UserSvc.userExtendSession()

      const user = this.$store.getters['user/getUserData']
      
      if (this.$Util.isEmpty(user)) {
        alert('로그인이 필요한 서비스입니다.')
        this.$router.push({ path: '/login' })
        return false
      }

      let studying = null
      studying = this.$cookies.get('studying')
      if (!studying || studying !== null) {
        if (studying === '1' || studying === 1) {
          alert('이미 동영상 시청 중입니다.\n* 동영상 시청 중이 아니라면 쿠키 및 캐시 삭제 후 재시도 부탁드립니다.')
          return false
        }
      }

      // 수강하기 클릭시마다 1시간 본인인증시간 지났는지 체크하는 로직
      await this.fetchData()
      const authTime = new Date(this.user.authTime).getTime()
      const now = new Date().getTime()
      const sec = parseInt(now - authTime) / 1000
      const hour = parseInt(sec / 60 / 60)
      if (hour >= 1) {
        await this.$router.push({path: '/mystudy/list/auth', query: {rUrl: this.$route.fullPath}})
        return false
      }
      const option = "width=720, height=415, top=100, scrollbars, resizable"
      if (this.$open == null ||  this.$open.closed) {
        this.$cookies.set('studying', '1')
        // this.$windowOpenPop(`${this.$ConstCode.CODE_ENV_LIST.WEB_URL}/edu/gateway?onlineClassNo=${onlineClassNo}&onlineEduNo=${onlineEduNo}`, this, option)
        this.$windowOpenPop(`${this.$ConstCode.CODE_ENV_LIST.WEB_URL}/edu/video?onlineClassNo=${onlineClassNo}&onlineEduNo=${onlineEduNo}`, this, option)
        // this.$windowOpenPop(`https://www.kfoodedu.or.kr/edu/video?onlineClassNo=${onlineClassNo}&onlineEduNo=${onlineEduNo}`, this, option)
        // this.$windowOpenPop(`http://1.201.137.124:58202/edu/video?onlineClassNo=${onlineClassNo}&onlineEduNo=${onlineEduNo}`, this, option)
        this.studyInterval = window.setInterval(() => {
          try {
            if ( this.$open == null ||  this.$open.closed) {
              window.clearInterval(this.studyInterval)
              this.fnClosePopupEvent()
            }
          } catch (e) {
            this.$cookies.remove('studying')
            console.log('studyInterval catch e {}', e)
          }
        }, 500)
      }
    },
    fnClosePopupEvent: async function () {
      this.$cookies.remove('studying')
      await this.fnUpdate()
      await this.fetchData()
    },
    getReturnValue: function (values) {
      this.params.onlineClassNo = values.onlineClassNo
      this.params.ipAddress = values.ipAddress
      this.params.studyTime = values.studyTime
      this.params.status = values.status
      this.params.studyTime = values.studyTime
      this.params.onlineEduNo = values.onlineEduNo
    },
    fetchData: async function () {
      const params = {}
      params.onlineEduNo = this.form.info.onlineEduNo
      const res = await this.$OnlineEduSvc.fetchData(params)
      if (res.code === 1) {
        this.form = res.entity
        this.list = res.entity.class
      } else {
        alert(res.message)
        this.$router.go(-1)
      }
    },
    fnUpdate: async function () {
      await this.$UserOnlineSvc.updateEduStudyTime(this.params)
    },
    fnPrint: function () {
      this.$router.push({
        path: '/mystudy/print',
        query: { onlineEduNo: this.form.info.onlineEduNo }
      })
    },
    // 학습 진도율 퍼센트
    fnPercent: function () {
      this.percentage = 0
      let classPercentage = 0
      let twoChoice = 0

      // 강의 80%
      this.list.forEach(item => {
        const [minutes, seconds] = item.classTime.split(':').map(Number)
        const classSeconds = (minutes * 60) + seconds
        const progressRatio = Number(item.studyTime) / classSeconds
        const currentPercentage = Math.min(26.5 * progressRatio, 26.5)

        if (item.isChoice === 1) {
            classPercentage += currentPercentage
        } else if (item.isChoice === 3) {
          twoChoice = Math.max(twoChoice, currentPercentage)
          console.log(twoChoice + 'two')
        }
    })

    if (this.list.length === 1) {
      classPercentage = 80
    }

    classPercentage = Math.min(classPercentage + twoChoice, 80)
    this.percentage += classPercentage

      // 평가시험 10%
      if (this.form.info.isEvaluation === 1) {
        this.percentage += 10        
      }
      // 설문조사 10%
      if (this.form.info.isSurvey === 1) {
        this.percentage += 10
      }

      this.percentage = Math.round(this.percentage)
    }
  }
}
</script>

<style scoped>
.progress-container {
  width: 540px; /* 컨테이너의 고정 너비 */
  background-color: #bbbbbb; /* 컨테이너 배경색 */
  border-radius: 5px; /* 둥근 모서리 */
  overflow: hidden; /* 내용이 넘치지 않도록 */
  position: relative; /* 내부 요소의 절대 위치를 위한 기준 설정 */
}

.progress-bar {
  height: 30px; /* 퍼센트 바의 높이 */
  background: linear-gradient(90deg, #6f86d6 0%, #48c6ef 100%); /* 그라데이션 배경 */
  color: white; /* 텍스트 색상 */
  text-align: center; /* 텍스트 중앙 정렬 */
  line-height: 30px; /* 텍스트 수직 중앙 정렬 */
  font-weight: bold; /* 텍스트 두껍게 */
  position: relative; /* 절대 위치를 위한 기준 설정 */
}

.progress-text {
  position: absolute; /* 퍼센트 바 안에서의 절대 위치 설정 */
  width: 100%; /* 퍼센트 바의 전체 너비 */
  text-align: center; /* 텍스트 중앙 정렬 */
  top: 50%; /* 수직 중앙 정렬 */
  transform: translateY(-50%); /* 수직 중앙 정렬을 위한 변환 */
  font-size: 14px; /* 텍스트 크기 */
  z-index: 1; /* 텍스트를 위로 올려서 보이도록 함 */
  color: white;
}
</style>
