<template>
  <div class="modal_wrap">
    <div class="modal_inner center w1200">
      <div class="modal w80p">
        <div class="modal_con">
          <div class="licensing">
            <div class="search_cate">
              <div class="left">
                <div class="line">
                  <div class="search_cate_item">
                    <div class="search_cate_ttl">
                      <p>영업분야</p>
                    </div>
                    <div class="search_cate_con">
                      <div class="select">
                        <select name="foodGbn" id="foodGbn" v-model="foodGbn">
                          <option :value="'001'">음식점</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="search_cate_item">
                    <div class="search_cate_ttl">
                      <p>지역</p>
                    </div>
                    <div class="search_cate_con">
                      <div class="select">
                        <select name="sido" id="sido" v-model="sido">
                          <option value="">선택</option>
                          <option value="1100000000">서울특별시</option>
                          <option value="2600000000">부산광역시</option>
                          <option value="2700000000">대구광역시</option>
                          <option value="2800000000">인천광역시</option>
                          <option value="2900000000">광주광역시</option>
                          <option value="3000000000">대전광역시</option>
                          <option value="3100000000">울산광역시</option>
                          <option value="3611000000">세종특별자치시</option>
<!--                          <option value="4200000000">강원도</option>-->
                          <option value="5100000000">강원도</option>
                          <option value="4100000000">경기도</option>
                          <option value="4800000000">경상남도</option>
                          <option value="4700000000">경상북도</option>
                          <option value="4600000000">전라남도</option>
                          <!-- 식품나라 API 코드 변경으로 인하여 수정 (서남아이에스 임현아, 2024-01-18)-->
<!--                      <option value="4500000000">전라북도</option>       -->
                          <option value="5200000000">전북특별자치도</option>
                          <option value="5000000000">제주특별자치도</option>
                          <option value="4400000000">충청남도</option>
                          <option value="4300000000">충청북도</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="line">
                  <div class="search_cate_item">
                    <div class="search_cate_ttl">
                      <p>업체명</p>
                    </div>
                    <div class="search_cate_con">
                      <div class="input">
                        <input type="text" placeholder="업체명을 입력해주세요" v-model="bsshNm" @keyup.13="fnSearch">
                      </div>
                    </div>
                  </div>
                  <div class="search_cate_item">
                    <div class="search_cate_ttl">
                      <p>대표자명</p>
                    </div>
                    <div class="search_cate_con">
                      <div class="input">
                        <input type="text" placeholder="주대표자명" v-model="prsdntName" @keyup.13="fnSearch">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="right">
                <button class="btn" @click="fnSearch">검색</button>
              </div>
              <!-- <p class="hint_txt">
                ※ 지역과 업체명(일부분 입력가능)을 입력하고 검색 버튼 클릭 후 아래 목록에서 업체를 선택하세요.<br>
                ※ 업체명이 조회가 안 되는 경우 확인 하세요.<br>
                1. 업종이 휴게음식점, 제과점영업 등 일반음식점이 아닌 경우 조회되지 않습니다.<br>
                <span style="padding-left: 10px">타 업종인 경우 업종에 맞는 교육기관에서 교육 신청</span><br>
                <span style="padding-left: 10px">※ 업종 확인 방법: 영업신고증 > 영업의 형태 확인</span><br>
                2. 지역과 대표자명만 입력 후 조회
              </p> -->
              <!-- 문구 변경 (서남아이에스 임현아, 2024-11-14) -->
              <p class="hint_txt">
                <span class="hint_title">⚠️ 업체명이 조회되지 않는 경우</span><br><br>
                <span class="hint_subtitle">1. 일반음식점, 집단급식소, 위탁급식업이 <span style="text-decoration: underline">아닌 경우</span></span><br>
                <span class="hint_detail">▶ 해당 업종의 교육기관에서 수료해야 하므로 조회되지 않습니다.</span><br>
                <span style="color: gray;">(예) 휴게음식점 : 한국휴게음식업중앙회 / 제과점 : 대한제과협회</span><br><br>
                <span class="hint_subtitle">2. 업체명이 부정확한 경우</span><br>
                <span class="hint_detail">업체명의일부분(2~3자)만 입력</span>하거나, <span class="hint_detail">지역과 대표자명만 입력</span><br>
              </p>
              <p class="hint_txt" style="margin-top: 10px; width: 100%; background-color: #F8F8F8; padding: 10px;">
                <span class="hint_detail">(예)</span> 서울시 소재의 “맛나 식당” 검색 시<br>
                서울특별시 선택 ▶ 띄어쓰기 구분하여 “맛나 식당” 또는 “맛나”와 대표자명 입력<br>
              </p>
            </div>
            <div class="table hover_li">
              <div class="row head">
                <div class="cell fix90"><p>번호</p></div>
                <div class="cell fix150"><p>인허가번호</p></div>
                <div class="cell"><p>업체명</p></div>
                <div class="cell fix150"><p>업종</p></div>
                <div class="cell fix150"><p>대표자</p></div>
                <div class="cell"><p>소재지</p></div>
              </div>
              <div class="row" v-if="paginatedData.length <= 0">
                <div class="cell"><p>데이터가 없습니다.</p></div>
              </div>
              <a href="#" v-else>
                <div class="row" v-for="(item, index) in paginatedData" :key="index" @click.prevent="fnBind(item)">
                  <div class="cell fix90"><p>{{ item.rno }}</p></div>
                  <div class="cell fix150"><p>{{ item.lcns_no }}</p></div>
                  <div class="cell">
                    <p class="left">{{ item.bssh_nm }}</p>
                  </div>
                  <div class="cell fix150"><p>{{ item.busn_type_nm }}</p></div>
                  <div class="cell fix150"><p>{{ item.prsdnt_nm_secu }}</p></div>
                  <div class="cell">
                    <p class="left">{{ item.site_addr }}</p>
                  </div>
                </div>
              </a>
            </div>
            <div class="table_pager">
              <button class="prev" :disabled="pageNum === 0" @click="prevPage"></button>
              <button :class="{'on': pageNum + 1 === page}" v-for="page in pageCount" :key="page" @click="fnChange(page -1)">{{ page }}</button>
              <button class="next" :disabled="pageNum >= pageCount - 1" @click="nextPage"></button>
            </div>
          </div>
        </div>
        <div class="modal_btn">
          <div class="btn_wrap">
            <button class="btn" @click="$emit('eventClose')">닫기</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-license-number',
  props: {
    data: {
      type: [Array, Object],
      default: () => {}
    },
    callBack: {
      type: [Function]
    }
  },
  data: function() {
    return {
      sido: '',
      bsshNm: '',
      foodGbn: '001',
      prsdntName: '',
      list: [],
      pageInfo: {},
      pageNum: 0,
      pageSize: 10,
      start: 0,
      end: 0
    }
  },
  computed: {
    pageCount () {
      let listLeng = this.list.length,
        listSize = this.pageSize,
        page = Math.floor(listLeng / listSize);
      if (listLeng % listSize > 0) page += 1;
      return page;
    },
    paginatedData: function () {
      const start = this.pageNum * this.pageSize
      const end = start + this.pageSize
      return this.list.slice(start, end)
    }
  },
  methods: {
    fnSearch: async function() {
      const params = {}
      params.apikey = 'cpXvsyNv1QHEoLDXCxAGaOBKokcDlEfEVrHT5ZQR4pMWfPNGbnhsV3TRdiHYPhCj'
      params.food_gbn = this.foodGbn
      params.upsoname = this.bsshNm
      params.prsdnt_nm = this.prsdntName
      params.sido_cd = this.sido
      params.start_idx = 1
      if (this.data.industryType === 1) {
        params.induty_cd = `('101')`
      } else if (this.data.industryType === 2) {
        params.induty_cd = `('105')`
      } else if (this.data.industryType === 3) {
        params.induty_cd = `('120')`
      } else {
        params.induty_cd = `('101','105','120')`
      }
      const res = await this.$CommonSvc.getLicenseNumber(params)
      this.list = res.data
      this.start = this.pageNum * this.pageSize
      this.end = this.start + this.pageSize
      this.list.slice(this.start, this.end)
    },
    fnChange: function (page) {
      this.pageNum = page
    },
    nextPage: function () {
      this.pageNum += 1
    },
    prevPage: function () {
      this.pageNum -= 1
    },
    fnBind: function (item) {
      this.callBack(item)
      this.$emit('eventClose')
    }
  }
}
</script>

<style scoped>

</style>
