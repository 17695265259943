'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
/**
 * 공통 API Service
 * @class PaymentSvc
 */
class PaymentSvc extends ServiceExec {

  /**
   *  결제정보 리스트
   *  @param Authorization {string} Authorization
   *  @param limit {integer} 10
   *  @param page {integer} 1
   */
  fetchPaymentList(params = {}) {
    return this
      .get(`/api/payments/fetchPaymentList`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   * 결제 환불 처리
   * @param params
   * @returns {*}
   */
  cancelPayment(params = {}) {
    return this.queryPost(`/api/payments/cancelPayment`, params)
      .then(response => {
        return response.data
      })
  }
}
export default new PaymentSvc()
