<template>
  <div class="modal_inner center w1200">
    <div class="modal w50p">
      <div class="modal_head">
        <h1 class="h1">
          <span>아이디 찾기</span>
        </h1>
      </div>
      <div class="modal_con">
        <div class="modal_txt">
          <p>{{ data.userId }}</p>
        </div>
      </div>
      <div class="modal_btn">
        <div class="btn_wrap">
          <button class="btn bo grey" v-on:click="fnView('/login')">확인</button>
          <button class="btn" v-on:click="fnView('/login/findpw')">비밀번호 찾기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-id-result',
  description: '아이디 찾기 결과',
  props: {
    data: {
      type: [Array, Object],
      default: () => {}
    }
  },
  methods: {
    fnView: function(path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped>

</style>
