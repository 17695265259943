<template>
  <div class="loading" v-show="isLoading">
    <ul>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'dot-loading',
  computed: mapState({
    isLoading: state => (state.layout.isLoading && !state.layout.isNoneLoading) || state.layout.isForceLoading
  })
}
</script>
<style scoped>
.loading {
  z-index: 101;
}
</style>
