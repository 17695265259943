<template>
  <div class="modal_inner center w1200">
    <div class="modal w80p" style="height: 100%">
      <div class="modal_con" id="modal_con" style="height: 100%">
        <div class="print_wrap" style="height: 100%">
          <div class="print">
            <div class="table" style='position: unset;page-break-before:always'>
              <div class="row fix210">
                <div class="posit left_top" style="left: 60px;">
                  <p class="print_number">NO. {{ $Util.isEmpty(online.onlineEduNo)? offline.offlineEduCode : online.onlineEduCode }}</p>
                </div>
                <div class="posit right_top" style="right: 60px;">
                  <p class="print_to">{{ edu.userName }} 귀하</p>
                </div>
                <div class="cell">
                  <p class="print_ttl">
                    {{ `${$Util.isEmpty(online.onlineEduNo)? '집합' : '온라인'}` }} 식품위생교육<br>
                    영수증
                  </p>
                </div>
              </div>
              <div class="row fix70">
                <div class="cell">
                  <div class="dl_wrap left">
                    <dl>
                      <dt class="two_letter" style='width: 85px;'>금액 :</dt>
                      <dd>{{ $Util.formatNumber(code.price) }}원</dd>
                    </dl>
                    <dl>
                      <dt style='width: 85px;'>교육과정 :</dt>
                      <dd>
                        {{ $ConstCode.CODE_SALES_TYPE[edu.salesType].name }}
                        ({{ $ConstCode.CODE_INDUSTRY_TYPE[edu.industryType].name }})
                      </dd>
                    </dl>
                    <dl>
                      <dt style='width: 85px;'>결제수단 :</dt>
                      <dd>{{ paymentType(edu.paymentType) }}</dd>
                    </dl>
                    <dl>
                      <dt style='width: 85px;'>결제일자 :</dt>
                      <dd>{{ payment.signDate ? $moment(payment.signDate).format('YYYY.MM.DD') : '' }}</dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div class="row fix70">
                <div class="col right_bo fix100">
                  <div class="cell">
                    <p>
                      공<br>
                      급<br>
                      자
                    </p>
                  </div>
                </div>
                <div class="col">
                  <div class="row fix70">
                    <div class="cell fix180"><p>사업자 등록번호</p></div>
                    <div class="cell"><p class="left">215-82-12083</p></div>
                  </div>
                  <div class="row fix70">
                    <div class="cell fix180"><p>상호</p></div>
                    <div class="cell"><p class="left">(사)한국외식산업협회</p></div>
                    <div class="cell fix150"><p>이름</p></div>
                    <div class="cell fix180">
                      <div class="wth_stamp type02">
                        <p class="left">윤홍근</p>
                        <img src="@/assets/static/images/stamp02.png" alt="">
                      </div>
                    </div>
                  </div>
                  <div class="row fix70">
                    <div class="cell fix180"><p>사업자 소재지</p></div>
                    <div class="cell">
                      <div class="left">
                        <p>서울특별시 서초구 양재동 232번지 at센터</p>
                        <p>1101호</p>
                      </div>
                    </div>
                  </div>
                  <div class="row fix70">
                    <div class="cell fix180"><p>업태</p></div>
                    <div class="cell"><p class="left">서비스</p></div>
                    <div class="cell fix150"><p>종목</p></div>
                    <div class="cell fix180">
                      <p class="left">
                        <span class="small">교육, 컨설팅, 학술연구용역</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="announce">
                  <p class="announce_center">
                    영수증은 세금계산서 등 세무상 증빙서류로 활용할 수 없으며,<br>
                    거래내역 및 거래금액을 확인하는 용도로만 사용 가능합니다.<br>
                    <br>
                    위 금액을 영수함
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal_btn">
        <div class="btn_wrap">
          <button class="btn bo grey" @click="fnCallBack">이전으로</button>
          <button class="btn" @click="fnPrint">출력하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-alert-bills',
  description: '결제 영수증',
  props: {
    data: {
      type: [Array,Object],
      default: () => {}
    },
    callBack: {
      type: [Function]
    }
  },
  data() {
    return {
      payment: {},
      online: {},
      offline: {},
      code: {},
      edu: {}
    }
  },
  async created() {
    this.payment = this.data.item.payment
    this.online = this.data.item.online
    this.offline = this.data.item.offline
    this.code = this.data.item.code
    if (!this.$Util.isEmpty(this.online.onlineEduNo)) {
      this.edu = this.online
    } else {
      this.edu = this.offline
    }
  },
  methods: {
    fnCallBack: function(e) {
      if (!this.$Util.isEmpty(this.callBack)) {
        this.callBack(e)
      }
      this.$emit('eventClose')
    },
    fnPrint: function() {
      const html = document.querySelector('html')
      const printContents = document.querySelector('.modal_con').innerHTML
      const printDiv = document.createElement('div')
      printDiv.className = 'print-div'

      html.appendChild(printDiv)
      printDiv.innerHTML = printContents
      document.body.style.display = 'none'
      window.print()
      document.body.style.display = 'block'
      printDiv.style.display = 'none'
    },
    paymentType: function(paymentType) {
      let label = ''
      const payment = this.$ConstCode.CODE_PAYMENT_TYPE
      if (paymentType === 1) label = payment.CARD.label
      if (paymentType === 2) label = payment.PHONE.label
      if (paymentType === 3) label = payment.VIRTUAL.label
      if (paymentType === 4) label = payment.REAL_ACCOUNT.label
      if (paymentType === 5) label = payment.ADMIN.label
      return label
    }
  }
}
</script>

<style scoped>

</style>
