<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_ttl">
          <h1 class="h1">
            <span>마이페이지</span>
          </h1>
        </div>
        <div class="page_con">
          <div class="form_table">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_ttl">
                    <p>아이디</p>
                  </div>
                  <div class="form_in">
                    <p class="bind">{{ form.userId }}</p>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p>비밀번호</p>
                  </div>                  
                  <div class="form_in">
                    <div class="inline">
                      <button class="btn" @click="$store.commit('layout/setModalAlert', {modalAlertName: $modalAlertNames.CHANGEPWINFO })">변경하기</button>
                      <!-- <button class="btn" v-on:click="fnAuth">변경하기</button> -->
                      </div>
                  </div>
                </li>
                <!-- <li class="form_li">
                  <div class="form_ttl">
                    <p>비밀번호 확인</p>
                  </div>
                  <div class="form_in">
                    <div class="input">
                      <input type="password" placeholder="다시 한 번 입력해 주세요" v-model="form.password2">
                      <p class="valid on" v-show="$v.form.password2.$error">{{ msg.password2 }}</p>
                    </div>
                  </div>
                </li> -->
                <li class="form_li">
                  <div class="form_ttl">
                    <p class="essen">이메일</p>
                  </div>
                  <div class="form_in">
                    <div class="input">
                      <input type="email" placeholder="이메일 주소를 입력해 주세요" v-model="form.email">
                      <p class="valid on" v-show="$v.form.email.$error">이메일 주소를 입력해 주세요</p><!--'on 추가'-->
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p class="essen">휴대폰 번호</p>
                  </div>
                  <div class="form_in">
                    <div class="inline">
                      <div class="input">
                        <input type="number" placeholder="-를 제외하고 입력해 주세요" v-model="form.userPhone" id="userPhone" readonly>
                      </div>
                      <!-- <button class="btn bo" v-on:click="fnAuth">{{ msg.send }}</button> -->
                      <button class="btn"  @click="$store.commit('layout/setModalAlert', {modalAlertName: $modalAlertNames.CHANGEPHONEINFO })">변경하기</button>
                    </div>
                    <!-- <p class="valid on" v-show="$v.form.userPhone.$error">휴대폰 번호를 입력해주세요</p>
                    <div class="inline time_count" v-show="isAuthForm">
                      <div class="input">
                        <input type="number" v-model="authNumber" id="authNumber">
                        <span class="time" id="time" v-show="isRunning" style="color: red;">{{ count }}</span>
                      </div>
                      <button class="btn bo grey" disabled v-on:click="fnConfirmAuth" id="block">인증하기</button>
                    </div> -->
<!--                    <p class="valid on">인증번호를 입력해주세요</p>&lt;!&ndash;'on 추가'&ndash;&gt;-->
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p>이름</p>
                  </div>
                  <div class="form_in">
                    <p class="bind">{{ form.name }}</p>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p>생년월일</p>
                  </div>
                  <div class="form_in">
                    <p class="bind">{{ form.birth }}</p>
<!--                    <p class="bind">{{ $Util.formatStringBirth(form.birth) }}</p>-->
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p class="">전화번호</p>
                  </div>
                  <div class="form_in">
                    <div class="input">
                      <input type="number" placeholder="-를 제외하고 입력해 주세요" v-model="form.companyPhone">
<!--                      <p class="valid on" v-show="$v.form.companyPhone.$error">번호를 입력해주세요</p>&lt;!&ndash;'on 추가'&ndash;&gt;-->
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p class="essen">주소</p>
                  </div>
                  <div class="form_in">
                    <div class="inline">
                      <div class="input">
                        <input type="number" value="01234" placeholder="우편 번호" disabled v-model="form.postCode">
                      </div>
                      <button class="btn bo" @click="$store.commit('layout/setModalAlert', {modalAlertName: $modalAlertNames.DAUMPOSTCODE, isHideHeader: false, callBack: fnBindDate})">주소 검색</button>
                    </div>
                    <div class="input">
                      <input type="text"  placeholder="주소를 입력해 주세요" disabled v-model="form.address">
                    </div>
                    <div class="input">
                      <input type="text"  placeholder="상세주소를 입력해 주세요" v-model="form.address2">
                    </div>
                    <p class="valid on" v-show="$v.form.address.$error">주소를 입력해주세요</p><!--'on 추가'-->
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p>수신동의</p>
                  </div>
                  <div class="form_in">
                    <div class="inline">
                      <div class="checkbox">
                        <input type="checkbox" id="d" :true-value="1" :false-value="2" v-model="form.isSms"><!--디폴트: 체크된 상태-->
                        <label for="d"><span>SMS</span></label>
                      </div>
                      <div class="checkbox">
                        <input type="checkbox" id="e" :true-value="1" :false-value="2" v-model="form.isEmail">
                        <label for="e"><span>이메일</span></label>
                      </div>
                      <!-- 수신동의 : 전화추가 (서남아이에스 임현아, 2024-05-24) -->
                      <div class="checkbox">
                        <input type="checkbox" id="p" :true-value="1" :false-value="2" v-model="form.isPhone">
                        <label for="p"><span>전화</span></label>
                      </div>
                      <!-- 수신동의 : 전화추가 끝 -->
                      <div class="checkbox">
                        <input type="checkbox" id="f" :true-value="1" :false-value="2" v-model="form.isMarketing">
                        <label for="f"><span>제3자 마케팅</span></label>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <button class="withdraw" v-on:click="fnQuit">회원탈퇴</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="page_btn">
          <button class="btn" v-on:click="fnSave">저장하기</button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'mypage-index',
  description: '마이페이지',
  data() {
    return {
      form: {
        userNo: -1,
        // password: '',
        // password2: '',
        email: '',
        // oldUserPhone: '',
        // userPhone: '',
        companyPhone: '',
        postCode: '',
        address: '',
        address2: '',
        isSms: -1,
        isEmail: -1,
        isPhone: -1, //수신동의 : 전화추가 (서남아이에스 임현아, 2024-05-24)       
        isMarketing: -1
      },
      isAuthForm: false,
      authNumber: '', // 인증번호
      authConfirmNumber: '', // 발송된 인증번호,
      isAuth: false,  // 인증번호 인증여부
      isRunning: false, // 타이머 작동 여부,
      // msg: {
      //   send: '인증',
      //   password: '',
      //   password2: ''
      // },
      count: '03:00',
      timeCounter: 180 // 3분
    }
  },
  destroyed() {
    this.fnStop()
  },
  async created() {
    const params = {}
    params.userNo = 1
    const res = await this.$UserSvc.fetchUser()
    this.form = res.entity
    // this.form.oldUserPhone = res.entity.userPhone
    this.form.postCode = res.entity.postalCode
  },
  methods: {
    fnSave: function() {
      this.$v.$touch()
      if (!this.$Util.isValid(this.$v, this.$el)) return false
      const params = {}
      // params.userPw = this.form.password
      params.email = this.form.email
      // params.userPhone = this.form.userPhone
      params.companyPhone = this.form.companyPhone
      params.postCode = this.form.postCode
      params.address = this.form.address
      params.address2 = this.form.address2
      params.isSms = this.form.isSms
      params.isEmail = this.form.isEmail
      params.isPhone = this.form.isPhone //수신동의 : 전화추가 (서남아이에스 임현아, 2024-05-24)
      params.isMarketing = this.form.isMarketing
      // 핸드폰 번호를 변경이 인증시 필요
      // if (this.isAuth === false && this.form.userPhone !== this.form.oldUserPhone) {
      //   alert('휴대폰인증을 해주세요')
      //   return false
      // }
      this.$UserSvc.updateUser(params).then(res => {
        if (res.code === 1) {
          alert('회원정보가 저장되었습니다.')
          this.$router.push({ path: '/main' })
        }
      })
    },
    fnQuit: function() {
      if (!confirm('탈퇴 후에는 해당 아이디로 다시 가입할 수 없으며, 아이디와 데이터는 복구할 수 없습니다.\n재 가입을 원하실 경우 사무국으로 연락 부탁드립니다.')) return false
      this.$UserSvc.deleteUser().then(res => {
        if(res.code === 1) {
          alert('탈퇴되었습니다.')
          const params = {}
          params.applicationId = 'edu'
          this.$UserSvc.logout(params).then(res => {
            if (res.code === 1) {
              this.$router.push({ path: '/main' })
            }
          })
        }
      })
    },
    fnBindDate: function ({roadAddress, zonecode}) {
      this.form.address = roadAddress
      this.form.postCode = zonecode
    },
    // fnAuth: function() {
    //   if (this.$Util.isEmpty(this.form.userPhone)) return false
    //   // 발송후 핸드폰 번호칸은  readonly
    //   this.isAuthForm = true
    //   this.msg.send = '재전송'
    //   // 인증번호 타이머 시작 (세팅시간 3분)
    //   this.fnTimer()
    //   this.isRunning = true
    //   document.getElementById("userPhone").readOnly = true
    //   document.getElementById("block").disabled = false
    //   const params = {}
    //   params.userNo = -1
    //   params.userPhone = this.form.userPhone
    //   this.$AuthSvc.send(params).then(res => {
    //     if (res.code === 1 ) {
    //       this.authConfirmNumber = res.entity
    //     }
    //   })
    // },
    // fnConfirmAuth: function() {
    //   if(this.$Util.isEmpty(this.authNumber)) return false
    //   if (this.authNumber !== this.authConfirmNumber) {
    //     alert('인증번호가 일치하지 않습니다.')
    //     return false
    //   } else {
    //     alert('인증되었습니다.')
    //     this.fnStop()
    //     document.getElementById("block").disabled = true
    //     this.isRunning = false // 타이머 숨기기
    //     this.isAuth = true
    //   }
    // },
    // fnTimer: function() {
    //   this.fnStop()
    //   this.timer = setInterval( () => {
    //     this.timeCounter --
    //     if (this.timeCounter <= 0) {
    //       this.fnStop()
    //       alert('인증 번호가 만료되었습니다.\n다시 인증해주세요.')
    //       document.getElementById("block").disabled = true
    //     }
    //     let time = this.timeCounter / 60
    //     let min = parseInt(time)
    //     let sec = Math.round((time - min) * 60)
    //     this.count = this.pad(min, 2) + ':' + this.pad(sec, 2)
    //   }, 1000)
    // },
    // pad: function(n, width){
    //   n = n + ''
    //   return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n
    // },
    // fnStop: function() {
    //   clearInterval(this.timer)
    //   this.timeCounter = 180
    //   this.count = "03:00"
    // }
  },
  validations: {
    form: {
      // password: {
      //   test: function(val) {
      //     this.msg.password = '비밀번호를 입력해주세요'
      //     if (!this.$Util.isEmpty(val)) {
      //       if (val.length < 6) {
      //         this.msg.password = '숫자 6자 이상 입력해주세요'
      //         return false
      //       }
      //     }
      //     return required(val)
      //   }
      // },
      // password2: {
      //   test: function(val, obj){
      //     this.msg.password2 = ''
      //     if (obj.password !== val) {
      //       this.msg.password2 = '위의 비밀번호와 동일하게 입력해주세요'
      //       return false
      //     }
      //     return required(val)
      //   }
      // },
      email: {
        required
      },
      // userPhone: {
      //   required
      // },
       address: {
         required
       }
    }
  }
}
</script>

<style scoped>

</style>
