'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
/**
 * 공통 API Service
 * @class NoticeSvc
 */
class NoticeSvc extends ServiceExec {

  /**
   *  공지사항 리스트
   *  @param endDate {string}
   *  @param examType {integer}
   *  @param limit {integer} 10
   *  @param noticeType {integer}
   *  @param page {integer} 1
   *  @param searchText {string}
   *  @param startDate {string}
   */
  fetchUserNoticeList(params = {}) {
    return this.get(`/api/notice/fetchUserNoticeList`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  공지사항 상세보기
   *  @param noticeNo {integer} noticeNo
   */
  fetchData(params = {}) {
    return this.get(`/api/notice/fetchData`, params)
      .then(response => {
        return response.data
      })
  }

}
export default new NoticeSvc()
