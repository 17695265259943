<template>
  <div>
    <component-board-tap />
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <!-- 홈페이지 유지보수 개발요청안 | 사이드 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
          <side-banner v-bind:isURL="isURL"></side-banner>
          <div class="lnb">
            <ul>
              <li><p class="home"></p></li>
              <li>
                <p v-if="eduType.isOnline === 1 && eduType.salesType === 1">신규영업자</p>
                <p v-if="eduType.isOnline === 1 && eduType.salesType === 2">기존영업자</p>
                <p v-if="eduType.isOnline === 2 && eduType.salesType === 1">신규영업자</p>
                <p v-if="eduType.isOnline === 2 && eduType.salesType === 2">기존영업자</p>
              </li>
              <li>
                <p>{{ eduType.industryType === 1 ? '일반음식점' : eduType.industryType === 2 ? '집단급식소' : '위탁급식업' }}</p>
              </li>
              <li><p>영수증 출력</p></li>
            </ul>
          </div>
          <div class="page_ttl">
            <h1 class="h1">
              <span>영수증 출력</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="table mt20">
              <div class="row head">
                <div class="cell fix151"><p>주문번호</p></div>
                <div class="cell"><p>강의명</p></div>
                <div class="cell fix150"><p>접수일자</p></div>
                <div class="cell fix150"><p>교육비용</p></div>
                <div class="cell fix150"><p>결제수단</p></div>
                <div class="cell fix150"><p>결제여부</p></div>
                <div class="cell fix150"><p>영수증</p></div>
              </div>
              <div class="page_con" v-if="list.length === 0">
                <div class="empty_wrap">
                  <div class="empty">
                    <span>내역이 존재하지 않습니다.</span>
                  </div>
                </div>
              </div>
              <div class="row" v-for="(item, index) in list" :key="index">
                <div class="cell fix151"><p>{{ item.online.onlineEduCode || item.offline.offlineEduCode }}</p></div>
                <div class="cell">
                  <p class="left">
                    {{ bindEduName(item) }}
                  </p>
                </div>
                <div class="cell fix150">
                  <p>
                    {{ item.payment.signDate ? $moment(item.payment.signDate).format('YYYY.MM.DD') : ''}}<br>
                    {{ item.payment.signDate ? $moment(item.payment.signDate).format('HH:mm') : ''}}
                  </p>
                </div>
                <div class="cell fix150"><p>{{ $Util.formatNumber(item.code.price) }}원</p></div>
                <div class="cell fix150" v-if="isOnline"><p>{{ paymentType(item) }}</p></div>
                <div class="cell fix150">
                  <p class="state" :class="{ color: item.payment.paymentType === 1 || item.payment.paymentType === 3, error: item.payment.paymentType === 4 }">
                    {{ item.payment.paymentType === 1 ? '결제완료' : item.payment.paymentType === 3 ? '관리자 결제': '환불완료' }}
                  </p>
                </div>
                <div class="cell fix150">
                  <button class="btn bo" v-on:click="fnPrint(item)" :disabled="item.payment.paymentType === 4">출력하기</button>
                </div>
              </div>
            </div>
            <base-pagination
              v-model="pageInfo.page"
              v-on:input="fetchData"
              v-on:change="fetchData"
              v-bind:pageInfo="pageInfo"
            />
          </div>
        </div>
      </div>
    </main>
    <!-- 홈페이지 유지보수 개발요청안 | 푸터 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
    <bottom-banner v-bind:isURL="isURL"></bottom-banner>
  </div>
</template>

<script>
import BasePagination from '@/components/common/base/BasePagingation'
import ComponentBoardTap from '@/components/common/edu/board-tap'
import SideBanner from '@/components/common/banner/SideBanner.vue'
import BottomBanner from '@/components/common/banner/BottomBanner.vue'

export default {
  name: 'mystudy-payment',
  description: '나의학습방 결제정보',
  components: {
    ComponentBoardTap,
    BasePagination,
    SideBanner,
    BottomBanner
  },
  computed: {
    eduType: function() {
      return this.$store.getters['education/getEduType']
    },
    URL() {
      return this.searchURL(); // 메소드 호출
    }
  },
  data() {
    return {
      isOnline: true,
      params: {
        page: 1,
        limit: 10,
        isFinishPayment: 1,
        code: ''
      },
      pageInfo: {},
      list: [],
      isURL: ''
    }
  },
  watch: {
    'eduType.industryType': function() {
      this.fetchData(1)
      this.searchURL()
    }
  },
  async created() {
    this.searchURL()
    this.params.code = `${this.eduType.isOnline}${this.eduType.salesType}${this.eduType.industryType}`
    const res = await this.$PaymentSvc.fetchPaymentList(this.params)
    console.log('res', res)
    if (res.code === 1) {
      this.list = res.entity.list
      this.pageInfo = res.entity.pageInfo
    }
  },
  methods: {
    searchURL: function(){
      if(this.eduType.isOnline === 1){
        if (this.eduType.industryType === 1) {
          this.isURL = '4'
        }
        if (this.eduType.industryType === 2) {
          this.isURL = '8'
        }
        if (this.eduType.industryType === 3) {
          this.isURL = '12'
        }
      }

      if(this.eduType.isOnline === 2){
        if (this.eduType.industryType === 1) {
          this.isURL = '17'
        }
        if (this.eduType.industryType === 2) {
          this.isURL = '22'
        }
        if (this.eduType.industryType === 3) {
          this.isURL = '27'
        }
      }
    },
    fetchData: async function(page) {
      this.params.page = page
      this.params.code = `${this.eduType.isOnline}${this.eduType.salesType}${this.eduType.industryType}`
      const res = await this.$PaymentSvc.fetchPaymentList(this.params)
      if (res.code === 1) {
        this.list = res.entity.list
        this.pageInfo = res.entity.pageInfo
      }
    },
    paymentType: function(item) {
      let label = ''
      const payment = this.$ConstCode.CODE_PAYMENT_TYPE
      if (item.online.paymentType === 1 || item.offline.paymentType === 1) label = payment.CARD.label
      if (item.online.paymentType === 2 || item.offline.paymentType === 2) label = payment.PHONE.label
      if (item.online.paymentType === 3 || item.offline.paymentType === 3) label = payment.VIRTUAL.label
      if (item.online.paymentType === 4 || item.offline.paymentType === 4) label = payment.REAL_ACCOUNT.label
      if (item.online.paymentType === 5 || item.offline.paymentType === 5) label = payment.ADMIN.label
      return label
    },
    fnPrint: function(item) {
      this.$store.commit('layout/setModalAlert', {data: { item }, modalAlertName: this.$modalAlertNames.BILLS})
    },
    fnView: function(path) {
      this.$router.push({ path: path })
    },
    fnBoard: function(path, boardType) {
      this.$store.commit('education/setBoardType', { boardData: { boardType: boardType } })
      this.$router.push({ path: path })
    },
    bindEduName: function(item) {
      let resName = ''
      console.log(item.online.onlineEduNo)
      if (!this.$Util.isEmpty(item.online.onlineEduNo)) {
        resName = this.$ConstCode.CODE_SALES_TYPE[item.online.salesType].name
        resName += '(' + this.$ConstCode.CODE_INDUSTRY_TYPE[item.online.industryType].name + ')'
      } else {
        resName = this.$ConstCode.CODE_SALES_TYPE[item.offline.salesType].name
        resName += '(' + this.$ConstCode.CODE_INDUSTRY_TYPE[item.offline.industryType].name + ')'
      }
      return resName
    }
  }
}
</script>

<style scoped>

</style>
