<template>
  <div>
    <header class="header">
      <div class="inner">
        <h1>주소검색</h1>
      </div>
    </header>
    <main class="main">
      <div class="page btm">
        <div class="inner">
          <div class="shipping_address_srch">
            <div class="ttl">
              <h1>지번, 도로명, 건물명 입력</h1>
            </div>
            <div class="srch_st01 st02">
              <div class="input">
                <input type="text" placeholder="주소검색" ref="searchInput" v-model="form.searchText" @keyup.13="fetchData">
              </div>
              <button class="btn_srch" @click="fetchData"></button>
            </div>
            <ul>
              <li v-for="(item, index) in items" :key="index" @click="fnView(item.jibunAddr)">
                <p>{{item.jibunAddr}}</p>
                <p>[도로명] {{item.rn}} {{ item.buldMnnm}}{{item.buldSlno !== '0' ? `-${item.buldSlno}` : ''}} {{item.roadAddrPart2}}</p>
              </li>
            </ul>
            <base-no-data v-show="$Util.isEmpty(items) && isSearch" :bind-text="'조회된 내용이 없습니다.'" />
            <base-list-scroll-page
              v-if="!$Util.isEmpty(items)"
              v-bind:isActive="!$Util.isEmpty(items)"
              v-bind:listFunction="listFunction"
              v-on:finishFetch="fnFinishFetch"
            />
          </div>
        </div>
      </div>
      <div class="btn_basic">
        <button v-on:click="$emit('eventClose')"><p>닫기</p></button>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'modal-address',
  data: function() {
    return {
      form: {
        page: 1,
        limit: 20,
        searchText: ''
      },
      items: [],
      isSearch: false
    }
  },
  created: function() {
    setTimeout(() => {
      this.$refs['searchInput'].focus()
    }, 800)
  },
  methods: {
    fetchData: async function() {
      this.items = []
      this.isSearch = false
      const res = await this.$CommonSvc.getAddress(this.form)
      this.items = res.entity.list
      this.isSearch = true
    },
    listFunction: function ({page}) {
      let params = Object.assign({}, this.form)
      params.page = page
      return this.$CommonSvc.getAddress(params)
    },
    fnFinishFetch: function (items) {
      this.items = items
    },
    fnView: function(address) {
      // this.$router.push({
      //   path: '/easy/delivery/view',
      //   query: {address: address}
      // })
      this.$emit('eventMoveRoute', '/easy/delivery/view', {address: address})
      // this.$emit('eventClose')
    }
  }
}
</script>

<style scoped>

</style>
