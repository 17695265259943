'use strict'
// import '@babel/polyfill'
import axios from 'axios'
import store from '@/store'
import ConstCode from '@/common/constants/ConstCode'
import Util from '@/common/utils/Util'
import queryString from 'query-string'
import router from '@/router'
// import $_ from 'lodash'
let _this = null
const loadingMinDuration = 300
class ServiceExec {
  constructor() {
    // this.enhanceAccessToeken()
    let ServiceExec = axios.create({
      headers: { csrf: 'ownerAppMap' },
      withCredentials: true
    })
    // setLoading
    ServiceExec.interceptors.request.use(this.handleRequest)
    ServiceExec.interceptors.response.use(this.handleSuccess, this.handleError)
    this.ServiceExec = ServiceExec
    this.ConstCode = ConstCode
    this.store = store
    this.router = router
    this.axios = axios
    _this = this
  }

  storeSetLoading(isLoading) {
    store.commit('layout/setLoading', { isLoading: isLoading })
  }

  handleRequest(request) {
    _this.storeSetLoading(true)
    if (!Util.isEmpty(store)) {
      const accessToken = store.getters['user/getAccessToken']
      if (!accessToken) return request
      request.headers.common['Authorization'] = `${accessToken}`
    }
    return request
  }

  handleSuccess(response) {
    setTimeout(() => {
      _this.storeSetLoading(false)
    }, loadingMinDuration)
    return response
  }

  async refreshAdminToken(userData) {
    const params = {}
    params.employeeNo = userData.employeeNo
    const res = await _this.queryPost('/smart-user/sign/changeToken', params)
    let authToken = res.headers['Authorization']
    userData = res.data.entity
    if (!Util.isEmpty(authToken)) {
      store.commit('user/storeLogin', {
        accessToken: authToken,
        userData: JSON.stringify(userData)
      })
      return true
    } else {
      store.commit('user/storeLogout')
      return false
    }
  }

  async handleError(error) {
    _this.storeSetLoading(false)
    switch (error.response.status) {
      case 404:
        alert('서버와 통신이 원활하지 않습니다.')
        break
      default:
        alert('서버와 통신이 원활하지 않습니다.')
        break
    }
    return Promise.reject(error)
  }

  get(path, params = {}) {
    if (path !== '/api/user/adminLogin') {
      _this.userExtendSession()
    }
    return this.ServiceExec.get(path, { params })
  }

  patch(path, payload) {
    _this.userExtendSession()
    return this.ServiceExec.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload
    })
  }

  post(path, payload) {
    _this.userExtendSession()
    return this.ServiceExec.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    })
  }

  delete(path, payload) {
    _this.userExtendSession()
    payload = `${path}?${queryString.stringify(payload)}`
    return this.ServiceExec.delete(payload)
  }

  queryPost(path, payload) {
    _this.userExtendSession()
    payload = `${queryString.stringify(payload)}`
    return this.ServiceExec.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    })
  }

  queryMultiPost(path, payload) {
    _this.userExtendSession()
    payload = `${queryString.stringify(payload)}`
    return this.ServiceExec.request({
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    })
  }

  multiPost(path, payload) {
    if (path !== '/api/online/edu/updateUserOnlineInfo'
    && path !== '/api/online/edu/updateUserOfflineInfo'){
      _this.userExtendSession()
    }
    return this.ServiceExec.request({
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    })
  }
  enhanceAccessToeken() {
    let accessToken = ''
    if (!Util.isEmpty(store)) {
      accessToken = store.getters['user/getAccessToken']
    }
    console.log('여기서 체크 1 :: ', accessToken)
    if (!accessToken) return
    console.log('여기서 체크 2 :: ', accessToken)
    axios.defaults.headers.common['Authorization'] = `${accessToken}`
  }

  userExtendSession() {
    const user = store.getters['user/getUserData']
    if (!Util.isEmpty(user)) {
      store.commit('user/setTimeoutClock', new Date().getTime())
      store.commit('user/setTimeout', 7200)

      const params = {}
      params.applicationId = 'edu'

      return this.ServiceExec.get(`/api/user/userExtendSession`, { params })
        .then(response => {
          const userSession = response.data;
          store.commit('user/setTimeout', userSession.entity)
          if (userSession.code === -1014) {
            store.commit('user/storeLogout')
          }
        })
      }
  }
}

export default ServiceExec
