<template>
  <div>
    <component-board-tap />
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <!-- 홈페이지 유지보수 개발요청안 | 사이드 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
          <side-banner v-bind:isURL="isURL"></side-banner>
          <component-edu-type />
          <div class="page_ttl">
            <h1 class="h1">
              <span>교육 신청</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="form_table">
              <div class="form">
                <ul class="form_ul">
                  <li class="form_li">
                    <div class="form_ttl">
                      <p class="essen">교육 이수자 이름</p>
                    </div>
                    <div class="form_in">
                      <div class="input">
                        <!-- 박스 잠금 (서남아이에스 임현아, 2024-11-14) -->
                        <input type="text" placeholder="이름을 입력해 주세요" v-model="form.userName" readonly disabled>
                        <p class="valid on" v-show="$v.form.userName.$error">필수입력사항입니다.</p><!--'on 추가'-->
                      </div>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">
                      <p class="essen">생년월일</p>
                    </div>
                    <div class="form_in">
                      <div class="input">
                        <!-- 박스 잠금 (서남아이에스 임현아, 2024-11-14) -->
                        <input type="number" placeholder="생년월일을 입력해 주세요" v-model="form.birth" readonly disabled>
                        <p class="valid on" v-show="$v.form.birth.$error">필수입력사항입니다.</p><!--'on 추가'-->
                      </div>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">
                      <p>업종 분류</p>
                    </div>
                    <div class="form_in">
                      <p class="bind">{{ $ConstCode.CODE_INDUSTRY_TYPE[eduType.industryType].name }}</p>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">
                      <p class="essen">직책</p>
                    </div>
                    <div class="form_in">
                      <div class="inline">
                        <div class="radio">
                          <input type="radio" name="a" id="aa" v-bind:value="1" v-model="form.positionType">
                          <label for="aa"><span>대표자</span></label>
                        </div>
                        <div class="radio">
                          <input type="radio" name="a" id="ab" v-bind:value="2" v-model="form.positionType">
                          <label for="ab"><span>위생관리자</span></label>
                        </div>
                      </div>
                      <p class="valid on" v-show="$v.form.positionType.$error">필수입력사항입니다.</p><!--'on 추가'-->
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="inform_box">
                      <ol class="dot">
                        <li class="color">
                        <!-- 문구 변경 (서남아이에스 임현아, 2024-11-14) -->
                          <!-- <span class="ol_li_txt">
                                 교육 이수자의 직책이 ‘위생관리자’인 경우,
                                  ‘식품위생관리 책임자 지정확인서’를 제출해야합니다.<br>
                                  <br>
                                  지정확인서 안내 및 제출 방법 확인 경로 : 고객센터 > 공지사항 > 위생관리자 지정확인서
                          </span> -->
                          <span class="ol_li_txt">
                            교육 이수자의 직책이 <span class="ol_li_txt_span">‘위생관리자’</span>인 경우, <span class="ol_li_txt_span">‘식품위생관리 책임자 지정확인서’</span>를 제출해야 하며,<br>
                            위생관리자로 수강하는 경우 지자체에 <span class="ol_li_txt_span">영업신고 시 위생관리자의 보건증을 지참</span>해야 하는 점 참고 바랍니다.<br><br>
                            <!-- 한국외식산업협회 위생교육 지정확인서 제출 양식 버튼 (서남아이에스 임현아, 2024-11-14) -->
                            ※ 지정확인서 제출 방법 : 나의 학습방 ▶ 지정 확인서 제출
                            <button class="btn" style="border: 0px; border-radius: 10px; background-color: #FF9F00; font-size: 14px; font-weight: 500; width: 150px !important;" v-on:click="fnView()">제출 양식 바로가기</button>
                          </span> 
                        </li>
                      </ol>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">
                      <p class="essen">휴대폰 번호</p>
                    </div>
                    <div class="form_in">
                      <div class="input">
                        <input type="number" placeholder="-를 제외하고 입력해 주세요" v-model="form.userPhone">
                        <p class="valid on" v-show="$v.form.userPhone.$error">필수입력사항입니다.</p><!--'on 추가'-->
                      </div>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">
                      <p class="essen">지역</p>
                    </div>
                    <div class="form_in">
                      <div class="inline">
                        <div class="select">
                          <select v-model="form.location">
                            <option value="" disabled selected>선택</option>
                            <option
                              v-for="item in $ConstCode.CODE_LOCATION"
                              :key="item.value"
                              :value="item.value"
                            >{{ item.name }}</option>
                          </select>
                        </div>
<!--                        <div class="datepicker-btn" v-show="isCalender" @click="fnEducation"></div>-->
                      </div>
                      <p class="valid on" v-show="$v.form.location.$error">필수입력사항입니다.</p><!--'on 추가'-->
                    </div>
                  </li>
                  <li class="form_li" v-if="showSchedule">
                    <div class="form_ttl">
                      <p>집합 교육 일정</p>
                    </div>
                    <div class="form_in">
                      <div class="table">
                        <div class="row head">
<!--                          <div class="cell fix90"><p>선택</p></div>-->
                          <div class="cell fix150"><p>교육일</p></div>
                          <div class="cell fix150"><p>입실시간</p></div>
                          <div class="cell"><p>교육장소</p></div>
                          <div class="cell fix150"><p>신청가능 인원</p></div>
                        </div>
                        <div class="empty" v-if='$Util.isEmpty(schedule.list.location)'>
                          <span>내역이 존재하지 않습니다.</span>
                        </div>
                        <div class="row" v-if='!$Util.isEmpty(schedule.list.location)'>
<!--                          <div class="cell fix90">-->
<!--                            <div class="radio">-->
<!--                              <input type="radio" name="b" :id="`b_${index}`" :value="item.edu.offlineEducationNo" @click="fnSelected(item.edu.offlineEducationNo, item.list.sceneNo)">-->
<!--                              <label :for="`b_${index}`"></label>-->
<!--                            </div>-->
<!--                          </div>-->
                          <div class="cell fix150"><p>{{ $Util.isEmpty(schedule.edu.eduDate) && $Util.isEmpty(schedule.edu) ? '' : $moment(schedule.edu.eduDate).format('YYYY.MM.DD') }}</p></div>
                          <div class="cell fix150"><p>{{ `${schedule.edu.startTime}`  }}</p></div>
                          <div class="cell"><p class="left">{{ schedule.list.location }}</p></div>
                          <div class="cell fix150"><p>{{ (schedule.list.personnel - schedule.count) > 0 ? (schedule.list.personnel - schedule.count) : '0' }} 명</p></div>
                        </div>
                      </div>
                      <p class="valid on" v-show="$v.form.sceneNo.$error">교육 일정을 선택해주세요.</p><!--'on 추가'-->
                    </div>
                  </li>
                  <li class="form_li" v-if="eduType.salesType === 2">
                    <div class="form_ttl">
                      <p class="essen">인허가 번호</p>
                    </div>
                    <div class="form_in">
                      <div class="inline">
                        <div class="input">
                          <input type="number" placeholder="번호를 입력해 주세요" v-model="form.licenseNumber" :disabled="eduType.salesType === 2">
                        </div>
                        <button class="btn bo" @click="fnLicense" @bindData="fnBindLicense">인허가 번호 검색</button>
                      </div>
                      <p class="hint">
                        인허가번호를 입력해 검색 후 해당 영업소를 선택하시면 아래 모든 항목이 자동으로 입력됩니다.
                      </p>
                      <p class="valid on" v-show="$v.form.licenseNumber.$error">필수입력사항입니다.</p><!--'on 추가'-->
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">
                      <p class="essen">영업소 & 상호명<br>명칭</p>
                    </div>
                    <div class="form_in">
                      <div class="input">
                        <input type="text" placeholder="명칭을 입력해 주세요" v-model="form.businessName" :disabled="this.eduType.salesType === 2">
                        <p class="hint" v-show="eduType.salesType === 1">
                          영업소 & 상호명 명칭이 미정인 경우 입력란에 ‘미정’ 으로 적어주세요.<br>
                          <!-- 고객센터 목차명 변경 및 숨김처리 요청 1:1 게시판/신고센터로 통합, 원격지원 숨김 (서남아이에스 임현아, 2024-08-30) -->
                          (추후 상호명 변경을 원하시면 1:1 게시판 / 신고센터에 문의하시기 바랍니다.)
                          <!-- (추후 상호명 변경을 원하시면 1:1 게시판 및 신고센터에 문의하시기 바랍니다.) -->
                        </p>
                        <p class="valid on" v-show="$v.form.businessName.$error">필수입력사항입니다.</p><!--'on 추가'-->
                      </div>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">
                      <p class="essen">대표자 이름</p>
                    </div>
                    <div class="form_in">
                      <div class="input">
                        <input type="text" placeholder="이름을 입력해 주세요" v-model="form.representative" :disabled="this.eduType.salesType === 2">
                        <p class="valid on" v-show="$v.form.representative.$error">필수입력사항입니다.</p><!--'on 추가'-->
                      </div>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">
                      <p class="essen">영업소 소재지</p>
                    </div>
                    <div class="form_in">
                      <div class="inline">
                        <div class="input">
                          <input type="number" placeholder="우편 번호" disabled v-model="form.postCode">
                        </div>
                        <button
                          v-show="this.eduType.salesType === 1"
                          class="btn bo"
                          @click="$store.commit('layout/setModalAlert', {modalAlertName: $modalAlertNames.DAUMPOSTCODE, isHideHeader: false, callBack: fnBindDate})"
                        >주소 검색</button>
                      </div>
                      <div class="input">
                        <input type="text" placeholder="주소를 입력해 주세요" v-model="form.address" disabled>
                      </div>
                      <div class="input">
                        <input type="text" placeholder="상세주소를 입력해 주세요" v-model="form.address2">
                      </div>
                      <p class="valid on" v-show="$v.form.address.$error">필수입력사항입니다.</p><!--'on 추가'-->
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="page_btn">
            <button class="btn" @click="fnSubmit" :disabled='!isOver'>
              {{ $ConstCode.CODE_SALES_TYPE[eduType.salesType].name }}
              집합 위생교육 접수
            </button>
          </div>
        </div>
      </div>
      <!-- 홈페이지 유지보수 개발요청안 | 푸터 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
      <bottom-banner v-bind:isURL="isURL"></bottom-banner>
    </main>
  </div>
</template>

<script>
import ComponentEduType from '@/components/common/edu/edu-type'
import { required } from 'vuelidate/lib/validators'
import ComponentBoardTap from '@/components/common/edu/board-tap'
import SideBanner from '@/components/common/banner/SideBanner.vue'
import BottomBanner from '@/components/common/banner/BottomBanner.vue'

export default {
  name: 'apply-offline',
  description: '교육신청 (집합)',
  components: {
    ComponentEduType,
    ComponentBoardTap,
    SideBanner,
    BottomBanner
  },
  data() {
    return {
      changeZipProvinceNo: -1,
      isCalender: false,
      isOver: true,
      form: {
        offlineEducationNo: -1,
        applyEduNo: -1,
        educationCodeNo: -1,
        userName: '',
        birth: '',
        positionType: 1,
        userPhone: '',
        businessName: '',
        representative: '',
        location: '',
        postCode: '',
        address: '',
        address2: '',
        licenseNumber: '',
        isNew: '',
        sceneNo: '' // 집합 일정 교육 선택 값
      },
      schedule: {
        list: {
          sceneNo: -1,
          eduDate: '',
          time: '',
          address: '',
          personnel: '',
          location: ''
        },
        edu: {
          eduDate: '',
          startTime: ''
        }
      },
      showSchedule: false,
      isURL: ''
    }
  },
  watch: {
    'eduType': function() {
      this.form.location = ''
      this.form.businessName = ''
      this.form.representative = ''
      this.form.postCode = ''
      this.form.address = ''
      this.form.address2 = ''
      this.showSchedule = false
      this.searchURL()
    },
    'form.location': async function(val) {
      if (val !== this.changeZipProvinceNo) {
        this.schedule.list.sceneNo = -1
        this.schedule.list.time = ''
        this.schedule.list.address = ''
        this.schedule.list.personnel = 0
        this.schedule.list.location = ''
        this.schedule.edu.eduDate = ''
        this.schedule.edu.startTime = ''
        this.schedule.edu.endTime = ''
      }
      await this.fnLocation()
      await this.fnEducation()
    }
  },
  async created() {
    this.searchURL()
    // 현재 로그인 되어있느 회원정보 바인딩 (이름, 생년월일, 휴대폰번호)
    const res = await this.$UserSvc.fetchUser()
    this.form.userName = res.entity.name
    this.form.birth = res.entity.birth
    this.form.userPhone = res.entity.userPhone

    // 집합 교육은 신규 영업자만 가능
    // 진입 시, 지닌 영업자 코드가 기존이라면 메인 화면으로 이동하도록 (서남아이에스 임현아, 2024-02-20)
    if (this.eduType.salesType === 2) { // 기존 영업자인 경우 메인 화면으로 이동
          this.$router.push({ path: '/' })
    }
  },
  computed: {
    eduType: function() {
      return this.$store.getters['education/getEduType']
    },
    URL() {
      return this.searchURL(); // 메소드 호출
    }
  },
  methods: {
    searchURL: function(){
      if (this.eduType.industryType === 1) {
        this.isURL = '13'
      }
      if (this.eduType.industryType === 2) {
        this.isURL = '18'
      }
      if (this.eduType.industryType === 3) {
        this.isURL = '23'
      }
    },
    fnSubmit: async function() {
      this.isOver = true
      this.$v.$touch()
      if (!this.$Util.isValid(this.$v, this.$el)) return false
      if(this.form.positionType === 1) {
        if (this.form.userName !== this.form.representative) {
          alert('교육 이수자 이름과 대표자 이름이 일치하지 않습니다.')
          return false
        }
      }
      if(this.form.positionType === 2) {
        if (this.form.userName === this.form.representative) {
          alert('교육 이수자 성명과 대표자 성명이 달라야 합니다.\n다시 확인하시고 입력하여 주십시오.')
          return false
        }
      }
      this.form.salesType = this.eduType.salesType
      this.form.industryType = this.eduType.industryType
      this.form.offlineEduNo = -1
      console.log(this.form)
      this.isOver = false
      const res = await this.$OfflineEduSvc.upsertOfflineEdu(this.form)
      if(res.code === 1) {
        alert('신청서 접수가 완료되었습니다.\n결제내역에서 결제하기 버튼을 눌러 위생교육 결제를 진행해주세요.')
        this.$store.commit('education/setEduType', {eduData: {salesType: this.eduType.salesType, industryType: this.eduType.industryType,
            isOnline: this.eduType.isOnline, optionType: 2 }})
        await this.$router.push({ path: '/apply/list' })
        this.isOver = true
        // this.$router.go(-1)
      } else {
        alert(res.message)
        this.isOver = true
        // this.$Util.toastAlert(this, res.message, 'danger')
      }
    },
    fnBindDate: function ({roadAddress, zonecode}) {
      this.form.address = roadAddress
      this.form.postCode = zonecode
    },
    fnLocation: async function() {
      this.showSchedule = true
      const params = {}
      params.salesType = this.eduType.salesType
      params.industryType = this.eduType.industryType
      params.zipProvinceNo = this.form.location
      params.isView = 1
      const res = await this.$OfflineEduSvc.fetchLocation(params)
      if(res.code === 1) {
        // this.schedule = res.entity
        this.isCalender = true
      } else {
        this.isCalender = false
      }
    },
    fnView: function() {
      this.$store.commit('education/setBoardType', {boardData: {boardType: 2}})
      this.$router.push({
        // 게시글 이동 URL 변경 (서남아이에스 임현아, 2024-11-14)
        // path: '/board/detail?boardType=2&no=78'
        path: '/board/detail?boardType=2&no=136'
      })
    },
    fnSelected: function(offlineEducationNo, sceneNo) {
      this.form.sceneNo = sceneNo
      this.form.offlineEducationNo = offlineEducationNo
    },
    fnEducation: function() {
      this.$store.commit('layout/setModalAlert', {modalAlertName: this.$modalAlertNames.CALENDER, data: { industryType: this.eduType.industryType, salesType: this.eduType.salesType, zipProvinceNo: this.form.location, isModify: false }, callBack: this.fnBindEdu})
    },
    fnBindEdu: function(item, emitType) {
      if (emitType !== 'close') {
        this.changeZipProvinceNo = item.edu.zipProvinceNo;
        if (this.form.location !== item.edu.zipProvinceNo) {
          this.form.location = item.edu.zipProvinceNo
        }
        this.form.sceneNo = item.list.sceneNo
        this.form.offlineEducationNo = item.edu.offlineEducationNo
        this.schedule.list.sceneNo = item.list.sceneNo
        this.schedule.list.time = item.list.endTime
        this.schedule.list.address = item.list.address
        this.schedule.list.personnel = item.list.personnel
        this.schedule.list.location = item.list.location
        this.schedule.edu.eduDate = item.edu.eduDate
        this.schedule.edu.startTime = item.edu.startTime
        this.schedule.count = item.count
      }
    },
    fnLicense: function() {
      this.$store.commit('layout/setModalAlert', {modalAlertName: this.$modalAlertNames.LICENSE, data: { industryType: this.eduType.industryType}, callBack: this.fnBindLicense})
    },
    fnBindLicense: function (item) {
      this.form.licenseNumber = item.lcns_no
      this.form.businessName = item.bssh_nm
      this.form.representative = item.prsdnt_nm
      this.form.postCode = item.zipno
      this.form.address = item.site_addr_juso
      this.form.address2 = item.site_addr_dtl
    }
  },
  validations: {
    form: {
      userName: {
        required
      },
      birth: {
        required
      },
      positionType: {
        required
      },
      userPhone: {
        required
      },
      businessName: {
        required
      },
      representative: {
        required
      },
      location: {
        required
      },
      address: {
        required
      },
      licenseNumber: {
        test: function(val) {
          if (this.eduType.salesType === 2){
            if (this.$Util.isEmpty(val)) return false
          }
          return true
        }
      },
      sceneNo: {
        test: function(val) {
          if (val > 0) {
            return true
          } else {
            return false
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
