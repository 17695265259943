<template>
  <main class="main" role="main">
    <info-tab-index />
    <div class="page">
      <div class="inner">
        <div class="lnb">
          <ul>
            <li><p class="home"></p></li>
            <li><p>고객센터</p></li>
            <li><p>신고센터</p></li>
          </ul>
        </div>
        <div class="page_ttl">
          <h1 class="h1">
            <span>신고센터</span>
          </h1>
        </div>
        <div class="page_con">
          <div class="table detail">
            <div class="row head">
              <div class="cell">
                <p class="left">
                  <span class="lock" v-if="form.inquiry.isSecret === 1"></span>
                  <span class="lock on" v-if="form.inquiry.isSecret === 2"></span>
                  <span class="board_ttl">{{ form.inquiry.title }}</span>
                </p>
              </div>
              <div class="cell fix240"><p class="date">{{ $moment(form.inquiry.regDate).format('YYYY.MM.DD') }}</p></div>
            </div>
            <div class="row">
              <div class="cell detail_con">
                <div class="editor">
                  <div v-html="form.inquiry.contents">
                  </div><!--div 꼭 넣어주세요!-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page_btn">
          <button class="btn" v-on:click="$router.go(-1)">목록으로</button>
        </div>
        <div class="page_con mt30">
          <div class="table comment">
            <div class="row">
              <div class="cell fix150">
                <p>
                  {{ form.admin.adminId }}
                </p>
              </div>
              <div class="cell">
                <p class="left">{{ form.inquiry.answer }}</p>
              </div>
              <div class="cell fix240">
                <p class="date">
                  {{ form.inquiry.answerDate ? $moment(form.inquiry.answerDate).format('YYYY.MM.DD HH:mm') : '' }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import InfoTabIndex from '@/components/common/info/tab-index'
export default {
  name: 'report-board-detail',
  components: { InfoTabIndex },
  description: '신고센터 상세',
  data() {
    return {
      form: {},
      params: {
        page: 1,
        limit: 10,
        inquiryNo: -1,
        reviewContent: ''
      }
    }
  },
  async created() {
    this.params.inquiryNo = this.$route.query.inquiryNo
    const res = await this.$InquirySvc.fetchData(this.params)
    this.form = res.entity
  }
}
</script>

<style scoped>

</style>
