import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
import store from '@/store'
import Layout from '@/layouts/Layout'
import Util from '@/common/utils/Util'

/** 메인 **/
import MainIndex from '@/pages/main/index'
import PreCert from '@/pages/previous/index'
import Terms from '@/pages/main/terms'
import Privacy from '@/pages/main/privacy'
import Marketing from '@/pages/main/marketing'
import Block from '@/pages/block'
import Intro from '@/pages/intro'


/** 회원가입 **/
import SignUpIndex from '@/pages/signup/index'
import SignUpForm from '@/pages/signup/form'

/** 로그인 **/
import LoginIndex from '@/pages/login/index'
import FindId from '@/pages/login/findid'
import FindPw from '@/pages/login/findpw'

/** 마이페이지 **/
import MyPageIndex from '@/pages/mypage/index'

/** 온라인, 집합 **/
import ApplyOnline from '@/pages/apply/online'
import ApplyOffline from '@/pages/apply/offline'
import ApplyList from '@/pages/applylist/index'
import EduPrint from '@/pages/eduprint/index'
import ListPayment from '@/pages/applylist/payment'
import PaymentDone from '@/pages/applylist/done'

/** 집합(오프라인) **/
import OfflinePrintReceipt from '@/pages/offline/print/receipt'
import OfflinePrintEduReceipt from '@/pages/offline/print/edureceipt'

import PaymentIndex from '@/pages/payment/index'

/** 게시판 **/
import Boards from '@/pages/board/index'
import BoardsDetail from '@/pages/board/detail'
import InfoBoard from '@/pages/info/board/index'

/** 나의 학습방 **/
import StudyList from '@/pages/mystudy/list'
import StudyPrint from '@/pages/mystudy/print'
import ListAuth from '@/pages/mystudy/list/auth'
import ListExam from '@/pages/mystudy/list/exam'
import ListStudy from '@/pages/mystudy/list/studyroom'
import ListSurvey from '@/pages/mystudy/list/survey'
import Confirmation from '@/pages/mystudy/confirmation/index'
import Payment from '@/pages/mystudy/payment/index'
import EduReplay from '@/pages/mystudy/replay/index'
import replayView from '@/pages/mystudy/replay/view'
import replayList from '@/pages/mystudy/replay/list'

/** 학습지원 **/
import Remote from '@/pages/support/remote'
import Board from '@/pages/support/board'
import BoardDetail from '@/pages/support/boarddetail'
import BoardWrite from '@/pages/support/boardwrite'

/** 신고센터 **/
import ReportBoard from '@/pages/report/board'
import ReportBoardDetail from '@/pages/report/boarddetail'
import ReportBoardWrite from '@/pages/report/boardwrite'

/** 이용안내 **/
import InfoOnline from '@/pages/info/online'
import InfoOffline from '@/pages/info/offline'
import InfoContact from '@/pages/info/contact'
/** 비디오 플레이어 **/
import VideoPlayer from '@/components/common/edu/edu-video'
import VideoReplayPlayer from '@/components/common/edu/edu-replay-video'
import UserSvc from '@/common/service/UserSvc'
// import CommonSvc from '@/common/service/CommonSvc'

/**
 * header, footer와 같은 layout 상태 update
 * @param meta.isFooter {Boolean} footer show 여부
 * @param meta.isHeader {Boolean} header show 여부
 * @param meta.titleName {String} header title String
 * @param meta.isMain {Boolean} main header show 여부
 *
 * */
const updateLayout = meta => {
  store.commit('layout/setLayout', {
    titleName: meta.titleName,
    isHeader: meta.isHeader,
    isFooter: meta.isFooter,
    isMain: meta.isMain,
    isSideMenu: meta.isSideMenu,
    isMenuHeader: meta.isMenuHeader,
    isBack: meta.isBack,
    isIcon: meta.isIcon,
    isSearch: meta.isSearch
  })
}


const checkUserData = async (to, from, next) => {
  const user = store.getters['user/getUserData']
  if (Util.isEmpty(user)) {
    alert('로그인이 필요한 서비스입니다.')
    await router.replace({path: '/login', query: {rUrl: to.fullPath}})
  }
  return next()
}
// 접속 유지 24시간 이후 자동 제거
const loginCheck = async (to, from, next) => {
  const user = store.getters['user/getUserData']
  if (!Util.isEmpty(user)) {
    const res = await UserSvc.fetchUser()
    if (res.code === -9997) {
      await UserSvc.logout()
      next()
    }
    const loginDate = new Date(res.entity.loginDate).getTime()
    const now = new Date().getTime()
    const sec = parseInt(now - loginDate) / 1000
    const hour = parseInt(sec / 60 / 60)
    if (Number(hour) >= 24) {
      await UserSvc.logout()
      next()
      return false
    } else {
      next()
    }
  }
  next()
}
// 이벤트 없을 시 자동 로그아웃 기능 추가 (서남아이에스 임현아, 2024-09-09)
// 접속 유지 2시간 제한
const timeoutCheck = async (to, from, next) => {
  const user = store.getters['user/getUserData']
  if (!Util.isEmpty(user) || user.length > 0) {
    // 시간 제한
    let timeCounter = store.getters['user/getTimeout']
    let timeoutClock = store.getters['user/getTimeoutClock']

    // 이벤트 등록 (이벤트 감지용)

    // 시간 제한 카운트
    let timer = setInterval( () => {
      if (store.getters['user/getTimeout'] === '7200') {
        timeCounter = 7200
      }
      if (Util.isEmpty(localStorage.userData)){
        clearInterval(timer)
      }
      
      timeCounter --

      const loginDate = timeoutClock
      const now = new Date().getTime()
      const sec = parseInt(now - loginDate) / 1000
      const hour = parseInt(sec / 60 / 60)
      
      if (timeCounter <= 0 || Number(hour) >= 2) {
        alert('로그인 시간이 만료되어 재 로그인이 필요합니다.')
        
        const params = {}
        params.applicationId = 'edu'
        UserSvc.logout(params)
        clearInterval(timer)
        next({ path: '/main' })
        return false
      }
      store.commit('user/setTimeout', timeCounter)
    }, 1000)


  }
  next()
}
// 접속 차단 IP 일치시 창 강제 끄기
let nowPath = ''
let referrerPaths = ['/']
const ipBlockCheck = async (to, from, next)=> {
  const res1 = await UserSvc.searchIp()
  const res = await UserSvc.fetchSearchBlock()
  if (res.code === -2002){
    // alert(res.message)
    // router.push({path: '/block', query: {ip: params.block}})
    next({path: '/block', query: {ip: res1.entity}})
  } else {
    nowPath = to.fullPath
    const referrer = document.referrer
    // 처음 경로 유입했을때 체크
    console.log(referrer, nowPath)
    if (referrerPaths.indexOf('/') > -1 && !Util.isEmpty(referrer)) {
      const params = {}
      params.count = 1
      params.siteName = referrer
      await UserSvc.insertConnect(params)
    }
    const authorization = to.query.authorization
    if (!Util.isEmpty(authorization)) {
      const params = {}
      params.authorization = authorization
      params.applicationId = 'edu'
      // 동시 로그인 방지
      const resDuplicate = await UserSvc.userDuplicateValidCheck(params)
        if(resDuplicate.code === -1013) {// 동시 로그인 방지 기능 생성 (서남아이에스 임현아, 2024-09-09)
          if(confirm('이미 연결되어있는 홈페이지가 있습니다.\n연결을 해제하고 로그인 하시겠습니까?')){
            UserSvc.adminLogin(params).then(res => {
              let authToken = res.headers['authorization']
              let userData = res.data.entity
              store.commit('user/storeLogin', {accessToken: authToken, userData: JSON.stringify(userData)})
            })
          } else {
            alert ('로그인에 실패하였습니다.')
            return false
          }
        } else {
          UserSvc.adminLogin(params).then(res => {
            let authToken = res.headers['authorization']
            let userData = res.data.entity
            store.commit('user/storeLogin', {accessToken: authToken, userData: JSON.stringify(userData)})
          })
        }
    }
    loginCheck()
    if (nowPath === '/' || nowPath === `/?authorization=${authorization}`) {
      next({ path: '/main' })
    } else {
      next()
    }
  }
}

const router = new Router({
  linkActiveClass: 'on',
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    // 원하는 위치로 돌아가기
    return new Promise(resolve => {
      setTimeout(() => {
        if (to.hash === '#ma') {
          return false
        }
        if (savedPosition) {
          resolve(savedPosition)
        }
        resolve({ x: 0, y: 0 })
      }, 100)
    })
  },
  routes: [
    {
      path: '/',
      component: Layout,
      redirect: '/main',
      beforeEnter:timeoutCheck,
      children: [
        {
          description: '인트로화면',
          path: '/',
          name: 'intro',
          meta: {
            isHeader: false,
            page: 1,
            isMain: false,
            isBack: false,
            isFooter: false
          },
          component: Intro,
          beforeEnter:ipBlockCheck
        },
        {
          description: 'IP차단접속화면',
          path: '/block',
          name: 'block',
          meta: {
            isHeader: false,
            page: 1,
            isMain: false,
            isBack: false,
            isFooter: false
          },
          component: Block
        },
        {
          description: '메인',
          path: '/main',
          name: 'main-index',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: true
          },
          component: MainIndex,
          beforeEnter:loginCheck
        },
        {
          description: '전년도 수료증 출력',
          path: '/previous',
          name: 'previous-index',
          meta: {
            isHeader: false,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: PreCert
        },
        {
          description: '이용 약관',
          path: '/main/terms',
          name: 'main-terms',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: true
          },
          component: Terms
        },
        {
          description: '개인정보처리방침',
          path: '/main/privacy',
          name: 'main-privacy',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: true
          },
          component: Privacy
        },
        {
          description: '제 3자 마케팅 활용 동의서',
          path: '/main/marketing',
          name: 'main-marketing',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: true
          },
          component: Marketing
        },
        {
          description: '회원가입',
          path: '/signup',
          name: 'sign-up-index',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: true
          },
          component: SignUpIndex
        },
        {
          description: '회원가입 폼',
          path: '/signup/form',
          name: 'sign-up-form',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: true
          },
          component: SignUpForm
        },
        {
          description: '로그인',
          path: '/login',
          name: 'login-index',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: true
          },
          component: LoginIndex
        },
        {
          description: '아이디 찾기',
          path: '/login/findid',
          name: 'login-findid',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: true
          },
          component: FindId
        },
        {
          description: '비밀번호 찾기',
          path: '/login/findpw',
          name: 'login-findpw',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: true
          },
          component: FindPw
        },
        {
          description: '마이페이지',
          path: '/mypage',
          name: 'mypage-index',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: true
          },
          component: MyPageIndex
        },
        {
          description: '교육신청 (온라인)',
          path: '/apply/online',
          name: 'apply-online',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: true
          },
          component: ApplyOnline,
          beforeEnter: checkUserData
        },
        {
          description: '교육신청 (집합)',
          path: '/apply/offline',
          name: 'apply-offline',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: true
          },
          component: ApplyOffline,
          beforeEnter: checkUserData
        },
        {
          description: '신청목록',
          path: '/apply/list',
          name: 'apply-list',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: true
          },
          component: ApplyList,
          beforeEnter: checkUserData
        },
        {
          description: '교육접수증 출력',
          path: '/eduprint',
          name: 'edu-print',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: true
          },
          component: EduPrint,
          beforeEnter: checkUserData
        },
        {
          description: '신청목록 결제',
          path: '/apply/list/payment',
          name: 'apply-list-payment',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: true
          },
          component: ListPayment,
          beforeEnter: checkUserData
        },

        {
          description: '신청목록 결제완료',
          path: '/apply/list/done',
          name: 'apply-list-done',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: true
          },
          component: PaymentDone,
          beforeEnter: checkUserData
        },
        {
          description: '집합 교육접수증 출력',
          path: '/offline/print/receipt',
          name: 'online-print-receipt',
          meta: {
            isHeader: false,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: OfflinePrintReceipt,
          beforeEnter: checkUserData
        },
        {
          description: '집합 교육접수증 출력',
          path: '/offline/print/edureceipt',
          name: 'online-print-edureceipt',
          meta: {
            isHeader: false,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: OfflinePrintEduReceipt,
          beforeEnter: checkUserData
        },
        {
          description: '나의학습방 수강목록',
          path: '/mystudy/list',
          name: 'mystudy-list',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: StudyList,
          beforeEnter: checkUserData
        },
        {
          description: '나의학습방 수료증출력',
          path: '/mystudy/print',
          name: 'mystudy-print',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: StudyPrint,
          beforeEnter: checkUserData
        },
        {
          description: '나의학습방 수강목록 학습방',
          path: '/mystudy/list/studyroom',
          name: 'mystudy-list-studyroom',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: ListStudy,
          beforeEnter: checkUserData
        },
        {
          description: '나의학습방 수강목록 본인인증',
          path: '/mystudy/list/auth',
          name: 'mystudy-list-auth',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: ListAuth,
          beforeEnter: checkUserData
        },
        {
          description: '나의학습방 수강목록 교육평가시험',
          path: '/mystudy/list/exam',
          name: 'mystudy-list-exam',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: ListExam,
          beforeEnter: checkUserData
        },
        {
          description: '나의학습방 수강목록 설문조사',
          path: '/mystudy/list/survey',
          name: 'mystudy-list-survey',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: ListSurvey,
          beforeEnter: checkUserData
        },
        {
          description: '나의학습방 지정확인서제출',
          path: '/mystudy/confirmation',
          name: 'mystudy-confirmation',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: Confirmation,
          beforeEnter: checkUserData
        },
        {
          description: '나의학습방 결제정보',
          path: '/mystudy/payment',
          name: 'mystudy-payment',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: Payment,
          beforeEnter: checkUserData
        },
        {
          description: '온라인 교육 다시보기',
          path: '/mystudy/replay',
          name: 'mystudy-replay',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: EduReplay,
          beforeEnter: checkUserData
        },
        {
          description: '온라인 교육 다시보기 리스트',
          path: '/mystudy/replay/list',
          name: 'mystudy-replay-list',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: replayList,
          beforeEnter: checkUserData
        },
        {
          description: '온라인 교육 다시보기 상세',
          path: '/mystudy/replay/view',
          name: 'mystudy-replay-view',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: replayView,
          beforeEnter: checkUserData
        },
        {
          description: '학습지원 원격지원',
          path: '/support/remote',
          name: 'support-remote',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: Remote
        },
        {
          description: '게시판',
          path: '/board',
          name: 'board-index',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: Boards
        },
        {
          description: '게시판',
          path: '/info/board',
          name: 'info-board-index',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: InfoBoard
        },
        {
          description: '게시판 상세',
          path: '/board/detail',
          name: 'board-detail',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: BoardsDetail
        },
        {
          description: '학습지원 1대1게시판',
          path: '/support/board',
          name: 'support-board',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: Board
        },
        {
          description: '학습지원 1대1게시판 상세',
          path: '/support/boarddetail',
          name: 'support-board-detail',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: BoardDetail
        },
        {
          description: '학습지원 1대1게시판 글쓰기',
          path: '/support/boardwrite',
          name: 'support-board-write',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: BoardWrite,
          beforeEnter: checkUserData
        },
        {
          description: '학습지원 신고센터',
          path: '/report/board',
          name: 'report-board',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: ReportBoard
        },
        {
          description: '학습지원 신고센터 상세',
          path: '/report/boarddetail',
          name: 'report-board-detail',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: ReportBoardDetail
        },
        {
          description: '학습지원 신고센터 글쓰기',
          path: '/report/boardwrite',
          name: 'report-board-write',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: ReportBoardWrite,
          beforeEnter: checkUserData
        },
        {
          description: '이용안내 온라인위생교육안내',
          path: '/info/online',
          name: 'info-online',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: InfoOnline
        },
        {
          description: '이용안내 집합위생교육안내',
          path: '/info/offline',
          name: 'info-offline',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: InfoOffline
        },
        {
          description: '이용안내 지회지부 연락처',
          path: '/info/contact',
          name: 'info-contact',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: false
          },
          component: InfoContact
        },
        {
          description: '교육 영상',
          path: '/edu/video',
          name: 'edu-video',
          meta: {
            isHeader: false,
            page: 1,
            isMain: false,
            isBack: false,
            isFooter: false
          },
          component: VideoPlayer
        },
        {
          description: '교육 영상 재수강',
          path: '/edu/replay/video',
          name: 'edu-replay-video',
          meta: {
            isHeader: false,
            page: 1,
            isMain: false,
            isBack: false,
            isFooter: false
          },
          component: VideoReplayPlayer
        },
        {
          description: '신청목록 결제',
          path: '/payment',
          name: 'payment-index',
          meta: {
            isHeader: true,
            page: 1,
            isMain: true,
            isBack: false,
            isFooter: true
          },
          component: PaymentIndex,
          beforeEnter: checkUserData
        },
      ]
    }
  ]
})

let closePaths = ['/']
window.fnBack = (isBack = true) => {
  if (closePaths.indexOf(nowPath) > 0) {
    Util.nativeFinish()
  } else if (isBack) {
    router.go(-1)
  }
}

window.refresh = () => {
  router.go(-1)
}

window.fnCloseModal = () => {
  let isModal = store.state.layout.modal.isModal
  if (isModal) {
    store.commit('layout/setIsShowModal', { isShow: false })
    setTimeout(() => {
      store.commit('layout/setModal', { isModal: false, modalName: '' })
    }, 200)
    return true
  }
  return false
}

window.fnCloseModalAlert = () => {
  let isModalAlert = store.state.layout.modalAlert.isModalAlert
  if (isModalAlert) {
    store.commit('layout/setIsShowModalAlert', { isShowAlert: false })
    setTimeout(() => {
      store.commit('layout/setModalAlert', {
        isModalAlert: false,
        modalAlertName: ''
      })
    }, 200)
    return true
  }
  return false
}

router.beforeEach((to, from, next) => {
  window.fromPath = from.path
  let meta = to.meta
  updateLayout(meta)
  nowPath = to.fullPath
  return next()
})

router.afterEach((to, from) => {
  if (from.path === '/main/wait') {
    let el = document.getElementsByName('body')
    console.log(el, el.scrollTop)
    // mainScrollPosition =
  }
})

window.fnMoveDetail = (type1, type2, params) => {
  if (type1 === 'B') {
    // 영업활동
    setTimeout(() => {
      router.push({ path: `/business/docs/view${params}` })
    }, 400)
  } else if (type1 === 'S') {
    // 상담활동
    let path = `/counseling/report/docs/view${params}`
    if (Number(type2) === 0) {
      // 담당자배정시
      path = '/counseling'
    } else if (Number(type2) === 1) {
      // '상담진행' 경우만 path 가 다름
      path = `/counseling/ing${params}`
    }
    setTimeout(() => {
      router.push({ path: path })
    }, 400)
  }
}

// 푸시 조회수 증가
window.fnUpdatePushReadCount = async pushNo => {
  const params = {}
  params.pushNo = Number(pushNo)
  // await PushSvc.updatePushReadCount(params)
}

export default router
