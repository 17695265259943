<template>
  <div class="pop_cover_inner">
    <div class="pop_cont">
      <div class="pop_txt">
        <p v-html="data.message">
        </p>
      </div>
      <div class="pop_btn">
        <button v-on:click="fnCallBack"><p>확인</p></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-alert-alert',
  props: {
    data: {
      type: [Array,Object],
      default: () => {}
    },
    callBack: {
      type: [Function]
    }
  },
  methods: {
    fnCallBack: function(e) {
      if (!this.$Util.isEmpty(this.callBack)) {
        this.callBack(e)
      }
      this.$emit('eventClose')
    }
  }
}
</script>

<style scoped>

</style>
