<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="lnb">
          <ul>
            <li><p class="home"></p></li>
            <li><p>이용 약관</p></li>
          </ul>
        </div>
        <div class="page_ttl">
          <h1 class="h1">
            <span>이용 약관</span>
          </h1>
        </div>
        <div class="page_con">
          <div class="rule_list">
            <ul class="rule_list_ul">
              <li class="rule_list_li">
                <div class="rule_list_ttl">
                  <h3 class="h3">제1장 총칙</h3>
                </div>
                <div class="rule_list_con">
                  <div class="term">
                    <div class="term_ttl">
                      <span>제1조 (목적)</span>
                    </div>
                    <div class="term_con">
                      <p>
                        이 약관은 (사)한국외식산업협회(이하 ‘협회’라 합니다.)가
                        제공하는 온라인 교육 서비스(이하 ‘서비스’라 합니다.)의 이용조건,
                        절차 및 기타 필요한 사항을 규정함을 목적으로 합니다.
                      </p>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제2조 (약관의 효력과 변경)</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                          <span class="ol_li_txt">이 약관은 회원에게 공지함으로써 효력을 발생합니다.</span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 약관의 내용을 변경할 수 있으며,
                                                    변경된 약관은 제1항과 같은 방법으로 공지 또는
                                                    통지함으로써 효력을 발생합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    회원은 변경된 약관에 동의하지 않으면 회원 탈퇴를 요청할 수 있으며,
                                                    변경된 약관의 효력 발생일 이후에도 서비스를 계속 사용할 경우
                                                    약관의 변경 사항에 동의한 것으로 간주합니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제3조 (약관 이외의 준칙)</span>
                    </div>
                    <div class="term_con">
                      <p>
                        이 약관에 명시되지 않은 사항이 관계 법령에 규정되어 있으면 그 규정에 따릅니다.
                      </p>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제4조 (용어의 정의)</span>
                    </div>
                    <div class="term_con">
                      <p>
                        이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                      </p>
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    회원 : 협회의 홈페이지(www.kofsia.or.kr)에서 회원가입을 통해
                                                    이용자 아이디(ID)를 부여받은 자를 말합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    아이디(ID) : 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고
                                                    협회가 승인하는 문자와 숫자의 조합을 말합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    비밀번호 : 회원이 부여받은 ID와 일치된 회원임을 확인하고,
                                                    회원 자신의 비밀을 보호하기 위하여 회원이 정한 문자와 숫자의 조합을 말합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    회원 정보 : 이용자가 회원 가입 시 협회에 등록하는 이용자 ID, 이름, 주소 등
                                                    이용자의 신상에 관련된 정보를 말합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    운영자 : 서비스의 전반적인 관리와 원활한 운영을 위하여 협회에서 선정한 사람을 말합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    업소코드 : 교육대상자(영업신고기준)별 고유의 식별번호로 업소정보(대표자명, 상호,
                                                    업체 주소, 업종, 영업신고번호)와 연계되는 숫자의 조합을 말합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    교육과정 : 협회가 수강 희망자에게 통해 제공하는 교육콘텐츠를 말합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    수강료 : 교육과정을 이용한 대가로 협회가 정한 기간단위로 부과하는 금액을 말합니다.

                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    탈퇴 : 회원이 서비스의 정상 개시 후에 그 이용 계약을 해약하는 것을 말합니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </li>
              <li class="rule_list_li">
                <div class="rule_list_ttl">
                  <h3 class="h3">제2장 서비스 이용계약</h3>
                </div>
                <div class="rule_list_con">
                  <div class="term">
                    <div class="term_ttl">
                      <span>제5조 (이용계약의 성립)</span>
                    </div>
                    <div class="term_con">
                      <p>
                        이용계약은 서비스 이용신청자의 이용신청에 대해여 협회가 승낙함으로써 성립합니다.
                      </p>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제6조 (이용신청)</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    서비스 이용신청자는 협회 홈페이지를 통하여 협회 소정의 회원
                                                    가입신청서를 제출함으로써 이용신청을 할 수 있습니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    서비스 이용신청자는 반드시 실명으로 이용신청을 하여야 하며,
                                                    1개의 ID만 신청할 수 있습니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제7조 (회원가입의 승낙)</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 회원이 제7조에서 정한 모든 사항을 정확히 기재하여
                                                    회원가입을 하였을 때 특별한 사정이 없는 한 접수 순서대로 회원가입을 승낙합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 다음 각 호의 1에 해당하는 경우 회원가입에 대한 승낙을 제한할 수 있고,
                                                    그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.
                                                </span>
                          <ol class="nb_dot">
                            <li>
                                                        <span class="ol_li_txt">
                                                            설비에 여유가 없거나 기술상에 지장 있는 경우
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            서비스 이용요금을 납부하지 아니한 경우
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            기타 협회가 필요하다고 인정되는 경우
                                                        </span>
                            </li>
                          </ol>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 다음 각 호의 1에 해당하는 회원가입 신청에 대하여는
                                                    이를 승낙하지 아니할 수 있습니다.
                                                </span>
                          <ol class="nb_dot">
                            <li>
                                                        <span class="ol_li_txt">
                                                            이름이 실명이 아닌 경우
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            다른 사람의 명의를 사용하여 신청한 경우
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            가입 신청서의 내용을 허위로 기재한 경우
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            사회의 안녕과 질서 혹은 미풍양속을 저해할 목적으로 신청한 경우
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            기타 협회가 정한 소정의 이용신청 요건을 충족하지 못하는 경우
                                                        </span>
                            </li>
                          </ol>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    제2항 또는 3항에 의하여 회원가입의 승낙을 유보하거나 승낙하지 아니하는 경우,
                                                    협회는 이를 가입 신청자에게 알려야 합니다. 다만, 협회의 귀책사유 없이 가입
                                                    신청자에게 통지할 수 없는 경우는 예외로 합니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제8조 (회원 아이디(ID)의 변경) </span>
                    </div>
                    <div class="term_con">
                      <p>
                        다음 각 호의 1에 해당하는 경우 협회는 직권 또는 회원의 신청에 따라
                        회원 아이디(ID)를 변경할 수 있습니다.
                      </p>
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    회원 아이디(ID)가 회원의 전화번호, 주민등록번호 등으로
                                                    등록되어 있어서 회원의 사생활을 침해할 우려가 있는 경우
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    기타 협회 소정의 합리적인 사유가 있는 경우
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </li>
              <li class="rule_list_li">
                <div class="rule_list_ttl">
                  <h3 class="h3">제3장 서비스 제공 및 이용</h3>
                </div>
                <div class="rule_list_con">
                  <div class="term">
                    <div class="term_ttl">
                      <span>제9조 (서비스의 내용)</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    협회가 제공하는 서비스는 교육서비스, 커뮤니티 서비스,
                                                    기타 학습 관련 자료 및 정보 등 협회가 제공하는 모든 정보 및 행위입니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있습니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제10조 (서비스의 요금)</span>
                    </div>
                    <div class="term_con">
                      <p>
                        협회가 제공하는 서비스는 무료 또는 유료로 구분하여 제공합니다.
                      </p>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제11조 (서비스의 개시)</span>
                    </div>
                    <div class="term_con">
                      <p>
                        서비스는 협회가 제7조에 따라 이용 신청을 승낙한 때부터 즉시 개시됩니다.
                        다만, 협회의 업무상 또는 기술상의 장애로 인하여 서비스를 즉시 개시하지
                        못하는 경우 협회는 회원에게 이를 바로 통지합니다.
                      </p>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제12조 (서비스의 이용시간)</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    서비스는 협회의 업무상 또는 기술상 장애,
                                                    기타 특별한 사유가 없는 한 연중무휴, 1일 24시간
                                                    이용할 수 있습니다. 다만 설비의 점검 등 협회가
                                                    필요한 경우 또는 설비의 장애, 서비스 이용의 폭주 등
                                                    불가항력 사항으로 인하여 서비스 이용에 지장이 있는 경우
                                                    예외적으로 서비스 이용의 전부 또는 일부에 대하여
                                                    제한할 수 있습니다. 이 경우 협회는 그 내용을 서비스 안내에
                                                    게시하거나 별도로 사전에 공지하여야 합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    협회가 제공하는 서비스 중 일부에 대한 서비스 이용시간을
                                                    별도로 정할 수 있으며, 이 경우 그 이용시간을 사전에
                                                    회원에게 공지 또는 통지합니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </li>
              <li class="rule_list_li">
                <div class="rule_list_ttl">
                  <h3 class="h3">제4장 교육 유료서비스의 이용</h3>
                </div>
                <div class="rule_list_con">
                  <div class="term">
                    <div class="term_ttl">
                      <span>제13조 (수강신청 효력 발생)</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    수강신청은 수강신청 희망자의 수강신청을 협회가 승인함으로써 효력이 발생합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    효력은 교육과정 수강 기간 종료일까지로 합니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제14조 (수강신청)</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    유료 교육서비스 수강신청자는 서비스를 통하여 협회 소정의 수강
                                                    신청서를 제출함으로써 수강신청을 할 수 있습니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    유료 교육서비스 수강신청자는 반드시 실명으로 수강신청을 하여야 하며,
                                                    1개 이상의 과정을 신청할 수 있습니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    수강신청은 협회 회원에 한합니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제14조의1 (수강료 납부)</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    제14조 제1항의 규정에 따른 서비스를 이용하고자 하는 회원은
                                                    수강료를 납입하여야 합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    제1항의 규정에 의한 수강료, 그 납부방법 등에 대한 것은 다음 각 호에 따릅니다.
                                                </span>
                          <ol class="nb_dot">
                            <li>
                                                        <span class="ol_li_txt">
                                                            수강료는 협회가 정한 요금체계에 의하며 협회 홈페이지에서
                                                            공지한 가격을 기준으로 합니다.
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            수강료는 신용카드 결제, 가상계좌 입금, 휴대폰 결제 등으로 납부합니다.
                                                        </span>
                            </li>
                          </ol>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    미성년자가 수강신청을 하는 경우 수강료 납부자를 법정대리인으로 하여
                                                    수강신청을 할 수 있습니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    개인 명의로 유료서비스를 이용하고 개인이 수강료를 납부하는 경우 회원 명은
                                                    반드시 유료서비스를 이용하는 회원 자신의 본명(실명) 이어야 하며,
                                                    수강료 납부 책임자는 유료서비스 이용 회원 외에 타인으로 지정할 수 있습니다.
                                                    단, 유료서비스 이용자와 수강료 납부 책임자가 다른 경우 협회는 이를 확인할 수
                                                    있는 제 증명을 요구할 수 있습니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    개인 명의로 유료서비스를 이용하고 개인이 속한 법인 등 단체가 수강료를 납부하는
                                                    경우 회원 명은 반드시 유료서비스를 이용하는 회원 자신의 본명(실명)이어야 하며,
                                                    수강료 납부는 유료서비스 이용 회원과 이용 회원이 속한 법인 등의 단체가 연대하여
                                                    책임을 집니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제15조 (결제수단 및 방법)</span>
                    </div>
                    <div class="term_con">
                      <p>
                        결제 수단은 현금으로 신용카드, 휴대폰, 가상계좌 또는 기타 협회가 인정하는
                        결제수단으로 할 수 있습니다.
                      </p>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제16조 (수강승인)</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 제14조에 따른 수강신청에 대하여 협회가 수강료 납부 사실
                                                    확인 후 또는 결제 승인 후 수강신청을 승인합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 다음 각 호의 1에 해당하는 사항을 인지하는 경우 등 수강신청을
                                                    승인하지 아니합니다.
                                                </span>
                          <ol class="nb_dot">
                            <li>
                                                        <span class="ol_li_txt">
                                                            교육 유료서비스 과정의 수강료 납부하지 아니하고 수강신청 기간이 지난 경우
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            이름이 실명이 아닌 경우
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            수강신청서의 내용을 허위로 기재한 경우
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            기타 소정의 수강신청요건을 충족하지 못하는 경우
                                                        </span>
                            </li>
                          </ol>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    제2항에 따라 수강신청의 승인을 유보하거나 승인하지 아니하는 경우,
                                                    협회는 이를 수강신청자에 알려야 합니다. 다만, 협회의 귀책사유 없이
                                                    수강신청자에게 통지할 수 없는 경우는 예외로 합니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제17조 (수강취소 및 환불절차)</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                   협회는 수강 취소 및 환불 조건과 절차를 명확히 정하여 이에 따라 성실히 이행하여야 한다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    집합교육 수강 취소 및 환불 규정
                                                </span>
                          <ol class="nb_dot">
                            <li>
                                                        <span class="ol_li_txt">
                                                            교육비를 결제한 수강자가 교육 종료 전 취소할 경우 전액 환불
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            교육비를 결제한 수강자가 교육 종류 후 취소할 경우 환불하지 않음.
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                                결제 후 취소 의사를 밝히지 않고 무단 결석할 경우 환불하지 않음.
                                                        </span>
                            </li>
                          </ol>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    온라인교육 수강 취소 및 환불 규정
                                                </span>
                          <ol class="nb_dot">
                            <li>
                                                        <span class="ol_li_txt">
                                                            교육비를 결제한 수강자가 “미수료” 상태일 경우 결제수단에 따라 환불 및 취소처리
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            교육비를 결제한 수강자가 “수료” 상태일 경우 환불하지 않음.
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            결제 후 취소 의사를 밝히지 않고, 강의제공 기한 내에 강의 수강을 완료하지 않을 경우 환불하지 않음.
                                                        </span>
                                                        </li>
                          </ol>
                        </li>
                        <li style="color: red;">
                                                <span class="ol_li_txt">
                                                    교육 유료서비스(온라인 교육) 결제일 기준 1개월 또는 그해 말일이
                                                    지난 후에도 미수료 시 교육비는 환불되지 않으며 교육비는 협회로 귀속됩니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    수강취소/환불신청은 반드시 식품위생교육 홈페이지에 등록하여야 합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    수강료를 입금한 수강신청자가 환불 신청하는 경우 환불 받을 수 있는
                                                    지정은행 및 지정계좌를 입력하여야 합니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제18조 (수료확인)</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    교육 유료서비스 수강자의 수료처리는 협회가 정한 기준과
                                                    절차(식품위생교육 홈페이지에 공지)에 따라 처리를 합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    진도, 시험 등 수료 관련 데이터는 식품위생교육 홈페이지에서 확인할 수 있습니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제19조 (정보의 제공 및 광고의 게재)</span>
                    </div>
                    <div class="term_con">
                      <p>
                        협회는 서비스의 운용과 관련하여 서비스 화면, 홈페이지,
                        전자우편 등에 광고 등을 게재할 수 있으며, 회원이 서비스
                        이용 중 필요가 있다고 인정되는 다양한 정보에 대해서 전자
                        우편이나 SMS, 전화 등의 방법으로 회원에게 제공할 수 있습니다.
                      </p>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제20조 (서비스 제공의 중지)</span>
                    </div>
                    <div class="term_con">
                      <p>협회는 다음 각 호의 1에 해당하는 경우 서비스의 제공을 중지할 수 있습니다.</p>
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    설비의 보수 등을 위하여 부득이한 경우
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지하는 경우
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    기타 서비스를 제공할 수 없는 사유가 발생한 경우
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </li>
              <li class="rule_list_li">
                <div class="rule_list_ttl">
                  <h3 class="h3">제5장 서비스와 관련한 권리·의무관계</h3>
                </div>
                <div class="rule_list_con">
                  <div class="term">
                    <div class="term_ttl">
                      <span>제21조 (협회의 의무)</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 수 있도록
                                                    최선의 노력을 다하여야 합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 서비스에 관련된 설비를 항상 운용할 수 있는 상태로 유지보수하고,
                                                    장애가 발생하는 경우 바로 이를 수리·복구할 수 있도록 최선의 노력을 다하여야 합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 즉시 처리하여야 하며,
                                                    즉시 처리가 곤란한 경우 그 사유와 처리일정을 전화 또는 전자우편 등을 통하여 동
                                                    회원에게 통지하여야 합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며
                                                    이 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하는 데
                                                    최선을 다하여야 합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 항상 회원의 신용정보를 포함한 개인신상정보의 보안에 대하여 기술적
                                                    안전 조치를 강구하고 관리에 만전을 기함으로써 회원의 정보보안에 최선을 다하여야 합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 공정하고 건전한 운영을 통하여 전자상거래 질서 유지에 최선을 다하고,
                                                    지속적인 연구, 개발을 통하여 양질의 서비스를 제공함으로써 고객만족을
                                                    극대화하여 인터넷 비즈니스 발전에 기여하도록 노력합니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제22조 (개인정보보호)</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 회원의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호"에 관한
                                                    법률 "개인정보취급방침"을 준용합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 회원 가입 시 회원이 제공하는 정보, 기타 서비스 이용 과정에서
                                                    수집되는 정보 등을 본 회원 가입의 이행과 본 서비스 제공을 위한 목적으로만 사용되며,
                                                    협회 이외의 어떤 기관에도 절대로 회원의 개인정보를 제공하지 않습니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 서비스 제공과 관련하여 취득한 회원의 신상정보를 본인의 승낙 없이
                                                    제3자에게 누설 또는 배포할 수 없으며 상업적 목적으로 사용할 수 없습니다.
                                                    다만, 다음의 각 호에 해당하는 경우에는 그러하지 아니합니다.
                                                </span>
                          <ol class="nb_dot">
                            <li>
                                                        <span class="ol_li_txt">
                                                            정보통신서비스의 제공에 따른 요금 정산을 위하여 필요한 경우
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 제공하는 경우
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            관계 법령에 따라 수사상 목적으로 정해진 절차와 방법에 따라
                                                            관계기관의 요구가 있는 경우
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            다른 법률에 특별한 규정이 있는 경우
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            정보통신윤리위원회의 요청이 있는 경우
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            식품위생 관련 정보 및 홍보에 이용하는 경우
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            협회의 승인된 학술대회 및 직무교육 안내를 위한 목적으로 제공하는 경우
                                                        </span>
                            </li>
                          </ol>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 회원의 동의하에 개인정보를 제3자에게 제공할 수 있습니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제23조 (회원의 의무)</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    회원은 관계 법령, 이 약관의 규정, 이용안내 및 주의사항 등 회사가
                                                    통지하는 사항을 준수하여야 하며, 기타 협회의 업무에 방해되는 행위를
                                                    하여서는 아니 됩니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    회원은 협회의 사전 승낙 없이 서비스를 이용하여 어떠한 영리 행위도 할 수 없습니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    회원은 서비스를 이용하여 얻은 정보를 협회의 사전 승낙 없이 복사, 복제,
                                                    변경, 번역, 출판·방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    회원은 이용신청서의 기재 내용 중 변경된 내용이 있는 경우 서비스를 통하여
                                                    그 내용을 협회에 통지하여야 합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    회원은 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 아니 됩니다.
                                                </span>
                          <ol class="nb_dot">
                            <li>
                                                        <span class="ol_li_txt">
                                                            다른 회원의 아이디(ID)를 부정 사용하는 행위
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            선량한 풍속, 기타 사회질서를 해하는 행위
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            타인의 명예를 훼손하거나 모욕하는 행위
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            타인의 지적 재산권 등의 권리를 침해하는 행위
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            해킹 행위 또는 컴퓨터 바이러스의 유포 행위
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로
                                                            전송 또는 타 사이트를 링크하는 행위
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            기타 관계 법령에 위배되는 행위
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            게시판 등 커뮤니티를 통한 상업적 광고홍보 또는 상거래 행위
                                                        </span>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제24조 (게시물 또는 내용물의 삭제)</span>
                    </div>
                    <div class="term_con">
                      <p>
                        협회는 서비스의 게시물 또는 내용물이 제13조의 규정에 위반되거나
                        협회 소정의 게시 기간을 초과하는 경우 사전 통지나 동의 없이 이를 삭제할 수 있습니다.
                      </p>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제25조 (게시물의 저작권)</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    게시물에 대한 저작권을 포함한 모든 권리 및 책임은 이를 게시한 회원에게 있습니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    회원이 서비스 내에 게시하거나 등록한 자료의 지적 재산권은 동 회원에게 귀속됩니다.
                                                    단, 협회는 서비스 내의 게재권을 가지며 비상업적 목적으로는 회원의 게시물을
                                                    활용할 수 있습니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    회원은 타인의 게시물을 가공, 판매하는 행위 등 게재된 자료를 상업적으로 이용할
                                                    수 없으며 이를 위반하여 발생하는 제반 문제에 대한 책임은 회원에게 있습니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </li>
              <li class="rule_list_li">
                <div class="rule_list_ttl">
                  <h3 class="h3">제6장 기타</h3>
                </div>
                <div class="rule_list_con">
                  <div class="term">
                    <div class="term_ttl">
                      <span>제26조 (양도금지)</span>
                    </div>
                    <div class="term_con">
                      <p>
                        회원이 서비스의 이용권한, 기타 이용계약 상 지위를 타인에게 양도,
                        증여할 수 없으며, 이를 담보로 제공할 수 없습니다.
                      </p>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제27조 (계약해지 및 이용제한)</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    회원이 이용계약을 해지하고자 하는 때에는 본인이 서비스 또는 전자우편을 통하여
                                                    해지하고자 하는 날의 1일 전까지(단, 해지일이 법정 공휴일인 경우 공휴일
                                                    개시 2일 전까지) 이를 협회에 신청하여야 합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 회원이 제13조 약관의 내용을 위반하고, 협회 소정의 기간 이내에
                                                    이를 해소하지 아니하는 경우 서비스 이용 계약을 해지할 수 있습니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 제2항에 의해 해지된 회원이 다시 이용신청을 하는 경우 일정 기간 그 승낙을
                                                    제한할 수 있습니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제28조 (손해배상)</span>
                    </div>
                    <div class="term_con">
                      <p>
                        협회는 무료로 제공되는 서비스와 관련하여 회원에게 어떠한 손해가
                        발생하더라도 동 손해가 협회의 중대한 과실에 의한 경우를 제외하고
                        이에 대하여 책임을 부담하지 아니합니다.
                      </p>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제29조 (면책·배상) </span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 회원이 서비스에 게재한 정보, 자료, 사실의 정확성, 신뢰성 등
                                                    그 내용에 관하여는 어떠한 책임을 부담하지 아니하고, 회원은 자기의
                                                    책임 아래 서비스를 이용하며, 서비스를 이용하여 게시 또는 전송한 자료
                                                    등에 관하여 손해가 발생하거나 자료의 취사선택, 기타 서비스 이용과 관련하여
                                                    어떠한 불이익이 발생하더라도 이에 대한 모든 책임은 회원에게 있습니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    협회는 제23조의 규정을 위반하여 회원 간 또는 회원과 제3자 간에 서비스를
                                                    매개로 하여 물품거래 등과 관련하여 어떠한 책임도 부담하지 아니하고,
                                                    회원이 서비스의 이용과 관련하여 기대하는 이익에 관하여 책임을 부담하지 않습니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    회원 아이디(ID)와 비밀번호의 관리 및 이용 상의 부주의로 인하여 발생
                                                    되는 손해 또는 제3자에 의한 부정 사용 등에 대한 책임은 모두 회원에게 있습니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    회원이 제23조, 기타 이 약관의 규정을 위반함으로 인하여 협회가
                                                    회원 또는 제3자에 대하여 책임을 부담하게 되고, 이로써 협회에 손해가
                                                    발생하게 되는 경우, 이 약관을 위반한 회원은 협회에게 발생하는 모든 손해를
                                                    배상하여야 하며, 동 손해로부터 협회를 면책시켜야 합니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제30조 (분쟁의 해결)</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    협회와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여
                                                    필요한 모든 노력을 하여야 합니다.
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    제1항의 규정에도 불구하고 분쟁으로 인하여 소송이 제기될 경우 소송은
                                                    협회 본사 소재지 법원의 관할로 합니다.
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>부 칙 (시행일)</span>
                    </div>
                    <div class="term_con">
                      <p>
                        본 약관은 2021년 1월 1일부터 시행합니다.
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    name: 'main-terms',
    description: '이용약관'
  }
</script>

<style scoped>

</style>
