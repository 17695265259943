<template>
  <div class="modal_wrap">
    <div class="modal_inner center w1200">
      <!-- 홈페이지 유지보수 개발요청안 | 팝업 여러개 표출 (서남아이에스 임현아, 2024-07-02) -->
      <!-- <div class="modal w50p"> -->
      <div v-for="(item, index) in items" :key="index" :id="index" :style="getModalStyle(item, index)">
        <!-- <div class="modal" v-bind:style="{ 'width': '600px', 'top': fnTop(item.popupNo), 'position': 'fixed', 'left': item.seq + 'px', 'z-index': '2' }"> -->
          <div class="modal_con">
            <!--슬라이드 배너-->
            <div class="intro_bnr">
              <!-- <swiper class="intro_bnr_ul" ref="mySwiper" :options="swiperOptions">-->
                <!-- <swiper-slide class="intro_bnr_li">-->
                  <div class="img_wrap" @click="fnMove(item.webLink)">
                    <img :src="$ConstCode.getImagePath(item.imagePath, '720/')">                  
                    <!-- <img :src="item.imagePath"/> -->
                  </div>
                <!-- </swiper-slide>-->
              <!-- </swiper>-->
              <!--//슬라이드 배너-->
              <div class="intro_bnr_pager" slot="pagination" style="z-index: 20;">
<!--              <div class="dot on"></div>&lt;!&ndash;'on 추가'&ndash;&gt;-->
<!--              <div class="dot"></div>-->
<!--              <div class="dot"></div>-->
              </div>
            </div>
          </div>
          <div class="intro_btn">
            <div class="checkbox">
              <input type="checkbox" :id="index + 'a'" :true-value="true" :false-value="false" :checked="isNone" @change="toggleCheckbox(index)" :v-model="'isNone' + index">
              <label :for="index + 'a'"><span>오늘 하루 보지 않기</span></label>
            </div>
            <button class="btn" @click="fnClose(index)">닫기</button>
          </div>
        </div>
      </div>

        <!-- <div class="modal" style="width: 600px; top: 0em; position: fixed; left: 0em; z-index: 2;">
        <div class="modal" v-bind:style="{ 'width': '600px', 'top': top, 'position': 'fixed', 'left': left, 'z-index': '2' }">
        <div class="modal_con">
          <div class="intro_bnr">
            <swiper class="intro_bnr_ul" ref="mySwiper" :options="swiperOptions">
              <swiper-slide class="intro_bnr_li"
                            v-for="(item, index) in items"
                            :key="index">
                <div class="img_wrap" @click="fnMove(item.webLink)">
                  <img src="https://www.kfoodedu.or.kr/720/20240118/40f196d3c07b4f63806dd77f755b3792.jpg"/>
                </div>
              </swiper-slide>
            </swiper>
            <div class="intro_bnr_pager" slot="pagination" style="z-index: 20;">
              <div class="dot on"></div>&lt;!&ndash;'on 추가'&ndash;&gt;
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </div>
        </div>
        <div class="intro_btn">
          <div class="checkbox">
            <input type="checkbox" id="a" :true-value="true" :false-value="false" :checked="isNone" v-model="isNone">
            <label for="a"><span>오늘 하루 보지 않기</span></label>
          </div>
          <button class="btn" @click="fnClose">닫기1</button>
        </div>
      </div>
      <div class="modal w50p" v-bind:style="{ 'width': '600px', 'top': top2, 'position': 'fixed', 'left': left2, 'z-index': '2' }">
       <div class="modal w50p" style="width: 600px; top: 650px; position: fixed; left: 1280px; z-index: 1;"> -->
    </div>
  <!-- </div> -->
</template>

<script>
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
export default {
  name: 'modal-alert-popup',
  props: {
    data: {
      type: [Array, Object],
      default: () => {}
    },
    callBack: {
      type: [Function]
    }
  },
  components: {
    // Swiper,
    // SwiperSlide
  },
  data: function() {
    const items = this.data.items
    return {
      isNone: false,
      isNone0: false,
      isNone1: false,
      isNone2: false,
      isNone3: false,
      popupCnt: 0,
      items,
      swiperOptions: {
        autoplay: {
          delay: 3000,
        },
        slideToClickedSlide: true,
        pagination: {
          el: '.intro_bnr_pager'
        }
      },
      // swiper: {},
    }
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.swiper = this.$refs.mySwiper.$swiper
    // })

    for(var i = 0; i < this.data.items.length; i++){
      if(!this['isNone'+i]){
        this.popupCnt += 1;
      }
    }
    
    // 창 크기 변경 시 이벤트 리스너 등록
    window.addEventListener('resize', this.setElementClass);
  },
  methods: {
    fnClose: function (index) {
      if (this['isNone'+index]) {
        this.$cookies.set('isNone'+index, 1, 60 * 60 * 24)
      }
      document.getElementById(index).style.display = 'none';

      // 열려있는 팝업 갯수 ~> 하나가 되면 eventClose 시킬 것 
      this.popupCnt -= 1;

      if(this.popupCnt === 0){
        this.$emit('close')
      }
    },
    fnMove: function(link) {
      if (this.$Util.isEmpty(link)) return false
      window.open(link, '_blank')
    },
    getModalStyle(item, index) {
      var display_style = !this.$Util.isEmpty(this.$cookies.get('isNone' + index)) ? 'none' : ''
      
      if(display_style === 'none'){
        this['isNone'+index] = 'false'
      }

      return {
        // width: '600px',
        position: 'fixed',
        top: this.fnTop(item.topLocation),
        left: this.fnLeft(item.leftLocation),
        'z-index': index,
        display: display_style
      }
      
    },
    fnTop: function(topLocation){
      // popupNo 이 서버에서 보내주는 top 값이라면
      var top = 0

      if(window.innerHeight < 800 || window.innerWidth < 1200){
        top = 0
      }else if(window.innerHeight - topLocation < 600){
        top = window.innerHeight - 600
      }else{
        top = topLocation
      }

       return 'calc(' + top + '%)';
    },
    fnLeft: function(leftLocation){
      // seq 이 서버에서 보내주는 left 값이라면
      var left = 0

      if(window.innerWidth < 1300 || window.innerWidth < 1200){
        left = 0
      }else if(window.innerWidth - leftLocation < 600){
      left = window.innerWidth - 600
      }else{
        left = leftLocation
      }

      return 'calc(' + left + '%)';
    },
    setElementClass() {
      for(var i = 0; i < this.items.length; i++){
        var top = 0
        var left = 0

        if(window.innerWidth < 1200){
          top = 0
          left = 0
        }else{
          top = this.items[i].topLocation
          left = this.items[i].leftLocation
        }

        document.getElementById(i).style.top = top + '%'
        document.getElementById(i).style.left = left + '%'
      }
    },
    toggleCheckbox(index) {
      this['isNone' + index] = !this['isNone' + index];
    }
  }
}
</script>

<style scoped>

</style>
