'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
import Util from '@/common/utils/Util'
import store from '@/store'
import {forEach} from 'lodash'

/**
 * 회원 관련 API Service
 * @class UserSvc
 */
class UserSvc extends ServiceExec {
  /**
   *  회원 가입
   *  @param address {string} address
   *  @param address2 {string} address2
   *  @param birth {integer} birth
   *  @param companyPhone {string} companyPhone
   *  @param email {string} email
   *  @param isAuth {integer} isAuth
   *  @param isEmail {integer} isEmail
   *  @param isMarketing {integer} isMarketing
   *  @param isSms {integer} isSms
   *  @param name {string} name
   *  @param userId {string} userId
   *  @param userNo {integer} userNo
   *  @param userPhone {string} userPhone
   *  @param userPw {string} userPw
   *  @param userType {integer} userType
   */
  join(params = {}) {
    return this.get(`/api/user/join`, params)
      .then(response => {
        let authToken = response.headers['authorization']
        let userData = response.data.entity
        if (!Util.isEmpty(authToken)) store.commit('user/storeLogin', {accessToken: authToken, userData: JSON.stringify(userData)})
        return response.data
      })
  }

  /**
   *  회원 정보 상세보기
   *  @param userNo {integer} userNo
   */
  fetchUser(params = {}) {
    return this.get(`/api/user/fetchUser`, params).then(response => {
      return response.data
    })
  }


  /**
   *  회원 최신 신청 내역 정보 가져오기
   *  @param Authorization {string} Authorization
   */
  fetchRecentEdu(params = {}) {
    return this
      .get(`/api/user/fetchRecentEdu`, params)
      .then(response => {
        return response.data
      })
  }


  /**
   *  회원 로그인
   *  @param userId {string} 아이디
   *  @param userPw {string} 패스워드
   */
  login(params = {}) {
    return this.get(`/api/user/login`, params)
      .then((response) => {
        if (response.data.code !== 1) {
          return response.data
        }
        let authToken = response.headers['authorization']
        let userData = response.data.entity
        if (!Util.isEmpty(authToken)) store.commit('user/storeLogin', {accessToken: authToken, userData: JSON.stringify(userData)})
        this.enhanceAccessToeken()
        return response.data
      })
  }


  /**
   *  회원로그아웃
   *  @param Authorization {string} Authorization
   *  @param deviceId {string} deviceId
   */
  logout (params = {}) {
    return this.get(`/api/user/logout`, params)
      .then(response => {
        this.store.commit('user/storeLogout')
        this.enhanceAccessToeken()
        this.axios.defaults.headers.common['Authorization'] = ''
        return response.data
      })
  }

  /**
   *  회원 정보 중복체크
   *  @param userId {string} userId
   */
  duplicationId(params = {}) {
    return this.get(`/api/user/duplicationId`, params)
      .then(response => {
        return response.data
      })
  }


  /**
   *  회원 마이페이지 수정
   *  @param Authorization {string} Authorization
   *  @param address {string} address
   *  @param address2 {string} address2
   *  @param companyPhone {string} companyPhone
   *  @param email {string} email
   *  @param postCode {integer} postCode
   *  @param userPhone {string} userPhone
   *  @param userPw {string} userPw
   */
  updateUser(params = {}) {
    return this
      .queryPost(`/api/user/updateUser`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  아이디 찾기
   *  @param name {string} name
   *  @param userPhone {string} userPhone
   */
  id(params = {}) {
    return this
      .get(`/api/user/find/id`, params)
      .then(response => {
        return response.data
      })
  }
  /**
   *  비밀번호 찾기
   *  @param name {string} name
   *  @param type {integer} type
   *  @param userId {string} userId
   *  @param userPhone {string} userPhone
   */
  pw(params = {}) {
    return this
      .get(`/api/user/find/pw`, params)
      .then(response => {
        return response.data
      })
  }


  /**
   *  회원 인증시간 수정
   *  @param Authorization {string} Authorization
   */
  updateAuthUser(params = {}) {
    let formData = new FormData()
    forEach(params, function (value, key) {
      formData.append(key, value)
    })
    return this.patch(`/api/user/updateAuthUser`, formData)
      .then(response => {
        let authToken = response.headers['authorization']
        let userData = response.data.entity
        if (!Util.isEmpty(authToken)) store.commit('user/storeLogin', {accessToken: authToken, userData: JSON.stringify(userData)})
        return response.data
      })
  }

  /**
   *  회원 탈퇴
   *  @param Authorization {string} Authorization
   */
  deleteUser(params = {}) {
    return this
      .post(`/api/user/deleteUser`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  접속 차단 IP 조회
   *  @param block {string} block
   */
  fetchSearchBlock(params = {}) {
    return this.get(`/api/user/fetchSearchBlock`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  사용자 IP 조회
   */
  searchIp(params = {}) {
    return this.get(`/api/user/searchIp`, params)
      .then(response => {
        return response.data
      })
  }


  /**
   *  접속방향 분석 등록
   *  @param count {integer} count
   *  @param siteName {string} siteName
   */
  insertConnect(params = {}) {
    return this.queryPost(`/api/connect/insertConnect`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  회원 본인인증 시간 업데이트
   *  @param Authorization {string} Authorization
   *  @param authCode {integer} authCode
   */
  userUpdateAuth(params = {}) {
    return this.get(`/api/user/userUpdateAuth`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  회원 강제 로그인
   *  @param Authorization {string} Authorization
   */
  adminLogin(params = {}) {
    store.commit('user/storeLogout')
    return this.get(`/api/user/adminLogin`, params).then(response => {
      return response
    })
  }

  /**
   *  회원 상태변경
   *  @param status  {integer} status
   *  @param userPhone {string} userPhone
   */
  // 홈페이지 유지보수 개발요청안 | 휴면해제 기능 추가 (서남아이에스 임현아, 2024-07-04)
    userModifyStatus(params = {}) {
      return this
        .get(`/api/user/userModifyStatus`, params)
        .then(response => {
          return response.data
        })
    }

  /**
   *  회원 비밀번호 변경
   *  @param userPw {string} userPw
   *  @param userOldPw {string} userOldPw
   */
  // 홈페이지 유지보수 개발요청안 | 휴면해제 기능 추가 (서남아이에스 임현아, 2024-07-04)
  updateUserPw(params = {}) {
    return this
      .queryPost(`/api/user/updateUserPw`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  회원 이전 비밀번호 변경
   *  @param userPw {string} userPw
   *  @param userOldPw {string} userOldPw
   */
  // 홈페이지 유지보수 개발요청안 | 휴면해제 기능 추가 (서남아이에스 임현아, 2024-07-04)
  updateUserPwCheck(params = {}) {
    return this
      .queryPost(`/api/user/updateUserPwCheck`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  회원 전화번호 변경
   *  @param userPhone {string} userPhone
   */
  // 홈페이지 유지보수 개발요청안 | 휴면해제 기능 추가 (서남아이에스 임현아, 2024-07-04)
  updateUserPhone(params = {}) {
    return this
      .queryPost(`/api/user/updateUserPhone`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  회원 동시 로그인 방지
   *  @param userId {string} 아이디
   *  @param userPw {string} 패스워드
   */
  // 홈페이지 유지보수 개발요청안 | 동시 로그인 방지 (서남아이에스 임현아, 2024-09-11)
  userDuplicateValidCheck(params = {}) {
    return this
      .get(`/api/user/userDuplicateValidCheck`, params)
      .then(response => {
        return response.data
      })
  }

  // 홈페이지 유지보수 개발요청안 | 동시 로그인 방지 (서남아이에스 임현아, 2024-09-11)
  userLoginSessionCheck(params = {}) {
    return this
      .get(`/api/user/userLoginSessionCheck`, params)
      .then(response => {
        return response.data
      })
  }

  userExtendSession() {
    const user = store.getters['user/getUserData']
    if (!Util.isEmpty(user)) {
      store.commit('user/setTimeoutClock', new Date().getTime())
  
      const params = {}
      params.applicationId = 'edu'

      return this.ServiceExec.get(`/api/user/userExtendSession`, { params })
        .then(response => {
          const userSession = response.data;
          store.commit('user/setTimeout', userSession.entity)
          if (userSession.code === -1014) {
            store.commit('user/storeLogout')
          }
        })
      }
  }
}
export default new UserSvc()
