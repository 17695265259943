'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
/**
 * 공통 API Service
 * @class UserOnlineSvc
 */
class UserOnlineSvc extends ServiceExec {

  /**
   *  회원 온라인 강의 리스트
   *  @param Authorization {string} Authorization
   *  @param limit {integer} 10
   *  @param page {integer} 1
   *  @param salesType {integer}
   */
  fetchOnlineClassList(params = {}) {
    return this
      .get(`/api/user/class/fetchOnlineClassList`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  온라인 강의 시간 및 접속위치
   *  @param Authorization {string} Authorization
   *  @param ipAddress {string} ipAddress
   *  @param onlineEduNo {integer} onlineEduNo
   *  @param studyTime {string} studyTime
   */
  updateEduStudyTime(params = {}) {
    return this.get(`/api/user/class/updateEduStudyTime`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  온라인 평가시험 여부
   *  @param Authorization {string} Authorization
   *  @param isEvaluation {integer} isEvaluation
   *  @param onlineClassNo {integer} onlineClassNo
   */
  updateEvaluation(params = {}) {
    return this.get(`/api/user/class/updateEvaluation`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  온라인 설문지 제출 여부
   *  @param Authorization {string} Authorization
   *  @param isSurvey {integer} isSurvey
   *  @param onlineEduNo {integer} onlineEduNo
   */
  updateSurvey(params = {}) {
    return this
      .get(`/api/user/class/updateSurvey`, params)
      .then(response => {
        return response.data
      })
  }
}
export default new UserOnlineSvc()
