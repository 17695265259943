<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_ttl">
          <h1 class="h1">
            <span>회원가입</span>
          </h1>
        </div>
        <div class="page_con">
          <div class="form_table">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_ttl">
                    <p class="essen">아이디</p>
                  </div>
                  <div class="form_in">
                    <div class="inline">
                      <div class="input">
                        <input type="text" placeholder="아이디를 입력해 주세요" v-model="form.userId">
                      </div>
                      <button class="btn bo" @click="fnCheckId">중복 확인</button>
                    </div>
                    <p class="valid on" v-show="$v.form.userId.$error">{{ msg.userId }}</p><!--'on 추가'-->
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p class="essen">비밀번호</p>
                  </div>
                  <div class="form_in">
                    <div class="input">
                      <input type="password" placeholder="비밀번호를 입력해 주세요" v-model="form.userPw">
                      <p class="valid on" v-show="$v.form.userPw.$error"> {{ msg.userPw }}</p><!--'on 추가'-->
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p class="essen">비밀번호 확인</p>
                  </div>
                  <div class="form_in">
                    <div class="input">
                      <input type="password" placeholder="다시 한 번 입력해 주세요"  v-model="form.userPw2">
                      <p class="valid on" v-show="$v.form.userPw2.$error">{{ msg.userPw2 }}</p><!--'on 추가'-->
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p>휴대폰 번호</p>
                  </div>
                  <div class="form_in" v-show="!$Util.isEmpty(user.userPhone)">
                    <p class="bind">{{ user.userPhone }}</p>
                  </div>
                  <div class="form_in" v-show="$Util.isEmpty(user.userPhone)">
                    <div class="input">
                      <input type="number" placeholder="-를 제외하고 입력해 주세요" v-model="form.userPhone">
                      <p class="valid on" v-show="$v.form.userPhone.$error">휴대폰 번호를 입력해주세요.</p><!--'on 추가'-->
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p>이름</p>
                  </div>
                  <div class="form_in">
                    <p class="bind">{{ user.name }}</p>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p>생년월일</p>
                  </div>
                  <div class="form_in">
                    <p class="bind">{{ user.birth }}</p>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p class="essen">Email</p>
                  </div>
                  <div class="form_in">
                    <div class="input">
                      <input type="text" placeholder="이메일을 입력해 주세요"  v-model="form.email">
                      <p class="valid on" v-show="$v.form.email.$error">이메일을 입력해주세요.</p><!--'on 추가'-->
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p class="">전화 번호</p>
                  </div>
                  <div class="form_in">
                    <div class="input">
                      <input type="number" placeholder="-를 제외하고 입력해 주세요" v-model="form.companyPhone">
<!--                      <p class="valid on" v-show="$v.form.companyPhone.$error">전화번호를 입력해주세요.</p>&lt;!&ndash;'on 추가'&ndash;&gt;-->
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p class="essen">주소</p>
                  </div>
                  <div class="form_in">
                    <div class="inline">
                      <div class="input">
                        <input type="number" placeholder="우편 번호" disabled v-model="form.postalCode">
                      </div>
                      <button class="btn bo" @click="$store.commit('layout/setModalAlert', {modalAlertName: $modalAlertNames.DAUMPOSTCODE, isHideHeader: false, callBack: fnBindDate})">주소 검색</button>
                    </div>
                    <div class="input">
                      <input type="text" placeholder="주소를 입력해 주세요" disabled v-model="form.address">
                    </div>
                    <div class="input">
                      <input type="text" placeholder="상세주소를 입력해 주세요" v-model="form.address2">
                    </div>
                    <p class="valid on" v-show="$v.form.address.$error">주소를 입력해주세요.</p><!--'on 추가'-->
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p>수신동의</p>
                  </div>
                  <div class="form_in">
                    <div class="inline">
                      <div class="checkbox">
                        <input type="checkbox" id="d" :true-value="1" :false-value="0" v-model="form.isSms"><!--디폴트: 체크된 상태-->
                        <label for="d"><span>SMS</span></label>
                      </div>
                      <div class="checkbox">
                        <input type="checkbox" id="e" :true-value="1" :false-value="0" v-model="form.isEmail">
                        <label for="e"><span>이메일</span></label>
                      </div>
                    </div>
                    <p class="valid">유효성 문구 영역</p><!--'on 추가'-->
                  </div>
                </li>
                <li class="form_li top_bo">
                  <div class="check_list">
                    <ul class="check_list_ul w600">
                      <li class="check_list_li">
                        <div class="checkbox">
                          <input type="checkbox" id="a" :true-value="1" :false-value="0" v-model="form.isPrivacy">
                          <label for="a"><span class="essen">개인정보 처리 방침에 동의합니다</span></label>
                          <p class="valid on" v-show="$v.form.isPrivacy.$error">필수 입니다.</p><!--'on 추가'-->
                        </div>
                        <button class="btn bo grey">내용보기</button>
                      </li>
                      <li class="check_list_li">
                        <div class="checkbox">
                          <input type="checkbox" id="b" v-model="form.isTerms" :true-value="1" :false-value="0">
                          <label for="b"><span class="essen">이용약관에 동의합니다</span></label>
                          <p class="valid on" v-show="$v.form.isTerms.$error">필수 입니다.</p><!--'on 추가'-->
                        </div>
                        <button class="btn bo grey">내용보기</button>
                      </li>
<!--                      <li class="check_list_li">
                        <div class="checkbox">
                          <input type="checkbox" id="c">
                          <label for="c">
                                                    <span>
                                                        개인정보 마케팅 활용 이용에 동의합니다
                                                        <span class="grey">(선택)</span>
                                                    </span>
                          </label>
                          <p class="valid">유효성 문구 영역</p>&lt;!&ndash;'on 추가'&ndash;&gt;
                        </div>
                        <button class="btn bo grey">내용보기</button>
                      </li>-->
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="page_btn">
          <button class="btn" @click="fnSave">회원가입</button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {required} from 'vuelidate/lib/validators'
export default {
  name: 'sign-up-form',
  description: '회원가입 폼',
  data: function () {
    return {
      user: {
        name: this.$route.query.name,
        userPhone: this.$route.query.userPhone,
        birth: this.$route.query.birth,
        authCode: this.$route.query.authType
      },
      checkId: false,
      form: {
        address: '',
        address2: '',
        birth: '',
        companyPhone: '',
        email: '',
        isAuth: 1,
        isEmail: 0,
        isSms: '',
        name: '',
        userId: '',
        userNo: -1,
        userPhone: '',
        userPw: '',
        userType: 1,
        postalCode: '',
        userPw2: '',
        isPrivacy: 0,
        isTerms: 0
      },
      msg: {
        address: '',
        address2: '',
        birth: '',
        companyPhone: '',
        email: 0,
        name: '',
        userId: '',
        userPhone: '',
        userPw2: '',
        userPw: '',
        userType: 1,
        isPrivacy: 0,
        isTerms: 0
      }
    }
  },
  validations: {
    form: {
      userId: {
        test: function(val) {
          if (val.checkId === false) {
            this.msg.userId = '중복 확인을 해주세요.'
            return false
          }
          if (!required(val)) {
            this.msg.userId = '아이디를 입력해주세요.'
            return false
          }
          if (val.length < 5) {
            this.msg.userId = '올바르지 않은 아이디 형식입니다.'
            return false
          }
          return true
        }
      },
      userPw: {
        valid (val) {
          this.msg.userPw = ''
          if (val.length < 4) {
            this.msg.userPw = '숫자 4자 이상 입력해주세요.'
            return false
          }
          return required(val)
        }
      },
      userPw2: {
        valid (val, obj) {
          this.msg.userPw2 = ''
          if (val.length < 4) {
            this.msg.userPw = '숫자 4자 이상 입력해주세요.'
            return false
          }
          if (obj.userPw !== val) {
            this.msg.userPw2 = '위의 비밀번호와 동일하게 입력해주세요'
            return false
          }
          return required(val)
        }
      },
      email: {
        required
      },
      userPhone: {
        required
      },
      address: {
        required
      },
      isPrivacy: {
        test: function(val) {
          if (val <= 0) {
            return false
          }
          return true
        }
      },
      isTerms: {
        test: function(val) {
          if (val <= 0) {
            return false
          }
          return true
        }
      }
    }
  },
  methods: {
    fnCheckId: async function() {
      const params = {}
      params.userId = this.form.userId
      const res = await this.$UserSvc.duplicationId(params)
      if (res.code === 1) {
        this.checkId = true
        alert('사용가능한 아이디 입니다.')
      } else {
        this.checkId = false
        alert(res.message)
      }
    },
    fnBindDate: function ({roadAddress, zonecode}) {
      this.form.address = roadAddress
      this.form.postalCode = zonecode
    },
    fnSave: async function () {
      this.$v.$touch()
      let params = {}
      params = this.$_.merge({}, this.form, this.user)
      if (this.$Util.isEmpty(this.user.userPhone)) {
        params.userPhone = this.form.userPhone
      }
      params.authCode = this.$ConstCode.USER_KCB__AUTH_TYPE[this.user.authCode].value
      if (!this.$Util.isValid(this.$v, this.$el)) return false
      const res = await this.$UserSvc.join(params)
      if (res.code === 1) {
        this.$router.push({
          path: '/main'
        })
      } else {
        alert(res.message)
      }
    }
  }
}
</script>

<style scoped>

</style>
