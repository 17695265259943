<template>
  <div>
    <info-tab-index />
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <div class="lnb">
            <ul>
              <li><p class="home"></p></li>
              <li><p>고객센터</p></li>
              <li><p>교육안내</p></li>
              <li><p>온라인 위생교육 안내</p></li>
            </ul>
          </div>
          <div class="page_ttl">
            <h1 class="h1">
              <span>온라인 위생교육 안내</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="rule_list">
              <ul class="rule_list_ul">
                <li class="rule_list_li">
                  <div class="rule_list_ttl">
                    <h3 class="h3">근거규정</h3>
                  </div>
                  <div class="rule_list_con">
                    <p>영업자를 대상으로 한 식품위생교육은 다음과 같은 근거규정을 실천합니다.</p>
                    <ol>
                      <li>
                                          <span>
                                              식품위생법 제41조 제2항(법 제 88조 제8항에 따라 준용되는 경우 포함)<br>
                                              제 36조 제1항의 각 호에 따른 영업을 하려는 자는 미리 식품 위생교육을 받아야 한다.
                                          </span>
                      </li>
                    </ol>
                  </div>
                </li>
                <li class="rule_list_li">
                  <div class="rule_list_ttl">
                    <h3 class="h3">교육 대상자</h3>
                  </div>
                  <div class="rule_list_con">
                    <p>
                      협회 회원 중, 기존영업자 및 그의 식품위생관리책임자에 해당하며
                      상기 온라인 위생교육을 수강하려는 자
                    </p>
                  </div>
                </li>
                <li class="rule_list_li">
                  <div class="rule_list_ttl">
                    <h3 class="h3">해당 업종</h3>
                  </div>
                  <div class="rule_list_con">
                    <p>일반음식점, 집단급식소, 위탁급식업</p>
                  </div>
                </li>
                <li class="rule_list_li">
                  <div class="rule_list_ttl">
                    <h3 class="h3">교육 기간</h3>
                  </div>
                  <div class="rule_list_con">
                    <p>온라인 위생교육 강의 결제 후 1달 이내로 수강</p>
                  </div>
                </li>
                <li class="rule_list_li">
                  <div class="rule_list_ttl">
                    <h3 class="h3">교육 시간</h3>
                  </div>
                  <div class="rule_list_con">
                    <p>3시간</p>
                  </div>
                </li>
                <li class="rule_list_li">
                  <div class="rule_list_ttl">
                    <h3 class="h3">교육 장소</h3>
                  </div>
                  <div class="rule_list_con">
                    <p>PC, 모바일</p>
                  </div>
                </li>
                <li class="rule_list_li">
                  <div class="rule_list_ttl">
                    <h3 class="h3">강의</h3>
                  </div>
                  <div class="rule_list_con">
                    <p>온라인 동영상 강의</p>
                  </div>
                </li>
                <li class="rule_list_li">
                  <div class="rule_list_ttl">
                    <h3 class="h3">교육신청방법</h3>
                  </div>
                  <div class="rule_list_con">
                    <img src="@/assets/static/images/img_steps01.png" alt="">
                  </div>
                </li>
                <li class="rule_list_li">
                  <div class="rule_list_ttl">
                    <h3 class="h3">온라인 교육 수강료</h3>
                  </div>
                  <div class="rule_list_con">
                    <div class="table">
                      <div class="row head">
                        <div class="cell"><p>일반음식점</p></div>
                        <div class="cell"><p>집단급식소</p></div>
                        <div class="cell"><p>위탁급식업</p></div>
                      </div>
<!--                      <div class="row">-->
<!--                        <div class="col head">-->
<!--                          <div class="cell"><p>신규 영업자</p></div>-->
<!--                        </div>-->
<!--                        <div class="col">-->
<!--                          <div class="cell"><p>{{ `${$Util.formatNumber(codeList[0].price)}원` }}</p></div>-->
<!--                        </div>-->
<!--                        <div class="col head">-->
<!--                          <div class="cell"><p>신규 영업자</p></div>-->
<!--                        </div>-->
<!--                        <div class="col">-->
<!--                          <div class="cell"><p>{{ `${$Util.formatNumber(codeList[1].price)}원` }}</p></div>-->
<!--                        </div>-->
<!--                        <div class="col head">-->
<!--                          <div class="cell"><p>신규 영업자</p></div>-->
<!--                        </div>-->
<!--                        <div class="col">-->
<!--                          <div class="cell"><p>{{ `${$Util.formatNumber(codeList[2].price)}원` }}</p></div>-->
<!--                        </div>-->
<!--                      </div>-->
                      <div class="row">
<!--                        <div class="col head">-->
<!--                          <div class="cell"><p>기존 영업자</p></div>-->
<!--                        </div>-->
                        <div class="col">
                          <div class="cell"><p>{{ `${$Util.formatNumber(codeList[3].price)}원` }}</p></div>
                        </div>
<!--                        <div class="col head">-->
<!--                          <div class="cell"><p>기존 영업자</p></div>-->
<!--                        </div>-->
                        <div class="col">
                          <div class="cell"><p>{{ `${$Util.formatNumber(codeList[4].price)}원` }}</p></div>
                        </div>
<!--                        <div class="col head">-->
<!--                          <div class="cell"><p>기존 영업자</p></div>-->
<!--                        </div>-->
                        <div class="col">
                          <div class="cell"><p>{{ `${$Util.formatNumber(codeList[5].price)}원` }}</p></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="rule_list_li">
                  <div class="rule_list_ttl">
                    <h3 class="h3">결제방법</h3>
                  </div>
                  <div class="rule_list_con">
                    <div class="pay_way small">
                      <div class="pay_way_card">
                        <img src="@/assets/static/images/img_creditcard.svg" alt="">
                        <div class="txt">
                          <h4 class="h4">신용카드 결제</h4>
                          <p>ISP/안심클릭</p>
                        </div>
                      </div>
                      <div class="pay_way_card">
                        <img src="@/assets/static/images/img_phone.svg" alt="">
                        <div class="txt">
                          <h4 class="h4">휴대폰 결제</h4>
                          <p>본인명의 휴대폰으로 결제</p>
                        </div>
                      </div>
                      <div class="pay_way_card">
                        <img src="@/assets/static/images/img_bank.svg" alt="">
                        <div class="txt">
                          <h4 class="h4">가상계좌</h4>
                          <p>발급된 가상계좌로 송금</p>
                        </div>
                      </div>
                      <div class="pay_way_card">
                        <img src="@/assets/static/images/img_livecount.svg" alt="">
                        <div class="txt">
                          <h4 class="h4">실시간계좌이체</h4>
                          <p>실시간 계좌이체로 결제</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
  import InfoTabIndex from '@/components/common/info/tab-index'
  export default {
    name: 'info-online',
    components: { InfoTabIndex },
    description: '이용안내 온라인위생교육안내',
    data() {
      return {
        codeList: {}
      }
    },
    async created() {
      await this.fetchCode()
    },
    methods: {
      fnReady: function() {
        alert('준비중입니다.')
      },
      fetchCode: async function() {
        const res = await this.$CommonSvc.code()
        this.codeList = res.entity.list
      }
    }
  }
</script>

<style scoped>

</style>
