<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_ttl">
          <h1 class="h1">
            <span>전년도 수료증 확인 및 출력</span>
          </h1>
        </div>
        <div class="page_con">
          <div class="form_table">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_ttl">
                    <p>연도</p>
                  </div>
                  <div class="form_in">
                    <div class="inline">
                      <div class="select">
                        <select v-model="form.year">
                          <option value="" disabled selected>선택</option>
                          <option value="2016">2016</option>
                          <option value="2017">2017</option>
                          <option value="2018">2018</option>
                          <option value="2019">2019</option>
                          <option value="2020">2020</option>
                          <option value="2021">2021</option>
                        </select>
                      </div>
                    </div>
                    <p class="valid on" v-show="$v.form.year.$error">연도를 선택해주세요.</p><!--'on 추가'-->
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p>업체명</p>
                  </div>
                  <div class="form_in">
                    <div class="input">
                      <input type="text" placeholder="업체명을 입력하세요" v-model="form.cname">
                      <p class="valid on" v-show="$v.form.cname.$error">업체명을 입력해주세요.</p><!--'on 추가'-->
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p>이수자 성명</p>
                  </div>
                  <div class="form_in">
                    <div class="input">
                      <input type="text" placeholder="교육이수자 이름을 입력하세요" v-model="form.uname">
                      <p class="valid on" v-show="$v.form.uname.$error">교육이수자 이름을 입력해주세요.</p><!--'on 추가'-->
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p>생년월일</p>
                  </div>
                  <div class="form_in">
                    <div class="input">
                      <input type="number" placeholder="생년월일을 입력해 주세요" v-model="form.ubir" maxlength="8">
                      <p class="hint">
                        생년월일 8자리(ex: 20200131)를 입력해 주세요.
                      </p>
                      <p class="valid on" v-show="$v.form.ubir.$error">{{ msg.ubir }}</p><!--'on 추가'-->
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p>강의 종류</p>
                  </div>
                  <div class="form_in">
                    <div class="inline">
                      <div class="select">
                        <select v-model="form.salesType">
                          <option value="" disabled selected>선택</option>
                          <option>신규</option>
                          <option>기존</option>
                        </select>
                      </div>
                    </div>
                    <p class="valid on" v-show="$v.form.salesType.$error">강의 종류를 선택해주세요.</p><!--'on 추가'-->
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="page_btn">
          <button class="btn" v-on:click="fnSearch">검색</button>
        </div>
        <div class="empty_wrap" v-show="list.length === 0 && search">
          <div class="empty">
            <span>내역이 존재하지 않습니다.</span>
          </div>
        </div>
        <div class="page_con mt40" v-show="list.length > 0 && search">
          <div class="table">
            <div class="row head">
              <div class="cell fix150"><p>업체명</p></div>
              <div class="cell fix150"><p>이수자 이름</p></div>
              <div class="cell fix150"><p>생년월일</p></div>
              <div class="cell"><p>강의 종류</p></div>
              <div class="cell"><p>주소</p></div>
              <div class="cell fix151"><p>발급번호</p></div>
              <div class="cell fix150"><p>수료증</p></div>
            </div>
            <div class="row" v-for="(item, index) in list" :key="index">
              <div class="cell fix150"><p>{{ !$Util.isEmpty(item.mode) ? item.cname : item.cuname }}</p></div>
              <div class="cell fix150"><p>{{ item.uname }}</p></div>
              <div class="cell fix150"><p>{{ item.ubir }}</p></div>
              <div class="cell" v-if="$Util.isEmpty(item.mode)"><p>{{ item.gubun }}</p></div>
              <div class="cell" v-else><p>{{ fnType(item.gubun) }}</p></div>
              <div class="cell"><p>{{ item.uaddr1 }}</p></div>
              <div class="cell fix151"><p>{{ item.outnumber }}</p></div>
              <div class="cell fix150">
                <button class="btn bo" v-on:click="fnPrint(item.uid, item.ubir)">출력</button>
              </div>
            </div>
          </div>
          <base-pagination
            class="pagination-no-border"
            v-model="pageInfo.page"
            v-on:input="fetchData"
            v-on:change="fetchData"
            v-bind:pageInfo="pageInfo"
          >
          </base-pagination>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import BasePagination from '@/components/common/base/BasePagingation'

export default {
  name: 'previous-index',
  description: '전년도 수료증 출력',
  components: { BasePagination },
  data() {
    return {
      search: false, //검색전 리스트 숨기기
      form: {
        year: '',
        uname: '',
        cname: '',
        ubir: '',
        salesType: ''
      },
      params: {
        page: 1,
        limit: 10,
        searchText: '',
        year: '',
        uname: '',
        cuname: '',
        ubir: '',
        salesType: '',
        startDate: '',
        endDate: ''
      },
      msg: {
        ubir: ''
      },
      list: [],
      pageInfo: {}

    }
  },
  computed: {
    gubun(utel, gubun) {
      console.log(gubun)
      console.log(utel)
      if (utel === "new") {
        return '신규영업자'
      } else {
        return '기존영업자'
      }
    }
  },
  methods: {
    fnSearch: async function() {
      this.$v.$touch()
      if (!this.$Util.isValid(this.$v, this.$el)) return false
      this.search = true
      // console.log('params'. this.form)
      const res = await this.$OldEduSvc.fetchUserOldEdu(this.form)
      console.log('res', res)
      if (res.code === 1) {
        this.list = res.entity.list
        this.pageInfo = res.entity.pageInfo
      }
    },
    fnPrint: function(uid, ubir) {
      this.$store.commit('layout/setModalAlert', { data: { uid: uid, ubir: ubir, isOld: true }, modalAlertName: this.$modalAlertNames.CERTIFICATION})
    },
    fnType: function(gubun) {
      let type = ''
      let type2 = ''
      if (gubun === "1" || gubun === "2" || gubun === "3") type = '신규영업자'
      else type = '기존영업자'
      if(gubun === "1" || gubun === "4") type2 = '(일반음식점)'
      if(gubun === "2" || gubun === "5") type2 = '(집합급식소)'
      if(gubun === "3" || gubun === "6") type2 = '(위탁급식업)'
      return type+type2
    }
  },
  validations: {
    form: {
      year: {
        required
      },
      cname: {
        required
      },
      uname: {
        required
      },
      ubir: {
        test: function(val) {
          if (this.$Util.isEmpty(val)) {
            this.msg.ubir = '생년월일을 입력해주세요.'
            return false
          }
          if (val.length < 8) {
            this.msg.ubir = '생년월일 8자리를 입력해주세요.'
            return false
          }
          return true
        }
      },
      salesType: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>
