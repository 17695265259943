<template>
  <div>
    <component-board-tap />
    <main class="main" role="main">      
      <div class="page">
        <div class="inner">
          <!-- 홈페이지 유지보수 개발요청안 | 사이드 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
          <side-banner v-bind:isURL="isURL"></side-banner>
          <component-edu-type />
          <div class="page_ttl">
            <h1 class="h1">
              <span>교육 신청</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="form_table">
              <div class="form">
                <ul class="form_ul">
                  <li class="form_li">
                    <div class="form_ttl">
                      <p class="essen">교육 이수자 이름</p>
                    </div>
                    <div class="form_in">
                      <div class="input">
                        <!-- 잠금 처리 (서남아이에스 임현아, 2024-11-14) -->
                        <input type="text" placeholder="이름을 입력해 주세요" v-model="form.userName" readonly disabled>
                        <p class="valid on" v-show="$v.form.userName.$error">필수입력사항입니다.</p><!--'on 추가'-->
                      </div>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">
                      <p class="essen">생년월일</p>
                    </div>
                    <div class="form_in">
                      <div class="input">
                        <!-- 잠금 처리 (서남아이에스 임현아, 2024-11-14) -->
                        <input type="number" placeholder="생년월일을 입력해 주세요" v-model="form.birth" readonly disabled>
                        <p class="valid on" v-show="$v.form.birth.$error">필수입력사항입니다.</p><!--'on 추가'-->
                      </div>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">
                      <p>업종 분류</p>
                    </div>
                    <div class="form_in">
                      <p class="bind">{{ $ConstCode.CODE_INDUSTRY_TYPE[eduType.industryType].name }}</p>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">
                      <p class="essen">직책</p>
                    </div>
                    <div class="form_in">
                      <div class="inline">
                        <div class="radio">
                          <input type="radio" name="a" id="aa" v-bind:value="1" v-model="form.positionType">
                          <label for="aa"><span>대표자</span></label>
                        </div>
                        <div class="radio">
                          <input type="radio" name="a" id="ab" v-bind:value="2" v-model="form.positionType">
                          <label for="ab"><span>위생관리자</span></label>
                        </div>
                      </div>
                      <p class="valid on" v-show="$v.form.positionType.$error">필수입력사항입니다.</p><!--'on 추가'-->
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="inform_box">
                      <ol class="dot">
                        <li class="color">
                              <!-- 문구 수정 (서남아이에스 임현아, 2024-11-14) -->
                              <span class="ol_li_txt">
                                  교육 이수자의 직책이 <span class="ol_li_txt_span">‘위생관리자’</span>인 경우,
                                  <span class="ol_li_txt_span">‘식품위생관리 책임자 지정확인서’</span>를 제출해야합니다.<br>
                                  <br>
                                  <!-- 지정확인서 안내 및 제출 방법 확인 경로 : 고객센터 > 공지사항 > 위생관리자 지정확인서 -->
                                  ※ 지정확인서 제출 방법 : 나의 학습방 ▶ 지정 확인서 제출
                                <!--230427 문구변경-->
                                <!-- 한국외식산업협회 위생교육 지정확인서 제출 양식 버튼 (서남아이에스 임현아, 2024-11-14) -->
                                <button class="btn" style="border: 0px; border-radius: 10px; background-color: #FF9F00; font-size: 14px; font-weight: 500; width: 150px !important;" v-on:click="fnView()">제출 양식 바로가기</button>
                                <!--                                                <button class="btn">지정확인서 안내</button>-->
                              </span>
                        </li>
                      </ol>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">
                      <p class="essen">휴대폰 번호</p>
                    </div>
                    <div class="form_in">
                      <div class="input">
                        <!-- 잠금 처리 (서남아이에스 임현아, 2024-11-14) -->
                        <input type="number" placeholder="-를 제외하고 입력해 주세요" v-model="form.userPhone" disabled>
                        <p class="valid on" v-show="$v.form.userPhone.$error">필수입력사항입니다.</p><!--'on 추가'-->
                      </div>
                    </div>
                  </li>
                  <li class="form_li" v-if="eduType.salesType === 2">
                    <div class="form_ttl">
                      <p class="essen">인허가 번호</p>
                    </div>
                    <div class="form_in">
                      <div class="inline">
                        <div class="input">
                          <!-- <input type="text" placeholder="번호를 입력해 주세요" v-model="form.licenseNumber" disabled> -->
                          <input type="text" style="width: auto;" placeholder="인허가 번호 검색을 눌러주세요." v-model="form.licenseNumber" disabled>
                        </div>
                        <button class="btn bo" style="margin-left: 50px;" @click="fnLicense" @bindData="fnBindLicense">인허가 번호 검색</button>
                      </div>
                      <!-- 문구 및 색상 변경 (서남아이에스 임현아, 2024-11-14) -->
                      <p class="hint" style="color: #41AC26 !important;">
                        <!-- 인허가번호를 입력해 검색 후 해당 영업소를 선택하시면 아래 모든 항목이 자동으로 입력됩니다. -->
                        <span style="color:#FF9F00; font-weight: 500;">인허가 번호 검색</span>을 누른 후 해당 영업소를 검색하여 선택하시면 아래 모든 항목이 자동으로 입력됩니다.
                      </p>
                      <p class="valid on" v-show="$v.form.licenseNumber.$error">필수입력사항입니다.</p><!--'on 추가'-->
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">
                      <p class="essen">영업소 & 상호명<br>명칭</p>
                    </div>
                    <div class="form_in">
                      <div class="input">
                        <!-- <input type="text" placeholder="명칭을 입력해 주세요" v-model="form.businessName" :disabled="this.eduType.salesType === 2"> -->
                        <input type="text" placeholder="인허가 번호 검색을 눌러주세요." v-model="form.businessName" :disabled="this.eduType.salesType === 2">
                        <p class="hint" v-show="this.eduType.salesType === 1">
                          영업소 & 상호명 명칭이 미정인 경우 입력란에 ‘미정’ 으로 적어주세요.<br>
                          <!-- 고객센터 목차명 변경 및 숨김처리 요청 1:1 게시판/신고센터로 통합, 원격지원 숨김 (서남아이에스 임현아, 2024-08-30) -->
                          (추후 상호명 변경을 원하시면 1:1 게시판 / 신고센터에 문의하시기 바랍니다.)
                          <!-- (추후 상호명 변경을 원하시면 1:1 게시판 및 신고센터에 문의하시기 바랍니다.) -->
                        </p>
                        <p class="valid on" v-show="$v.form.businessName.$error">필수입력사항입니다.</p><!--'on 추가'-->
                      </div>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">
                      <p class="essen">대표자 이름</p>
                    </div>
                    <div class="form_in">
                      <div class="input">
                        <!-- <input type="text" placeholder="이름을 입력해 주세요" v-model="form.representative" :disabled="this.eduType.salesType === 2"> -->
                        <input type="text" placeholder="인허가 번호 검색을 눌러주세요." v-model="form.representative" :disabled="this.eduType.salesType === 2">
                        <p class="valid on" v-show="$v.form.representative.$error">필수입력사항입니다.</p><!--'on 추가'-->
                      </div>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">
                      <p class="essen">영업소 소재지</p>
                    </div>
                    <div class="form_in">
                      <div class="inline">
                        <div class="input">
                          <!-- <input type="number" placeholder="우편 번호" v-model="form.postCode" disabled> -->
                          <input type="number" style="width: auto;" placeholder="인허가 번호 검색을 눌러주세요." v-model="form.postCode" disabled>
                        </div>
                        <button
                          class="btn bo"
                          v-show="this.eduType.salesType === 1"
                          @click="$store.commit('layout/setModalAlert',
                          {modalAlertName: $modalAlertNames.DAUMPOSTCODE, isHideHeader: false, callBack: fnBindDate})">
                          주소 검색</button>
                      </div>
                      <div class="input">
                        <!-- <input type="text" placeholder="주소를 입력해 주세요" v-model="form.address" disabled> -->
                        <input type="text" placeholder="인허가 번호 검색을 눌러주세요." v-model="form.address" disabled>
                      </div>
                      <div class="input">
                        <!-- <input type="text" placeholder="상세주소를 입력해 주세요" v-model="form.address2" :disabled="this.eduType.salesType === 2"> -->
                        <input type="text" placeholder="인허가 번호 검색을 눌러주세요." v-model="form.address2" :disabled="this.eduType.salesType === 2">
                      </div>
                      <p class="valid on" v-show="$v.form.address.$error">필수입력사항입니다.</p><!--'on 추가'-->
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="page_btn">
            <button class="btn" v-on:click="fnSubmit" :disabled='!isOver'>
              {{ $ConstCode.CODE_SALES_TYPE[eduType.salesType].name }}
              온라인 위생교육 접수
            </button>
          </div>
        </div>
        <!-- 홈페이지 유지보수 개발요청안 | 푸터 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
        <bottom-banner v-bind:isURL="isURL"></bottom-banner>
      </div>
    </main>
  </div>
</template>

<script>
import ComponentEduType from '@/components/common/edu/edu-type'
import ComponentBoardTap from '@/components/common/edu/board-tap'
import SideBanner from '@/components/common/banner/SideBanner.vue'
import BottomBanner from '@/components/common/banner/BottomBanner.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'apply-online',
  description: '교육신청 (온라인)',
  components: {
    ComponentEduType,
    ComponentBoardTap,
    SideBanner,
    BottomBanner
  },
  data() {
    return {
      isOver: true,
      schedule: -1,
      form: {
        userName: '',
        birth: '',
        positionType: 1,
        userPhone: '',
        businessName: '',
        representative: '',
        location: 1,
        postCode: '',
        address: '',
        address2: '',
        licenseNumber: '',
        isNew: ''
      },
      userList: {},
      isURL: ''
    }
  },
  watch: {
    'eduType': function() {
      this.form.businessName = ''
      this.form.representative = ''
      this.form.postCode = ''
      this.form.address = ''
      this.form.address2 = ''
      this.searchURL()
    }
  },
  async created() {
    this.searchURL()
    const year = new Date().getFullYear();
      // 2024.01.01 교육마감 START\
      if (this.eduType.salesType === 2) {
          if (this.$moment('2024-12-31 18:00:00').format('YYYY-MM-DD HH:mm:ss') <= this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss') &&
            this.$moment('2025-01-01 09:00:00').format('YYYY-MM-DD HH:mm:ss') >= this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')) {
          alert(`현재는 ${year}년도 교육 수강 신청 기간이 아닙니다.`);
          this.$router.push({ path: '/' })
        }
      }
    // 온라인 교육은 기존 영업자만 가능
    // 진입 시, 지닌 영업자 코드가 신규라면 메인 화면으로 이동하도록 (서남아이에스 임현아, 2024-02-20)
    if (this.eduType.salesType === 1) { // 신규 영업자인 경우 메인 화면으로 이동
          this.$router.push({ path: '/' })
    }

    // 현재 로그인 되어있느 회원정보 바인딩 (이름, 생년월일, 휴대폰번호)
    const res = await this.$UserSvc.fetchUser()
    this.form.userName = res.entity.name
    this.form.birth = res.entity.birth
    this.form.userPhone = res.entity.userPhone
    // 2024.01.01 START 식품안전나라 API 안되서 임시로막음
    //this.form.representative = res.entity.name
    // 2024.01.01 END 식품안전나라 API 안되서 임시로막음
  },
  computed: {
    eduType: function() {
      return this.$store.getters['education/getEduType']
    },
    URL() {
      return this.searchURL(); // 메소드 호출
    }
  },
  methods: {
    searchURL: function(){
      if (this.eduType.industryType === 1) {
        this.isURL = '1'
      }
      if (this.eduType.industryType === 2) {
        this.isURL = '5'
      }
      if (this.eduType.industryType === 3) {
        this.isURL = '9'
      }
    },
    fnSubmit: async function() {
      this.isOver = true
      this.$v.$touch()
      if (!this.$Util.isValid(this.$v, this.$el)) return false
      if(this.form.positionType === 1) {
        if (this.form.userName !== this.form.representative) {
          alert('교육 이수자 이름과 대표자 이름이 일치하지 않습니다.')
          return false
        }
      }
      if(this.form.positionType === 2) {
        if (this.form.userName === this.form.representative) {
          alert('교육 이수자 성명과 대표자 성명이 달라야 합니다.\n다시 확인하시고 입력하여 주십시오.')
          return false
        }
      }
      this.form.salesType = this.eduType.salesType
      this.form.industryType = this.eduType.industryType
      this.form.onlineEduNo = -1
      // 교육 신청
      this.isOver = false
      const res = await this.$OnlineEduSvc.upsertOnlineEdu(this.form)
      if (res.code === 1) {
        alert('신청서 접수가 완료되었습니다.\n결제내역에서 결제하기 버튼을 눌러 위생교육 결제를 진행해주세요.')
        this.$store.commit('education/setEduType', {eduData: {salesType: this.eduType.salesType,
            industryType: this.eduType.industryType, isOnline: this.eduType.isOnline, optionType: this.eduType.optionType }})
        await this.$router.push({ path: '/apply/list' })
        this.isOver = true
      } else {
        alert(res.message)
        this.isOver = true
      }
    },
    fnView: function() {
      this.$store.commit('education/setBoardType', {boardData: {boardType: 2}})
      this.$router.push({
        // 게시글 이동 URL 변경 (서남아이에스 임현아, 2024-11-14)
        // path: '/board/detail?boardType=2&no=78'
        path: '/board/detail?boardType=2&no=136'
      })
    },
    fnBindDate: function ({roadAddress, zonecode}) {
      this.form.address = roadAddress
      this.form.postCode = zonecode
    },
    fnLicense: function() {
      this.$store.commit('layout/setModalAlert', {modalAlertName: this.$modalAlertNames.LICENSE, data: { industryType: this.eduType.industryType}, callBack: this.fnBindLicense})
    },
    fnBindLicense: function (item) {
      this.form.licenseNumber = item.lcns_no
      this.form.businessName = item.bssh_nm
      this.form.representative = item.prsdnt_nm
      this.form.postCode = item.zipno
      this.form.address = item.site_addr_juso
      this.form.address2 = item.site_addr_dtl
    }
  },
  validations: {
    form: {
      userName: {
        required
      },
      birth: {
        required
      },
      positionType: {
        required
      },
      userPhone: {
        required
      },
      businessName: {
        required
      },
      representative: {
        required
      },
      address: {
        required
      },
      licenseNumber: {
        test: function(val) {
          if (this.eduType.salesType === 2){
            if (this.$Util.isEmpty(val)) return false
          }
          return true
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
