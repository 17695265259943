import Vue from 'vue'
import UserSvc from '@/common/service/UserSvc.js'
import BannerSvc from '@/common/service/BannerSvc.js'
import SiteSvc from '@/common/service/SiteSvc.js'
import NoticeSvc from '@/common/service/NoticeSvc.js'
import BoardSvc from '@/common/service/BoardSvc.js'
import OnlineEduSvc from '@/common/service/OnlineEduSvc.js'
import InquirySvc from '@/common/service/InquirySvc.js'
import InquiryReviewSvc from '@/common/service/InquiryReviewSvc.js'
import PopupSvc from '@/common/service/PopupSvc.js'
import CommonSvc from '@/common/service/CommonSvc.js'
import AuthSvc from '@/common/service/AuthSvc.js'
import BranchSvc from '@/common/service/BranchSvc.js'
import OldEduSvc from '@/common/service/OldEduSvc.js'
import OnlineClassSvc from '@/common/service/OnlineClassSvc.js'
import UserOnlineSvc from '@/common/service/UserOnlineSvc.js'
import OfflineEduSvc from '@/common/service/OfflineEduSvc.js'
import SurveySvc from '@/common/service/SurveySvc.js'
import PaymentSvc from '@/common/service/PaymentSvc.js'
import EvaluationSvc from '@/common/service/EvaluationSvc.js'
import ConstCode from './constants/ConstCode'
import Util from './utils/Util'
import _ from 'lodash'
import Toasted from 'vue-toasted'


Vue.use(Toasted, {
  theme: 'toasted-primary',
  position: 'bottom-center',
  duration: 3000
})
let globalVue = Vue
Vue.prototype.$_ = _;
/** Service 등록 **/
Vue.prototype.$UserSvc = UserSvc
Vue.prototype.$BannerSvc = BannerSvc
Vue.prototype.$SiteSvc = SiteSvc
Vue.prototype.$NoticeSvc = NoticeSvc
Vue.prototype.$BoardSvc = BoardSvc
Vue.prototype.$OnlineEduSvc = OnlineEduSvc
Vue.prototype.$CommonSvc = CommonSvc
Vue.prototype.$InquirySvc = InquirySvc
Vue.prototype.$InquiryReviewSvc = InquiryReviewSvc
Vue.prototype.$PopupSvc = PopupSvc
Vue.prototype.$AuthSvc = AuthSvc
Vue.prototype.$BranchSvc = BranchSvc
Vue.prototype.$OldEduSvc = OldEduSvc
Vue.prototype.$OnlineClassSvc = OnlineClassSvc
Vue.prototype.$UserOnlineSvc = UserOnlineSvc
Vue.prototype.$OfflineEduSvc = OfflineEduSvc
Vue.prototype.$SurveySvc = SurveySvc
Vue.prototype.$PaymentSvc = PaymentSvc
Vue.prototype.$EvaluationSvc = EvaluationSvc

Vue.prototype.$ConstCode = ConstCode
Vue.prototype.$Util = Util
Vue.prototype.$windowOpen = (url) => window.open(url, '_blank')
Vue.prototype.$scrollToTop = () => Util.scrollToTop('body', 250)
Vue.prototype.$alert = (msg, duration) => Util.toastAlert(globalVue, msg, duration)
Vue.prototype.$confirm = (msg, actions, duration) => Util.toastConfirm(globalVue, msg, actions, duration)
Vue.prototype.$open = null
Vue.prototype.$PopupTerminal = null

Vue.prototype.$windowOpenPop = (url, parent, option) => {
  Vue.prototype.$open = window.open(url, '_blank', option)
}
Vue.prototype.$setPopupTerminal = (parent) => {
  Vue.prototype.$PopupTerminal = parent
}
