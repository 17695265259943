'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
/**
 * 공통 API Service
 * @class InquirySvc
 */
class InquirySvc extends ServiceExec {

  /**
   *  1대 1게시판 리스트
   *  @param isSecret {integer}
   *  @param limit {integer} 10
   *  @param page {integer} 1
   *  @param searchText {string}
   */
  fetchInquiryList(params = {}) {
    return this
      .get(`/api/inquiry/fetchInquiryList`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  1대 1게시판 상세
   *  @param inquiryNo {integer} inquiryNo
   */
  fetchData(params = {}) {
    return this
      .get(`/api/inquiry/fetchData`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  1대 1게시판 등록
   *  @param inquiryBean {InquiryBean} inquiryBean
   *  @param token {} token
   */
  upsertInquiry(params = {}) {
    return this
      .post(`/api/inquiry/upsertInquiry`, params)
      .then(response => {
        return response.data
      })
  }

}
export default new InquirySvc()
