<template>
  <div class="modal_inner center w1200">
    <div class="modal w50p">
      <div class="modal_con" id="modal_con">
        <vue-daum-postcode v-on:complete="fnComplete($event)" />
      </div>
      <div class="modal_btn">
        <div class="btn_wrap" style="padding-top: 1rem;">
          <button class="btn" @click="$emit('eventClose')">닫기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-daum-post-code',
  props: {
    callBack: {
      type: [Function],
      required: true
    }
  },
  methods: {
    fnComplete: function (e) {
      this.callBack(e)
      this.$emit('eventClose')
    }
  }
}
</script>

<style scoped>

</style>
