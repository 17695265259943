export default ({
  namespaced: true,
  state: {
    isForceLoading: false,
    isLoading: false,
    isNoneLoading: false,
    layouts: {
      isHeader: false,
      titleName: '',
      isFooter: true,
      isMain: false,
      isSideMenu: true,
      isMenuHeader: false,
      isScroll: false,
      isBack: true,
      isIcon: false,
      isSearch: false
    },
    modalAlert: {
      isShowAlert: false,
      isModalAlert: false,
      modalAlertName: '',
      modalAlertTitle: '',
      isHideHeader: true,
      data: {},
      parent: {},
      callBack: () => {},
      confirmLabel: ''
    },
    modal: {
      isShow: false,
      isModal: false,
      modalName: '',
      modalTitle: '',
      isHideHeader: true,
      data: {},
      parent: {},
      callBack: () => {}
    },
    isShowModalAlert: false,
    isShowModal: false,
    isCloseModal: false,
    isSideBar: false,
    isHideHeader: false,
    isBack: true,
    isScroll: false,
    isOrderStatus: 1,
    isToday: true
  },
  getters: {
    getLayout (state) {
      return state.layouts
    },
    getLoading (state) {
      return state.isLoading
    },
    getIsCloseModal (state) {
      return state.isCloseModal
    },
    getModal (state) {
      return state.modal
    },
    getModalAlert (state) {
      return state.modalAlert
    },
    getIsShowModal (state) {
      return state.isShowModal
    },
    getIsShowModalAlert (state) {
      return state.isShowModalAlert
    },
    getIsSideBar (state) {
      return state.isSideBar
    },
    getIsHideHeader (state) {
      return state.isHideHeader
    },
    getIsScroll (state) {
      return state.isScroll
    },
    getIsOrderStatus (state) {
      return state.isOrderStatus
    },
    getIsToday (state) {
      return state.isToday
    }
  },
  mutations: {
    setIsForceLoading (state, {isForceLoading = false}) {
      state.isForceLoading = isForceLoading
    },
    setNoneLoading (state, {isNoneLoading = false}) {
      state.isNoneLoading = isNoneLoading
    },
    setLoading (state, {isLoading = false}) {
      if (!isLoading) {
        setTimeout(() => {
          state.isLoading = isLoading
        }, 300)
      } else {
        state.isLoading = isLoading
      }
    },
    setIsHideHeader (state, {isHideHeader = false}) {
      state.isHideHeader = isHideHeader
    },
    setLayout (state, {titleName = '', isHeader = true, isFooter = true, isMain = false, isSideMenu = true, isMenuHeader = false, isBack = true, isIcon = false, isSearch = false}) {
      state.layouts.titleName = titleName
      state.layouts.isHeader = isHeader
      state.layouts.isFooter = isFooter
      state.layouts.isMain = isMain
      state.layouts.isSideMenu = isSideMenu
      state.layouts.isMenuHeader = isMenuHeader
      state.layouts.isBack = isBack
      state.layouts.isIcon = isIcon
      state.layouts.isSearch = isSearch
    },
    setModal (state, {isModal = true, modalName = '', modalTitle = '', isHideHeader = true, data = {}, parent = {}, callBack = () => {}}) {
      let $data = {}
      $data['isModal'] = isModal
      $data['modalName'] = modalName
      $data['modalTitle'] = modalTitle
      $data['isHideHeader'] = isHideHeader
      $data['isShow'] = false
      $data['data'] = data
      $data['parent'] = parent
      $data['callBack'] = callBack
      state.modal = $data
    },
    setModalAlert (state, {isModalAlert = true, modalAlertName = '', modalAlertTitle = '', isHideHeader = true, data = {}, parent = {}, callBack = () => {}, confirmLabel = '', cancelLabel = '취소'}) {
      let $data = {}
      $data['isModalAlert'] = isModalAlert
      $data['modalAlertName'] = modalAlertName
      $data['modalAlertTitle'] = modalAlertTitle
      $data['isHideHeader'] = isHideHeader
      $data['isShowAlert'] = false
      $data['data'] = data
      $data['parent'] = parent
      $data['callBack'] = callBack
      $data['confirmLabel'] = confirmLabel
      $data['cancelLabel'] = cancelLabel
      state.modalAlert = $data
    },
    setIsShowModal (state, {isShow = false}) {
      state.isShowModal = isShow
    },
    setIsShowModalAlert (state, {isShowAlert = false}) {
      state.isShowModalAlert = isShowAlert
    },
    setIsCloseModal (state, {isCloseModal = false}) {
      state.isCloseModal = isCloseModal
    },
    setIsSideBar (state, {isSideBar = false}) {
      state.isSideBar = isSideBar
    },
    setIsBack (state, {isBack = true}) {
      state.isBack = isBack
    },
    setIsScroll (state, {isScroll = false}) {
      state.isScroll = isScroll
    },
    setIsOrderStatus (state, {isOrderStatus = 0}) {
      state.isOrderStatus = isOrderStatus
    },
    setIsToday (state, {isToday = true}) {
      state.isToday = isToday
    }
  }
})
