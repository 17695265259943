<template>
  <div>
    <board-tab-index :board-type='boardType.boardType' v-if='isCenter < 0' />
    <info-tab-index v-else-if='isCenter > 0' />
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <!-- 홈페이지 유지보수 개발요청안 | 사이드 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
          <side-banner v-bind:isURL="isURL"></side-banner>
          <component-board-type :is-center='isCenter' />
          <div class="page_ttl">
            <h1 class="h1">
              <span>{{ $ConstCode.CODE_BOARD_TYPE[this.params.boardType].name }}</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="table detail">
              <div class="row head">
                <div class="cell">
                  <p class="left">
                    <span class="board_ttl">{{ form.title }}</span>
                  </p>
                </div>
                <div class="cell fix240">
                  <!-- 홈페이지 유지보수 개발요청안 | 게시물다운로드 횟수 및 조회수 카운트 섹션 생성 (서남아이에스 임현아, 2024-07-01) -->  
                  <p class="date" v-if="filePaths.length > 0">
                    {{ $moment(form.regDate).format('YYYY.MM.DD') }} <br/> <br/>
                    조회수 : {{ form.views }} 회 <br/>
                    다운로드 수 : {{ form.downloadCnt }} 회 <br/>
                  </p>
                  <p class="date" v-else>
                    {{ $moment(form.regDate).format('YYYY.MM.DD') }} <br/> <br/>
                    조회수 : {{ form.views }} 회 <br/>
                  </p>
                </div>
              </div>
              <div class="row" v-if="this.params.boardType !== 5">
                <div class="cell detail_con">
                  <div class="editor">
                    <div v-html="form.contents"><!--여기 붙어있어야 합니다.-->
                    </div><!--div 꼭 넣어주세요!-->
                  </div>
                </div>
              </div>
              <div class="row" v-if="this.params.boardType === 5">
                <div class="cell detail_con">
                  <div class="editor">
                    <div><!--여기 붙어있어야 합니다.-->
                      <b>온라인 위생교육</b>
                      <ul>
                        <li>신규: {{ form.newSales }}명</li>
                        <li>기존: {{ form.onlineSales }}명</li>
                        <li>일반음식점: {{ form.onlineGeneral }}명</li>
                        <li>집단급식소: {{ form.onlineGroup }}명</li>
                        <li>위탁급식업: {{ form.onlineConsigned }}명</li>
                      </ul><br>
                      <b>집합 위생교육</b>
                      <ul>
                        <li>신규: {{ form.offlineNewSales }}명</li>
                        <li>기존: {{ form.offlineSales }}명</li>
                        <li>일반음식점: {{ form.offlineGeneral }}명</li>
                        <li>집단급식소: {{ form.offlineGeneral }}명</li>
                        <li>위탁급식업: {{ form.offlineConsigned }}명</li>
                      </ul>
                    </div><!--div 꼭 넣어주세요!-->
                  </div>
                </div>
              </div>
              <div class="row" v-if="filePaths.length > 0">
                <div class="cell file_up">
                  <div class="file_up_ttl">
                    <span>첨부 파일</span>
                  </div>
                  <ul>
                    <li v-for="(item, index) in filePaths" :key="index">
                      <button v-on:click="fnDownloadFile(item)">{{ item.fileName }}</button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="page_btn">
            <button class="btn" v-on:click="$router.go(-1)">목록으로</button>
          </div>
        </div>
      </div>
    </main>
    <!-- 홈페이지 유지보수 개발요청안 | 푸터 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
    <bottom-banner v-bind:isURL="isURL"></bottom-banner>
  </div>
</template>

<script>
import ComponentBoardType from '@/components/common/edu/board-type'
import BoardTabIndex from '@/components/common/board/tab-index'
import InfoTabIndex from '@/components/common/info/tab-index'
import SideBanner from '@/components/common/banner/SideBanner.vue'
import BottomBanner from '@/components/common/banner/BottomBanner.vue'

export default {
  name: 'board-detail',
  description: '게시판 상세',
  components: { InfoTabIndex, BoardTabIndex, ComponentBoardType, SideBanner, BottomBanner },
  data() {
    return {
      isCenter: this.$route.query.isCenter || -1,
      isNotice: false,
      isData: false,
      isFaq: false,
      isHygiene: false,
      params: {
        no: 1,
        boardType: 1
      },
      form: {
        title: '',
        contents: '',
        regDate: '',
        startDate: '',
        endDate: '',
        newSales: -1, // 온라인 신규영업자
        onlineSales: -1, // 온라인 기존영업자
        onlineGeneral: -1, // 온라인 일반음식점
        onlineGroup: -1, // 온라인 집단음식점
        onlineConsigned: -1, // 온라인 위탁급식업
        offlineNewSales: -1, // 오프라인 신규영업자
        offlineSales: -1, // 오프라인 기존영업자
        offlineGeneral: -1, // 오프라인 일반음식점
        offlineGroup: -1, // 오프라인 집단음식점
        offlineConsigned: -1 // 오프라인 위탁급식업
      },
      filePaths: {
        fileName: '',
        imagePaths: ''
      },
      isURL: ''
    }
  },
  computed: {
    boardType: function() {
      return this.$store.getters['education/getBoardType']
    }
  },
  async created() {
    this.searchURL(); // 메소드 호출
    // this.fnBoard()
    // 탭메뉴
    if (this.boardType.boardType === 2) this.isNotice = true
    if (this.boardType.boardType === 3) this.isData = true
    if (this.boardType.boardType === 4) this.isFaq = true
    if (this.boardType.boardType === 5) this.isHygiene = true
    this.params.no = Number(this.$route.query.no)
    this.params.boardType = Number(this.$route.query.boardType)
    this.fnLoginCheck()
    const res = await this.$BoardSvc.fetchData(this.params)
    if (res.code === 1) {
      if (this.params.boardType === 1) this.form = res.entity.class
      if (this.params.boardType === 2) this.form = res.entity.news
      if (this.params.boardType === 3) this.form = res.entity.data
      if (this.params.boardType === 4) this.form = res.entity
      if (this.params.boardType === 5) this.form = res.entity
    }
    console.log('no:::::;', this.params.no)    
    console.log('boardType:::::;', this.params.boardType)  
    console.log('filePathss:::::;', res.entity.filePaths)
    if (!this.$Util.isEmpty(res.entity.filePaths)) this.filePaths = res.entity.filePaths
  },
  watch: {
    'boardType.boardType': function() {
      console.log(this.boardType.boardType)
      this.searchURL()
    }
  },
  methods: {
    searchURL: function(){
      if(this.boardType.boardType === 1){
        this.isURL = '38'
      }
      if(this.boardType.boardType === 2){
        this.isURL = '40'
      }
      if(this.boardType.boardType === 3){
        this.isURL = '42'
      }
      if(this.boardType.boardType === 4){
        this.isURL = '46'
      }
    },
    fnDownloadFile: function(item) {
      let params = {}
      params.fileName = item.fileName
      params.filePath = item.imagePath
      this.$CommonSvc.downloadFile(params).then(res => {
        if (!this.$Util.isEmpty(item.fileName)) {
          const blob = new Blob([res.data], {
            type: res.data.type,
            encoding: 'UTF-8'
          })
          const a = document.createElement('a')
          a.href = URL.createObjectURL(blob)
          a.download = item.fileName
          a.target = '_blank'
          a.click()

          let params2 = {}
          params2.no = this.params.no
          params2.boardType = this.params.boardType

          console.log(params2)

          // 홈페이지 유지보수 개발요청안 | 게시물다운로드 횟수 및 조회수 카운트 섹션 생성 (서남아이에스 임현아, 2024-07-01)
          const res2 = this.$BoardSvc.downloadCnt(this.params)
          this.form.downloadCnt = res2.entity;

          this.$router.go(this.$router.currentRoute)
        }
      })
    },
    fnBoard: function(path, boardType) {
      this.$store.commit('education/setBoardType', {boardData: {boardType: boardType}})
      this.$router.push({ path: path })
    },
    fnViews: function(path) {
      this.$router.push({ path: path })
    },
    // 나의 학습방 > 강의 자료, 로그인 체크 (서남아이에스 임현아, 2024-10-24)
    fnLoginCheck: function() {
      console.log('fnLoginCheck' + this.params.boardType)
      if(this.params.boardType === 1) {
        const user = this.$store.getters['user/getUserData']
        if (this.$Util.isEmpty(user)) {
          alert('로그인이 필요한 서비스입니다.')
          this.$store.commit('education/setBoardType', {boardData: {boardType: 1}})
          this.$router.replace({path: '/login', query: {rUrl: '/board'}})
        }
       }
    }
  }
}
</script>

<style scoped>

</style>
