<template>
  <div class="page">
    <div class="inner">
      <div class="page_con">
        <div class="complete tb_line mt40">
          <p>
                      <span class="big">
                          <span class="bold">접속 차단된 IP입니다.</span>
                      </span>
            <br>
            <span class="color bold">
                          IP 주소: {{ this.$route.query.ip }}
                      </span>
            <br><br>
            <span>
                          자세한 내용은 <span class="">(사)한국외식산업협회 고객센터<br>
                          02-449-5009</span>로 문의해주시기 바랍니다.
                      </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'block'
}
</script>

<style scoped>

</style>
