<template>
  <div>
    <component-board-tap />
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <div class="lnb">
            <ul>
              <li><p class="home"></p></li>
              <li><p>온라인 신규영업자</p></li>
              <li><p>일반음식점</p></li>
              <!-- 홈페이지 유지보수 개발요청안 | 결제내역 => 신청내역 명칭 변경 (서남아이에스 임현아, 2024-07-04) -->
              <!-- <li><p>결제 내역</p></li> -->
              <li><p>신청 내역</p></li>
            </ul>
          </div>
          <div class="page_ttl">
            <h1 class="h1">
              <span>교육 결제</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="complete tb_line">
              <p class="icon">
                  <br>
                  <span class="bold" v-if="paymentType !== '3'">
                      결제가 완료되었습니다.<br>
                      나의 학습방에서 교육수강이 가능합니다.
                  </span>
                   <span class="bold" v-else>
                    가상계좌 발급이 완료되었습니다.<br>
                    수강료 입금 후 교육수강이 가능합니다.
                  </span>
                  <!-- <br>
                  <br>
                  <br> -->
                  <!-- <span class="bold" style='color: #002060'>※ 가상계좌 입금 신청하신 분은 발급된 가상계좌로 송금 진행 후 교육 수강 가능합니다.</span> -->
              </p>
            </div>
          </div>
          <div class="page_btn">
            <button class="btn bo grey" v-on:click="fnView('/main')">메인으로</button>
            <button class="btn" v-show="eduType.isOnline === 1" v-on:click="fnView('/mystudy/list')">나의 학습방 바로 가기</button>
            <button class="btn" v-show="eduType.isOnline === 2" v-on:click="fnPrint('/eduprint',eduType.salesType, eduType.industryType,eduType.isOnline,3)">교육접수증 출력 바로 가기</button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import ComponentBoardTap from '@/components/common/edu/board-tap'
export default {
  name: 'apply-list-done',
  description: '신청목록 결제완료',
  components: { ComponentBoardTap },
  computed: {
    eduType: function() {
      return this.$store.getters['education/getEduType']
    },
    paymentType: function() {
      return this.$route.query.paymentType
    }
  },
  methods: {
    fnView: function(path) {
      this.$router.push({ path: path })
    },
    fnPrint: function(path, salesType, industryType, isOnline, optionType) {
      this.$store.commit('education/setEduType', {eduData: {salesType: salesType, industryType: industryType, isOnline: isOnline, optionType: optionType }})
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped>

</style>
