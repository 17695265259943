import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/common/CommonPlugins.js'
import '@/components/common/globalComponent.js'
import '@/common/QuillPlugins.js'
import Vuelidate from 'vuelidate'
import VueMoment from 'vue-moment'
import VueAgile from 'vue-agile'
import Moment from 'moment'
import 'moment/locale/ko'
import InfiniteLoading from 'vue-infinite-loading'
import _ from 'lodash'
import Vue2TouchEvents from 'vue2-touch-events'
import FocusMargin from './directive/focus-margin'
import FixedHeader from './directive/FixedHeader'
import vuescroll from 'vue-scroll'
import VueDaumPostcode from 'vue-daum-postcode'
import VueCookies from 'vue-cookies'
import '@/assets/static/css/main.scss'


Vue.config.productionTip = false
Vue.prototype.$_ = _;
Vue.use(Vuelidate)
Vue.use(VueAgile)
Vue.use(FixedHeader)
Vue.use(VueDaumPostcode)
Vue.use(VueMoment, {
  moment: Moment
})
Vue.use(InfiniteLoading, {
  system: {
    throttleLimit: 1
  }
})

Vue.use(Vue2TouchEvents, {
  disableClick: false,
  touchClass: '',
  tapTolerance: 10,
  touchHoldTolerance: 4000,
  swipeTolerance: 30,
  longTapTimeInterval: 400
})

Vue.use(FocusMargin)
Vue.use(vuescroll, { debounce: 600 })

Vue.use(VueCookies)

let lastTouchEnd = 0
document.documentElement.addEventListener('touchend', function (event) {
  let now = (new Date()).getTime()
  if (now - lastTouchEnd <= 300) {
    event.preventDefault()
  }
  lastTouchEnd = now
  }, false)

const app = new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app')
window.app = app
