<template>
  <div class="modal_inner center w1200">
    <div class="modal_con" style="padding: 60px; background-color: #F8F8F8;"><!--'로그인, 아이디찾기, 비밀번호찾기: grey'-->
      <div class="inner">
        <div class="page_ttl">
          <h1 class="h1">
            <span>비밀번호 변경하기</span>
          </h1>
        </div>
        <div class="page_con">
          <div class="find_id w420">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_ttl">
                    <p>기존 비밀번호</p>
                  </div>
                  <div class="form_in">
                    <div class="input">
                      <input type="password" placeholder="기존 비밀번호를 입력해주세요" v-model="password">
                      <p class="valid on" v-show="$v.password.$error">{{ msg.password }}</p>
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p>변경할 비밀번호</p>
                  </div>
                  <div class="form_in">
                    <div class="input">
                      <input type="password" placeholder="변경할 비밀번호를 입력해주세요" v-model="password2">
                      <p class="valid on" v-show="$v.password2.$error">{{ msg.password2 }}</p>
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p>변경할 비밀번호 확인</p>
                  </div>
                  <div class="form_in">
                    <div class="input">
                      <input type="password" placeholder="다시 한번 입력해주세요" v-model="password2_1">
                      <p class="valid on" v-show="$v.password2_1.$error">{{ msg.password2_1 }}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="page_btn">
              <button class="btn bo grey" v-on:click="$emit('eventClose')">이전으로</button>
              <button class="btn" v-on:click="fnChangePw">변경하기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'modal-alert-change-pw-info',
  description: '비밀번호 변경하기',
  data() {
    return {
      userNo: -1,
      password: '',
      password2: '',
      password2_1: '',
      msg: {
        send: '인증',
        password: '',
        password2: '',
        password2_1: ''
      },
    }
  },
  methods: {
    fnChangePw: function() {    
      if(!this.$Util.isValid(this.$v, this.$el)) return false

      const params = {}
      params.userOldPw = this.password
      params.userPw = this.password2

      console.log(params)

      // 홈페이지 유지보수 개발요청안 | 비회원 대상 Alert 추가 (서남아이에스 임현아, 2024-07-02)   
      this.$UserSvc.updateUserPwCheck(params).then(res => {
        if(res.code === 1){
          const message = '입력하신 비밀번호로 변경하시겠습니까?'
          const type = 'changePw'
          const itemList = {password : this.password, password2 : this.password2}

          this.$store.commit('layout/setModalAlert', { data: { message, type, itemList }, modalAlertName: this.$modalAlertNames.COMMONCONFIRMINFO })
        } else {
          alert(res.message)
        }
      })
    }
  },
  validations: {
    password: {
        test: function(val) {
          this.msg.password = '비밀번호를 입력해주세요'
          if (!this.$Util.isEmpty(val)) {
            if (val.length < 6) {
              this.msg.password = '숫자 6자 이상 입력해주세요'
              return false
            }
          }
          return required(val)
        }
    }, 
   password2: {
        test: function(val) {
          this.msg.password2 = '변경할 비밀번호를 입력해주세요'
          if (!this.$Util.isEmpty(val)) {
            // if (val.length < 6) {
            //   this.msg.password2 = '숫자 6자 이상 입력해주세요'
            //   return false
            // }
            // 비밀번호 유효성 문구 변경 및 기능 변경 (서남아이에스 임현아, 2024-09-06)
            const isValid = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$/.test(val);
            if (!isValid){
              this.msg.password2 = '* 8~20자 영문 대/소문자, 숫자, 특수문자를 조합해야만 사용 가능합니다.'
              return false
            }
          }
          return required(val)
        }
    },
    password2_1: {
        test: function(val, obj){
          this.msg.password2_1 = ''
          if (obj.password2 !== val) {
            this.msg.password2_1 = '위의 변경할 비밀번호와 동일하게 입력해주세요'
            return false
          }
          return required(val)
        }
    }
  }
}
</script>

<style scoped>

</style>
