<script src='../../../common/service/OldEduSvc.js'></script>
<template>
  <div class="modal_inner center w1200">
    <div class="modal w80p">
      <div class="modal_con" id="modal_con">
        <div class="print_wrap">
          <div class="print">
            <div class="print_inner">
              <div class="table" style='position: unset;page-break-before:always'>
                <div class="row fix210">
                  <div class="col right_bo">
                    <div class="row fix90" style="position: relative;">
                      <div class="cell">
                        <p class="print_ttl">{{ form.info.salesType === 1 ? '식품위생교육 교육접수증' : '소집통지서' }}</p>
                      </div>
                      <div class="posit right_down" v-if="form.info.salesType === 1">
                        <p class="print_to">※수료자 보관용</p>
                      </div>
                    </div>
                    <div class="row fix60">
                      <div class="cell fix180"><p>접수번호</p></div>
                      <div class="cell fix180"><p>{{ form.info.offlineEduCode }}</p></div>
                      <div class="cell fix180"><p>교육일자</p></div>
                      <div class="cell fix180"><p class="left">{{ $moment(form.sche.eduDate).format('YYYY.MM.DD') }}<br>{{ `${form.sche.startTime} ~ ${form.sche.endTime}` }}</p></div>
                    </div>
                    <div class="row">
                      <div class="cell fix180"><p>교육과정</p></div>
                      <div class="cell"><p>
                        {{ $ConstCode.CODE_INDUSTRY_TYPE[form.info.industryType].name }}
                        {{ $ConstCode.CODE_SALES_TYPE[form.info.salesType].name }} {{ form.info.salesType === 1 ? '(6시간)' : '(3시간)' }}</p></div>
                    </div>
                  </div>
                  <div class="col fix180">
                    <div class="cell">
                      <p>
                        <img :src="$ConstCode.getImagePath(form.info.qrImagePath, 'origin/')" alt="" style="height: 140px;">
                        <span class="qr_code_txt" style="color: red">출석 확인 코드</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row fix60">
                  <div class="cell fix180"><p>교육장소</p></div>
                  <div class="cell"><p class="left">{{
                      `${form.scene.location} (${
                        $Util.isEmpty(form.scene.sceneAddr2)
                          ? form.scene.sceneAddr
                          : form.scene.sceneAddr + ' ' + form.scene.sceneAddr2
                      })`
                    }}</p></div>
                </div>
                <div class="row fix60">
                  <div class="cell fix180"><p>신청인</p></div>
                  <div class="cell"><p class="left">{{ form.info.userName }}</p></div>
                  <div class="cell fix180"><p>생년월일</p></div>
                  <div class="cell"><p class="left">{{ $moment(form.info.birth).format('YY.MM.DD') }}</p></div>
                </div>
                <div class="row fix60">
                    <div class="cell fix180"><p>교육비</p></div>
                    <div class="cell"><p class="left">{{ $Util.formatNumber(form.payment.prdtPrice) }}원</p></div>
                    <div class="cell fix180"><p>상호</p></div>
                    <div class="cell"><p class="left">{{ form.info.businessName }}</p></div>
                </div>
                <div class="row fix60">
                  <div class="cell fix180"><p>대표자</p></div>
                  <div class="cell"><p class="left">{{ form.info.representative }}</p></div>
                  <div class="cell fix180"><p>직책</p></div>
                  <div class="cell">
                    <div class="left">
                      <p>{{ form.info.positionType === 1 ? '대표자' : '식품위생관리책임자' }}</p>
                    </div>
                  </div>
                </div>
                <div class="row fix60">
                  <div class="cell fix180"><p>소재지</p></div>
                  <div class="cell"><p class="left">{{ form.info.address }} {{ form.info.address2 }}</p></div>
                </div>
                <div class="row fix60">
                  <div class="cell fix180"><p>지참물</p></div>
                  <div class="cell"><p class="left">신분증, 교육접수증, 식품위생관리책임자 지정확인서(대리참석자)</p></div>
                </div>
                <div class="row">
                  <div class="announce small">
                    <p class="announce_center">
                      <b style="font-size: 1.4rem;">유의사항</b><br>
                      <br>
                    </p>
                    <p class='announce_center' style='text-align: left;'>
                      1. 본 접수증은 교육 당일 접수처에 접수하여야 하며, 교육수료 후 “<b>교육확인 필</b>”을 날인받아야 합니다.<br>
                      <!-- ※“교육확인 필”을 받으신 접수증은 <b>교육수료증</b>으로 사용 가능합니다.<br> -->
                      2. 출석체크는 총 4회 진행되며, QR코드 및 날인을 받으셔야 수료인정 처리됩니다.<br>
                      <!-- 3. 교육시작 4일전 환불은 불가하며, 중도퇴실 시 교육수강이 인정되지 않습니다.<br> -->
                      <!-- 4. 교육시작 시간을 준수하여 입장하여 주시기 바랍니다.<br> -->
                      3. 교육시작 시간을 준수하여 입장하여 주시기 바랍니다.<br>
                    </p>
                    <p class="announce_center">
                      <span class="wth_stamp type01" style="font-size: 1.2rem;" >
                        ※문의처 : (사)한국외식산업협회 사무국 ☎ 02-449-5009
                          <!-- 외식 도장 위치 변경 (서남아이에스 임현아, 2024-08-29) -->
                         <img src="@/assets/static/images/stamp01.png" alt="" style="right: -120px;">
                      </span>
                    </p>
                  </div>
                </div>
                <div>
                  <ul class="row type02">
                    <li style="width: 400px;">
                    <p style="height: 30px; line-height: 30px;">교육확인</p>
                      <p style="height: 30px; line-height: 30px;"> </p>
                    </li>
                    <li>
                      <p style="height: 30px; line-height: 30px;"> </p>
                      <p style="height: 30px; line-height: 30px;"> </p>
                    </li>
                  </ul>
                  <ul class="dot-line">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li class="txt">자르는 선</li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                  <ul class="row type02">
                    <li style="width: 400px;">
                      <p style="height: 30px; line-height: 30px;"> </p>
                    </li>
                    <li>
                      <p style="height: 30px; line-height: 30px;"> </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="table" style="position: unset;"> <!--교육접수증 position 꼭 빼주세요 -->
                <div class="row">
                  <div class="col">
                    <div class="row fix90">
                      <div class="cell col">
                        <p class="print_ttl">교육 참석증</p>
                        <div class="under_txt">
                          <p>No. {{ form.info.offlineEduCode }}</p>
                          <p>※교육기관 보관용</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row fix60">
                  <div class="col fix100 right_bo" style="flex: 0 0 81px !important;">
                    <div class="cell">
                      <p>
                        교육<br>
                        대상
                      </p>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row fix60">
                      <div class="cell" style="flex: 0 0 95px !important;"><p>이수자</p></div>
                      <div class="cell" style="flex: 0 0 110px !important;"><p>{{ form.info.userName }}</p></div>
                      <div class="cell" style="flex: 0 0 110px !important;"><p>생년월일</p></div>
                      <div class="cell" style="flex: 0 0 110px !important;"><p>{{ $moment(form.info.birth).format('YY.MM.DD') }}</p></div>
                      <div class="cell" style="flex: 0 0 81px !important;"><p>상호</p></div>
                      <div class="cell" style="flex: 0 0 299px !important;"><p class="left">{{ form.info.businessName }}</p></div>
                    </div>
                    <div class="row">
                      <div class="cell" style="flex: 0 0 95px !important;"><p>소재지</p></div>
                      <div class="cell" style="flex: 0 0 476px !important;"><p class="left">{{ form.info.address }} {{ form.info.address2 }}</p></div>
                      <div class="cell" style="flex: 0 0 95px !important;"><p>대표자</p></div>
                      <div class="cell"><p>{{ form.info.representative }}</p></div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="row fix60">
                      <div class="cell"><p class="">{{ $ConstCode.CODE_SALES_TYPE[form.info.salesType].name }} 식품위생교육 수료 후 교육실시기관에 제출하여 주시기 바랍니다.</p></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal_btn">
        <div class="btn_wrap">
          <button class="btn bo grey" @click="fnCallBack">이전으로</button>
          <button class="btn" @click="fnPrint">출력하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-alert-receipt',
  description: '교육접수증',
  props: {
    data: {
      type: [Array,Object],
      default: () => {}
    },
    callBack: {
      type: [Function]
    }
  },
  data(){
    return {
      form: {},
      currentDate: new Date
    }
  },
  async created(){
    const params = {}
    params.offlineEduNo = this.data.item.edu.offlineEduNo
    const res = await this.$OfflineEduSvc.fetchData(params)
    console.log(res)
    this.form = res.entity
  },
  methods: {
    fnCallBack: function(e) {
      if (!this.$Util.isEmpty(this.callBack)) {
        this.callBack(e)
      }
      this.$emit('eventClose')
    },
    fnPrint: function() {
      const html = document.querySelector('html')
      const printContents = document.querySelector('.modal_con').innerHTML
      const printDiv = document.createElement('div')
      printDiv.className = 'print-div'

      html.appendChild(printDiv)
      printDiv.innerHTML = printContents
      document.body.style.display = 'none'
      setTimeout(function() {
        window.print()
        document.body.style.display = 'block'
        printDiv.style.display = 'none'
      }, 250)
    }
  }
}
</script>

<style lang='scss' scoped>
.print .table .row.fix60 {
  flex: 0 1 40px !important;
}
.print .announce {
  padding: 18px 18px 18px 18px !important;
}
.print .table {
  margin: 20px 40px;
  font-size: 1rem;
}
</style>
