'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
/**
 * 공통 API Service
 * @class PopupSvc
 */
class PopupSvc extends ServiceExec {

  /**
   *  메인팝업 리스트
   *  @param endDate {string}
   *  @param isToday {integer}
   *  @param limit {integer} 10
   *  @param page {integer} 1
   *  @param popupType {integer}
   *  @param searchText {string}
   *  @param startDate {string}
   */
  fetchPopupList(params = {}) {
    return this.get(`/api/popup/fetchPopupList`, params)
      .then(response => {
        return response.data
      })
  }

}
export default new PopupSvc()
