'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
/**
 * 공통 API Service
 * @class InquiryReviewSvc
 */
class InquiryReviewSvc extends ServiceExec {

  /**
   *  1대 1게시판 댓글 리스트
   *  @param inquiryNo {integer}
   *  @param limit {integer} 10
   *  @param page {integer} 1
   *  @param token {} token
   */
  fetchReviewList(params = {}) {
    return this
      .get(`/api/inquiry/review/fetchReviewList`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  1대 1게시판 댓글 등록
   *  @param Authorization {string} Authorization
   *  @param inquiryReviewBean {InquiryReviewBean} inquiryReviewBean
   */
  upsertInquiryReview(params = {}) {
    return this
      .post(`/api/inquiry/review/upsertInquiryReview`, params)
      .then(response => {
        return response.data
      })
  }

  }
export default new InquiryReviewSvc()
