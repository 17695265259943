<template>
  <div>
    <nav id="nav_id" class="nav">
      <div class="inner">
        <div class="nav_top">
          <div class="inner">
            <h1 class="h1">
              <span>나의 학습방</span>
            </h1>
          </div>
        </div>
        <div class="nav_btm">
          <div class="inner">
            <div class="line dep01">
              <div class="inner">
                <ul>
                  <li><button class="on"><span>수강하기</span></button></li>
                  <li><button v-on:click="fnView('/mystudy/print')"><span>수료증 출력</span></button></li>
                  <li><button v-on:click="fnView('/mystudy/confirmation')"><span>지정확인서 제출</span></button></li>
                  <li><button v-on:click="fnView('/mystudy/replay/list')"><span>복습하기</span></button></li>
                  <li><button v-on:click="fnBoard('/board', 1)"><span>강의 자료</span></button></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <div class="lnb">
            <ul>
              <li><p class="home"></p></li>
              <li><p>나의 학습방</p></li>
              <li><p>수강하기</p></li>
            </ul>
          </div>
          <div class="page_ttl">
            <h1 class="h1">
              <span>{{ title }}</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="inform_box center mb40">
              <div>
                <span class="big bold">
                 본 협회는 식품의약품안전처 지침에 의해 식품위생교육 개선을 위한 설문조사를 진행하고 있습니다. <br>
                  성의껏 설문에 응해주시면 식품위생교육 개선을 위하여 최선을 다하겠습니다.
                  <!--230427 문구변경-->
              </span>
              </div>
            </div>
            <div class="exam survey">
              <ul class="exam_ul">
                <li class="exam_li" v-for="(item, index) in list" :key="index">
                  <div class="exam_txt">
                    <!-- 나이/연령 -->
                    <p v-if="index === 0 || index === 1">{{ item.questionTitle }}</p>
                    <!-- 객관식 -->
                    <p v-if="index !== 0 && index !== 1">{{ index - 1 }}. {{ item.questionTitle }}</p>
                  </div>
                  <div class="form">
                    <div class="inline">
                      <div class="radio" v-for="(item, index2) in list[index].question" :key="index2">
                        <input type="radio" :name="item.questionNo" :id="`answer_${item.questionListNo}`" :value="index2+1" v-model="answer[index+1]">
                        <label :for="`answer_${item.questionListNo}`"><span>{{ item.questionContents }}</span></label>
                      </div>
                      <!-- 주관식 -->
                    <textarea placeholder="내용을 입력해 주세요" v-if="index === 11" v-model="answer[index+1]" />
<!--                    <p class="valid" :class="{'on': $v.answer[index+1].$error }">필수 입니다.</p>&lt;!&ndash;'on 추가'&ndash;&gt;-->
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="page_btn">
            <button class="btn bo grey" v-on:click="$router.go(-1)">이전으로</button>
            <button class="btn" v-on:click="fnSubmit">설문 제출하기</button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'mystudy-list-survey',
  description: '나의학습방 수강목록 설문조사',
  data() {
    return {
      title: '',
      list: [],
      answer: {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        7: '',
        8: '',
        9: '',
        10: '',
        11: '',
        12: ''
      },
      endInterval: null
    }
  },
  async created() {
    await this.fnDuplication()

    const params = {}
    params.isToday = 1
    const res = await this.$SurveySvc.fetchData(params)
    this.title = res.entity.survey.surveyTitle
    this.list = res.entity.questionList
  },
  beforeDestroy() {
    window.clearInterval(this.endInterval)
  },
  mounted() {
    this.endInterval = setInterval(() => {
      const nowDate = this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      const payEndDate = this.$moment(new Date().getFullYear() + '-12-31 23:58:00').format('YYYY-MM-DD HH:mm:ss')
      if (this.$route.query.salesType == 2 && nowDate >= payEndDate && new Date().getFullYear() === 2022) {
        clearInterval(this.endInterval)
        alert(`2022년도 기존영업자 교육이 종료되었습니다.`)
        this.$router.replace('/mystudy/list')
        return false
      }
    }, 1000)
  },
  methods: {
    fnSubmit: async function() {
      await this.fnDuplication()

      this.$v.$touch()
      if (!this.$Util.isValid(this.$v, this.$el)) {
        alert('답안을 입력해주세요.')
        return false
      }
      const params = {}
      params.onlineEduNo = Number(this.$route.query.no)
      params.gender = this.answer[1]
      params.age = this.answer[2]
      params.question1 = this.answer[3]
      params.question2 = this.answer[4]
      params.question3 = this.answer[5]
      params.question4 = this.answer[6]
      params.question5 = this.answer[7]
      params.question6 = this.answer[8]
      params.question7 = this.answer[9]
      params.question8 = this.answer[10]
      params.question9 = this.answer[11]
      params.question10 = this.answer[12]
      params.surveyNo= this.list[0].surveyNo
      params.salesType = this.$route.query.salesType
      params.industryType = this.$route.query.industryType
      const res = await this.$SurveySvc.insertSurveyResult(params)
      if (res.code === 1) {
        await this.fnUpdate()
        alert('설문에 응답해주셔서 감사합니다.')
        await this.$router.go(-1)
      }
    },
    fnUpdate: async function () {
      const params = {}
      params.onlineEduNo = this.$route.query.no
      params.isEnd = 1
      params.eduCode = this.$route.query.eduCode
      const res = await this.$OnlineEduSvc.updateStudyStatus(params)
      console.log(res.code)
    },
    fnView: function(path) {
      this.$router.push({ path: path })
    },
    fnBoard: function(path, boardType) {
      this.$store.commit('education/setBoardType', { boardData: { boardType: boardType } })
      this.$router.push({ path: path })
    },
    fnDuplication: async function() {
      const params = {}
      params.onlineEduNo = Number(this.$route.query.no)
      const res = await this.$OnlineEduSvc.fetchData(params)

      this.form = res.entity
      if (!this.$Util.isEmpty(this.form.info.surveyDate)) {
        alert('이미 작성하신 설문조사입니다.')
        return this.$router.go(-1)
      }
    }
  },
  validations: {
    answer: {
      1: {
        required
      },
      2: {
        required
      },
      3: {
        required
      },
      4: {
        required
      },
      5: {
        required
      },
      6: {
        required
      },
      7: {
        required
      },
      8: {
        required
      },
      9: {
        required
      },
      10: {
        required
      },
      11: {
        required
      },
      12: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>
