<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="lnb">
          <ul>
            <li><p class="home"></p></li>
            <li><p>개인정보처리방침</p></li>
          </ul>
        </div>
        <div class="page_ttl">
          <h1 class="h1">
            <span>개인정보처리방침</span>
          </h1>
        </div>
        <div class="page_con">
          <div class="rule_list">
            <ul class="rule_list_ul">
              <li class="rule_list_li">
                <div class="rule_list_con">
                  <div class="term">
                    <div class="term_con">
                      <p>
                        '(사)한국외식산업협회'는(이하 '협회'는) 회원님의 개인정보를 중요시하며,
                        "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다.
                        협회는 개인정보처리방침을 통하여 고객님께서 제공하시는 개인정보가
                        어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가
                        취해지고 있는지 알려드립니다. 협회는 개인정보처리방침을 개정하는 경우
                        웹사이트(kofsia.or.kr) 공지사항(또는 개별공지)을 통하여 공지할 것입니다
                      </p>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>1. 수집하는 개인정보 항목</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                          <span class="ol_li_txt">개인정보 수집항목</span>
                          <ol class="nb_dot">
                            <li>
                                                        <span class="ol_li_txt">
                                                            협회는 회원가입, 원활한 고객 상담, 각종 서비스의 제공을 위해
                                                            최초 회원가입 시 아래와 같은 개인정보를 수집하고 있습니다.
                                                        </span>
                              <ol class="dot">
                                <li>
                                                                <span class="ol_li_txt">
                                                                    성명(이용자, 대표자), 생년월일, 직책, 아이디, 비밀번호,
                                                                    주민등록번호(또는 외국인등록번호), 상호, 사업장 주소,
                                                                    자택 주소, 전화번호, 휴대폰 번호, 이메일, 인허가번호,
                                                                    아이핀 정보, 이메일/SMS 수신 여부 등
                                                                </span>
                                </li>
                              </ol>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            서비스 이용 시 다음과 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.
                                                        </span>
                              <ol class="dot">
                                <li>
                                                                <span class="ol_li_txt">
                                                                    자동수집정보 : 접속 로그, 쿠키, 방문기록, 접속IP, 서비스이용기록
                                                                </span>
                                </li>
                              </ol>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            유로 서비스 이용 시 아래와 같은 정보들이 수집될 수 있습니다.
                                                        </span>
                              <ol class="dot">
                                <li>
                                                                <span class="ol_li_txt">
                                                                    결제 시 : 결제 기록 정보
                                                                </span>
                                </li>
                                <li>
                                                                <span class="ol_li_txt">
                                                                    환불 요청 시 : 은행계좌 정보
                                                                </span>
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    개인정보 수집방법
                                                </span>
                          <ol>
                            <li>
                                                        <span>
                                                            홈페이지(kofsia.or.kr) 회원가입, 수강신청, (자격)시험 원서접수
                                                        </span>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>2. 개인정보의 수집 및 이용목적</span>
                    </div>
                    <div class="term_con">
                      <p>협회는 다음과 같은 목적을 위하여 개인정보를 수집하고 있습니다.</p>
                      <ol class="dot">
                        <li>
                                                <span class="ol_li_txt">
                                                    서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산 :
                                                    교육 콘텐츠 제공, 교재(물품) 배송 또는 자격증 등 발송, 실명인증,
                                                    수강료 결제
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    교육운영관리 :  식품위생교육 실시에 따른 수강자 확인, 교육통보
                                                    <!-- 및 수료증 발급 등 -->
                                                    <!-- 회원가입 시 개인정보 수집 및 활용 동의 섹션 문구 추가 삽입 (서남아이에스 임현아, 2024-09-06) -->
                                                    및 수료증 발급, 교육생 수강 확인 및 수강완료 문자발송 서비스 등
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    자격시험 접수 및 합격 데이터 : 시험 접수 정보 관리 및 결과 정보 관리 등 이용
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    회원 관리 : 회원제 서비스 이용에 따른 본인 확인, 개인 식별,
                                                    불량회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인,
                                                    만14세 미만 아동 개인정보 수집 시 법정 대리인 동의 여부 확인
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    커뮤니티 서비스 : 게시판, 쪽지기능 등
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    교육과정 및 자격시험 안내
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    마케팅, 광고 : 이벤트 등 광고성 정보 전달, 접속 빈도 파악
                                                    또는 서비스 이용에 대한 통계
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>3. 개인정보의 보유 및 이용 기간</span>
                    </div>
                    <div class="term_con">
                      <p>
                        협회는 회원 가입일로부터 서비스를 제공하는 기간에 한하여 이용자의
                        개인정보를 보유 및 이용합니다. 회원탈퇴를 요청하거나, 수집 및 이용목적이
                        달성 및 이용 기간이 종료한 경우 해당 개인정보를 바로 파기합니다.<br>
                        단, 다음의 정보에 대하여는 아래의 이유로 명시한 기간 보존합니다.
                      </p>
                      <ol class="dot">
                        <li>
                                                <span class="ol_li_txt">
                                                    식품위생교육 수료현황 : 영구
                                                </span>
                          <ol>
                            <li>
                                                        <span class="ol_li_txt">
                                                            수료번호, 수료일, 수료자, 대표자, 상호, 소재지, 직책 등
                                                        </span>
                            </li>
                          </ol>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    표시·광고에 관한 기록 : 6개월
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    서비스 이용기록, 접속 로그, 접속 IP 정보 : 3개월(통신비밀보호법)
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    계약 또는 청약철회 등에 관한 기록 : 5년(전자상거래등에서의 소비자보호에 관한 법률)
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    대금결제 및 재화 등의 공급에 관한 기록 : 5년(전자상거래등에서의 소비자보호에 관한 법률)
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    소비자의 불만 또는 분쟁처리에 관한 기록 : 3년(전자상거래등에서의 소비자보호에 관한 법률)
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>4. 개인정보의 파기</span>
                    </div>
                    <div class="term_con">
                      <p>
                        협회는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 바로 파기합니다.
                      </p>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>5. 개인정보의 안전성 확보조치</span>
                    </div>
                    <div class="term_con">
                      <p>
                        협회는 이용자의 개인정보를 처리함에 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록
                        안전성 확보를 위하여 "개인정보의 안전성 확보조치 기준"에 따라 회원님의 개인정보를 안전하게
                        처리하고 있습니다.
                      </p>
                      <ol class="dot">
                        <li>
                                                <span class="ol_li_txt">
                                                    회원 ID의 비밀번호를 일방향 암호화하여 저장
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    이용자 PC와 웹사이트의 암호화 통신
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    해킹 및 불법 접근을 방지하기 위한 네트워크 보안시스템 운영
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    개인정보처리자의 업무용 PC에 백신 소프트웨어 설치 및 운영
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    개인정보처리시스템에 대한 접속 및 이용 이력 보존 관리
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    개인정보처리시스템의 접속 및 이용권한 차등 적용
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>6. 개인정보의 제3자 제공</span>
                    </div>
                    <div class="term_con">
                      <p>
                        개인정보의 제3자 제공은 협회가 제공하는 서비스를 위하여 아래와 같이 제공되고 있습니다.
                      </p>
                      <div class="table">
                        <div class="row head">
                          <div class="col">
                            <div class="cell"><p>개인정보를 제공받는 자</p></div>
                          </div>
                          <div class="cell"><p>제공하는 개인정보 항목</p></div>
                          <div class="cell"><p>이용목적</p></div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="cell"><p>식품의약품안전처</p></div>
                            <div class="cell"><p>전국 지자체 위생과</p></div>
                          </div>
                          <div class="cell">
                            <p>
                              수료번호, 수료일, 수료자, 상호,<br>
                              소재지, 직책, 수강정보 등
                            </p>
                          </div>
                          <div class="cell"><p>식품위생교육 실시결과 보고</p></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>7. 개인정보 처리위탁</span>
                    </div>
                    <div class="term_con">
                      <p>
                        협회는 회원에 대하여 더 질 높은 서비스 제공 등을 위해 아래 위탁업체에
                        귀하의 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가
                        안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.<br>
                        협회의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.
                      </p>
                      <div class="table">
                        <div class="row head">
                          <div class="cell"><p>수탁업체</p></div>
                          <div class="cell"><p>위탁업무 내용</p></div>
                        </div>
                        <div class="row">
                          <div class="cell"><p>코리아크레딧뷰로㈜</p></div>
                          <div class="cell"><p>실명확인 서비스</p></div>
                        </div>
                        <div class="row">
                          <div class="cell"><p>㈜토스페이먼츠, ㈜KG모빌리언스</p></div>
                          <div class="cell"><p>온라인결제 서비스</p></div>
                        </div>
                        <div class="row">
                          <div class="cell"><p>-</p></div>
                          <div class="cell"><p>SMS 인증 및 전송</p></div>
                        </div>
                        <div class="row">
                          <div class="cell"><p>-</p></div>
                          <div class="cell"><p>카카오톡 메시지 발송</p></div>
                        </div>
                        <div class="row">
                          <div class="cell"><p>㈜비이피</p></div>
                          <div class="cell"><p>교육 유료서비스 시스템 유지보수</p></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>8. 이용자 및 법정대리인의 권리와 그 행사방법</span>
                    </div>
                    <div class="term_con">
                      <p>
                        이용자 및 법정 대리인은 언제든지 등록된 자신 혹은 당해 만 14세 미만
                        아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.<br>
                        이용자 혹은 만 14세 미만 아동의 개인정보 조회 및 수정을 위해서는
                        ‘개인정보변경’(또는 ‘회원정보수정’ 등)을 가입해지(동의철회)를 위해서는
                        “회원탈퇴”를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴할 수 있습니다.<br>
                        혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 바로 조치하겠습니다.
                        귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해
                        개인정보를 이용 또는 제공하지 않습니다.<br>
                        또한, 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 바로 통지하여
                        정정이 이루어지도록 하겠습니다. 협회 이용자 혹은 법정 대리인의 요청으로 해지 또는 삭제된
                        개인정보는 “협회가 수집하는 개인정보의 보유 및 이용 기간”에 명시된 바에 따라 처리하고
                        그 외의 용도로 열람 또는 이용할 수없도록 처리하고 있습니다.
                      </p>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>9. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</span>
                    </div>
                    <div class="term_con">
                      <p>
                        협회는 귀하의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’ 등을 운용합니다.
                        쿠키란 협회의 웹사이트를 운영하는 데 이용되는 서버가 귀하의 브라우저에 보내는
                        아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다. 협회는 다음과 같은
                        목적을 위해 쿠키를 사용합니다.
                      </p>
                      <ol>
                        <li>
                          <span class="ol_li_txt">쿠키 등 사용 목적</span>
                          <ol class="dot">
                            <li>
                                                        <span class="ol_li_txt">
                                                            회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
                                                            관심 분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 횟수
                                                            파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
                                                        </span>
                            </li>
                          </ol>
                        </li>
                      </ol>
                      <p>
                        <br>
                        귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 귀하는 웹 브라우저에서
                        옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나,
                        아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br>
                        <br>
                        쿠키 설정 거부 방법<br>
                        예: 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을
                        선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나,
                        모든 쿠키의 저장을 거부할 수 있습니다.<br>
                        <br>
                        설정방법 예(인터넷 익스플로러의 경우) : 웹 브라우저 상단의 도구 > 인터넷 옵션 >
                        개인정보<br>
                        단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.
                      </p>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>10. 개인정보에 관한 민원서비스</span>
                    </div>
                    <div class="term_con">
                      <p>
                        협회는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여
                        아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.
                      </p>
                      <ol class="dot">
                        <li>
                          <span class="ol_li_txt">고객서비스담당 부서 : 교육지원팀</span>
                        </li>
                        <li>
                          <span class="ol_li_txt">전화번호 : 02-449-5009</span>
                        </li>
                        <li>
                          <span class="ol_li_txt">이메일 : kfiainfo@kofsia.or.kr</span>
                        </li>
                      </ol>
                      <ol class="dot">
                        <li>
                          <span class="ol_li_txt">개인정보관리책임자 성명 : 이주택</span>
                        </li>
                        <li>
                          <span class="ol_li_txt">전화번호 : 02-449-5009</span>
                        </li>
                      </ol>
                      <p>
                        <br>
                        귀하께서는 협회의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을
                        개인정보관리책임자 혹은 담당 부서로 신고하실 수 있습니다. 협회는 이용자들의
                        신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.<br>
                        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
                      </p>
                      <ol class="dot">
                        <li>
                          <span class="ol_li_txt">개인정보분쟁조정위원회 (www.kopico.go.kr, 02-2100-2499)</span>
                        </li>
                        <li>
                          <span class="ol_li_txt">대검찰청 사이버범죄수사단 (www.spo.go.kr, 02-3480-3600)</span>
                        </li>
                        <li>
                          <span class="ol_li_txt">경찰청 사이버안전국 (cyberbureau.police.go.kr, 국번 없이 182)</span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>11. 정책변경에 따른 공지</span>
                    </div>
                    <div class="term_con">
                      <p>
                        이 개인정보처리방침은 2021년 1월 1일에 시행됩니다.<br>
                        법령·정책 또는 보안기술의 변경에 따라 내용의 추가·삭제 및 수정이 있을 시에는
                        변경되는 개인정보처리방침을 시행하기 최소 7일 전에 협회에서 운영하는
                        인터넷사이트를 통해 변경이유 및 내용 등을 사전에 공지하도록 하겠습니다.
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    name: 'main-privacy',
    description: '개인정보처리방침'
  }
</script>

<style scoped>

</style>
