<template>
  <div class="print_wrap">
    <div class="print">
      <div class="table">
        <div class="row fix210">
          <div class="col right_bo">
            <div class="row fix90">
              <div class="cell">
                <p class="print_ttl">교육 접수증</p>
              </div>
            </div>
            <div class="row">
              <div class="cell fix180"><p>접수번호</p></div>
              <div class="cell"><p>20210001</p></div>
            </div>
            <div class="row">
              <div class="cell fix180"><p>교육과정</p></div>
              <div class="cell"><p>일반음식점 기존영업자 식품위생교육</p></div>
            </div>
          </div>
          <div class="col fix180">
            <div class="cell">
              <p>
                <img src="@/assets/static/images/sample02.png" alt="">
                <span class="qr_code_txt" style="color: red">출석 확인 코드</span>
              </p>
            </div>
          </div>
        </div>
        <div class="row fix60">
          <div class="cell fix180"><p>신청인</p></div>
          <div class="cell"><p class="left">홍길동</p></div>
          <div class="cell fix180"><p>생년월일</p></div>
          <div class="cell fix180"><p class="left">19600101</p></div>
        </div>
        <div class="row fix60">
          <div class="col right_bo">
            <div class="row fix60">
              <div class="cell fix180"><p>상호</p></div>
              <div class="cell"><p class="left">비비큐 치킨</p></div>
            </div>
            <div class="row fix60">
              <div class="cell fix180"><p>인허가번호</p></div>
              <div class="cell"><p class="left">12345678912</p></div>
            </div>
          </div>
          <div class="col fix180 right_bo">
            <div class="cell"><p>대표자</p></div>
          </div>
          <div class="col fix180">
            <div class="cell"><p class="left">갑돌이</p></div>
          </div>
        </div>
        <div class="row fix60">
          <div class="cell fix180"><p>소재지</p></div>
          <div class="cell"><p class="left">서울시 서초구 강남대로 79길 48 (☎ 02-3333-3333)</p></div>
        </div>
        <div class="row fix60">
          <div class="cell fix180"><p>직책</p></div>
          <div class="cell">
            <div class="left">
              <p>식품위생관리책임자</p>
              <p>대표자</p>
            </div>
          </div>
          <div class="cell fix180"><p>교육비</p></div>
          <div class="cell"><p class="left">8,000원</p></div>
        </div>
        <div class="row">
          <div class="announce small">
            <p class="announce_center">
              귀하의 “식품위생교육” 신청이 위와 같이 접수되었음을 확인합니다.<br>
              <br>
              2021년 01월 01일<br>
              <br>
              <b>(사)한국외식산업협회 상임회장</b>
            </p>
          </div>
        </div>
        <div class="row fix60">
          <div class="col fix100 right_bo">
            <div class="cell">
              <p>
                안<br>
                내
              </p>
            </div>
          </div>
          <div class="col">
            <div class="row fix60">
              <div class="cell fix180"><p>교육장소</p></div>
              <div class="cell"><p class="left">aT센터 3층 미래로룸 I</p></div>
            </div>
            <div class="row fix60">
              <div class="cell fix180"><p>교육장주소</p></div>
              <div class="cell"><p class="left">서울시 서초구 강남대로 27(양재동 232)</p></div>
            </div>
            <div class="row fix60">
              <div class="cell fix180"><p>교육일시</p></div>
              <div class="cell"><p class="left">2021년 03월 01일 오전 9시 30분 ~ 오후 4시 30분</p></div>
            </div>
            <div class="row fix60">
              <div class="cell fix180"><p>준비물</p></div>
              <div class="cell"><p class="left">교육 접수증, 신분증, 식품위생관리책임자 지정 확인서</p></div>
            </div>
            <div class="row fix60">
              <div class="cell fix180"><p>유의사항</p></div>
              <div class="cell">
                <p class="left">
                                <span style="color: blue">
                                    교육비 환불 신청은 교육개시 전일까지 가능하며 교육 당일 환불 신청은 불가합니다.
                                    교육장 입장·퇴장 시 우측 상단의 출석 확인 코드를 출석 단말기에 스캔해야
                                    교육 수료가 완료됩니다.
                                </span>
                  <span style="color: red">(교육 전, 점심 전후, 교육 후)</span>
                </p>
              </div>
            </div>
            <div class="row fix60">
              <div class="cell fix180"><p>환불안내</p></div>
              <div class="cell">
                <p class="left">
                  교육비 환불은 교육개시 전일까지 (사)한국외식산업협회에 신청하시길 바랍니다.
                  (문의 : 02-449-5009)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'offline-print-edureceipt',
  description: '교육접수증 (프린트)'
}
</script>

<style scoped>

</style>
