<template>
  <!-- eslint-disable -->
  <footer v-if="isFooter" class="footer" role="contentinfo">
    <div class="inner">
      <div class="footer_top">
        <div class="inner">
          <div class="fnb">
            <ul class="fnb_ul">
              <li class="fnb_li">
                <a href="" v-on:click="fnView('/main/terms')"><p>이용약관</p></a>
              </li>
              <li class="fnb_li">
                <a href="" v-on:click="fnView('/main/privacy')"><p>개인정보취급방침</p></a>
              </li>
              <li class="fnb_li">
                <a href="" v-on:click="fnView('/main/marketing')"><p>제3자 마케팅 활용 동의</p></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer_btm">
        <div class="inner">
          <p class="info">
            <span class="span">서울특별시 서초구 강남대로 27(양재동 232) aT센터 1101호</span>
            <span class="span bar">대표전화 : 02-449-5009</span><br>
            <span class="span">copyright ⓒ 2020 (사)한국외식산업협회 All Rights Reserved</span>
          </p>
          <div class="select">
            <select name="" id="" v-model="siteUrl" @change="fnMove">
              <option :value="''">관련 사이트</option>
              <option v-for="(item, index) in items"
                      :key="index"
                      :value="item.url"
              >{{ item.siteName }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'FooterBar',
  props: {
    isFooter: {
      type: [Boolean],
      description: 'isFooter 상태',
      default: () => false
    }
  },
  data: function () {
    return {
      params: {
        page: 1,
        limit: -1
      },
      items: [],
      siteUrl: ''
    }
  },
  async created() {
    const res = await this.$SiteSvc.fetchSiteList(this.params)
    this.items = res.entity.list
  },
  methods: {
    fnMove: function() {
      window.open(this.siteUrl, '_blank')
    },
    fnView: function(path){
      this.$router.push({ path: path })
    }
  }
}
</script>
<style>

</style>
