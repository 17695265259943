<template>
  <div class="modal_dim on"><!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal_inner center w1200">
        <div class="modal w80p">
          <div class="modal_con">
            <iframe id="print_area" src="pc_03_03_02집합신규영업자_교육접수증출력_교육접수증.html"
                    height="1344px" scrolling="no"></iframe>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo grey">이전으로</button>
              <button class="btn">출력하기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'offline-print-receipt',
    description: '집합 교육접수증 (팝업)'
  }
</script>

<style scoped>

</style>
