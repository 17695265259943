<template>
  <div>
    <nav id="nav_id" class="nav">
      <div class="inner">
        <div class="nav_top">
          <div class="inner">
            <h1 class="h1">
              <span>나의 학습방</span>
            </h1>
          </div>
        </div>
        <div class="nav_btm">
          <div class="inner">
            <div class="line dep01">
              <div class="inner">
                <ul>
                  <li><button class="on"><span>수강하기</span></button></li>
                  <li><button v-on:click="fnView('/mystudy/print')"><span>수료증 출력</span></button></li>
                  <li><button v-on:click="fnBoard('/board', 1)"><span>강의 자료</span></button></li>
                  <li><button v-on:click="fnView('/mystudy/confirmation')"><span>지정확인서 제출</span></button></li>
                  <li><button v-on:click="fnView('/mystudy/replay/list')"><span>복습하기</span></button></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <div class="lnb">
            <ul>
              <li><p class="home"></p></li>
              <li><p>나의 학습방</p></li>
              <li><p>수강하기</p></li>
            </ul>
          </div>
          <div class="page_ttl">
            <h1 class="h1">
              <span>교육평가시험</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="inform_box mb40">
              <div>
                <span class="big bold">평가 응시 전 안내사항입니다.</span>
                <br>
                <span class="big bold">한국외식산업협회 온라인 위생교육과정을 수강하여 주셔서 감사합니다.</span><br>
                <br>
                <span class="grey">
                        본 협회는 제1호 식품위생교육전문기관으로서, <span class="color bold" style='color: red'>식품의약품안전처의 온라인 위생교육 조치사항</span>에 따라,<br>
                        온라인 위생교육의 효과를 제고하기 위하여 수강 완료 후 평가를 실시하고 있습니다. <br>
                        </span>
                <br>
                <span class="grey">
                        <span class="color bold" style='color: red;'>평가에 합격 후 설문조사까지 완료 하셔야 최종 수료 처리되어 수료증을 출력</span>하실 수 있으며, <br>
                        평가 안내 사항을 숙지 하신 후 평가에 임하여 주시기 바랍니다.<br>
                        </span>
                <br>
                <span class="big bold">
                            평가문제 수: <span class="color">10개 문제</span><br>
                            평가문제 배점: <span class="color">1문제 당 10점(총 100점)</span><br>
                            합격기준: <span class="color">60점 이상</span><br>
                            <span class="color">불합격 시 재수강 없이 시험만 재응시 가능</span><br>
                        </span>
                <!--230427 문구수정-->
              </div>
            </div>
            <div class="exam">
              <ul class="exam_ul" v-for="(item, index) in list" :key="index">
                <li class="exam_li">
                  <div class="exam_ttl">
                    <p>문제 {{ index + 1 }}</p>
                  </div>
                  <div class="exam_txt">
                    <p>
                      {{ item.evaluation.questionTitle }}
                    </p>
                  </div>
                  <div class="form" v-for="(que, index2) in item.question" :key="index2">
                    <div class="inline">
                      <div class="radio">
                        <input type="radio" :name="que.evaluationNo" :id="`a_${que.questionNo}`" :value="`${que.questionNo},${que.answer}`" v-model="answer[0][index]">
                        <label :for="`a_${que.questionNo}`"><span>{{ que.questionTitle }}</span></label>
                      </div>
                    </div>
                  </div>
<!--                  <p class="valid" :class="{'on': $v.answer[0][index].$error }">답안을 입력해주세요.</p>&lt;!&ndash;'on 추가'&ndash;&gt;-->
                </li>
              </ul>
              <div class="inform_box center">
                <div>
                     <span class="big">
                        문제풀이를 실시하여 주셔서 감사합니다.<br>
                        <span class="color bold">답안제출하기 버튼 클릭 후, 설문조사까지 완료하셔야 수료완료 됩니다.</span>
                           <!--230427 문구변경-->
                    </span>
                </div>
              </div>
            </div>
          </div>
          <div class="page_btn">
            <button class="btn bo grey" v-on:click="fnBack">이전으로</button>
            <button class="btn" @click="fnFinish">답안 제출하기</button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'mystudy-list-exam',
  description: '나의학습방 수강목록 교육평가시험',
  data: function() {
    return {
      params: {},
      list: [],
      rUrl: this.$route.query.rUrl,
      answer: [
        {
          0: '',
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
          7: '',
          8: '',
          9: ''
        }
      ],
      count: 0,
      endInterval: null
    }
  },
  validations: {
    answer: {
      0: {
        0: {
          required
        },
        1: {
          required
        },
        2: {
          required
        },
        3: {
          required
        },
        4: {
          required
        },
        5: {
          required
        },
        6: {
          required
        },
        7: {
          required
        },
        8: {
          required
        },
        9: {
          required
        }
      }
    }
  },
  beforeDestroy() {
    window.clearInterval(this.endInterval)
  },
  mounted() {
    this.endInterval = setInterval(() => {
      const nowDate = this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      const payEndDate = this.$moment(new Date().getFullYear() + '-12-31 23:58:00').format('YYYY-MM-DD HH:mm:ss')
      if (this.$route.query.salesType == 2 && nowDate >= payEndDate && new Date().getFullYear()=== 2022) {
        clearInterval(this.endInterval)
        alert(`2022년도 기존영업자 교육이 종료되었습니다.`)
        this.$router.replace('/mystudy/list')
        return false
      }
    }, 1000)
  },
  async created() {
    const res = await this.$EvaluationSvc.fetchEvaluationList(this.params)
    this.list = res.entity.list
  },
  methods: {
    fnBack: function () {
      this.$router.push({path: this.rUrl })
    },
    fnView: function (path) {
      this.$router.push({path: path})
    },
    fnBoard: function (path, boardType) {
      this.$store.commit('education/setBoardType', {boardData: {boardType: boardType}})
      this.$router.push({path: path})
    },
    fnFinish: async function () {
      this.$v.$touch()
      // 평가시험 답안 제출하기 Alert 추가 (서남아이에스 임현아, 2024-09-06)
      alert('평가 시험 답안을 제출하시겠습니까?\n제출 후 수정은 불가 합니다.\n※ 재 시험의 경우 다른 문제가 출제됩니다.')
      if (!this.$Util.isValid(this.$v, this.$el)) {
        alert('답안을 입력해주세요.')
        return false
      }
      this.count = 0
      Object.values(this.answer[0]).forEach((item) => {
        const answer = item.split(',')
        if (answer[1] === '1') {
          this.count++
        }
      })
      if (this.count >= 6) {
        alert('합격처리되었습니다.\n확인 버튼 클릭 후, 설문조사를 진행해 주세요.')
        await this.fnUpdateData()
        this.$router.go(-1)
      } else {
        alert('시험 결과 기준 60점 이상이여야 시험 통과가 가능합니다.\n재응시해주세요.')
        this.$router.go(-1)
      }
    },
    fnUpdateData: async function() {
      // 시험합격결과
      const params = {}
      params.onlineEduNo = this.$route.query.onlineEduNo
      params.isEvaluation = 1
      const res = await this.$UserOnlineSvc.updateEvaluation(params)
      console.log(res)
    }
  }
}
</script>

<style scoped>

</style>
