<template>
  <div>
    <board-tab-index :board-type='boardType.boardType' @fetchData='fetchData(1)' />
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <!-- 홈페이지 유지보수 개발요청안 | 사이드 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
          <side-banner v-bind:isURL="isURL"></side-banner>
          <component-board-type />
          <div class="page_ttl">
            <h1 class="h1">
              <span>{{ $ConstCode.CODE_BOARD_TYPE[boardType.boardType].name }}</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="table_search">
              <div class="select">
                <select name="" id="">
                  <option value="">제목</option>
                </select>
              </div>
              <div class="search_wrap">
                <div class="input">
                  <input type="search" placeholder="검색" v-model="params.searchText" v-on:keyup.enter="fetchData(1)">
                </div>
                <button class="btn" v-on:click="fetchData(1)">검색</button>
              </div>
            </div>
            <div class="table hover"><!--'게시판: hover'-->
              <div class="row head">
                <div class="cell fix150"><p>번호</p></div>
                <div class="cell"><p>제목</p></div>
                <div class="cell fix240"><p>등록일</p></div>
              </div>
              <div class="empty_wrap" v-if="list.length === 0">
                <div class="empty">
                  <span>글이 존재하지 않습니다.</span>
                </div>
              </div>
              <div class="row" :class="{'noty': item.isTop === 1}" v-for="(item, index) in list" :key="index">
                <div class="cell fix150"><p>{{ item.isTop === 1 ? '공지' : pageInfo.virtualNum - index }}</p></div>
                <div class="cell">
                  <p class="left">
                    <!--아래 영역 클릭하면 이동하도록 해주세요-->
                    <span class="board_ttl" v-html="item.title" v-on:click="fnView(item)"></span>
                  </p>
                </div>
                <div class="cell fix240"><p class="date">{{ $moment(item.regDate).format('YYYY.MM.DD') }}</p></div>
              </div>
            </div>
            <base-pagination
              v-model="pageInfo.page"
              v-on:input="fetchData"
              v-on:change="fetchData"
              v-bind:pageInfo="pageInfo"
            />
          </div>
        </div>
      </div>
      <!-- 홈페이지 유지보수 개발요청안 | 푸터 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
      <bottom-banner v-bind:isURL="isURL"></bottom-banner>
    </main>
  </div>
</template>

<script>
import ComponentBoardType from '@/components/common/edu/board-type'
import BasePagination from '@/components/common/base/BasePagingation'
import BoardTabIndex from '@/components/common/board/tab-index'
import SideBanner from '@/components/common/banner/SideBanner.vue'
import BottomBanner from '@/components/common/banner/BottomBanner.vue'

export default {
  name: 'board-index',
  description: '게시판 (1:강의자료, 2:공지사항, 3:자료실, 4:자주하는질문, 5:식품위생)',
  components: {  BoardTabIndex, BasePagination, ComponentBoardType, SideBanner, BottomBanner },
  computed: {
    boardType: function() {
      return this.$store.getters['education/getBoardType']
    }
  },
  data: function () {
    return {
      params: {
        listType: 2,
        boardType: 1,
        limit: 10,
        page: 1,
        examType: 1,
        searchText: ''
      },
      pageInfo: {},
      list: [],
      isURL: ''
    }
  },
  async created() {
    this.fnLoginCheck();
    this.searchURL(); // 메소드 호출
    this.params.boardType = this.boardType.boardType
    const res = await this.$BoardSvc.fetchBoardTypeList(this.params)
    this.list = res.entity.list
    this.pageInfo = res.entity.pageInfo
  },
  watch: {
    'boardType.boardType': function() {
      this.searchURL()
    }
  },
  methods: {
    searchURL: function(){
      if(this.boardType.boardType === 1){
        this.isURL = '37'
      }
      if(this.boardType.boardType === 2){
        this.isURL = '39'
      }
      if(this.boardType.boardType === 3){
        this.isURL = '41'
      }
    },
    fetchData: async function ( page = 1) {
      this.params.page = page
      this.params.boardType = this.boardType.boardType
      const res = await this.$BoardSvc.fetchBoardTypeList(this.params)
      this.list = res.entity.list
      this.pageInfo = res.entity.pageInfo
    },
    fnView: function(item) {
      let no = -1
      const boardType = this.boardType.boardType
      if (boardType === 1) no = item.classNo
      if (boardType === 2) no = item.noticeNo
      if (boardType === 3) no = item.dataNo
      if (boardType === 4) no = item.faqNo
      if (boardType === 5) no = item.foodNo
      this.$router.push ({
        path: '/board/detail',
        query: {  boardType: boardType, no: no }
      })
    },
    // 나의 학습방 > 강의 자료, 로그인 체크 (서남아이에스 임현아, 2024-10-24)
    fnLoginCheck: function() {
      if(this.boardType.boardType === 1) {
        const user = this.$store.getters['user/getUserData']
        if (this.$Util.isEmpty(user)) {
          alert('로그인이 필요한 서비스입니다.')
          this.$store.commit('education/setBoardType', {boardData: {boardType: 1}})
          this.$router.replace({path: '/login', query: { rUrl: '/board' }})       
        }
     }
    }
  }
}
</script>

<style scoped>

</style>
