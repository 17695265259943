'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
/**
 * 공통 API Service
 * @class AuthSvc
 */
class EvaluationSvc extends ServiceExec {

  /**
   *  평가관리 리스트 관리
   *  @param limit {integer} limit
   *  @param page {integer} page
   */
  fetchEvaluationList(params = {}) {
    return this.get(`/api/evaluation/fetchEvaluationList`, params)
      .then(response => {
        return response.data
      })
  }

}
export default new EvaluationSvc()
