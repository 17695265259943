'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
import { forEach } from 'lodash'
/**
 * 공통 API Service
 * @class OfflineEduSvc
 */
class OfflineEduSvc extends ServiceExec {

  /**
   *  집합교육신청 등록
   *  @param Authorization {string} Authorization
   *  @param file {file} file
   *  @param offlineEduBean {OfflineEduBean} offlineEduBean
   */
  upsertOfflineEdu(params = {}) {
    const formData = new FormData()
    formData.append(
      'offlineEduBean',
      new Blob([JSON.stringify(params)], { type: 'application/json' })
    )
    return this
      .multiPost(`/api/offline/edu/upsertOfflineEdu`, formData)
      .then(response => {
        return response.data
      })
  }
  /**
   *  교육일정 지역선택에 따른 현장 상세보기
   *  @param token {} token
   *  @param zipProvinceNo {integer} zipProvinceNo
   */
  fetchLocation(params = {}) {
    return this
      .get(`/api/offline/edu/fetchLocation`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  오프라인 회원 신청 리스트
   *  @param Authorization {string} Authorization
   *  @param eduType {integer} eduType
   *  @param industryType {integer}
   *  @param isEnd {integer}
   *  @param limit {integer} 10
   *  @param page {integer} 1
   *  @param salesType {integer}
   *  @param searchText {string}
   */
  fetchUserOfflineEduList(params = {}) {
    return this
      .get(`/api/offline/edu/fetchUserOfflineEduList`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  오프라인 회원 신청 삭제
   *  @param applyEduNo {integer} applyEduNo
   */
  // 홈페이지 유지보수 개발요청안 | 교육 취소 기능 추가 (서남아이에스 임현아, 2024-07-02)
    deleteData(params = {}) {
      return this
        .delete(`/api/offline/edu/deleteData`, params)
        .then(response => {
          return response.data
        })
    }

  /**
   *  오프라인 신청목록 상세페이지
   *  @param offlineEduNo {integer} offlineEduNo
   *  @param token {} token
   */
  fetchData(params = {}) {
    return this
      .get(`/api/offline/edu/fetchData`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  오프라인 수강목록
   *  @param Authorization {string} Authorization
   *  @param limit {integer} limit
   *  @param page {integer} page
   */
  fetchUserOfflineClass(params = {}) {
    return this
      .get(`/api/offline/edu/fetchUserOfflineClass`, params)
      .then(response => {
        return response.data
      })
  }


  /**
   *  온라인 위생관리자 지정서 변경
   *  @param Authorization {string} Authorization
   *  @param file {file} file
   *  @param isHygiene {} isHygiene
   *  @param onlineEduNo {} offlineEduNo
   */
  updateUserOfflineInfo(params = {}) {
    const formData = new FormData()
    forEach(params, function(value, key) {
      formData.append(key, value)
    })
    formData.append('file', params.file)
    return this
      .multiPost(`/api/offline/edu/updateUserOfflineInfo`, formData)
      .then(response => {
        return response.data
      })
  }

  /**
   *  오프라인 지정확인서 제출 리스트
   *  @param Authorization {string} Authorization
   *  @param limit {integer} limit
   *  @param page {integer} page
   */
  fetchUserConfirmationList(params = {}) {
    return this
      .get(`/api/offline/edu/fetchUserConfirmationList`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  집합교육 신청 출력상태 값 변경
   *  @param offlineEduNo {integer} offlineEduNo
   *  @param token {} token
   */
  updatePrintStatus(params = {}) {
    return this.get(`/api/offline/edu/updatePrintStatus`, params)
      .then(response => {
        return response.data
      })
  }


  /**
   *  집합교육 지정확인서 제출 파일 삭제
   *  @param offlineEduNo {integer} offlineEduNo
   *  @param token {} token
   */
  updateHygieneFile(params = {}) {
    return this.get(`/api/offline/edu/updateHygieneFile`, params)
      .then(response => {
        return response.data
      })
  }


  /**
   *  집합교육 캘린더 가져오기
   *  @param Authorization {string} Authorization
   *  @param searchMonth {string} 캘린더 기간 종료
   *  @param searchType {string} 검색어
   *  @param searchYear {string} 캘린더 기간 시작
   */
  fetchCalender(params = {}) {
    return this.get(`/api/offline/edu/fetchCalender`, params)
      .then(response => {
        return response.data
      })
  }


  /**
   *  집합교육 변경
   *  @param Authorization {string} Authorization
   *  @param searchMonth {string} 캘린더 기간 종료
   *  @param searchType {string} 검색어
   *  @param searchYear {string} 캘린더 기간 시작
   */
  changeEducation(params = {}) {
    console.log('params::::{}', params)
    return this.queryPost(`/api/offline/edu/changeEducation`, params)
      .then(response => {
        return response.data
      })
  }


}
export default new OfflineEduSvc()
