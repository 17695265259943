'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
/**
 * 공통 API Service
 * @class OnlineClassSvc
 */
class OnlineClassSvc extends ServiceExec {

  /**
   *  온라인 수강목록
   *  @param limit {integer} 10
   *  @param page {integer} 1
   *  @param token {} token
   */
  fetchUserOnlineClass(params = {}) {
    return this
      .get(`/api/online/class/fetchUserOnlineClass`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  온라인 수강목록 상세보기
   *  @param onlineClassNo {integer} onlineClassNo
   *  @param token {} token
   */
  fetchUserOnlineClassData(params = {}) {
    return this
      .get(`/api/online/class/fetchUserOnlineClassData`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  온라인 지정확인서 제출 리스트
   *  @param Authorization {string} Authorization
   *  @param limit {integer} limit
   *  @param page {integer} page
   */
  fetchUserConfirmationList(params = {}) {
    return this
      .get(`/api/online/class/fetchUserConfirmationList`, params)
      .then(response => {
        return response.data
      })
  }

}
export default new OnlineClassSvc()
