<template>
  <main class="main" role="main">
    <!-- 상세 메뉴 버튼 미표출되는 오류 해결 (서남아이에스 임현아, 2024-08-20) -->
    <info-tab-index />
    <div class="page">
      <div class="inner">
        <div class="lnb">
          <ul>
            <li><p class="home"></p></li>
            <li><p>고객센터</p></li>
            <li><p>신고센터</p></li>
          </ul>
        </div>
        <div class="page_ttl">
          <h1 class="h1">
            <span>신고센터</span>
          </h1>
        </div>
        <div class="page_con">
          <div class="form_table">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_ttl">
                    <p>제목</p>
                  </div>
                  <div class="form_in">
                    <div class="input">
                      <input type="text" placeholder="제목을 입력해 주세요" v-model="form.title">
                      <p class="valid on" v-show="$v.form.title.$error">필수입력사항입니다.</p><!--'on 추가'-->
                    </div>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl">
                    <p>내용</p>
                  </div>
                  <div class="form_in">
                    <div class="board_write">
                      <editor
                        v-model="form.contents"
                        :contents="form.contents"
                        v-on:eventBusChangeEditor="fnChangeContents"
                      />
                      <p class="valid">유효성 문구 영역</p><!--'on 추가'-->
                    </div>
                  </div>
                </li>
                <!-- 텍스트 수정 요청 상세 (서남아이에스 임현아, 2024-08-30) -->
                <li class="form_li">
                  <div class="form_ttl">
                    <p></p>
                  </div>
                  <div class="form_in">
                    <div style="font-weight: 400;">
                      <p>게시글은 비공개처리되며, 작성자 본인만 확인 가능합니다.</p>
                    </div>
                  </div>
                </li>
<!--                <li class="form_li">-->
<!--                  <div class="form_ttl">-->
<!--                    <p>비밀글</p>-->
<!--                  </div>-->
<!--                  <div class="form_in">-->
<!--                    <div class="inline">-->
<!--                      <div class="radio">-->
<!--                        <input type="radio" name="a" id="aa"  v-bind:value="2" v-model="form.isSecret">-->
<!--                        <label for="aa"><span>비밀글</span></label>-->
<!--                      </div>-->
<!--                      <div class="radio">-->
<!--                        <input type="radio" name="a" id="ab" v-bind:value="1" v-model="form.isSecret">-->
<!--                        <label for="ab"><span>전체공개</span></label>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <p class="valid on" v-show="$v.form.isSecret.$error">필수입력사항입니다.</p>&lt;!&ndash;'on 추가'&ndash;&gt;-->
<!--                  </div>-->
<!--                </li>-->
              </ul>
            </div>
          </div>
        </div>
        <div class="page_btn">
          <button class="btn" v-on:click="fnSave">글쓰기</button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import InfoTabIndex from '@/components/common/info/tab-index'
import Editor from '@/components/common/editor/editor'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'report-board-write',
  description: '신고센터 글쓰기',
  components: { Editor, InfoTabIndex },
  data() {
    return {
      form: {
        title: '',
        contents: '',
        isSecret: 2,
        isAnswer: 2,
        boardType: 2
      }
    }
  },
  methods: {
    fnSave: function() {
      if (!this.$Util.isValid(this.$v, this.$el)) return false
      if (!confirm('등록하시겠습니까?')) return false
      this.$InquirySvc.upsertInquiry(this.form).then(res => {
        if (res.code === 1) {
          alert('등록되었습니다.')
          this.$router.go(-1)
        }
      })
    },
    fnChangeContents: function(content) {
      this.form.contents = content
    }
  },
  validations: {
    form: {
      title: {
        required
      },
      isSecret: {
        test: function(val) {
          if (!this.$Util.isEmpty(val)) {
            return true
          }
            return false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
