<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="lnb">
          <ul>
            <li><p class="home"></p></li>
            <li><p>제3자 마케팅 활용 동의서</p></li>
          </ul>
        </div>
        <div class="page_ttl">
          <h1 class="h1">
            <span>제3자 마케팅 활용 동의서</span>
          </h1>
        </div>
        <div class="page_con">
          <div class="rule_list">
            <ul class="rule_list_ul">
              <li class="rule_list_li">
                <div class="rule_list_con">
                  <div class="table">
                    <div class="row head">
                      <div class="col"><!--'모달창에서: fix150 추가'-->
                        <div class="row" style="height: 100%;">
                          <div class="cell"><p>제공대상자</p></div>
                        </div>
                      </div>
                      <div class="cell"><p>제공정보</p></div>
                      <div class="cell"><p>이용목적</p></div>
                      <div class="cell"><p>이용기간</p></div>
                    </div>
                    <!-- 통신판매중개 제공대상자 및 제공정보 변경 (서남아이에스 임현아, 2024-05-21) -->
                    <div class="row">
                      <div class="col"><!--'모달창에서: fix150 추가'-->
                        <div class="row" style="height: 100%;">
                          <div class="cell">
                            <p>                              
                              <span class="red">통신판매중개</span><br>
                              <span> 가나안디앤아이, 대호CNI </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="cell">
                        <p>성명, 아이디, 생년월일, 전화번호, 휴대폰번호, 이메일</p>
                      </div>
                      <div class="cell">
                        <p class="red">서비스 안내, 상품 판매</p>
                      </div>
                      <div class="cell">
                        <p>회원탈퇴 또는 제공 동의 철회 시까지</p>
                      </div>
                    </div>
                    <!-- 통신판매중개 제공대상자 및 제공정보 변경 (서남아이에스 임현아, 2024-02-26) -->
                    <div class="row">
                      <div class="col"><!--'모달창에서: fix150 추가'-->
                        <div class="row" style="height: 100%;">
                          <div class="cell">
                            <p>
                              <span class="red">통신판매중개</span><br>
                              <span class="strong">㈜한울스마트워크, ㈜지니택스로, 한울세무, 회계법인 원지</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="cell">
                        <p>성명, 아이디, 생년월일, 전화번호, 휴대폰번호, 이메일, 주소</p>
                      </div>
                      <div class="cell">
                        <p class="red">서비스 안내, 상품 판매</p>
                      </div>
                      <div class="cell">
                        <p>회원탈퇴 또는 제공 동의 철회 시까지</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col"><!--'모달창에서: fix150 추가'-->
                        <div class="row" style="height: 100%;">
                          <div class="cell">
                            <p>
                              <span class="red">광고, 마케팅</span><br>
                              <span> - </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="cell">
                        <p>성명, 아이디, 생년월일, 전화번호, 휴대폰번호, 이메일</p>
                      </div>
                      <div class="cell">
                        <p class="red">서비스 안내</p>
                      </div>
                      <div class="cell">
                        <p>회원탈퇴 또는 제공 동의 철회 시까지</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col"><!--'모달창에서: fix150 추가'-->
                        <div class="row" style="height: 100%;">
                          <div class="cell">
                            <p>
                              <span class="red">포스, 카드단말기</span><br>
                              <span> - </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="cell">
                        <p>성명, 아이디, 생년월일, 전화번호, 휴대폰번호, 이메일</p>
                      </div>
                      <div class="cell">
                        <p class="red">서비스 안내, 상품 판매</p>
                      </div>
                      <div class="cell">
                        <p>회원탈퇴 또는 제공 동의 철회 시까지</p>
                      </div>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_con">
                      <p>
                        본 동의서에는 전화, 문자, 이메일, DM 등을 활용한 광고성 정보 수신 동의
                        내용이 포함되어 있습니다.<br>
                        회원님에게 보다 다양한 정보를 안내하기 위하여 마케팅활용에 선택적으로
                        동의하신 회원님에 한하여 해당 제휴사에 회원님의 정보를 제공하고, 이는
                        상품소개, 홍보 및 혜택 안내 등으로 사용됩니다.
                      </p>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>수집 및 활용 관련 정보</span>
                    </div>
                    <div class="term_con">
                      <p>
                        (사)한국외식산업협회는 회원님들에게 보다 다양한 정보를 제공하고, 서비스의 질을 향상시키기 위하여
                        당사의 비즈니스 파트너에게 본 활용 동의서에 동의한 회원의 개인정보를 제공합니다.<br>
                        회원님들 중 개인정보의 제3자 마케팅 활용 동의서에 동의하신 회원님들의 정보만이
                        제공되며, 제공된 정보는 명시된 이용목적을 벗어나 이용되지 않습니다.
                        개인정보보호관련 법률에 의거 회원님의 정보는 안전하게 관리되며, 개인정보의 유출 등
                        사고가 일어나지 않도록 더욱 철저한 보안이 이루어지도록 노력하고 있습니다.
                      </p>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>제3자 정보제공의 동의 철회</span>
                    </div>
                    <div class="term_con">
                      <p>
                        2021년 1월 1일 이전 가입 회원 또는 본 동의서에 동의하고 가입하신 신규 회원 중
                        제3자 정보제공을 철회하고 싶은 회원은 이미 제3자에게 제공된 개인정보라 하더라도,
                        언제든지 열람, 정정, 삭제를 요구할 수 있습니다. 열람, 정정, 삭제 및 정보제공
                        동의 철회는 (사)한국외식산업협회(02-449-5009)을 통하여 본인 확인 후 요청할 수
                        있습니다.<br>
                        또한 향후의 정보제공을 원치 않거나, 새롭게 동의를 하고자 하는 경우에는
                        <span style="color: red;">회원정보→회원정보조회/수정→기본정보</span>
                        에서 마케팅 동의 여부를 변경할 수 있습니다.<br>
                        마케팅 활용 동의를 통해 이미 제공된 회원정보에 대해 동의 철회를 요청하시는 경우,
                        동의상태 변경 또는 철회 요청일로부터 최대 7일 이내에, 회원님의 정보는 기술적인
                        방법으로 복구 불가하도록 안전하게 철회 및 삭제 처리 됩니다<br>
                        <br>
                        회원님께서는 동의를 거부하실 수 있으며, 동의 거부 시 회원가입은 가능하나,
                        각종 광고 및 이벤트 안내 등의 서비스가 제한됩니다.<br>
                        <br>
                        <span style="font-weight: bold;">
                                                마케팅 활용 동의 변경 시행일자 : 2023-12-22
                                            </span>
                      </p>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_con">
                      <p>
                        '한국외식산업협회'는 (이하 '협회'는) 가입자의 개인정보를 중요시하며,
                        수집된 개인 정보를 마케팅 목적으로 이용하는 경우 신용정보이용 및 보호에
                        관한 법률 등에 따라 사전에 고객에게 동의를 얻고 있으며 동의 이후에라도
                        고객의 의사에 따라 동의를 철회 할 수 있습니다. 이에 협회는 가입자가
                        제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를
                        위해 어떠한 조치가 취해지고 있는지 알려드립니다. 협회는 개인정보취급방침을
                        개정하는 경우 웹사이트(kfoodedu.or.kr) 공지사항(또는 개별공지)을 통하여
                        공지 할 것입니다.
                      </p>
                    </div>
                  </div>
<!--                  <div class="term">-->
<!--                    <div class="term_ttl">-->
<!--                      <span>1. 개인정보를 제공받는 자</span>-->
<!--                    </div>-->
<!--                    <div class="term_con">-->
<!--                      <ol class="dot">-->
<!--                        <li>-->
<!--                                                <span class="ol_li_txt">-->
<!--                                                    ㈜더리얼마케팅 외식산업협위회 정책자문단-->
<!--                                                </span>-->
<!--                        </li>-->
<!--                        <li><span class="ol_li_txt">㈜비이피</span></li>-->
<!--                        <li><span class="ol_li_txt">㈜SKY c&s</span></li>-->
<!--                      </ol>-->
<!--                    </div>-->
<!--                  </div>-->
                  <div class="term">
                    <div class="term_ttl">
                      <span>1. 개인정보를 제공받는 자의 개인정보 이용 목적</span>
                    </div>
                    <div class="term_con">
                      <p>다음과 같은 목적을 위하여 개인정보 활용 이용 동의를 받고 있습니다.</p>
                      <ol class="dot">
                        <li>
                                                <span class="ol_li_txt">
                                                    협회와 회원의 복리 증진을 위한 정책 사업 추진
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    서비스 제공에 관한 정보 안내 및 관리 상담 :
                                                    세무/노무/POS/VAN/광고/홍보/마케팅 대행 등
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산 :
                                                    안내 제공되는 서비스에 한한 계약 이행 및 요금정산
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>2. 제공하는 개인정보의 항목</span>
                    </div>
                    <div class="term_con">
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    개인정보 수집항목
                                                </span>
                          <ol>
                            <li>
                              원활한 고객상담, 각종 서비스의 제공을 위해 최초 회원가입시
                              아래와 같은 개인정보 수집에 동의를 구하고 있습니다.<br>
                              ∙ 로그인ID, 성명, 회사명, 업체(매장)주소,
                              전화번호, 휴대폰번호, 이메일 등
                            </li>
                          </ol>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    개인정보 수집방법
                                                </span>
                          <ol>
                            <li>
                              ∙ 홈페이지(kfoodedu.or.kr) 회원가입 시 정보 이용 동의
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>3. 개인정보를 제공받는 자의 개인정보 보유 및 이용 기간</span>
                    </div>
                    <div class="term_con">
                      <p>
                        회원 가입일로부터 서비스를 제공하는 기간 동안에 한하여 이용자의
                        개인정보를 보유 및 이용합니다. 단, 회원탈퇴를 요청하거나,
                        수집 및 이용목적이 달성 및 이용기간이 종료한 경우 해당 개인정보를
                        지체 없이 파기합니다.<br>
                        이 경우, 다음의 정보에 대하여는 아래의 이유로 명시한 기간 동안 보존합니다.
                      </p>
                      <ol class="dot">
                        <li>
                                                <span class="ol_li_txt">
                                                    서비스 이용기록, 접속 로그, 접속 IP 정보 : 3개월(통신비밀보호법)
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    계약 또는 청약철회 등에 관한 기록 :
                                                    5년(전자상거래등에서의 소비자보호에 관한 법률)
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    대금결제 및 재화 등의 공급에 관한 기록 :
                                                    5년(전자상거래등에서의 소비자보호에 관한 법률)
                                                </span>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    소비자의 불만 또는 분쟁처리에 관한 기록 :
                                                    3년(전자상거래등에서의 소비자보호에 관한 법률)
                                                </span>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>4. 개인정보의 파기절차 및 방법</span>
                    </div>
                    <div class="term_con">
                      <p>
                        개인정보의 마케팅 활용 이용 동의를 철회한 경우 해당 정보를 지체 없이 파기합니다.
                        파기절차 및 방법은 다음과 같습니다.
                      </p>
                      <ol class="nb_circle">
                        <li>
                                                <span class="ol_li_txt">
                                                    파기절차
                                                </span>
                          <ol>
                            <li>
                              회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후
                              별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및
                              기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)
                              일정 기간 저장된 후 파기 됩니다. 별도 DB로 옮겨진 개인정보는
                              법률에 의한 경우가 아니고서는 보유 되어 이외의 다른 목적으로
                              이용되지 않습니다.
                            </li>
                          </ol>
                        </li>
                        <li>
                                                <span class="ol_li_txt">
                                                    파기방법
                                                </span>
                          <ol class="dot">
                            <li>
                                                        <span class="ol_li_txt">
                                                            전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는
                                                            기술적 방법을 사용하여 삭제합니다.
                                                        </span>
                            </li>
                            <li>
                                                        <span class="ol_li_txt">
                                                            종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
                                                        </span>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </div>
                  </div>
                  <div class="term">
                    <div class="term_ttl">
                      <span>5. 개인정보 마케팅 활용 이용 동의 거부권</span>
                    </div>
                    <div class="term_con">
                      <p>
                        회원은 본 ‘개인정보의 마케팅 활용 이용 동의’를 개인의 의사에 따라 거부 할 수 있으며,
                        동의거부에 따라 ㈜더리얼마케팅이 제공하는 회원 이벤트 경품 제공 및 세무/노무/POS/VAN 등
                        회원 복리서비스 상담 및 홈페이지 이용에 제약이 있을 수 있음을 명시합니다.
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    name: 'main-marketing',
    description: '제3자 마케팅 활용 동의서'
  }
</script>

<style scoped>

</style>
