<template>
  <!-- 식품위생관리책임자(위생관리자) 관련 법령 안내 팝업 (서남아이에스 임현아, 2024-11-19) -->
  <div class="modal_inner center w1200">
    <div class="modal_con">
      <div class="notice-box" style="background-color: #6FB92C;">
        <p class="title">식품위생관리책임자(위생관리자) 관련 법령 안내</p>
        <button class="close" v-on:click="fnClose()">X</button>
      </div>  
      <div class="notice-box">
        <p class="content">
          <span style="color: #41AC26; font-weight: 700;">※ 식품위생법 제41조 3항</span><br>
          <strong>- 식품위생관리책임자(위생관리자)를 임명하는 경우는 다음과 같습니다.</strong><br>
          <span style="color: gray;">1) 식품접객업 영업자가 영업에 직접 종사하지 않는 경우<br>
          2) 식품접객업 영업자가 두 곳 이상의 장소에서 영업을 하는 경우</span><br>
          <br>
          <strong>- 위 사항에 해당할 경우, 영업자는 종업원 중에서 식품위생에 관한 책임자<br>
          (위생관리자)를 지정하여 영업자 대신 교육을 받게 할 수 있으며, 교육 시<br>
          [식품위생관리책임자 지정확인서]를 제출하여야 합니다.</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-common-notice',
  props: {
    data: {
      type: [Array,Object],
      default: () => {}
    },
    callBack: {
      type: [Function]
    },
    confirmLabel: {
      type: [String],
      default: '확인'
    },
    cancelLabel: {
      type: [String],
      default: '취소'
    }
  },
  methods: {
    fnClose: function() {
      this.$emit('eventClose')
    }
  }
}
</script>

<style scoped>
.notice-box {
  padding: 15px;
  text-align: left; /* 가운데 정렬 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 18px;
  font-weight: bold;
  text-align: center; /* 가운데 정렬 */
}

.close {
  color: white;
  font-weight: 700;
  font-size: 20px;
  margin-left: 50px;
}

.content {
  font-size: 16px;
  line-height: 1.5;
}
</style>
