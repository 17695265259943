<template>
  <div class="modal_inner center w1200">
    <div class="modal_con" style="padding: 60px; background-color: #F8F8F8;"><!--'로그인, 아이디찾기, 비밀번호찾기: grey'-->
      <div class="inner">
        <div class="page_ttl">
          <h1 class="h1">
            <span>휴대폰번호 변경하기</span>
          </h1>
        </div>
        <div class="page_con">
          <div class="find_id w420">
            <div class="form">
              <ul class="form_ul">                
                <!-- <li class="form_li">
                  <div class="form_ttl">
                    <p>이름</p>
                  </div>
                  <div class="form_in">
                    <div class="input">
                      <input type="text" placeholder="이름을 입력해 주세요" v-model="name">
                      <p class="valid on" v-show="$v.name.$error">이름을 입력해주세요.</p>
                    </div>
                  </div>
                </li> -->
                <li class="form_li">
                  <div class="form_ttl">
                    <p>변경된 휴대폰 번호</p>
                  </div>
                  <div class="form_in">
                    <div class="input">
                      <input type="number" placeholder="-를 제외하고 입력해 주세요" v-model="userPhone" id="userPhone">
                    </div>
                    <p class="valid on" v-show="$v.userPhone.$error">{{ msg.userPhone }}</p><!--'on 추가'-->
                    <button class="btn bo" v-on:click="fnAuth">{{ msg.send }}</button>
                    <div class="inline time_count">
                      <div class="input">
                        <input type="number" v-model="authNumber">
                        <span class="time" id="time" style="color: red;" v-show="isRunning">{{ count }}</span>
                      </div>
                      <button class="btn bo grey" disabled v-on:click="fnConfirmAuth" id="block">인증하기</button>
                    </div>
                    <p class="valid">인증번호를 입력해주세요.</p><!--'on 추가'-->
                  </div>
                </li>
              </ul>
            </div>
            <div class="page_btn">
              <button class="btn bo grey" v-on:click="$emit('eventClose')">이전으로</button>
              <button class="btn" v-on:click="fnChangePhone">변경하기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'mypage-changepone',
  description: '휴대폰번호 변경하기',
  data() {
    return {      
      form: {
        userNo: -1,
        password: '',
        password2: '',
        email: '',
        oldUserPhone: '',
        userPhone: '',
        companyPhone: '',
        postCode: '',
        address: '',
        address2: '',
        isSms: -1,
        isEmail: -1,
        isPhone: -1, //수신동의 : 전화추가 (서남아이에스 임현아, 2024-05-24)       
        isMarketing: -1
      },
      userNo: -1,
      name: '',
      userPhone: '',
      authNumber: '', // 인증번호
      authConfirmNumber: '', // 발송된 인증번호,
      isAuth: false,  // 인증번호 인증여부
      isRunning: false, // 타이머 작동 여부,
      msg: {
        send: '인증번호 발송',
        userPhone: ''
      },
      count: '03:00',
      timeCounter: 180 // 3분
    }
  },
  destroyed() {
    this.fnStop()
  },
  async created() {
    const params = {}
    params.userNo = 1
    const res = await this.$UserSvc.fetchUser()
    this.form = res.entity
    this.form.oldUserPhone = res.entity.userPhone
    this.form.postCode = res.entity.postalCode
  },
  methods: {
    fnChangePhone: function() {
      if(!this.$Util.isValid(this.$v, this.$el)) return false
      
      const params = {}
      params.name = this.name
      params.userPhone = this.userPhone
      if (this.isAuth === false) {
        alert('휴대폰인증을 해주세요')
        return false
      }

      const message = '입력하신 휴대폰번호로 변경하시겠습니까?'
      const type = 'changePhone'
      const itemList = {userPhone : this.userPhone}

      this.$store.commit('layout/setModalAlert', { data: { message, type, itemList }, modalAlertName: this.$modalAlertNames.COMMONCONFIRMINFO })

    },
    fnAuth: function() {
      if (this.$Util.isEmpty(this.userPhone)) return false
      const params = {}
      params.userNo = this.userNo
      params.name = this.name
      params.userPhone = this.userPhone
      this.$AuthSvc.sendForPhoneChange(params).then(res => {
        if (res.code === 1 ) {
          this.authConfirmNumber = res.entity // 발송된 인증번호 넣어주기
          // 개발 환경
          // this.authNumber = this.authConfirmNumber
        // 발송후 핸드폰 번호칸은  readonly
      this.msg.send = '재전송'
      document.getElementById("userPhone").readOnly = true
      document.getElementById("block").disabled = false
      // 인증번호 타이머 시작 (세팅시간 3분)
      this.fnTimer()
      this.isRunning = true
      // 인증번호 발송을 다시 누를경우 초기화 시켜주기
      if(this.isRunning) clearTimeout(this.setTimer)
      
        } else if (res.code === -1012) {
          alert('이미 사용 중인 전화번호입니다.')
        }
      })
    },
    fnConfirmAuth: function() {
      // 인증번호 칸이 빈 값일 경우 리턴 false
      if(this.$Util.isEmpty(this.authNumber)) return false
      if (this.authNumber !== this.authConfirmNumber) {
        alert('인증번호가 일치하지 않습니다.')
        return false
      } else {
        alert('인증되었습니다.')
        this.fnStop()
        document.getElementById("block").disabled = true
        this.isRunning = false // 타이머 숨기기
        this.isAuth = true
      }
    },
    fnTimer: function() {
      this.fnStop()
      this.timer = setInterval( () => {
        this.timeCounter --
        if (this.timeCounter <= 0) {
          this.fnStop()
          alert('인증 번호가 만료되었습니다.\n다시 인증해주세요.')
        }
        let time = this.timeCounter / 60
        let min = parseInt(time)
        let sec = Math.round((time - min) * 60)
        this.count = this.pad(min, 2) + ':' + this.pad(sec, 2)
      }, 1000)
    },
    pad: function(n, width){
      n = n + ''
      return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n
    },
    fnStop: function() {
      clearInterval(this.timer)
      this.timeCounter = 180
      this.count = "03:00"
    }
  },
  validations: {
    // name: {
    //   required
    // },
    userPhone: {
      test: function(val) {
        if (this.$Util.isEmpty(val)) {
          this.msg.userPhone = '휴대폰 번호를 입력해주세요.'
          return false
        }
        return required(val)
      }
    }
  }
}
</script>

<style scoped>

</style>
