<template>
  <div>
    <component-board-tap />
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <!-- 홈페이지 유지보수 개발요청안 | 사이드 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
          <side-banner v-bind:isURL="isURL"></side-banner>
          <component-edu-type />
          <div class="page_ttl">
            <h1 class="h1">
              <!-- 홈페이지 유지보수 개발요청안 | 결제내역 => 신청내역 명칭 변경 (서남아이에스 임현아, 2024-07-04) -->
              <!-- <span>결제 내역</span> -->
              <span>신청 내역</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="table_search">
              <div class="select">
                <select name="" id="">
                  <option value="">업체명</option>
                </select>
              </div>
              <div class="search_wrap">
                <div class="input">
                  <input type="search" placeholder="검색" v-model="params.searchText" v-on:keyup.enter="fetchData(1)">
                </div>
                <button class="btn" v-on:click="fetchData(1)">검색</button>
              </div>
            </div>
            <div class="table">
              <div class="row head" v-if="this.eduType.isOnline === 1">
                <div class="cell fix180"><p>주문번호</p></div>
                <div class="cell fix180"><p>등록일</p></div>
                <div class="cell"><p>교육구분</p></div>
                <div class="cell fix180"><p>업체명</p></div>
                <div class="cell fix300"><p>결제</p></div>
<!--                <div class="cell fix180" v-if="isVirtualPayment"><p>가상계좌번호</p></div>-->
              </div>
              <div class="row head" v-else>
                <div class="cell fix180"><p>주문번호</p></div>
                <div class="cell fix120"><p>등록일</p></div>
                <div class="cell fix130"><p>교육구분</p></div>
                <div class="cell fix130"><p>교육일자</p></div>
                <div class="cell fix170"><p>교육장소</p></div>
                <div class="cell fix170"><p>업체명</p></div>
                <div class="cell fix300"><p>결제</p></div>
              </div>
              <div class="page_con" v-if="list.length === 0">
                <div class="empty_wrap">
                  <div class="empty">
                    <span>내역이 존재하지 않습니다.</span>
                  </div>
                </div>
              </div>
              <div v-if="this.eduType.isOnline === 1">
                <div class="row" v-for="(item, index) in list" :key="index">
                  <div class="cell fix180"><p>{{ eduType.isOnline === 1 ? item.edu.onlineEduCode : item.edu.offlineEduCode }}</p></div>
                  <div class="cell fix180">
                    <p>
                      {{ $moment(item.edu.regDate).format('YYYY.MM.DD') }}<br>
                      {{ $moment(item.edu.regDate).format('HH:mm') }}
                    </p>
                  </div>
                  <div class="cell">
                    <p class="left">
                      {{ $ConstCode.CODE_INDUSTRY_TYPE[item.edu.industryType].name }}
                      ({{ $ConstCode.CODE_SALES_TYPE[item.edu.salesType].name }})
                    </p>
                  </div>
                  <div class="cell fix180"><p>{{ item.edu.businessName }}</p></div>
                  <div class="cell fix300">
                    <button class="btn bo fix120" v-on:click="fnPayment(item)" v-if="item.edu.isFinishPayment !== 1 && $moment(item.edu.regDate).format('YYYY') === $moment().format('YYYY')">결제하기</button>
                    <!-- 홈페이지 유지보수 개발요청안 | 교육 취소 기능 추가 (서남아이에스 임현아, 2024-07-01) -->
                    <button class="btn bo fix120" v-on:click="fnCancel(item)" style="height: 50px; margin-left: 20px;" v-if="item.edu.isFinishPayment !== 1 && item.edu.isFinishPayment !== -1">교육신청<br/>취소하기</button>
                    <button class="btn bo fix120" v-if="fnStatus(item)" @click.prevent.stop="fnCancelPayment(item)">환불하기</button>
                    <p class="state" v-if="item.payment.paymentType === 4">환불완료</p>
                    <!-- <p class="account" v-if="item.payment.cashGb === 'VA' && item.edu.isFinishPayment !== 1">
                      {{ item.payment.virtualBankName }}<br />
                      {{ item.payment.virtualAccountNumber }}</p> -->
                    <p class="state" v-if="item.edu.isFinishPayment === 1">결제완료</p>
                  </div>
  <!--                <div class="cell fix180"  v-if="isVirtualPayment" >-->
  <!--                  {{ item.payment.virtualBankName }}<br/>-->
  <!--                  {{ item.payment.virtualAccountNumber }}-->
  <!--                </div>-->
                </div>
              </div>
              <div v-else>
                <div class="row" v-for="(item, index) in list" :key="index">
                  <div class="cell fix180"><p>{{ eduType.isOnline === 1 ? item.edu.onlineEduCode : item.edu.offlineEduCode }}</p></div>
                  <div class="cell fix120">
                    <p>
                      {{ $moment(item.edu.regDate).format('YYYY.MM.DD') }}<br>
                      {{ $moment(item.edu.regDate).format('HH:mm') }}
                    </p>
                  </div>
                  <div class="cell fix130">
                    <p class="left">
                      {{ $ConstCode.CODE_INDUSTRY_TYPE[item.edu.industryType].name }}<br/>
                      ({{ $ConstCode.CODE_SALES_TYPE[item.edu.salesType].name }})
                    </p>
                  </div>
                  <div class="cell fix130"><p>{{ $moment(item.info.eduDate).format('YYYY.MM.DD') }}<br/>{{ `${item.info.startTime} ~ ${item.info.endTime}` }}</p></div>
                  <div class="cell fix170"><p>{{`${item.scene.location} (${$Util.isEmpty(item.scene.address2) ? item.scene.address : item.scene.address + ' ' + item.scene.address2 })` }}</p></div>
                  <div class="cell fix170"><p>{{ item.edu.businessName }}</p></div>
                  <div class="cell fix300">
                    <button class="btn bo fix120" v-on:click="fnPayment(item)" v-if="item.edu.isFinishPayment !== 1 && $moment(item.edu.regDate).format('YYYY') === $moment().format('YYYY')">결제하기</button>
                    <!-- 홈페이지 유지보수 개발요청안 | 교육 취소 기능 추가 (서남아이에스 임현아, 2024-07-01) -->
                    <button class="btn bo fix120" v-on:click="fnCancel(item)" style="height: 50px; margin-left: 20px;" v-if="item.edu.isFinishPayment !== 1 && item.edu.isFinishPayment !== -1">교육신청<br/>취소하기</button>
                    <button class="btn bo fix120" v-if="fnStatus(item)" @click.prevent.stop="fnCancelPayment(item)">환불하기</button>
                    <p class="state" v-if="item.payment.paymentType === 4">환불완료</p>
                    <p class="state" v-if="item.edu.isFinishPayment === 1">결제완료</p>
                  </div>
                </div>
              </div>
            </div>
            <base-pagination
              v-model="pageInfo.page"
              v-on:input="fetchData"
              v-on:change="fetchData"
              v-bind:pageInfo="pageInfo"
            />
          </div>
        </div>
        <!-- 홈페이지 유지보수 개발요청안 | 푸터 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
        <bottom-banner v-bind:isURL="isURL"></bottom-banner>
      </div>
    </main>
  </div>
</template>

<script>
import ComponentEduType from '@/components/common/edu/edu-type'
import BasePagination from '@/components/common/base/BasePagingation'
import ComponentBoardTap from '@/components/common/edu/board-tap'
import SideBanner from '@/components/common/banner/SideBanner.vue'
import BottomBanner from '@/components/common/banner/BottomBanner.vue'

export default {
  name: 'apply-list',
  description: '신청목록',
  components: {
    ComponentBoardTap,
    ComponentEduType,
    BasePagination,
    SideBanner,
    BottomBanner
  },
  computed: {
    eduType: function() {
      return this.$store.getters['education/getEduType']
    },
    URL() {
      return this.searchURL(); // 메소드 호출
    }
  },
  async created() {
    this.searchURL()

    this.params.salesType = this.eduType.salesType
    this.params.industryType = this.eduType.industryType
    this.params.eduType = this.eduType.isOnline
    let res = {}
    if (this.eduType.isOnline === 1) {
      res = await this.$OnlineEduSvc.fetchUserOnlineList(this.params)
    } else {
      res = await this.$OfflineEduSvc.fetchUserOfflineEduList(this.params)
    }
    if (res.code === 1) {
      this.list = res.entity.list
      this.pageInfo = res.entity.pageInfo
      // this.isVirtualPayment = this.list.filter(item => (item.payment.cashGb === 'VA')).length > 0
    }
  },
  watch: {
    'eduType.industryType': function(val) {
      this.params.industryType = val
      this.fetchData()

      this.searchURL()
    }
  },
  data() {
    return {
      params: {
        page: 1,
        limit: 10,
        searchText: '',
        salesType: '',
        industryType: '',
        isOnline: '',
        isRefund: -1
      },
      refundForm: {
        paymentNo: -1,
        refundType: 2,
        industryType: '',
        isOnline: -1,
        eduNo: -1,
        bankCode: '',
        accountNumber: '',
        accountName: ''
      },
      pageInfo: {},
      list: [],
      isVirtualPayment: false,
      isURL: ''
    }
  },
  methods: {
    searchURL: function(){
      if(this.eduType.isOnline === 1){
        if (this.eduType.industryType === 1) {
          this.isURL = '2'
        }
        if (this.eduType.industryType === 2) {
          this.isURL = '6'
        }
        if (this.eduType.industryType === 3) {
          this.isURL = '10'
        }
      }

      if(this.eduType.isOnline === 2){
        if (this.eduType.industryType === 1) {
          this.isURL = '14'
        }
        if (this.eduType.industryType === 2) {
          this.isURL = '19'
        }
        if (this.eduType.industryType === 3) {
          this.isURL = '24'
        }
      }
    },
    fnStatus: function(item) {
      if (item.edu.isFinishPayment === 1) {
        if (item.edu.paymentType === 5) {
          return false;
        }

        // 홈페이지 유지보수 개발요청안 | 환불하기 버튼 수강 완료인 경우, 미표출 (서남아이에스 임현아, 2024-07-01)
        if(item.edu.isEnd === 1){
          return false;
        }

        return true;
      }
    },
    // 홈페이지 유지보수 개발요청안 | 교육 취소 기능 추가 (서남아이에스 임현아, 2024-07-01)
    fnCancel: function(item){
      console.log(item)

      const message = '신청하신 교육을 취소하시겠습니까?'
      const itemList = { isOnline : this.eduType.isOnline, applyEduNo : item.edu.applyEduNo }

      this.$store.commit('layout/setModalAlert', { data: { message, itemList }, modalAlertName: this.$modalAlertNames.CANCELEDU })

    },
    fnCancelPayment: async function(item) {
      if (!confirm("신청하신 교육을 취소하고 환불 신청 하시겠습니까?")) return false;
      this.refundForm.paymentNo = item.payment.paymentNo;
      this.refundForm.paymentType = item.edu.paymentType;
      this.refundForm.isOnline = this.eduType.isOnline;
      if (this.refundForm.isOnline === 1) {
        this.refundForm.eduNo = item.edu.onlineEduNo;
      } else {
        this.refundForm.eduNo = item.edu.offlineEduNo;
        this.refundForm.eduNo = item.edu.offlineEduNo;
      }
      this.refundForm.industryType = this.eduType.industryType;
      this.refundForm.salesType = this.eduType.salesType;

      const res = await this.$PaymentSvc.cancelPayment(this.refundForm)
      if (res.code === 1) {
         alert("환불 신청이 완료되었습니다.\n환불의 경우 영업일 기준 3일-5일 정도 소요됩니다.")
        await this.fetchData(1);

        this.$router.go(this.$router.currentRoute)
      } else {
        alert(res.message);
        return false;
      }
    },
    fetchData: async function(page) {
      this.params.page = page
      console.log(this.params)
      if (this.eduType.isOnline === 1) {
        const res = await this.$OnlineEduSvc.fetchUserOnlineList(this.params)
        if (res.code === 1) {
          this.list = res.entity.list
          this.pageInfo = res.entity.pageInfo
        }
      } else {
        const res = await this.$OfflineEduSvc.fetchUserOfflineEduList(this.params)
        if (res.code === 1) {
          this.list = res.entity.list
          this.pageInfo = res.entity.pageInfo
        }
      }
    },
    fnPayment: function(item) {
      console.log(item.payment)
      if (!this.$Util.isEmpty(item.payment.paymentType) && item.payment.paymentType === 0) {
        alert(`가상계좌 입금 대기중입니다.\n가상계좌번호: ${item.payment.virtualBankName} ${item.payment.virtualAccountNumber}`)
        return false
      }
      let no = ''
      const type = this.eduType.isOnline
      if (type === 1) {
        no = item.edu.onlineEduNo
        this.$router.push({ path: '/apply/list/payment', query: { onlineEduNo: no } })
      } else {
        no = item.edu.offlineEduNo
        this.$router.push({ path: '/apply/list/payment', query: { offlineEduNo: no } })
      }
    }
  }
}
</script>

<style scoped>

</style>
