<template>
  <div class="pop_cover_inner">
    <div class="pop_cont">
      <div class="pop_txt">
        <p v-html="data.message">
        </p>
      </div>
      <div class="pop_btn half">
        <button v-on:click="fnCallBack"><p>{{ confirmLabel }}</p></button>
        <button v-on:click="$emit('eventClose')"><p>{{ cancelLabel }}</p></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-alert-confirm',
  props: {
    data: {
      type: [Array,Object],
      default: () => {}
    },
    callBack: {
      type: [Function]
    },
    confirmLabel: {
      type: [String],
      default: '확인'
    },
    cancelLabel: {
      type: [String],
      default: '취소'
    }
  },
  methods: {
    fnCallBack: function(e) {
      this.callBack(e)
      this.$emit('eventClose')
    }
  }
}
</script>

<style scoped>

</style>
