<template>
  <main class="main" role="main">

    <div class="page home"><!--'홈: home'-->
      <div class="inner">
        <!--섹션 2-->
        <section class="sec02" style="min-width: 1200px;">
          <div class="inner">
            <!--바로가기 버튼-->
            <div class="shortcut">
              <div class="btn_wrap">
                <div class="shortcut_btn left">
                    <h2>기존 영업자</h2>
                    <!-- 한국외식산업협회 위생교육 신청 섹션 문구 수정 (서남아이에스 임현아, 2024-08-30) -->
                    <!-- 한국외식산업협회 위생교육 신청 섹션 문구 추가 (서남아이에스 임현아, 2024-11-14) -->
                    <!-- <p>기존영업자 위생교육은<br> -->
                      <p>• 영업신고 후 그 다음 해부터<br>
                      매년 받는 보수교육</p>
                      <span style="color:#FF9F00;">[대상업종: 일반음식점, 집단급식소, 위탁급식업]</span>
                      <span><br/><br/></span>
                      <button v-on:click="fnOnOffView('/apply/online', 2, 1, 1,1)">교육 신청하기</button>
                </div>
                <div class="shortcut_btn right">
                  <h2>신규 영업자</h2>
                  <p>• 영업을 하려는 자<br>
                    • 영업의 종류를 추가하려는 자<br>
                    • 지위승계를 받는 자</p>
                    <!-- 한국외식산업협회 위생교육 신청 섹션 문구 추가 (서남아이에스 임현아, 2024-11-14) -->
                    <span style="color:#FF9F00;">[대상업종: 일반음식점, 집단급식소, 위탁급식업]</span>
                    <!-- 한국외식산업협회 위생교육 신청 섹션 문구 삭제_식약처 (서남아이에스 임현아, 2025-01-08) -->
                    <!-- <span style="color:#878787;">[휴게음식업, 제과점업도 신규 수료 가능]</span> -->
                  <button v-on:click="fnOnOffView('/apply/offline', 1, 1, 2,1)">교육 신청하기</button>
                </div>
              </div>
            </div>
            <!--//바로가기 버튼-->
            <!--로그인-->
            <div class="login" v-show="$Util.isEmpty(userData)">
              <div class="form">
                <ul class="form_ul">
                  <li class="form_li">
                    <div class="form_in">
                      <div class="input">
                        <input type="text" placeholder="아이디" v-model="user.userId" v-on:keyup.enter="fnLogin">
                        <p class="valid on" v-show="$v.user.userId.$error">{{ msg.userId }}</p><!--'on 추가'-->
                      </div>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_in">
                      <div class="input">
                        <input type="password" placeholder="비밀번호" v-model="user.userPw" v-on:keyup.enter="fnLogin">
                        <p class="valid on" v-show="$v.user.userPw.$error">{{ msg.userPw }}</p><!--'on 추가'-->
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <button class="btn" @click="fnLogin">로그인</button>
              <div class="btn_wrap">
                <button v-on:click="fnView('/login/findid')">아이디 찾기</button>
                <button v-on:click="fnView('/login/findpw')">비밀번호 찾기</button>
                <button v-on:click="fnJoin">회원가입</button>
              </div>
            </div>
            <!--//로그인-->
            <div class="login" v-show='!$Util.isEmpty(userData)'>
              <div class="login_menu">
                <div class="user">
                  <p><strong>{{ userData.name }}</strong> 님, 안녕하세요.</p>
                </div>
                <div class="lec_info_box">
                  <ul>
                    <li class="lec_info" @click='fnList'>
                      <div class="num">{{ userInfo.applyCount > 99 ? `${userInfo.applyCount}+` : userInfo.applyCount }}</div>
                      <p class="txt">신청내역</p>
                    </li>
                    <router-link to='/mystudy/list' tag='button' class="lec_info">
                      <div class="num">{{ userInfo.studyCount > 99 ? `${userInfo.studyCount}+` :userInfo.studyCount }}</div>
                      <p class="txt">수강중</p>
                    </router-link>
                    <router-link to='/mystudy/print' tag='button' class="lec_info">
                      <div class="num">{{ userInfo.finishCount > 99 ? `${userInfo.finishCount}+` :userInfo.finishCount }}</div>
                      <p class="txt">수강완료</p>
                    </router-link>
                  </ul>
                </div>

                <div class="btn_wrap">
                  <router-link to='/mypage' tag='button' >회원정보</router-link>
                  <button @click='fnLogout'>로그아웃</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!--//섹션 2-->
        <!--섹션 3-->
        <section class="sec03">
          <div class="inner">
            <!--퀵메뉴-->
            <div class="quick_menu">
              <ul class="quick_menu_ul">
                <li class="quick_menu_li" v-on:click="fnBoardView('board/detail?boardType=2&no=137', 2, -1)">
                  <div class='icon'></div>
                  <div class='txt'>
                    <!-- 목차 이름 변경 (서남아이에스 임현아, 2024-09-11) -->
                    <!-- <p class='quick_menu_ttl'>교육신청방법</p> -->
                    <p class='quick_menu_ttl'>식품위생교육<br/> 제도안내</p>
                  </div>
                </li>
                <!-- 교육신청방법 게시글 번호 변경 (서남아이에스 임현아, 2024-08-02) -->
                <li class="quick_menu_li" v-on:click="fnBoardView('board/detail?boardType=2&no=254' ,2, -1)">
                  <div class='icon'></div>
                  <div class="txt">
                    <p class="quick_menu_ttl">집합교육일정</p>
                  </div>
                </li>
                <li class="quick_menu_li" v-on:click="fnView('/mystudy/print')">
                  <div class="icon"></div>
                  <div class="txt">
                    <p class="quick_menu_ttl">수료증출력</p>
                  </div>
                </li>
                <li class="quick_menu_li" v-on:click="fnBoardView('/info/board' ,4, -1)">
                  <div class='icon'></div>
                  <div class="txt">
                    <p class="quick_menu_ttl">자주 하는 질문</p>
                  </div>
                </li>
              </ul>
            </div>
            <!--//퀵메뉴-->
          </div>
        </section>
        <!--//섹션 3-->
        <!--섹션 4-->
        <section class="sec04">
          <div class="inner">
            <!--공지사항-->
            <div class="home_notice">
              <h1 class="h1">
                <span>공지사항</span>
                <small class="small">한국외식산업협회의 다양한 소식을 알려 드립니다</small>
              </h1>
              <button class="more_btn" @click="fnBoardView('/board' ,2)">
                <span>더보기</span>
              </button>
              <div class="box_wrap">
                <div class="box" v-for="(item, index) in notices"
                     :key="index"
                     v-on:click="fnNoticeDetail(item.noticeNo)"
                    >
                  <h2 class="h2">
                    <span>{{ item.title }}</span>
                  </h2>
                  <p v-html="item.contents" class="box_txt">
                  </p>
                  <p class="date">{{ $moment(item.regDate).format('YYYY.MM.DD') }}</p>
                </div>
              </div>
            </div>
            <!--//공지사항-->
          </div>
        </section>
      </div>
    </div>
    <div class="fab_top">
      <button v-on:click="fnTop" id="MOVE_TOP_BTN">
        <svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" viewBox="0 0 48 48">
          <g><polygon points="14.1,27.3 15.5,28.7 24,20.3 32.5,28.7 33.9,27.3 24,17.3"></polygon></g>
        </svg>
      </button>
    </div>
  </main>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'main-index',
  computed: {
    isSideBar: function() {
      return this.$store.getters['layout/getIsSideBar']
    },
    userData: function() {
      this.fnMainList()
      return this.$store.getters['user/getUserData']
    },
    banner: function() {
      return this.$store.getters['user/getBanner']
    }
  },
  data: function() {
    return {
      userInfo: {},
      info: {
        salesType: -1,
        industryType: -1,
        optionType: -1,
        eduType: -1
      },
      params: {
        limit: 4,
        page: 1,
        noticeType: 2,
        examType: 1
      },
      popParams: {
        page: 1,
        limit: -1,
        isToday: 1,
        popupType: 2
      },
      user: {
        userId: '',
        userPw: ''
      },
      msg: {
        userId: '',
        userPw: ''
      },
      isNotMatchUserPw: true,
      isNotMatchUserId: true,
      notices: [],
      isURL: '0'
    }
  },
  validations: {
    user: {
      userId: {
        test: function(val) {
          if (!this.isNotMatchUserId) {
            return false
          }
          if (!required(val)) {
            this.msg.userId = '아이디를 입력해주세요.'
            return false
          }
          if (val.length < 5) {
            this.msg.userId = '올바르지 않은 아이디 형식입니다.'
            return false
          }
          return true
        }
      },
      userPw: {
        test: function (val) {
          if (!this.isNotMatchUserPw) {
            return false
          }
         if (!required(val)) {
           this.msg.userPw = '비밀번호를 입력해주세요.'
           return false
         }
         if (val.length < 4) {
           this.msg.userPw = '숫자 4자 이상 입력해주세요.'
           return false
         }
         return true
        }
      }
    }
  },
  async created() {
    // window.location.reload()
    const res = await this.$NoticeSvc.fetchUserNoticeList(this.params)
    this.notices = res.entity.list
    await this.fetchPopupList()
  },
  async mounted() {
    await this.fnMainList()
  },
  methods: {
    fnView: function(path) {
      if (path === '/info/contact') {
        alert('준비중입니다.')
        return false
      }
      this.$router.push({ path: path })
      window.scrollTo(0,0)
    },
    fnMainList: async function() {
      const res = await this.$UserSvc.fetchUser()
      if (res.code === 1) {
        this.userInfo = res.entity
      }
      const res2 = await this.$UserSvc.fetchRecentEdu()
      if (res2.code === 1) {
        this.info = res2.entity
      }
    },
    fnList: async function() {
      if (!this.$Util.isEmpty(this.userInfo)) {
        await this.$store.commit('education/setEduType', {eduData: {salesType: this.info.salesType, industryType: this.info.industryType, isOnline: this.info.eduType, optionType: 2 }})
        await this.$router.push({ path: '/apply/list' })
      }
    },
    fnOnOffView: function(path, salesType, industryType, isOnline, optionType) {
      this.$store.commit('education/setEduType', {eduData: {salesType: salesType, industryType: industryType, isOnline: isOnline, optionType: optionType}})
      this.$router.push({ path: path })
    },
    fnBoardView: function(path, boardType, subType){
      if (boardType === 4) {
        this.$store.commit('education/setBoardType', {boardData: {boardType: boardType}})
        this.$store.commit('board/setBoardType', {boardTab: {boardType: 2 , subType: subType}})
        this.$router.push({ path: path })
      } else if (boardType === 1) {
        this.$store.commit('board/setBoardType', {boardTab: {boardType: boardType , subType: subType}})
        this.$router.push({ path: path })
      } else {
        this.$store.commit('education/setBoardType', {boardData: {boardType: boardType}})
        this.$router.push({ path: path })
      }
    },
    fnNoticeDetail: function(noticeNo) {
      this.$router.push({
        path: '/board/detail',
        query: { boardType: 2, no: noticeNo }
      })
    },
    fnLogin: async function() {
      // 초기화
      this.isNotMatchUserPw = true
      this.isNotMatchUserId = true
      this.$v.$touch()
      if (!this.$Util.isValid(this.$v, this.$el)) return false
      const params = {}
      params.userId = this.user.userId
      params.userPw = this.user.userPw
      params.applicationId = 'edu'
      const resDuplicate = await this.$UserSvc.userDuplicateValidCheck(params)
        if(resDuplicate.code === -1013) {// 동시 로그인 방지 기능 생성 (서남아이에스 임현아, 2024-09-09)
          const message = '이미 연결되어있는 홈페이지가 있습니다.<br/>연결을 해제하고 로그인 하시겠습니까?'
          const type = 'checkLoginSession'
          const itemList = {userId : this.user.userId, userPw : this.user.userPw}

          this.$store.commit('layout/setModalAlert', { data: { message, type, itemList }, modalAlertName: this.$modalAlertNames.COMMONCONFIRMINFO })

          return false
        } else {
          const res = await this.$UserSvc.login(params)
          if (res.code === 1) {
          await this.fnMainList()
          if (res.entity.isAuth === 2 || res.entity.isAuth < 0 || res.entity.isAuth === null) {
            alert('협회에서 본인인증을 진행해주시길 바랍니다.')
            await this.$UserSvc.logout()
            return false
            // await this.$router.replace({
            //   path: '/mystudy/list/auth'
            // })
          }
          this.$router.go(this.$router.currentRoute)
        } else {
          if (res.code === -1002) {
            this.isNotMatchUserPw = false
            console.log(res.message)
            this.msg.userPw = res.message
            return false
          } else if (res.code === -1000) {
            this.isNotMatchUserId = false
            this.msg.userId = res.message
            return false
          } else if (res.code === -1005) {
            // 홈페이지 유지보수 개발요청안 | 휴면해제 기능 추가 (서남아이에스 임현아, 2024-07-03)
            this.isNotMatchUserId = false
            this.msg.userId = res.message

            const message = '휴면 회원입니다. 휴면 회원 해제를 진행하시겠습니까?'
            const type = 'changeUserStatus'

            this.$store.commit('layout/setModalAlert', { data: { message, type }, modalAlertName: this.$modalAlertNames.COMMONCONFIRMINFO })

            return false
          } else {
            this.isNotMatchUserId = false
            this.msg.userId = res.message
            return false
          }
        }
      }
    },
    fetchPopupList: async function() {
      const res = await this.$PopupSvc.fetchPopupList(this.popParams)
      if (res.code === 1) {
        this.items = res.entity.list
      }
      this.fnModalAlert()
    },
    fnModalAlert: function() {
      if (this.$Util.isEmpty(this.items[0].imagePath)) return
      // if (!this.$Util.isEmpty(this.$cookies.get('isNone'))) return
      // 여러개의 팝업이 모두 하루보지 않기라면, 팝업 미표출
      // 홈페이지 유지보수 개발요청안 | 팝업 여러개 표출 (서남아이에스 임현아, 2024-07-02)
      var popupCnt = 0;

      for(var i = 0; i < this.items.length; i++){
        if(!this.$Util.isEmpty(this.$cookies.get('isNone'+i))){
          popupCnt += 1;
        }
      }

      if(this.items.length === popupCnt) return

      this.$store.commit('layout/setModalAlert', {
        modalAlertName: this.$modalAlertNames.POPUP,
        data: { items: this.items },
        callBack: () => {
          console.log('hahaha')
        }
      })
    },
    fnTop: function() {
      this.intervalId = setInterval(() => {
        if(window.pageYOffset === 0) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, window.pageYOffset - 50)
      }, 20)
    },
    fnJoin: function() {
      window.open(`${this.$ConstCode.CODE_ENV_LIST.WEB_MOVE_URL}/sign/up/step1`, '_blank')
      // window.open('https://www.kofsia.or.kr/sign/up/step1', '_blank')
      // window.open('http://1.201.137.124:52070/sign/up/step1', '_blank')
    },
    fnLogout: function () {
      if (!confirm('로그아웃하시겠습니까?')) return false
      const params = {}
      params.applicationId = 'edu'
      this.$UserSvc.logout(params).then(res => {
        if(res.code === 1) {
          this.$router.push({path: '/main'})
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
