<template>
  <div class="table_pager">
    <button class="prev" v-on:click="prevBlock"
            :class="{ disabled: pageInfo.page === 1 }"
            v-show="pageInfo.page !== 1"></button>
    <button :class="{'on': value === item}" v-for="item in pageList" :key="item" v-on:click="changePage(item)">{{ item }}</button>
    <button class="next" v-on:click="nextBlock"
            :class="{ disabled: pageInfo.page === pageInfo.totalPage }"
            v-show="pageInfo.page !== pageInfo.totalPage && pageInfo.totalPage > 0"
    ></button>
  </div>
</template>
<script>
export default {
  name: 'base-pagination',
  props: {
    pageInfo: {
      type: Object
    },
    size: {
      type: String,
      default: '',
      description: 'Pagination size'
    },
    align: {
      type: String,
      default: '',
      description: 'Pagination alignment (e.g center|start|end)'
    },
    value: {
      type: Number,
      default: 1
    }
  },
  computed: {
    pageList() {
      return this.$_.range(this.pageInfo.startPage, this.pageInfo.endPage + 1)
    },
    totalPages() {
      if (this.pageCount > 0) return this.pageCount
      if (this.total > 0) {
        return Math.ceil(this.total / this.perPage)
      }
      return 1
    },
    pagesToDisplay() {
      if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
        return this.totalPages
      }
      return this.defaultPagesToDisplay
    }
  },
  data: function() {
    return {
      defaultPagesToDisplay: 5,
      total: this.pageInfo.totalPage,
      perPage: this.pageInfo.rowPerPage,
      pageCount: 0
    }
  },
  methods: {
    range(min, max) {
      const arr = []
      for (let i = min; i <= max; i++) {
        arr.push(i)
      }
      return arr
    },
    changePage(item) {
      this.$emit('change', item)
    },
    nextPage() {
      if (this.value < this.pageInfo.totalPage) {
        this.$emit('change', this.value + 1)
      }
    },
    prevPage() {
      if (this.value > 1) {
        this.$emit('change', this.value - 1)
      }
    },
    prevBlock() {
      if (this.pageInfo.isPrevBlock) {
        this.$emit(
          'change',
          this.pageInfo.pagePerBlock * (this.pageInfo.pageBlock - 1)
        )
      }
    },
    nextBlock() {
      if (this.pageInfo.isNextBlock) {
        this.$emit(
          'change',
          this.pageInfo.pagePerBlock * this.pageInfo.pageBlock + 1
        )
      }
    }
  },
  watch: {
    perPage() {
      this.$emit('change', 1)
    },
    total() {
      this.$emit('change', 1)
    }
  }
}
</script>

<style scoped>

</style>
