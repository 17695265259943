<template>
  <div class='modal_wrap'>
    <div class='modal_inner center wPadding'>
      <div class='modal w100p'>
        <div class='modal_con'>
          <div class='area_calendar'>
            <div class='inner'>
              <div class='box_cld'>
                <div class='years'>
                  <button class='btn_cld_prev' v-on:click='changeMonth(-1)'></button>
                  <p class='date'>
                    <span>{{ params.searchYear }}</span>년 <span>{{ params.searchMonth }}</span>월
                  </p>
                  <button class='btn_cld_next' v-on:click='changeMonth(1)'></button>
                </div>
                <div class='calendar' :key='params.searchMonth'>
                  <table class='tbl tbl_cld'>
                    <colgroup>
                      <col width='14.3'>
                      <col width='14.3'>
                      <col width='14.3'>
                      <col width='14.2'>
                      <col width='14.3'>
                      <col width='14.3'>
                      <col width='14.3'>
                    </colgroup>
                    <thead>
                    <tr>
                      <td>
                        <p>일</p>
                      </td>
                      <td>
                        <p>월</p>
                      </td>
                      <td>
                        <p>화</p>
                      </td>
                      <td>
                        <p>수</p>
                      </td>
                      <td>
                        <p>목</p>
                      </td>
                      <td>
                        <p>금</p>
                      </td>
                      <td>
                        <p>토</p>
                      </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for='index in Math.ceil(list.length / 6)' v-bind:key='index'>
                      <td style='cursor: pointer;'
                          :class="[{'work': item.size > 0}, (cellIndex % 7 === 0 ? 'sunday' : (cellIndex % 7 === 6) ? 'saturday' : '')]"
                          v-for='(item, cellIndex) in list' :key='cellIndex'
                          v-show='(cellIndex / 7) >= (index - 1) && (cellIndex / 7) < index'
                          @click='fnView(item.day, item.size)'>
                        <p>
                          {{ item.day === -1 ? '' : item.day }}
                        </p>
                        <span v-for='(data, index2) in item.tasks' :key='index2'
                              v-show='item.size > 0' style='white-space: pre-line;'>{{ fnDataBind(data.list.location) }}</span>
                        <!--                          <span v-show="item.size > 0">{{ `${item.size} 건` }}</span>-->
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class='form'>
            <ul class='form_ul'>
              <li class='form_li' v-if='data.isModify'>
                <div class='form_ttl'>
                  <p class='essen'>업체명</p>
                </div>
                <div class='form_in'>
                  <div class='inline'>
                    <div class='select'>
                      <select name='' id='' v-model='data.applyEduNo'>
                        <option :value='-1' disabled selected hidden>선택</option>
                        <option v-for='(item, index) in salesList' :key='index' :value='item'>{{ item.edu.businessName
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <p class='valid on' v-show='$v.data.applyEduNo.$error'>필수 입력사항입니다.</p><!--'on 추가'-->
                </div>
              </li>
              <li class='form_li'>
                <div class='form_ttl'>
                  <p class='essen'>지역</p>
                </div>
                <div class='form_in'>
                  <div class='inline'>
                    <div class='select'>
                      <select name='' id='' v-model='data.zipProvinceNo'>
                        <option value='' disabled selected hidden>선택</option>
                        <option
                          v-for='item in $ConstCode.CODE_LOCATION'
                          :key='item.value'
                          :value='item.value'
                        >{{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <p class='valid on' v-show='$v.data.zipProvinceNo.$error'>필수입력사항입니다.</p><!--'on 추가'-->
                </div>
              </li>
            </ul>
          </div>
          <ul class='calendar-text' :key='eduList.eduDate' v-show='isViewer'>
            <li v-for='(item, index) in eduList' :key='index'>
              <div class='text-area'>
                <p class='date'>
                  {{ `${$moment(item.edu.eduDate).format('YYYY.MM.DD')} / 교육시간 ${item.edu.startTime} ~ ${item.edu.endTime}`
                  }}</p>
                <p class='area'>{{ item.list.location }}</p>
              </div>
              <div class='text-area'>
                <p class='sub'>{{ `${item.list.address} ${$Util.isEmpty(item.list.address2) ? '' : item.list.address2}`
                  }}</p>
                <button class='btn' @click="fnCallBack(item, data.isModify ? 'change' : 'select')">선택하기</button>
              </div>
            </li>
          </ul>
        </div>
        <div class='modal_btn'>
          <div class='btn_wrap'>
            <button class='btn bo grey' v-on:click="fnCallBack(null, 'close')">이전으로</button>
            <button class='btn' v-if='data.isModify' v-on:click="fnCallBack(items, 'modify')">변경하기</button>
            <!--              <button class="btn" v-on:click="$emit('eventClose')">확인</button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'modal-alert-calender',
  description: '캘린더',
  props: {
    data: {
      type: [Array, Object],
      default: () => {
      }
    },
    callBack: {
      type: [Function]
    }
  },
  data: function() {
    return {
      isViewer: false,
      dataList: [],
      params: {
        searchYear: '',
        searchMonth: '',
        searchDay: '',
        searchType: 0
      },
      salesParams: {
        limit: -1,
        page: 1,
        searchText: '',
        salesType: '',
        industryType: '',
        isOnline: '',
        isRefund: -1,
        isFinishPayment: 1,
        eduDate: ''
      },
      list: {},
      search: {
        limit: -1,
        page: 1,
        startBuyData: '',
        endBuyDate: ''
      },
      items: [],
      scheduleList: [],
      eduList: [],
      salesList: [],
      eduInfo: []
    }
  },
  validations: {
    data: {
      zipProvinceNo: {
        required
      },
      applyEduNo: {
        test: function(val) {
          if (val < 0) {
            return false
          }
          return true
        }
      }
    }
  },
  created() {
    this.fetchDate()
    if (this.data.isModify) {
      this.fetchList()
    }
  },
  watch: {
    'data.zipProvinceNo': async function() {
      await this.fnLocation()
    },
    'data.applyEduNo': function(item) {
      console.log(item)
      this.eduInfo = item
    }
  },
  methods: {
    fnDataBind: function(val) {
      let text = "";
      if (val.length > 15) {
        text = `${val.slice(0, 16)}\n${val.slice(16, val.length)}`
      } else {
        text = val
      }
      return text
    },
    fetchList: async function() {
      this.salesParams.salesType = this.data.salesType
      this.salesParams.industryType = this.data.industryType
      this.salesParams.eduType = this.data.isOnline
      this.salesParams.eduDate = this.$moment(new Date()).format('YYYYMMDD')
      const res = await this.$OfflineEduSvc.fetchUserOfflineEduList(this.salesParams)
      if (res.code === 1) {
        this.salesList = res.entity.list
      }
    },
    fnView: async function(day, size) {
      this.isViewer = false
      if (this.$Util.isEmpty(size)) {
        return false
      }
      // const eduDate =  String(this.params.searchYear) + String(this.params.searchMonth) + String((day < 10) ? '0' + day : day)
      this.isViewer = true
      this.eduList = this.scheduleList[day]
    },
    changeMonth: async function(type) {
      this.isViewer = false
      this.dataList = []
      this.params.searchType = type
      await this.fetchDate()
    },
    fetchDate: async function() {
      // 달력 일자 구하기
      this.dataList = []
      const res = await this.$OfflineEduSvc.fetchCalender(this.params)
      if (res.code === 1) {
        this.dataList.push(...res.entity.dateList)
        this.params.searchYear = res.entity.searchYear
        this.params.searchMonth = (res.entity.searchMonth < 10) ? '0' + res.entity.searchMonth : String(res.entity.searchMonth)
      }
      await this.fnLocation()
    },
    fnCallBack: function(item, emitType) {
      if (emitType === 'select') {
        this.items = item
        this.$v.$touch()
        if (!this.$Util.isValid(this.$v, this.$el)) return false
      }
      if (emitType === 'modify') {
        this.$v.$touch()
        if (!this.$Util.isValid(this.$v, this.$el)) return false
        this.items.info = this.eduInfo
        if (this.items.info.length === 0) {
          return false
        }
      }
      if (emitType === 'change') {
        this.items = item
        return false
      }
      this.callBack(item, emitType)
      this.$emit('eventClose')
    },
    fnLocation: async function() {
      const params = {}
      params.salesType = this.data.salesType
      params.industryType = this.data.industryType
      params.zipProvinceNo = this.data.zipProvinceNo
      params.isView = 1
      let lastDay = new Date(this.params.searchYear, this.params.searchMonth, 0).getDate()
      let startDate = this.params.searchYear + this.params.searchMonth + '01'
      let endDate = this.params.searchYear + this.params.searchMonth + lastDay
      params.startDate = this.$moment(startDate).format('YYYYMMDD')
      params.endDate = this.$moment(endDate).format('YYYYMMDD')
      const res = await this.$OfflineEduSvc.fetchLocation(params)
      const temp = res.entity
      // 리스트가 있는 경우에만
      if (!this.$Util.isEmpty(temp)) {
        temp.forEach(item => {
          item.eduDate = this.$moment(item.edu.eduDate).format('D')
        })
      }
      this.scheduleList = this.$_.groupBy(temp, 'eduDate')
      const result = this.dataList.map(item => {
        if (!this.$Util.isEmpty(this.scheduleList[item.day])) {
          console.log(this.scheduleList[item.day])
          return {
            day: item.day,
            tasks: this.scheduleList[item.day],
            size: Number(this.scheduleList[item.day].length)
          }
        }
        return item
      })
      this.list = result
    }
  }
}
</script>

<style scoped lang='scss'>
</style>
