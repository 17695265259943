import Env from '@/common/constants/Env.json'
import EnvStage from '@/common/constants/Env_stage.json'
import EnvProd from '@/common/constants/Env_prod.json'
import Util from '@/common/utils/Util'
import queryString from 'query-string'
import store from '@/store'

let CODE_ENV_LIST = Env

if (process.env.NODE_ENV === 'production') {
  CODE_ENV_LIST = EnvProd
} else if (process.env.VUE_APP_MODE === 'stage') {
  CODE_ENV_LIST = EnvStage
}

CODE_ENV_LIST = EnvProd

const CODE_SALES_TYPE = {
  1: { name: '신규영업자', value: 1 },
  2: { name: '기존영업자', value: 2 }
}

const USER_KCB__AUTH_TYPE = {
  'CID': { name: 'CID', value: 1, label: '신용카드' },
  'IDS': { name: 'IDS', value: 2, label: '휴대폰' },
  'TIS': { name: 'TIS', value: 3, label: '아이핀' }
}


const CODE_INDUSTRY_TYPE = {
  1: { name: '일반음식점', value: 1 },
  2: { name: '집단급식소', value: 2 },
  3: { name: '위탁급식업', value: 3 }
}

const CODE_ONLINE_OFFLINE = {
  1: { name: '온라인', value: 1 },
  2: { name: '집합', value: 2 }
}

const CODE_OPTION_TYPE = {
  1: { name: '교육 신청', value: 1 },
  // 홈페이지 유지보수 개발요청안 | 결제내역 => 신청내역 명칭 변경 (서남아이에스 임현아, 2024-07-04)
  // 2: { name: '결제 내역', value: 2 },
  2: { name: '신청 내역', value: 2 },
  3: { name: '교육접수증 출력', value: 3 }
}

const CODE_BOARD_TYPE = {
  1: { name: '강의자료', value: 1 },
  2: { name: '공지사항', value: 2 },
  3: { name: '알림/소식', value: 3 },
  4: { name: '자주하는 질문', value: 3 },
  5: { name: '식품위생교육 실적', value: 3 }
}

const CODE_LOCATION = {
  1: { name: '수도권', value: 1 },
  2: { name: '충청도', value: 2 },
  3: { name: '전라도', value: 3 },
  4: { name: '경상도', value: 4 },
  5: { name: '강원,제주', value: 5 }
}

const CODE_PAYMENT_TYPE = {
  CARD: {label: '카드결제', value: 1},
  PHONE: {label: '휴대폰결제', value: 2},
  VIRTUAL: {label: '가상계좌입금', value: 3},
  REAL_ACCOUNT: {label: '실시간 계좌이체', value: 4},
  ADMIN: {label: '관리자 결제', value: 5}
}

let windowObj
let vmFunction
let vmFunctionName
window.addEventListener('message', function ({data}) {
  if (!Util.isEmpty(data.source) && data.source === vmFunctionName) {
    // 콜백 message 처리
    if (windowObj) {
      windowObj.close()
    }
    vmFunction(data.params)
  }
})

const ConstCode = function() {
  return {
    CODE_ENV_LIST,
    CODE_SALES_TYPE,
    CODE_INDUSTRY_TYPE,
    CODE_OPTION_TYPE,
    CODE_ONLINE_OFFLINE,
    CODE_BOARD_TYPE,
    CODE_LOCATION,
    USER_KCB__AUTH_TYPE,
    CODE_PAYMENT_TYPE,
    getImagePath: function(path = '', size = '480/') {
      if (Util.isEmpty(path)) return ''
      return `${this.CODE_ENV_LIST.FILE_SERVER_URL}${size}${path}`
    },
    fnImageExpanding: function(path = '') {
      if (Util.isEmpty(path)) return false
      window.open(`${this.CODE_ENV_LIST.FILE_SERVER_URL}${path}`, '_blank')
    },
    openPayment: function(callbackFunction = {}, callbackFunctionName = '', params = {}, option = '') {
      console.log('callbackFunction', callbackFunction)
      console.log('callbackFunctionName', callbackFunctionName)
      console.log('params', params)
      console.log('option', option)
      vmFunction = callbackFunction
      vmFunctionName = callbackFunctionName
      if (params.paymentType === this.CODE_PAYMENT_TYPE.VIRTUAL.value) {
        windowObj = window.open(`${this.CODE_ENV_LIST.PAYMENT_VIRTUAL_URL}?${queryString.stringify(params)}`, '_blank', option)
      } else {
        windowObj = window.open(`${this.CODE_ENV_LIST.PAYMENT_URL}?${queryString.stringify(params)}`, '_blank', option)
      }

      store.commit('layout/setModalAlert', {isModalAlert: true, isHideHeader: false})
      this.checkPaymentWindow()
    },
    closePayment() {
      store.commit('layout/setModalAlert', {isModalAlert: false, isHideHeader: false})
    },
    getMoveUrl: function() {
      return this.CODE_ENV_LIST.WEB_MOVE_URL
    },
    checkPaymentWindow() {
      let interval = window.setInterval(() => {
        try {
          if (windowObj == null || windowObj.closed) {
            window.clearInterval(interval)
            this.closePayment()
          }
        }
        catch (e) {
          console.log(e)
        }
      }, 200);
    }
  }
}

export default ConstCode()
