<template>
  <div class="modal_inner center w1200">
    <div class="modal_con">
      <div class="modal_txt" style="padding: 100px;">
        <p v-html="data.message">
        </p>
      </div>
      <div class="modal_btn">
        <div class="btn_wrap">
          <button class="btn" v-on:click="fnYes(data.itemList.isOnline, data.itemList.applyEduNo)"><p>예</p></button>
          <button class="btn bo grey" v-on:click="fnNo()"><p>아니오</p></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal-alert-confirm',
  props: {
    data: {
      type: [Array,Object],
      default: () => {}
    },
    callBack: {
      type: [Function]
    },
    confirmLabel: {
      type: [String],
      default: '확인'
    },
    cancelLabel: {
      type: [String],
      default: '취소'
    }
  },
  methods: {
    fnYes : function(isOnline, applyEduNo){      
      // 홈페이지 유지보수 개발요청안 | 교육 취소 기능 추가 (서남아이에스 임현아, 2024-07-01)
      let params2 = {}
      params2.applyEduNo = applyEduNo

      if(isOnline === 1){
        this.$OnlineEduSvc.deleteData(params2).then(res => {
        if (res.code === 1) {
          alert('교육신청이 취소되었습니다.')
          this.$emit('eventClose')
          this.$router.go(this.$router.currentRoute)
        } else {
          alert(res.message)
          this.$emit('eventClose')
          this.$router.go(this.$router.currentRoute)
        }
      })}else{
        this.$OfflineEduSvc.deleteData(params2).then(res => {
        if (res.code === 1) {
          alert('교육신청이 취소되었습니다.')
          this.$emit('eventClose')
          this.$router.go(this.$router.currentRoute)
        } else {
          alert(res.message)
          this.$emit('eventClose')
          this.$router.go(this.$router.currentRoute)
          }
        })
      }
    },
    fnNo : function(){
      this.$emit('eventClose')
    }
  }
}
</script>

<style scoped>

</style>
