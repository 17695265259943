<template>
  <div>
    <info-tab-index />
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <div class="lnb">
            <ul>
              <li><p class="home"></p></li>
              <li><p>고객센터</p></li>
              <li><p>자주하는 질문</p></li>
            </ul>
          </div>
          <div class="page_ttl">
            <h1 class="h1">
              <span>{{ $ConstCode.CODE_BOARD_TYPE[boardType.boardType].name }}</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="table_search">
              <div class="select">
                <select name="" id="">
                  <option value="">제목</option>
                </select>
              </div>
              <div class="search_wrap">
                <div class="input">
                  <input type="search" placeholder="검색" v-model="params.searchText" v-on:keyup.enter="fetchData(1)">
                </div>
                <button class="btn" v-on:click="fetchData(1)">검색</button>
              </div>
            </div>
            <div class="table hover"><!--'게시판: hover'-->
              <div class="row head">
                <div class="cell fix150"><p>번호</p></div>
                <div class="cell"><p>제목</p></div>
                <div class="cell fix240"><p>등록일</p></div>
              </div>
              <div class="empty_wrap" v-if="list.length === 0">
                <div class="empty">
                  <span>글이 존재하지 않습니다.</span>
                </div>
              </div>
              <div class="row" :class="{'noty': item.isTop === 1}" v-for="(item, index) in list" :key="index">
                <div class="cell fix150"><p>{{ item.isTop === 1 ? '공지' : pageInfo.virtualNum - index }}</p></div>
                <div class="cell">
                  <p class="left">
                    <!--아래 영역 클릭하면 이동하도록 해주세요-->
                    <span class="board_ttl" v-html="item.title" v-on:click="fnView(item)"></span>
                  </p>
                </div>
                <div class="cell fix240"><p class="date">{{ $moment(item.regDate).format('YYYY.MM.DD') }}</p></div>
              </div>
            </div>
            <base-pagination
              v-model="pageInfo.page"
              v-on:input="fetchData"
              v-on:change="fetchData"
              v-bind:pageInfo="pageInfo"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import BasePagination from '@/components/common/base/BasePagingation'
import InfoTabIndex from '@/components/common/info/tab-index'

export default {
  name: 'info-board-index',
  description: '게시판 - 자주하는 질문',
  components: { InfoTabIndex, BasePagination },
  computed: {
    boardType: function() {
      return this.$store.getters['education/getBoardType']
    }
  },
  data: function () {
    return {
      params: {
        listType: 2,
        boardType: 4,
        limit: 10,
        page: 1,
        examType: 1,
        searchText: ''
      },
      pageInfo: {},
      list: []
    }
  },
  async created() {
    this.params.boardType = this.boardType.boardType
    const res = await this.$BoardSvc.fetchBoardTypeList(this.params)
    this.list = res.entity.list
    this.pageInfo = res.entity.pageInfo
  },
  methods: {
    fetchData: async function ( page = 1) {
      this.params.page = page
      this.params.boardType = this.boardType.boardType
      const res = await this.$BoardSvc.fetchBoardTypeList(this.params)
      this.list = res.entity.list
      this.pageInfo = res.entity.pageInfo
    },
    fnView: function(item) {
      let no = -1
      const boardType = this.boardType.boardType
      if (boardType === 1) no = item.classNo
      if (boardType === 2) no = item.noticeNo
      if (boardType === 3) no = item.dataNo
      if (boardType === 4) no = item.faqNo
      if (boardType === 5) no = item.foodNo
      this.$router.push ({
        path: '/board/detail',
        query: {  boardType: boardType, no: no, isCenter: 1 }
      })
    },
    fnBoard: function(path, boardType) {
      this.$store.commit('education/setBoardType', {boardData: {boardType: boardType}})
      this.$router.push({ path: path })
      this.fetchData()
    },
    fnViews: function(path) {
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped>

</style>
