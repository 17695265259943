<template>
  <div>
    <board-tab-index :board-type='1' @fetchData='fetchData(1)'/>
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <div class="lnb">
            <ul>
              <li><p class="home"></p></li>
              <li><p>나의 학습방</p></li>
              <li><p>수료증 출력</p></li>
            </ul>
          </div>
          <div class="page_ttl">
            <h1 class="h1">
              <span>수료증 출력</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="table_toggle">
              <div class="btn_wrap" v-if="isOnline">
                <button class="toggle_btn on"><span>온라인 교육</span></button><!--'선택 시: on'-->
                <button class="toggle_btn" v-on:click="fnChange"><span>집합 교육</span></button>
              </div>
              <div class="btn_wrap" v-if="!isOnline">
                <button class="toggle_btn" v-on:click="fnChange"><span>온라인 교육</span></button>
                <button class="toggle_btn on"><span>집합 교육</span></button>
              </div>
            </div>
            <div class="table">
              <div class="row head">
                <div class="cell fix180"><p>주문번호</p></div>
                <div class="cell"><p>강의명</p></div>
                <div class="cell fix180"><p>업체명</p></div>
                <div class="cell fix180"><p>수료상태</p></div>
                <div class="cell fix180"><p>수료증</p></div>
              </div>
              <div class="page_con" v-show="list.length === 0">
                <div class="empty_wrap">
                  <div class="empty">
                    <span>내역이 존재하지 않습니다.</span>
                  </div>
                </div>
              </div>
              <div class="row" v-for="(item, index) in list" :key="index">
                <div class="cell fix180" v-if="isOnline"><p>{{ item.edu.onlineEduCode }}</p></div>
                <div class="cell fix180" v-else><p>{{ item.edu.offlineEduCode }}</p></div>
                <div class="cell"><p class="left">
                  {{ $ConstCode.CODE_SALES_TYPE[item.edu.salesType].name }} ({{ $ConstCode.CODE_INDUSTRY_TYPE[item.edu.industryType].name }})
                </p></div>
                <div class="cell fix180"><p>{{ item.edu.businessName }}</p></div>
                <div class="cell fix180" v-if="isOnline">
                  <p class="state" :class="{light_grey : item.edu.isEnd === 2}">
                  {{ item.edu.isEnd === 1 ? '수료' : '미수료' }}
                  </p>
                </div>
                <div class="cell fix180" v-else>
                  <p class="state" :class="{light_grey : item.class.isEnd === 2}">
                    {{ item.edu.isEnd === 1 ? '수료' : '미수료' }}
                  </p>
                </div>
                <div class="cell fix180">
                  <button class="btn bo" v-on:click="fnPrint(item)" v-if="isOnline" :disabled="item.edu.isEnd === 2 || item.edu.positionType === 2 && item.edu.isHygiene !== 1">출력하기</button>
                  <button class="btn bo" v-on:click="fnPrint(item)" v-else :disabled="item.edu.isEnd === 2 || item.edu.positionType === 2 && item.edu.isHygiene !== 1">출력하기</button>
                </div>
              </div>
            </div>
            <base-pagination
              v-model="pageInfo.page"
              v-on:input="fetchData"
              v-on:change="fetchData"
              v-bind:pageInfo="pageInfo"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import BasePagination from '@/components/common/base/BasePagingation'
import BoardTabIndex from '@/components/common/board/tab-index'

export default {
  name: 'mystudy-print',
  description: '나의학습방 수료증출력',
  components: { BoardTabIndex, BasePagination },
  data() {
    return {
      isOnline: true,
      params: {
        page: 1,
        limit: 10,
        isEnd: 1
      },
      pageInfo: {},
      list: [],
      type: -1,
    }
  },
  computed: {
    eduType: function() {
      return this.$store.getters['education/getEduType']
    }
  },
  async created() {
    let res = {}
    if (this.eduType.isOnline === 1) {
      res = await this.$OnlineClassSvc.fetchUserOnlineClass(this.params)
      console.log('res', res)
      this.isOnline = true
    } else {
      res = await this.$OfflineEduSvc.fetchUserOfflineClass(this.params)
      this.isOnline = false
    }
    if (res.code === 1) {
      this.list = res.entity.list
      this.pageInfo = res.entity.pageInfo
    }
  },
  methods: {
    fetchData: async function(page) {
      this.params.page = page
      let res = {}
      if (this.eduType.isOnline === 1) {
        res = await this.$OnlineClassSvc.fetchUserOnlineClass(this.params)
        this.isOnline = true
      } else {
        res = await this.$OfflineEduSvc.fetchUserOfflineClass(this.params)
        this.isOnline = false
      }
      if (res.code === 1) {
        this.list = res.entity.list
        this.pageInfo = res.entity.pageInfo
      }
    },
    fnChange: function() {
      // 탭 변경 (on/off)
      this.isOnline = !this.isOnline
      // 현재 isOnline 타입 넣어주기
      if(this.isOnline === true) this.type = 1
      else this.type = 2
      this.$store.commit('education/setEduType', {eduData: {isOnline: this.type}})
      this.fetchData()
    },
    fnPrint: function(item) {
      this.$store.commit('layout/setModalAlert', {data: { item: item, isOld: false }, modalAlertName: this.$modalAlertNames.CERTIFICATION})
    },
    fnView: function(path) {
      this.$router.push({ path: path })
    },
    fnBoard: function(path, boardType) {
      this.$store.commit('education/setBoardType', { boardData: { boardType: boardType } })
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped>

</style>
