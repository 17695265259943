<template>
  <div>
    <board-tab-index :board-type='1' @fetchData='fetchData' />
    <main class="main" role="main">
      <div class="page">
        <div class="inner">
          <div class="lnb">
            <ul>
              <li><p class="home"></p></li>
              <li><p>나의 학습방</p></li>
              <li><p>복습하기</p></li>
            </ul>
          </div>
          <div class="page_ttl">
            <h1 class="h1">
              <span>복습하기 목록</span>
            </h1>
          </div>
          <div class="page_con">
            <div class="rule_list_ttl">
              <h3 class="h3">{{ form.info.businessName }}</h3>
            </div>
            <div class="table">
              <div class="row head">
                <div class="cell"><p>강의명</p></div>
                <div class="cell fix180"><p>학습시간</p></div>
                <div class="cell fix180"><p>강의 총시간</p></div>
                <div class="cell fix180"><p>수강하기</p></div>
              </div>
              <div class="row" v-for="(item, index) in list" :key="index">
                <div class="cell">
                  <p class="left">
                    <!-- 한국외식산업협회 위생교육 수강 페이지 강의명 하이라이트 (서남아이에스 임현아, 2024-08-30) -->
                    <!-- 문구 변경 (서남아이에스 임현아, 2024-11-19) -->
                    <span>[</span>
                    <span v-if="item.isChoice === 1" style="color:red">필수</span>
                    <!-- <span v-else-if="item.isChoice === 2" style="color:green">선택</span> -->
                    <span v-else-if="item.isChoice === 2" style="color:grey">자율선택</span>
                    <span v-else><span style="color:red">필수</span><span style="color:blue">선택</span></span>
                    <span style="margin-right: 5px;">] </span>
                    <span style="font-weight: 700;">{{item.className}}</span>
                    <!-- {{ `[${item.isChoice === 1 ? '필수' : item.isChoice === 2 ? '선택': '필수(선택)'}] ${item.className}` }} -->
                  </p>
                  <!-- <p class="left">{{ `[${item.isChoice === 1 ? '필수' : item.isChoice === 2 ? '선택': '필수(선택)'}] ${item.className}` }}</p> -->
                </div>
                <div class="cell fix180"><p>{{ $Util.isEmpty(item.studyTime) ? '' : $Util.secondsToTimes2(item.studyTime) }}</p></div>
                <div class="cell fix180"><p>{{ item.classTime }}</p></div>
                <div class="cell fix180">
                  <!-- 찾아가는 위생 교육 | 교육 구분 표출 변경 (서남아이에스 임현아, 2024-08-08) -->
                  <!-- <button
                    class="btn bo activate"
                    v-if="!$Util.isEmpty(form.code) && form.code.code !== 999"
                    :disabled="item.status === 2 && item.isChoice !== 2"
                    @click="fnVideo(item.onlineClassNo, item.onlineEduNo)"
                  >수강하기</button> -->
                  <button
                    class="btn bo activate"
                    v-if="!$Util.isEmpty(form.code) && form.code.code !== 999"
                    @click="fnVideo(item.onlineClassNo, item.onlineEduNo)"
                  >수강하기</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import BoardTabIndex from '@/components/common/board/tab-index'
export default {
  name: 'mystudy-list-studyroom',
  components: { BoardTabIndex },
  description: '나의학습방 재수강목록 학습방',
  data() {
    return {
      form: {},
      list: [],
      education: {},
      params: {
        onlineClassNo: -1,
        onlineEduNo: -1,
        ipAddress: '',
        studyTime: '',
        status: 1,
        isStart: 0
      },
      currentIndex: 1
    }
  },
  mounted() {
    window.addEventListener('keydown', this.fnUpdateVideo)
  },
  async created() {
    this.$setPopupTerminal(this)
    const params = {}
    params.onlineEduNo = Number(this.$route.query.onlineEduNo)
    params.applyEduNo = Number(this.$route.query.applyEduNo)
    params.status = this.params.status
    const res = await this.$OnlineEduSvc.fetchData(params)
    if (res.code === 1) {
      this.form = res.entity
      this.list = res.entity.class
    }
  },
  computed: {
    userData: function() {
      console.log(this.$store.getters['user/getUserData'])
      return this.$store.getters['user/getUserData']
    },
    // 강의상태(1:수료, 2:미수료)가 미수료일경우 리턴 false (버튼 활성화)
    classStatus: function() {
      let classStatus = 0
      this.list.forEach(item => {
        if(item.status === 2) {
          classStatus++
        }
      })
      return classStatus
    }
  },
  methods: {
    fnUpdateVideo: function() {
      if( (event.ctrlKey === true && (event.keyCode === 78 || event.keyCode === 82)) || (event.keyCode === 116) ) {
        this.fetchData()
      }
    },
    fnView: function(path) {
      this.$router.push({
        path: path,
        query: {onlineEduNo: this.form.info.onlineEduNo}
      })
    },
    fnBoard: function(path, boardType) {
      this.$store.commit('education/setBoardType', { boardData: { boardType: boardType } })
      this.$router.push({ path: path })
    },
    fnVideo: function (onlineClassNo, onlineEduNo) {
      const option = "width=720, height=415, top=100, scrollbars, resizable"
      this.$windowOpenPop(`${this.$ConstCode.CODE_ENV_LIST.WEB_URL}/edu/replay/video?onlineClassNo=${onlineClassNo}&onlineEduNo=${onlineEduNo}`, this, option)
      // this.$windowOpenPop(`https://www.kfoodedu.or.kr/edu/replay/video?onlineClassNo=${onlineClassNo}&onlineEduNo=${onlineEduNo}`, this, option)
      // this.$windowOpenPop(`http://1.201.137.124:58202/edu/replay/video?onlineClassNo=${onlineClassNo}&onlineEduNo=${onlineEduNo}`, this, option)
      let test = window.setInterval(() => {
        try {
          if ( this.$open == null ||  this.$open.closed) {
            this.fetchData()
            window.clearInterval(test)
          }
        } catch (e) {
          console.log('ddd')
        }
      }, 1000)
    },
    fetchData: async function () {
      const params = {}
      params.onlineEduNo = Number(this.$route.query.onlineEduNo)
      params.applyEduNo = Number(this.$route.query.applyEduNo)
      params.status = this.params.status
      const res = await this.$OnlineEduSvc.fetchData(params)
      if (res.code === 1) {
        this.form = res.entity
        this.list = res.entity.class
      }
    }
  }
}
</script>

<style scoped>

</style>
