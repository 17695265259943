'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
import { forEach } from 'lodash'
/**
 * 공통 API Service
 * @class OnlineEduSvc
 */
class OnlineEduSvc extends ServiceExec {

  /**
   *  온라인교육신청 등록
   *  @param Authorization {string} Authorization
   *  @param file {file} file
   *  @param onlineEduBean {OnlineEduBean} onlineEduBean
   */
  upsertOnlineEdu(params = {}) {
    const formData = new FormData()
    formData.append(
      'onlineEduBean',
      new Blob([JSON.stringify(params)], { type: 'application/json' })
    )
    return this
      .multiPost(`/api/online/edu/upsertOnlineEdu`, formData)
      .then(response => {
        return response.data
      })
  }

  /**
   *  온라인 회원 신청 리스트
   *  @param Authorization {string} Authorization
   *  @param industryType {integer}
   *  @param isEnd {integer}
   *  @param limit {integer} 10
   *  @param page {integer} 1
   *  @param salesType {integer}
   *  @param searchText {string}
   */
  fetchUserOnlineList(params = {}) {
    return this
      .get(`/api/online/edu/fetchUserOnlineList`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  온라인 회원 신청 삭제
   *  @param applyEduNo {integer} applyEduNo
   */
  // 홈페이지 유지보수 개발요청안 | 교육 취소 기능 추가 (서남아이에스 임현아, 2024-07-02)
  deleteData(params = {}) {
    return this
      .delete(`/api/online/edu/deleteData`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  온라인 신청목록 상세페이지
   *  @param onlineEduNo {integer} onlineEduNo
   *  @param token {} token
   */
  fetchData(params = {}) {
    return this
      .get(`/api/online/edu/fetchData`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  회원 온라인 강의 리스트
   *  @param Authorization {string} Authorization
   *  @param limit {integer} 10
   *  @param page {integer} 1
   *  @param salesType {integer}
   */
  fetchOnlineClassList(params = {}) {
    return this
      .get(`/api/user/class/fetchOnlineClassList`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  온라인 위생관리자 지정서 변경
   *  @param Authorization {string} Authorization
   *  @param file {file} file
   *  @param isHygiene {} isHygiene
   *  @param onlineEduNo {} onlineEduNo
   */
  updateUserOnlineInfo(params = {}) {
    const formData = new FormData()
    forEach(params, function(value, key) {
      formData.append(key, value)
    })
    formData.append('file', params.file)
    return this
      .multiPost(`/api/online/edu/updateUserOnlineInfo`, formData)
      .then(response => {
        return response.data
      })
  }


  /**
   *  온라인교육신청 강의상태 변경
   *  @param eduCode {string} eduCode
   *  @param isEnd {integer} isEnd
   *  @param onlineEduNo {integer} onlineEduNo
   *  @param token {} token
   *  @param userOnlineNo {integer} userOnlineNo
   */
  updateStudyStatus(params = {}) {
    return this.get(`/api/online/edu/updateStudyStatus`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  온라인교육신청 출력상태 값 변경
   *  @param onlineEduNo {integer} onlineEduNo
   *  @param token {} token
   */
  updatePrintStatus(params = {}) {
    return this.get(`/api/online/edu/updatePrintStatus`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  지정 확인서 제출 파일 삭제
   *  @param Authorization {string} Authorization
   *  @param onlineEduNo {integer} onlineEduNo
   */
  updateHygieneFile(params = {}) {
    return this.get(`/api/online/edu/updateHygieneFile`, params)
      .then(response => {
        return response.data
      })
  }

}
export default new OnlineEduSvc()
