export default {
  data: function () {
    return {
      zIndex: 2010
    }
  },
  computed: {
    isCloseModal: function () {
      return this.$store.getters['layout/getIsCloseModal']
    },
    modalState: function () {
      let modal = this.$store.getters['layout/getModal']
      return modal
    },
    isShow: function () {
      let isShow = this.$store.getters['layout/getIsShowModal']
      this.$Util.scrollLock(isShow)
      if (this.modalState.isHideHeader) {
        this.$store.commit('layout/setIsHideHeader', {isHideHeader: isShow})
      }
      return isShow
    }
  },
  watch: {
    isCloseModal: function (isClose) {
      if (isClose) window.fnCloseModal()
    }
  },
  methods: {
    fnCloseModal: function () {
      window.fnCloseModal()
    }
  }
}
