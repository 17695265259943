'use strict'
// import '@babel/polyfill'
import 'babel-polyfill'
import ServiceExec from '@/common/utils/ServiceExec'
/**
 * 공통 API Service
 * @class OldEduSvc
 */
class OldEduSvc extends ServiceExec {

  /**
   *  전년도수료기록 상세보기
   *  @param uid {integer} uid
   */
  fetchOldData(params = {}) {
    return this
      .get(`/api/old/edu/fetchOldData`, params)
      .then(response => {
        return response.data
      })
  }

  /**
   *  전년도 수료기록 조회
   *  @param cname {string} cname
   *  @param endDate {string} endDate
   *  @param limit {integer} limit
   *  @param page {integer} page
   *  @param salesType {string} salesType
   *  @param searchText {string} searchText
   *  @param startDate {string} startDate
   *  @param ubir {string} ubir
   *  @param uname {string} uname
   *  @param year {integer} year
   */
  fetchUserOldEdu(params = {}) {
    return this
      .get(`/api/old/edu/fetchUserOldEdu`, params)
      .then(response => {
        return response.data
      })
  }
}
export default new OldEduSvc()
