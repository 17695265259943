<template>
  <nav id="nav_id" class="nav">
    <div class="inner">
      <div class="nav_top">
        <div class="inner">
          <h1 class="h1" v-show="boardType !== 1">
            <span>고객센터</span>
          </h1>
          <h1 class="h1" v-show="boardType === 1">
            <span>나의 학습방</span>
          </h1>
        </div>
      </div>
      <div class="nav_btm">
        <div class="inner">
          <div class="line dep01" v-if="boardType === 1">
            <div class="inner">
              <ul>
                <li><button :class="{on : $route.path == '/mystudy/list' || $route.path == '/mystudy/list/studyroom'}" v-on:click="fnViews('/mystudy/list')"><span>수강하기</span></button></li>
                <li><button :class="{on : $route.path == '/mystudy/print'}" v-on:click="fnViews('/mystudy/print')"><span>수료증 출력</span></button></li>
                <li><button :class="{on : $route.path == '/mystudy/confirmation'}" v-on:click="fnViews('/mystudy/confirmation')"><span>지정확인서 제출</span></button></li>
                <li><button :class="{on : $route.path == '/mystudy/replay/list' || $route.path == '/mystudy/replay/view'}" v-on:click="fnViews('/mystudy/replay/list')"><span>복습하기</span></button></li>
                <li><button :class="{on : $route.path == '/board' || $route.path == '/board/detail'}" v-on:click="fnBoard('/board', 1)" ><span>강의 자료</span></button></li>
              </ul>
            </div>
          </div>
          <div class="line dep01" v-if="boardType !== 1">
            <div class="inner">
              <ul>
                <li><button :class="{ on : tabType.boardType === 6 }" v-on:click="fnMove('/board',6, -1)"><span>공지사항</span></button></li>
                <li><button :class="{ on : tabType.boardType === 7 }" v-on:click="fnMove('/board',7, -1)"><span>알림/소식</span></button></li>
                <li><button :class="{on : tabType.boardType === 1 }"  @click="fnMove('/info/online', 1 , 1)"><span>교육 안내</span></button></li>
                <li><button :class="{on : tabType.boardType === 2 }"  @click="fnMove('/info/board', 2 , -1)"><span>자주하는 질문</span></button></li>
                <!-- 고객센터 목차명 변경 및 숨김처리 요청 1:1 게시판/신고센터로 통합, 원격지원 숨김 (서남아이에스 임현아, 2024-08-30) -->
                <li><button :class="{on : tabType.boardType === 3 }"  @click="fnMove('/support/board', 3 , -1)"><span>1:1 게시판 / 신고센터</span></button></li>
                <!-- <li><button :class="{on : tabType.boardType === 3 }"  @click="fnMove('/support/board', 3 , -1)"><span>1:1 게시판</span></button></li> -->
                <!-- <li><button :class="{on : tabType.boardType === 4 }"  @click="fnMove('/report/board', 4 , -1)"><span>신고센터</span></button></li> -->
                <!-- <li><button :class="{on : tabType.boardType === 5 }"  @click="fnMove('/support/remote', 5 , -1)"><span>원격지원</span></button></li> -->
              </ul>
            </div>
          </div>
          <div class="line dep02" v-show='boardType !== 1 && tabType.subType > 0'>
            <div class="inner">
              <ul>
                <li><button :class="{on : tabType.subType === 1 }" @click="fnMove('/info/online', 1 , 1)"><span>온라인 위생교육 안내</span></button></li>
                <li><button :class="{on : tabType.subType === 2 }" @click="fnMove('/info/offline', 1 , 2)"><span>집합 위생교육 안내</span></button></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'board-tab-index',
  props: {
    boardType: {
      type: Number,
      default: 1
    }
  },
  computed: {
    tabType: function() {
      return this.$store.getters['board/getBoardType']
    }
  },
  watch: {
    'boardType': function() {
      this.$emit('fetchData')
    }
  },
  methods: {
    fnBoard: function(path, boardType) {
      this.$store.commit('education/setBoardType', {boardData: {boardType: boardType}})
      this.$router.push({ path: path })
      this.fetchData()
    },
    fnViews: function(path) {
      this.$router.push({ path: path })
    },
    fnMove: function(path, boardType, subType) {
      if (boardType === 2) {
        this.$store.commit('education/setBoardType', {boardData: {boardType: 4}})
      }
      if (boardType === 6) {
        this.$store.commit('education/setBoardType', {boardData: {boardType: 2}})
      }
      if (boardType === 7) {
        this.$store.commit('education/setBoardType', {boardData: {boardType: 3}})
      }
      this.$store.commit('board/setBoardType', {boardTab: {boardType: boardType, subType: subType}})
      this.$router.push({ path: path })
    }
  }
}
</script>

<style scoped>

</style>
