<template>
  <div id="video_container" class="full" :ref="'container'">
    <video :ref="'video'" id="video_player" preload="auto" autoplay webkit-playshinline="true" playsinline="true" type="video/mp4" v-show="!$Util.isEmpty(form)">
      <source :src="$ConstCode.getImagePath(form.class.filePath, 'origin/')" type="video/mp4">
    </video>
    <div id="video_player_cover" v-on:mouseover="fnShow"></div>
    <div id="video_controller" :class="{'hide': hide }" v-on:mouseover="fnControllShow" v-on:mouseout="fnControllHide">
      <div class="progress">
        <div class="bar" :style="`--video-bar-width:${this.bar}%;`" :ref="'bar'"></div>
      </div>
      <div class="control">
        <div class="action" :class="isPlay ? 'pause' : 'play'" ><!--'재생: play', '일시정지: pause'-->
          <button @click="fnPlay"></button><!--play-->
          <button @click="fnPlay"></button><!--pause-->
          <p class="time">
            <span>{{ startTime }}</span>
            <span class="slash">/</span>
            <span>{{ endTime }}</span>
          </p>
        </div>
        <div class="volume">
          <div class="icon"></div>
          <div class="range">
            <input type="range" min="1" max="100" v-model="volume">
          </div>
        </div>
        <div class="screen" :class="isFullScreen ? 'to_max' : 'to_min'"><!--'전체화면: to_max', '원래화면: to_min'-->
          <button @click="fnMaxSize"></button><!--to_max-->
          <button @click="fnMaxSize"></button><!--to_min-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'edu-view',
  data() {
    return {
      form: {},
      volume: 50,
      isPlay: true,
      isFullScreen: true,
      video: '',
      bar: 0,
      // 중간 단위 변경 (서남아이에스 임현아, 2024-10-18)
      pausedInt: 40 * 60,
      pauseTime: 0,
      startTime: 0,
      endTime: '',
      hide: '',
      ip: '',
      studyTime: '',
      status: 2,
      mouse: false,
      isStart: 0,
      time: '',
      study: null,
      isNormalPath: false
    }
  },
  beforeDestroy() {
    clearInterval(this.study)
    window.addEventListener('beforeunload', () => {
      this.$cookies.remove('studying')
    });
  },
  mounted() {
    if (!this.isNormalPath) return
    setTimeout(() => {
      const video = document.getElementsByTagName('video')[0]
      if (video.currentTime.toFixed(0) < Number(this.form.info.studyTime)) {
        video.currentTime = this.studyTime
      }
      this.bar = (100 / video.duration) * video.currentTime
      this.startTime = this.$Util.secondsToTimes(video.currentTime.toFixed(0))
      this.endTime = this.$Util.secondsToTimes(video.duration.toFixed(0))
    }, 200)
    this.study = setInterval(()=> {
      this.fnTimeCheck()
      const video = document.getElementsByTagName('video')[0]
      const values = {}
      values.ipAddress = this.ip
      values.studyTime = video.currentTime.toFixed(0)
      values.status = this.status
      values.isStart = this.isStart
      values.onlineClassNo = this.form.info.onlineClassNo
      values.onlineEduNo = this.$route.query.onlineEduNo
      window.opener.app.$root.$PopupTerminal.getReturnValue(values)
      if (video.currentTime.toFixed(0) < Number(this.form.info.studyTime)) {
        video.currentTime = this.studyTime
      }
      this.bar = (100 / video.duration) * video.currentTime
      this.startTime = this.$Util.secondsToTimes(video.currentTime.toFixed(0))
      this.endTime = this.$Util.secondsToTimes(video.duration.toFixed(0))
      window.addEventListener('keydown', this.fnNoKeyCode)
    }, 500)
  },
  async created() {
    if (! await this.fnCheckPath()) {
      return false
    }
    const params = {}
    params.onlineClassNo = this.$route.query.onlineClassNo
    params.onlineEduNo = this.$route.query.onlineEduNo
    const res = await this.$OnlineClassSvc.fetchUserOnlineClassData(params)
    if (res.code === 1) {
      this.form = res.entity
    }
    this.studyTime = Number(this.form.info.studyTime)
    const ip = await this.$CommonSvc.ip()
    this.ip = ip
    this.isStart = 1
    this.onlineEduNo = this.$route.query.onlineEduNo
    await this.fnUpdate()
  },
  watch: {
    volume: function(val) {
      const video = this.$refs.video
      video.volume = val * 0.01
    }
  },
  methods: {
    fnCheckPath: async function() {
      if (this.$Util.isEmpty(document.referrer) || !document.referrer.match('studyroom')) {
        this.isNormalPath = false
        alert('비정상적인 접근입니다.')
        await this.$router.replace({
          path: '/mystudy/list'
        })
        return false
      }
      this.isNormalPath = true
      return true
    },
    fetchData: async function() {
      const params = {}
      params.onlineClassNo = this.$route.query.onlineClassNo
      params.onlineEduNo = this.$route.query.onlineEduNo
      const res = await this.$OnlineClassSvc.fetchUserOnlineClassData(params)
      if (res.code === 1) {
        this.form = res.entity
      }
      this.studyTime = Number(this.form.info.studyTime)
      const ip = await this.$CommonSvc.ip()
      this.ip = ip
      this.isStart = 1
      this.onlineEduNo = this.$route.query.onlineEduNo
      await this.fnUpdate()
    },
    fnPlay: function() {
      this.isPlay = !this.isPlay
      const video = this.$refs.video
      if (video.paused) {
        video.play()
        this.isStart = 1
        this.fnUpdate()
      } else {
        video.pause()
        this.isStart = 0
        this.fnUpdate()
      }
    },
    fnNoKeyCode: function(event) {
      // if( (event.ctrlKey === true && (event.keyCode === 78 || event.keyCode === 82)) || (event.keyCode === 116) ) {
      //   event.preventDefault()
      // }
      event.preventDefault()
    },
    fnTimeCheck: async function () {
      const video = this.$refs.video
      let currentTime = Number(video.currentTime.toFixed(0)) // 현재 시간
      let endTime = Number(video.duration.toFixed(0)) // 종료시간
      if (this.$Util.isEmpty(currentTime)) return false
      currentTime = Math.floor(currentTime)
      // 기존영업자 수강일 막기
      // const nowDate = this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      // const payEndDate = this.$moment(new Date().getFullYear() + '-02-28 23:58:00').format('YYYY-MM-DD HH:mm:ss')
      // if (this.form.edu.salesType === 2 && nowDate >= payEndDate) {
      //   video.pause()
      //   alert(`2021년도 기존영업자 교육이 종료되었습니다.`)
      //   window.clearInterval(this.study)
      //   window.close()
      //   return false
      // }
      // currentTime, endTime 값 비교 추가 (서남아이에스 임현아, 2024-04-17)
      if((currentTime > 0 && !isNaN(currentTime)) && (endTime > 0 && !isNaN(endTime))) {
        if (Number(currentTime) + 2 >= endTime) {
          this.status = 1
          this.isStart = 1
          video.pause()
          const res = await this.fnEndUpdate()
          if (res.code === 1) {
            alert('영상이 종료되었습니다.')
            window.clearInterval(this.study)
            this.$cookies.remove('studying')
            window.close()
            return false
          } else {
            this.status = 2
            this.isStart = 1
            video.play()
          }
        }
      }
      if ((currentTime%(1 * 60)) === 0 && currentTime > 0 && this.pauseTime !== currentTime) {
        this.pauseTime = currentTime
        await this.fnUpdate()
      }
      if ((currentTime%this.pausedInt) === 0 && currentTime > 0 && this.pauseTime !== currentTime) {
        this.pauseTime = currentTime
        video.pause()
        // 중간 단위 변경 (서남아이에스 임현아, 2024-10-18)
        const check = !confirm('40분 단위 중간체크입니다.\n PLAY 버튼을 다시 눌러 시청하시기 바랍니다.')
        if (check) {
          this.isStart = 0
          await this.fnUpdate()
          video.pause()
          window.clearInterval(this.study)
          this.$cookies.remove('studying')
          window.close()
        } else {
          video.play()
          await this.fnUpdate()
        }
      }
    },
    fnUpdate: async function () {
      await this.$UserSvc.userExtendSession()
      
      const user = this.$store.getters['user/getUserData']

      if (this.$Util.isEmpty(user)) {
        alert('로그인이 필요한 서비스입니다.')
        window.close()
        return false
      }

      const video = this.$refs.video
      const studyTime = video.currentTime.toFixed(0)
      const params = {}
      if (this.isStart === 0) {
        params.studyTime = studyTime
      } else {
        params.studyTime = studyTime
      }
      params.ipAddress = this.ip
      params.onlineClassNo = this.form.info.onlineClassNo
      params.status = this.status
      params.isStart = this.isStart
      params.onlineEduNo = this.$route.query.onlineEduNo
      await this.$UserOnlineSvc.updateEduStudyTime(params)
    },
    fnEndUpdate: async function () {
      const video = this.$refs.video
      const studyTime = video.duration.toFixed(0)
      const params = {}
      params.studyTime = studyTime
      params.ipAddress = this.ip
      params.onlineClassNo = this.form.info.onlineClassNo
      params.status = this.status
      params.isStart = this.isStart
      params.onlineEduNo = this.$route.query.onlineEduNo
      const res = await this.$UserOnlineSvc.updateEduStudyTime(params)
      return res
    },
    fnMaxSize: function () {
      const videoContainer = this.$refs.container
      this.isFullScreen = !this.isFullScreen
      if (this.isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        if (videoContainer.requestFullscreen) { // edge
          videoContainer.requestFullscreen()
        } else if (videoContainer.webkitendfullscreen) {
          videoContainer.webkitEnterFullScreen()
        } else if (videoContainer.mozRequestFullScreen) { //FF
          videoContainer.mozRequestFullScreen()
        } else if (videoContainer.webkitRequestFullscreen) { //chrome opera
          videoContainer.webkitRequestFullscreen()
        } else if(videoContainer.msRequestFullscreen){ //ie
          videoContainer.msRequestFullscreen()
        } else if(videoContainer.msRequestFullscreen){ //ie
          videoContainer.msRequestFullscreen()
        }
      }
    },
    fnShow: function () {
      this.hide = false
      if (this.mouse) {
        return false
      }
      setTimeout(()=> {
        this.hide = true
      }, 5000)
    },
    fnControllShow: function () {
      this.hide = false
      this.mouse = true
    },
    fnControllHide: function () {
      this.mouse = false
    }
  }
}
</script>

<style scoped>

</style>
