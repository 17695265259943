<template>
  <div id="scroll_top">
    <header-bar
      v-bind:layoutState="layoutState"
      v-bind:userDataState="userDataState"
    ></header-bar>
    <!-- 홈페이지 유지보수 개발요청안 | 사이드 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
    <div v-if ="this.$router.currentRoute.path !== '/payment'">
      <side-banner v-bind:isURL="this.$router.currentRoute.path"></side-banner>
    </div>
    <router-view :key="$route.path" />
    <!-- 홈페이지 유지보수 개발요청안 | 푸터 배너 표출 (서남아이에스 임현아, 2024-07-20) -->
    <div v-if ="this.$router.currentRoute.path !== '/payment'">
      <bottom-banner v-bind:isURL="this.$router.currentRoute.path"></bottom-banner>
    </div>
  </div>
</template>
<script>
import HeaderBar from '@/layouts/HeaderBar'
import BottomBanner from '../components/common/banner/BottomBanner.vue'
import SideBanner from '../components/common/banner/SideBanner.vue'

export default {
  name: 'ContentView',
  components: {
    'header-bar': HeaderBar,
    'bottom-banner': BottomBanner,
    'side-banner': SideBanner
  },
  computed: {
    layoutState: function() {
      return this.$store.getters['layout/getLayout']
    },
    userDataState: function() {
      return this.$store.getters['user/getUserData']
    }
  },
  data() {
    return {
      keepAlive: [],
      transitionName: '',
      transitionMode: '',
      toPage: '',
      fromPage: '',
      isNoPage: false
    }
  },
  watch: {
    $route(to, from) {
      this.isNoPage = false
      this.toPage = to.name
      this.fromPage = from.name

      this.transitionName = 'fade2'
      // this.transitionName = toPage === fromPage ? 'fade2' : toPage > fromPage ? 'next' : 'prev'
      // this.transitionName = toPage > fromPage ? 'next' : 'prev'
      this.transitionMode = 'out-in'

      const user = this.$store.getters['user/getUserData']
      if (!this.$Util.isEmpty(user) || user.length > 0) {
        // 동시 로그인 방지
        const params = {}
        params.applicationId = 'edu'
        this.$UserSvc.userLoginSessionCheck(params).then(response => {
        if (response.code === -1014) {  
          const params = {}
          params.applicationId = 'edu'
          this.$UserSvc.logout(params)
          this.$router.push({ path: '/main' })
        }
       })
      }
    }
  },
  methods: {
    transition: function() {
      // this.isNoPage = (this.toPage === 'main-store-view' || this.fromPage === 'main-store-view')
    }
  }
}
</script>
<style lang="scss">
.loader-enter-active .loader-leave-active {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #fff;
  z-index: 100;
  transition: all 1s;
}
.loader-enter .loader-leave-to {
  opacity: 0;
  z-index: -1;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: all 0.3s;
}
.fade2-enter,
.fade2-leave-to {
  opacity: 0;
}

.page1 {
  min-height: 100%;
  display: grid;
  grid-template: 'main';
  flex: 1;
  background-color: white;
  position: relative;
  /*overflow-x: hidden;*/
}
.page1.noPage {
  display: block;
  /*overflow-x: hidden;*/
}

.page1 > * {
  grid-area: main;
  position: relative;
}
/*.page1 > *:not(.login) {*/
/*  background-color: white;*/
/*}*/
.pos > .inner {
  background-color: white;
}

.page1 > :first-child {
  z-index: 2004; /* Prevent flickering on first frame when transition classes not added yet */
}

/*.next-leave-to {*/
/*  animation: leaveToLeft 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);*/
/*  z-index: 0;*/
/*}*/

/*.next-enter-to {*/
/*  animation: enterFromRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);*/
/*  z-index: 2005;*/
/*}*/

/*.prev-leave-to {*/
/*  animation: leaveToRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);*/
/*  z-index: 2005;*/
/*}*/

/*.prev-enter-to {*/
/*  animation: enterFromLeft 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);*/
/*  z-index: 0;*/
/*}*/

.next-leave-to {
  animation: leaveToTop 800ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 0;
}

.next-enter-to {
  animation: enterFromBottom 800ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 2005;
}

.prev-leave-to {
  animation: leaveToBottom 800ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 2005;
}

.prev-enter-to {
  animation: enterFromTop 800ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 0;
}

@keyframes leaveToLeft {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
    filter: brightness(0.5);
  }
}

@keyframes enterFromLeft {
  from {
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
    filter: brightness(0.5);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes leaveToRight {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes enterFromRight {
  from {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    filter: brightness(0.5);
  }
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes leaveToTop {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
    filter: brightness(0.5);
  }
}

@keyframes enterFromTop {
  from {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
    filter: brightness(0.5);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes leaveToBottom {
  from {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  to {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    filter: brightness(0.5);
  }
}

@keyframes enterFromBottom {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    filter: brightness(0.5);
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>
