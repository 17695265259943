import { render, staticRenderFns } from "./edu-replay-video.vue?vue&type=template&id=52a87179&scoped=true"
import script from "./edu-replay-video.vue?vue&type=script&lang=js"
export * from "./edu-replay-video.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52a87179",
  null
  
)

export default component.exports